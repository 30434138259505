// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Js_exn = require("rescript/lib/js/js_exn.js");
var JwtDecode = require("jwt-decode");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var CS_Make$Util = require("./CS_Make.bs.js");
var S$RescriptSchema = require("rescript-schema/src/S.bs.js");
var Caml_js_exceptions = require("rescript/lib/js/caml_js_exceptions.js");

function asResult(str, opts) {
  try {
    return {
            TAG: "Ok",
            _0: JwtDecode.jwtDecode(str, opts)
          };
  }
  catch (raw_obj){
    var obj = Caml_js_exceptions.internalToOCamlException(raw_obj);
    if (obj.RE_EXN_ID === Js_exn.$$Error) {
      var m = obj._1.message;
      if (m !== undefined) {
        return {
                TAG: "Error",
                _0: m
              };
      } else {
        return {
                TAG: "Error",
                _0: "Unspecified JWT Parse Error"
              };
      }
    }
    throw obj;
  }
}

function getBody(str) {
  return asResult(str, {
              header: false
            });
}

function getHeader(str) {
  return asResult(str, {
              header: true
            });
}

var Impl = {
  asResult: asResult,
  getBody: getBody,
  getHeader: getHeader
};

var partialJWTSchema = S$RescriptSchema.object(function (s) {
      return {
              exp: s.f("exp", S$RescriptSchema.$$int)
            };
    });

function decodeJWT(str) {
  var match = asResult(str, {
        header: true
      });
  var match$1 = asResult(str, {
        header: false
      });
  if (match.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: match._0
          };
  }
  if (match$1.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: match$1._0
          };
  }
  var body = match$1._0;
  var match$2 = S$RescriptSchema.parseOrRaiseWith(body, partialJWTSchema);
  return {
          TAG: "Ok",
          _0: {
            exp: match$2.exp,
            header: match._0,
            body: body
          }
        };
}

var Bindings = {
  Impl: Impl,
  partialJWTSchema: partialJWTSchema,
  decodeJWT: decodeJWT
};

function toPrimitive(a) {
  return a;
}

function primitiveToEl(prim) {
  return prim;
}

function primitiveToJson(prim) {
  return prim;
}

var struct = S$RescriptSchema.refine(S$RescriptSchema.string, (function (s) {
        return function (v) {
          if (Belt_Result.isOk(decodeJWT(v))) {
            return ;
          } else {
            return s.fail("Must be a structurally valid JWT", undefined);
          }
        };
      }));

function meta(t) {
  return Belt_Result.getExn(decodeJWT(t));
}

var include = CS_Make$Util.Stringable.MakeFromMetaStruct({
      toPrimitive: toPrimitive,
      primitiveToEl: primitiveToEl,
      primitiveToJson: primitiveToJson,
      struct: struct,
      QueryVar: CS_Make$Util.QueryVar.$$String,
      meta: meta
    });

var UnverifiedJWT = include;

exports.Bindings = Bindings;
exports.UnverifiedJWT = UnverifiedJWT;
/* partialJWTSchema Not a pure module */
