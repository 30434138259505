// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var AppChip$Thin = require("../../common/atoms/AppChip.bs.js");
var AppAlert$Thin = require("../../common/atoms/AppAlert.bs.js");
var Stack = require("@mui/joy/Stack").default;
var BillPayData$Thin = require("./BillPayData.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var BDInvoiceDetails$Thin = require("../billing-payments/BDInvoiceDetails.bs.js");
var Check = require("@mui/icons-material/Check").default;

function InvoiceComparison$InvoiceSelector(props) {
  var selected = props.selected;
  return JsxRuntime.jsx(Stack, {
              justifyContent: "center",
              alignItems: "center",
              sx: {
                width: "100%",
                height: "100%",
                borderBottom: "2px solid",
                backgroundColor: "background.level1",
                borderColor: selected ? "primary.500" : "transparent",
                cursor: "pointer"
              },
              onClick: props.onChange,
              children: Caml_option.some(JsxRuntime.jsx(Typography, {
                        color: selected ? "primary" : "inherit",
                        level: "body-sm",
                        children: Caml_option.some(props.text)
                      }))
            });
}

var InvoiceSelector = {
  make: InvoiceComparison$InvoiceSelector
};

function InvoiceComparison(props) {
  var error = props.error;
  var draft = props.draft;
  var current = props.current;
  var match = React.useState(function () {
        return "Current";
      });
  var setWhichInvoice = match[1];
  var whichInvoice = match[0];
  var changelog = draft !== undefined ? BillPayData$Thin.getChangelog(current, draft) : undefined;
  var tmp;
  if (draft !== undefined) {
    var tmp$1;
    tmp$1 = whichInvoice === "Current" ? JsxRuntime.jsx(BDInvoiceDetails$Thin.make, {
            isActive: false,
            invoiceContent: current,
            changelog: changelog,
            showPaymentDetails: false,
            draftMode: true
          }) : JsxRuntime.jsx(BDInvoiceDetails$Thin.make, {
            isActive: false,
            invoiceContent: draft,
            showPaymentDetails: false,
            draftMode: true
          });
    tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx(Stack, {
                  sx: {
                    pt: {
                      xs: 2,
                      md: 0
                    },
                    px: {
                      xs: 2,
                      md: 0
                    }
                  },
                  children: Caml_option.some(JsxRuntime.jsx(AppAlert$Thin.make, {
                            message: "These are the changes made to your reservation and their impact on the initial cost. Once accepted, changes cannot be undone. Review carefully before confirming.",
                            type_: "warning"
                          }))
                }),
            JsxRuntime.jsxs(Stack, {
                  direction: "row",
                  spacing: 1,
                  sx: {
                    display: {
                      xs: "none",
                      md: "flex"
                    },
                    overflow: "scroll"
                  },
                  children: [
                    JsxRuntime.jsxs(Stack, {
                          direction: "column",
                          spacing: 1,
                          sx: {
                            p: 2,
                            border: "1px solid",
                            borderColor: "neutral.300",
                            borderRadius: "4px"
                          },
                          children: [
                            JsxRuntime.jsx(AppChip$Thin.make, {
                                  children: "Prior invoice details",
                                  color: "Neutral"
                                }),
                            JsxRuntime.jsx(BDInvoiceDetails$Thin.make, {
                                  isActive: false,
                                  invoiceContent: current,
                                  changelog: changelog,
                                  showPaymentDetails: false,
                                  draftMode: true
                                })
                          ]
                        }),
                    JsxRuntime.jsxs(Stack, {
                          direction: "column",
                          spacing: 1,
                          sx: {
                            p: 2,
                            border: "1px solid",
                            borderColor: "success.700",
                            borderRadius: "4px"
                          },
                          children: [
                            JsxRuntime.jsx(AppChip$Thin.make, {
                                  children: "New invoice details",
                                  startDecorator: Caml_option.some(JsxRuntime.jsx(Check, {})),
                                  color: "Success"
                                }),
                            JsxRuntime.jsx(BDInvoiceDetails$Thin.make, {
                                  isActive: false,
                                  invoiceContent: draft,
                                  showPaymentDetails: false,
                                  draftMode: true
                                })
                          ]
                        })
                  ]
                }),
            JsxRuntime.jsxs(Stack, {
                  direction: "column",
                  spacing: 1,
                  sx: {
                    display: {
                      xs: "flex",
                      md: "none"
                    }
                  },
                  children: [
                    JsxRuntime.jsxs(Joy$Util.Grid.make, {
                          children: [
                            JsxRuntime.jsx(Joy$Util.Grid.make, {
                                  children: Caml_option.some(JsxRuntime.jsx(InvoiceComparison$InvoiceSelector, {
                                            selected: whichInvoice === "Current",
                                            onChange: (function () {
                                                setWhichInvoice(function (param) {
                                                      return "Current";
                                                    });
                                              }),
                                            text: "Prior Invoice"
                                          })),
                                  xs: 6
                                }),
                            JsxRuntime.jsx(Joy$Util.Grid.make, {
                                  children: Caml_option.some(JsxRuntime.jsx(InvoiceComparison$InvoiceSelector, {
                                            selected: whichInvoice === "Draft",
                                            onChange: (function () {
                                                setWhichInvoice(function (param) {
                                                      return "Draft";
                                                    });
                                              }),
                                            text: "New Invoice"
                                          })),
                                  xs: 6
                                })
                          ],
                          container: true,
                          sx: {
                            width: "100%",
                            height: "3.5rem"
                          }
                        }),
                    JsxRuntime.jsx(Stack, {
                          sx: {
                            p: 2
                          },
                          children: Caml_option.some(tmp$1)
                        })
                  ]
                })
          ]
        });
  } else {
    tmp = JsxRuntime.jsx(AppAlert$Thin.make, {
          message: "No updates have been made to this booking.",
          type_: "warning"
        });
  }
  return JsxRuntime.jsxs(Stack, {
              spacing: 2,
              sx: {
                p: {
                  xs: 0,
                  md: 2
                }
              },
              children: [
                error !== undefined ? JsxRuntime.jsx(Stack, {
                        sx: {
                          p: {
                            xs: 2,
                            md: 0
                          }
                        },
                        children: Caml_option.some(JsxRuntime.jsx(AppAlert$Thin.make, {
                                  message: error,
                                  type_: "error"
                                }))
                      }) : null,
                tmp
              ]
            });
}

var make = InvoiceComparison;

exports.InvoiceSelector = InvoiceSelector;
exports.make = make;
/* react Not a pure module */
