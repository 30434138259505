// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Common$Thin = require("../../../utils/Common.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var ClaimTransferWithToken_ClaimTransferWithTokenMutation_graphql$Thin = require("../../../__generated__/ClaimTransferWithToken_ClaimTransferWithTokenMutation_graphql.bs.js");

var convertVariables = ClaimTransferWithToken_ClaimTransferWithTokenMutation_graphql$Thin.Internal.convertVariables;

var convertResponse = ClaimTransferWithToken_ClaimTransferWithTokenMutation_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = ClaimTransferWithToken_ClaimTransferWithTokenMutation_graphql$Thin.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, ClaimTransferWithToken_ClaimTransferWithTokenMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, ClaimTransferWithToken_ClaimTransferWithTokenMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var ClaimTransferWithTokenMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation() {
  var match = use();
  var mutate = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var setPassword = match$1[1];
  var password = match$1[0];
  var match$2 = React.useState(function () {
        return "";
      });
  var setConfirmPassword = match$2[1];
  var confirmPassword = match$2[0];
  var match$3 = React.useState(function () {
        return "";
      });
  var setEmail = match$3[1];
  var email = match$3[0];
  var match$4 = React.useState(function () {
        
      });
  var setError = match$4[1];
  var match$5 = React.useState(function () {
        
      });
  var setTransferSuccess = match$5[1];
  var onSubmit = function (e, t) {
    e.preventDefault();
    if (password === "" || confirmPassword === "" || password !== confirmPassword || email === "" || !Common$Thin.Email.isValid(email)) {
      return ;
    } else {
      setError(function (param) {
            
          });
      mutate({
            input: {
              allegedToken: t,
              newEmail: email,
              newPasswd: password
            }
          }, undefined, undefined, (function (store, param) {
              store.invalidateStore();
            }), (function (resp, err) {
              if (err === undefined) {
                var match = resp.claimTransferWithToken;
                var exit = 0;
                if (match !== undefined) {
                  var match$1 = match.claimTransferInvitationResult;
                  if (match$1 !== undefined) {
                    var verify = match$1.needsVerification;
                    if (verify !== undefined) {
                      var match$2 = match$1.success;
                      if (match$2 !== undefined && match$2) {
                        if (verify) {
                          setTransferSuccess(function (param) {
                                return "Verify";
                              });
                        } else {
                          setTransferSuccess(function (param) {
                                return "Login";
                              });
                        }
                        setEmail(function (param) {
                              return "";
                            });
                      } else {
                        exit = 1;
                      }
                    } else {
                      exit = 1;
                    }
                  } else {
                    exit = 1;
                  }
                } else {
                  exit = 1;
                }
                if (exit === 1) {
                  setError(function (param) {
                        return "An error occurred claiming transfer 1";
                      });
                }
                
              } else {
                setError(function (param) {
                      return "An error occurred claiming transfer 2";
                    });
              }
              setPassword(function (param) {
                    return "";
                  });
              setConfirmPassword(function (param) {
                    return "";
                  });
            }), (function (err) {
              console.log(err);
              setError(function (param) {
                    return "An error occurred claiming transfer 3";
                  });
              setPassword(function (param) {
                    return "";
                  });
              setConfirmPassword(function (param) {
                    return "";
                  });
            }), undefined);
      return ;
    }
  };
  return {
          isMutating: match[1],
          email: email,
          setEmail: setEmail,
          password: password,
          setPassword: setPassword,
          confirmPassword: confirmPassword,
          setConfirmPassword: setConfirmPassword,
          onSubmit: onSubmit,
          error: match$4[0],
          transferSuccess: match$5[0]
        };
}

exports.ClaimTransferWithTokenMutation = ClaimTransferWithTokenMutation;
exports.useMutation = useMutation;
/* commitMutation Not a pure module */
