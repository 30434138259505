// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Float = require("rescript/lib/js/belt_Float.js");

function fromArray(a) {
  return new URLSearchParams(Object.fromEntries(a));
}

function getValFromKey(url, key) {
  var searchParams = new URLSearchParams(url.search);
  if (key === "expiry") {
    var expiry = searchParams.get("expiry");
    if (expiry == null) {
      return ;
    }
    var expiry$1 = Belt_Float.fromString(expiry);
    if (expiry$1 !== undefined) {
      return {
              TAG: "Number",
              _0: expiry$1
            };
    } else {
      return ;
    }
  }
  var value = searchParams.get(key);
  if (!(value == null)) {
    return {
            TAG: "String",
            _0: value
          };
  }
  
}

var Dict;

var Iterator;

exports.Dict = Dict;
exports.Iterator = Iterator;
exports.fromArray = fromArray;
exports.getValFromKey = getValFromKey;
/* No side effect */
