// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var JsxRuntime = require("react/jsx-runtime");

function WaveLoader(props) {
  var __speed = props.speed;
  var __height = props.height;
  var __width = props.width;
  var width = __width !== undefined ? __width : 100;
  var height = __height !== undefined ? __height : 100;
  var speed = __speed !== undefined ? __speed : 2000;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("svg", {
                    children: [
                      JsxRuntime.jsx("path", {
                            d: "M0,5 C20,-10 40,20 60,5 v5 H0"
                          }),
                      JsxRuntime.jsx("path", {
                            d: "M0,5 C20,-10 40,20 60,5 v5 H0",
                            transform: "translate(60)"
                          })
                    ],
                    style: {
                      bottom: "0",
                      position: "absolute",
                      animation: "wave-shift " + String(speed) + "ms linear infinite"
                    },
                    height: "80",
                    width: "960",
                    fill: "#FF690F",
                    viewBox: "0 0 120 10",
                    xmlns: "http://www.w3.org/2000/svg"
                  }),
              style: {
                border: "2px solid transparent",
                height: String(height) + "px",
                overflow: "hidden",
                position: "relative",
                width: String(width) + "px",
                borderRadius: "100px",
                boxShadow: "0 0 0 4px #000"
              }
            });
}

var make = WaveLoader;

exports.make = make;
/* react/jsx-runtime Not a pure module */
