// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var AppAlert$Thin = require("../common/atoms/AppAlert.bs.js");
var Stack = require("@mui/joy/Stack").default;
var AppDrawer$Thin = require("../common/molecules/AppDrawer.bs.js");
var Button = require("@mui/joy/Button").default;
var ReactState$Util = require("util/src/ReactState.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var UpdateAccountData$Thin = require("../common/mutations/UpdateAccountData.bs.js");

function CAUpdateAccountDetailsDrawer(props) {
  var refetch = props.refetch;
  var u = props.u;
  var onClose = props.onClose;
  var countryFrag = props.countryFrag;
  var match = React.useState(function () {
        return false;
      });
  var setShowVoyageOptions = match[1];
  var initial = UpdateAccountData$Thin.Utils.convertUserAccountToUpdate(u);
  var match$1 = ReactState$Util.useDebouncedState((function () {
          return {
                  TAG: "Account",
                  _0: initial
                };
        }), undefined, undefined);
  var match$2 = match$1.state;
  var setUpdate = match$2[1];
  var update = match$2[0];
  var onSuccess = function (param) {
    setShowVoyageOptions(function (param) {
          return false;
        });
    onClose();
    refetch();
  };
  var match$3 = UpdateAccountData$Thin.useMutation(onSuccess);
  var updateUserAccount = match$3.updateUserAccount;
  var setError = match$3.setError;
  var error = match$3.error;
  var tmp;
  if (update.TAG === "Account") {
    var update$1 = update._0;
    tmp = UpdateAccountData$Thin.Utils.areUserAccountUpdatesEqual(initial, update$1) || Belt_Result.isError(UpdateAccountData$Thin.Utils.convertUpdateToMutationInput(update$1, "Connected"));
  } else {
    tmp = true;
  }
  var tmp$1;
  if (update.TAG === "Account") {
    var update$2 = update._0;
    tmp$1 = UpdateAccountData$Thin.Utils.areUserAccountUpdatesEqual(initial, update$2) || Belt_Result.isError(UpdateAccountData$Thin.Utils.convertUpdateToMutationInput(update$2, "Connected"));
  } else {
    tmp$1 = true;
  }
  return JsxRuntime.jsxs(AppDrawer$Thin.make, {
              open_: props.open_,
              title: "Edit " + props.fullName + "'s information",
              onClose: (function () {
                  onClose();
                  setShowVoyageOptions(function (param) {
                        return false;
                      });
                }),
              children: [
                JsxRuntime.jsx(DialogContent, {
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                direction: "column",
                                spacing: 2,
                                sx: {
                                  p: 2
                                },
                                children: [
                                  JsxRuntime.jsx(Stack, {
                                        direction: "column",
                                        sx: {
                                          p: 2,
                                          border: "1px solid",
                                          borderColor: "neutral.300",
                                          borderRadius: "4px"
                                        },
                                        children: Caml_option.some(JsxRuntime.jsx(UpdateAccountData$Thin.Form.Personal.make, {
                                                  fragmentRefs: countryFrag,
                                                  update: update,
                                                  setUpdate: setUpdate,
                                                  accountType: "Connected",
                                                  variant: "Gray",
                                                  disabled: false,
                                                  forceValidate: true
                                                }))
                                      }),
                                  JsxRuntime.jsx(Stack, {
                                        direction: "column",
                                        sx: {
                                          p: 2,
                                          border: "1px solid",
                                          borderColor: "neutral.300",
                                          borderRadius: "4px"
                                        },
                                        children: Caml_option.some(JsxRuntime.jsx(UpdateAccountData$Thin.Form.Travel.make, {
                                                  fragmentRefs: countryFrag,
                                                  update: update,
                                                  setUpdate: setUpdate,
                                                  userAccount: u,
                                                  variant: "Gray",
                                                  disabled: false,
                                                  forceValidate: true
                                                }))
                                      }),
                                  JsxRuntime.jsx(UpdateAccountData$Thin.Form.Voyages.make, {
                                        fragmentRefs: props.fragmentRefs,
                                        showVoyageOptions: match[0],
                                        setShowVoyageOptions: setShowVoyageOptions,
                                        update: update,
                                        setUpdate: setUpdate,
                                        display: true
                                      }),
                                  error !== undefined ? JsxRuntime.jsx(AppAlert$Thin.make, {
                                          message: error,
                                          type_: "error"
                                        }) : null
                                ]
                              }))
                    }),
                JsxRuntime.jsx(DialogActions, {
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                direction: {
                                  xs: "column-reverse",
                                  sm: "row"
                                },
                                component: "form",
                                justifyContent: "space-between",
                                spacing: 1,
                                sx: {
                                  width: "100%",
                                  p: 2
                                },
                                onSubmit: (function (e) {
                                    e.preventDefault();
                                    if (update.TAG !== "Account") {
                                      return ;
                                    }
                                    var input = UpdateAccountData$Thin.Utils.convertUpdateToMutationInput(update._0, "Connected");
                                    if (input.TAG === "Ok") {
                                      return updateUserAccount(input._0);
                                    }
                                    
                                  }),
                                disabled: tmp,
                                children: [
                                  JsxRuntime.jsx(Button, {
                                        onClick: (function (param) {
                                            setError(function (param) {
                                                  
                                                });
                                            setShowVoyageOptions(function (param) {
                                                  return false;
                                                });
                                            onClose();
                                            setUpdate(function (param) {
                                                  return {
                                                          TAG: "Account",
                                                          _0: initial
                                                        };
                                                });
                                          }),
                                        variant: "outlined",
                                        color: "neutral",
                                        children: "Cancel"
                                      }),
                                  JsxRuntime.jsx(Button, {
                                        loading: match$3.isMutating,
                                        type: "submit",
                                        disabled: tmp$1,
                                        children: "Save Changes"
                                      })
                                ]
                              }))
                    })
              ]
            });
}

var make = CAUpdateAccountDetailsDrawer;

exports.make = make;
/* react Not a pure module */
