// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var PervasivesU = require("rescript/lib/js/pervasivesU.js");
var Box = require("@mui/joy/Box").default;
var Radio = require("@mui/joy/Radio").default;
var Stack = require("@mui/joy/Stack").default;
var CategoryCard$Thin = require("./CategoryCard.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RadioGroup = require("@mui/joy/RadioGroup").default;
var SalesFlowState$Thin = require("../../../utils/sales-flow/SalesFlowState.bs.js");
var SalesFlowLoginModal$Thin = require("./SalesFlowLoginModal.bs.js");

function CategoryList(props) {
  var setPricingMode = props.setPricingMode;
  var pricingMode = props.pricingMode;
  var clearPaymentOption = props.clearPaymentOption;
  var sessionData = props.sessionData;
  var voyageSlug = props.voyageSlug;
  var signOnStatus = props.signOnStatus;
  var urlState = props.urlState;
  var url = props.url;
  var flow = props.flow;
  var occupancy = props.occupancy;
  var match = React.useState(function () {
        return false;
      });
  var setLoginModalOpen = match[1];
  var displayTotal;
  switch (pricingMode) {
    case "Total price" :
        displayTotal = true;
        break;
    default:
      displayTotal = false;
  }
  console.log(pricingMode + " and " + PervasivesU.string_of_bool(displayTotal));
  var nextTarget;
  switch (flow) {
    case "ByCategory" :
        nextTarget = SalesFlowState$Thin.ByCategory.nextNavTarget("CategorySelection");
        break;
    case "AllNoCabin" :
        nextTarget = SalesFlowState$Thin.ByAllNoCabin.nextNavTarget("CategorySelection");
        break;
    case "All" :
        nextTarget = SalesFlowState$Thin.ByAll.nextNavTarget("CategorySelection");
        break;
    
  }
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Stack, {
                      spacing: 2,
                      children: [
                        JsxRuntime.jsx(Stack, {
                              direction: "row",
                              justifyContent: "space-between",
                              spacing: 1,
                              sx: {
                                backgroundColor: "background.surface",
                                border: "1px solid",
                                borderColor: "neutral.200",
                                borderRadius: "8px",
                                width: "fit-content"
                              },
                              children: Caml_option.some(JsxRuntime.jsx(Box, {
                                        children: Caml_option.some(JsxRuntime.jsx(RadioGroup, {
                                                  orientation: "horizontal",
                                                  "aria-labelledby": "segmented-controls-example",
                                                  name: "pricemode",
                                                  value: pricingMode,
                                                  onChange: (function (ev) {
                                                      var newVal = ev.currentTarget.value;
                                                      setPricingMode(function (param) {
                                                            return newVal;
                                                          });
                                                    }),
                                                  sx: {
                                                    minHeight: 36,
                                                    padding: "4px",
                                                    borderRadius: "12px",
                                                    bgcolor: "background.surface",
                                                    "--RadioGroup-gap": "4px",
                                                    "--Radio-actionRadius": "8px"
                                                  },
                                                  size: "sm",
                                                  children: Caml_option.some(Belt_Array.map([
                                                            "Total price",
                                                            "Price per berth"
                                                          ], (function (item) {
                                                              return JsxRuntime.jsx(Radio, {
                                                                          color: "neutral",
                                                                          disableIcon: true,
                                                                          value: item,
                                                                          label: item,
                                                                          slotProps: {
                                                                            action: {
                                                                              sx: {
                                                                                bgcolor: pricingMode === item ? "primary.500" : "",
                                                                                "&:hover": {
                                                                                  bgcolor: pricingMode === item ? "primary.500" : ""
                                                                                }
                                                                              }
                                                                            },
                                                                            label: {
                                                                              sx: {
                                                                                color: pricingMode === item ? "neutral.softBg" : ""
                                                                              }
                                                                            }
                                                                          },
                                                                          sx: {
                                                                            px: 2,
                                                                            alignItems: "center"
                                                                          },
                                                                          variant: "plain"
                                                                        }, item);
                                                            })))
                                                })),
                                        sx: {
                                          display: "flex",
                                          alignItems: "center",
                                          gap: 2
                                        }
                                      }))
                            }),
                        Belt_Array.mapWithIndex(props.categories, (function (i, c) {
                                return JsxRuntime.jsx(CategoryCard$Thin.make, {
                                            occupancy: occupancy,
                                            flow: flow,
                                            signOnStatus: signOnStatus,
                                            c: c,
                                            displayTotal: displayTotal,
                                            setLoginModalOpen: setLoginModalOpen,
                                            nextTarget: nextTarget,
                                            url: url,
                                            urlState: urlState,
                                            voyageSlug: voyageSlug,
                                            sessionData: sessionData,
                                            clearPaymentOption: clearPaymentOption
                                          }, String(i));
                              }))
                      ]
                    }),
                JsxRuntime.jsx(SalesFlowLoginModal$Thin.make, {
                      modalOpen: match[0],
                      onClose: (function () {
                          setLoginModalOpen(function (param) {
                                return false;
                              });
                        }),
                      urlState: urlState,
                      url: url,
                      nextTarget: nextTarget,
                      brandFamily: props.brandFamily,
                      refetchSignOnStatus: props.refetchSignOnStatus,
                      voyageSlug: voyageSlug,
                      flow: flow,
                      refetchCurrentUserInfo: props.refetchCurrentUserInfo
                    })
              ]
            });
}

var make = CategoryList;

exports.make = make;
/* react Not a pure module */
