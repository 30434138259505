// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Js_exn = require("rescript/lib/js/js_exn.js");
var Http$Util = require("util/src/Http.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var ReactState$Util = require("util/src/ReactState.bs.js");
var RemoteData$Util = require("util/src/RemoteData.bs.js");
var S$RescriptSchema = require("rescript-schema/src/S.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Caml_js_exceptions = require("rescript/lib/js/caml_js_exceptions.js");
var Client = require("braintree-web/client");
var CS_UnverifiedJWT$Util = require("util/src/CustomScalars/CS_UnverifiedJWT.bs.js");
var Processor_Shared$Thin = require("./Processor_Shared.bs.js");
var HostedFields = require("braintree-web/hosted-fields");
var Processor_PaymentIntent$Thin = require("./Processor_PaymentIntent.bs.js");
var DataCollector = require("braintree-web/data-collector");
var Processor_TokenizationIntent$Thin = require("./Processor_TokenizationIntent.bs.js");

var mapError = Processor_Shared$Thin.BraintreeHostedFields.mapError;

var clientToken = Processor_Shared$Thin.BraintreeHostedFields.clientToken;

var braintreePaymentInit = Processor_Shared$Thin.BraintreeHostedFields.braintreePaymentInit;

var urlmap = Belt_MapString.fromArray([
      [
        "Visa",
        "https://www.braintreepayments.com/images/visa-logo.svg"
      ],
      [
        "Mastercard",
        "https://www.braintreepayments.com/images/mastercard-logo.svg"
      ],
      [
        "JCB",
        "https://www.braintreepayments.com/images/jcb-logo.svg"
      ],
      [
        "Discover",
        "https://www.braintreepayments.com/images/discover-logo.svg"
      ],
      [
        "American Express",
        "https://www.braintreepayments.com/images/amex-logo.svg"
      ],
      [
        "UnionPay",
        "https://www.braintreepayments.com/images/unionpay-logo.svg"
      ]
    ]);

var Img = {
  urlmap: urlmap
};

var Impl = {};

async function createClient(clientToken) {
  try {
    var client = await Client.create({
          authorization: clientToken
        });
    return {
            TAG: "Success",
            _0: client
          };
  }
  catch (raw_obj){
    var obj = Caml_js_exceptions.internalToOCamlException(raw_obj);
    if (obj.RE_EXN_ID === Js_exn.$$Error) {
      console.error(obj._1);
      return {
              TAG: "Failure",
              _0: "Could not load Braintree Client"
            };
    }
    throw obj;
  }
}

async function createHostedFields(dict) {
  try {
    var hostedFields = await HostedFields.create(dict);
    return {
            TAG: "Success",
            _0: hostedFields
          };
  }
  catch (raw_obj){
    var obj = Caml_js_exceptions.internalToOCamlException(raw_obj);
    if (obj.RE_EXN_ID === Js_exn.$$Error) {
      console.error(obj._1);
      return {
              TAG: "Failure",
              _0: "Could not load Braintree Hosted Fields"
            };
    }
    throw obj;
  }
}

async function createDataCollector(braintreeClient) {
  try {
    var dataCollector = await DataCollector.create({
          client: braintreeClient
        });
    return {
            TAG: "Success",
            _0: dataCollector
          };
  }
  catch (raw_obj){
    var obj = Caml_js_exceptions.internalToOCamlException(raw_obj);
    if (obj.RE_EXN_ID === Js_exn.$$Error) {
      console.error(obj._1);
      return {
              TAG: "Failure",
              _0: "Could not load Braintree Data Collector"
            };
    }
    throw obj;
  }
}

function getSupportedCardTypes(hostedFieldsInstance) {
  return new Promise((function (resolve, param) {
                hostedFieldsInstance.getSupportedCardTypes(function (err, payload) {
                      if (err !== null) {
                        return resolve({
                                    TAG: "Failure",
                                    _0: mapError(err)
                                  });
                      } else if (payload !== null) {
                        return resolve({
                                    TAG: "Success",
                                    _0: Belt_Array.map(payload, (function (str) {
                                            return {
                                                    niceName: str,
                                                    imgUrl: Belt_MapString.get(urlmap, str)
                                                  };
                                          }))
                                  });
                      } else {
                        return resolve({
                                    TAG: "Failure",
                                    _0: mapError({
                                          code: "UNMAPPED_GENERAL_ERROR",
                                          message: "no payload and no error",
                                          details: {}
                                        })
                                  });
                      }
                    });
              }));
}

async function load(clientToken, styles, fields) {
  return await RemoteData$Util.$$Promise.then(RemoteData$Util.$$Promise.then(RemoteData$Util.$$Promise.then(createClient(clientToken), (function (client) {
                        return RemoteData$Util.$$Promise.mapSuccess(createHostedFields({
                                        client: client,
                                        fields: fields,
                                        styles: styles
                                      }), (function (hostedFields) {
                                      return [
                                              client,
                                              hostedFields
                                            ];
                                    }));
                      })), (function (param) {
                    var hostedFields = param[1];
                    var client = param[0];
                    return RemoteData$Util.$$Promise.mapSuccess(createDataCollector(client), (function (dataCollector) {
                                  return [
                                          client,
                                          hostedFields,
                                          dataCollector
                                        ];
                                }));
                  })), (function (param) {
                var dataCollector = param[2];
                var hostedFields = param[1];
                var client = param[0];
                return RemoteData$Util.$$Promise.mapSuccess(RemoteData$Util.$$Promise.mapFailure(getSupportedCardTypes(hostedFields), (function (param) {
                                  return "Unable to load supported Card Types from Braintree";
                                })), (function (supportedCardTypes) {
                              return [
                                      client,
                                      hostedFields,
                                      dataCollector,
                                      supportedCardTypes
                                    ];
                            }));
              }));
}

function tokenize(hostedFieldsInstance) {
  return new Promise((function (resolve, param) {
                hostedFieldsInstance.tokenize(function (err, payload) {
                      if (err !== null) {
                        return resolve({
                                    TAG: "Failure",
                                    _0: mapError(err)
                                  });
                      } else if (payload !== null) {
                        return resolve({
                                    TAG: "Success",
                                    _0: payload.nonce
                                  });
                      } else {
                        return resolve({
                                    TAG: "Failure",
                                    _0: mapError({
                                          code: "UNMAPPED_GENERAL_ERROR",
                                          message: "no payload and no error",
                                          details: {}
                                        })
                                  });
                      }
                    });
              }));
}

function teardown(hostedFieldsInstance) {
  return new Promise((function (resolve, param) {
                hostedFieldsInstance.teardown(function (teardownSuccess) {
                      if (teardownSuccess) {
                        console.log("Hosted fields torn down");
                      } else {
                        console.error("Hosted fields could not be torn down");
                      }
                      resolve(teardownSuccess);
                    });
              }));
}

var Bindings = {
  Img: Img,
  Impl: Impl,
  createClient: createClient,
  createHostedFields: createHostedFields,
  createDataCollector: createDataCollector,
  getSupportedCardTypes: getSupportedCardTypes,
  load: load,
  tokenize: tokenize,
  teardown: teardown
};

function getClientTokenEffect(endpointUrl, tokenizationIntent, state) {
  return Http$Util.statefulJsonRequest(state, endpointUrl + "/token", "GET", undefined, [[
                "authorization",
                "Bearer " + CS_UnverifiedJWT$Util.UnverifiedJWT.toPrimitive(tokenizationIntent)
              ]], clientToken);
}

function getRunBraintreePaymentEffectProm(endpointUrl, paymentIntent, bti) {
  return Http$Util.jsonRequest(endpointUrl + "/" + S$RescriptSchema.parseOrRaiseWith(CS_UnverifiedJWT$Util.UnverifiedJWT.meta(paymentIntent).body, Processor_Shared$Thin.paymentIntentMethod), "POST", [
              bti,
              braintreePaymentInit
            ], [[
                "authorization",
                "Bearer " + CS_UnverifiedJWT$Util.UnverifiedJWT.toPrimitive(paymentIntent)
              ]], Processor_Shared$Thin.paymentReturn);
}

var RestAPI = {
  getClientTokenEffect: getClientTokenEffect,
  getRunBraintreePaymentEffectProm: getRunBraintreePaymentEffectProm
};

var cardNumId = "card-number";

var cvvId = "cvv";

var expDateId = "expiration-date";

var cardholderNameId = "cardholder-name";

var cardholderPostalCodeId = "postal-code";

var stylesSettings = {
  input: {
    background: "white",
    "font-size": "1rem",
    color: "#063953"
  }
};

var fieldSettings_number = {
  container: "#" + cardNumId,
  placeholder: "Card Number",
  type: undefined,
  preventAutofill: false
};

var fieldSettings_expirationDate = {
  container: "#" + expDateId,
  placeholder: "Expiration Date",
  type: undefined,
  preventAutofill: false
};

var fieldSettings_cvv = {
  container: "#" + cvvId,
  placeholder: "CVV/Security Code",
  type: undefined,
  preventAutofill: false
};

var fieldSettings_postalCode = {
  container: "#" + cardholderPostalCodeId,
  placeholder: "Billing Postal Code",
  type: undefined,
  preventAutofill: false
};

var fieldSettings_cardholderName = {
  container: "#" + cardholderNameId,
  placeholder: "Cardholder Name",
  type: undefined,
  preventAutofill: false
};

var fieldSettings = {
  number: fieldSettings_number,
  expirationDate: fieldSettings_expirationDate,
  cvv: fieldSettings_cvv,
  postalCode: fieldSettings_postalCode,
  cardholderName: fieldSettings_cardholderName
};

var displayNoneStyle = {
  display: "none"
};

var plainStyle = {};

function Processor_BraintreeHostedFields$Form(props) {
  return JsxRuntime.jsxs("form", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            id: cardholderNameId,
                            tabIndex: 0
                          })
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            id: cardNumId,
                            tabIndex: 0
                          })
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            id: expDateId,
                            tabIndex: 0
                          })
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            id: cvvId,
                            tabIndex: 0
                          })
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            id: cardholderPostalCodeId,
                            tabIndex: 0
                          })
                    })
              ],
              style: props.show ? plainStyle : displayNoneStyle,
              onSubmit: (function (e) {
                  e.preventDefault();
                })
            });
}

var Form = {
  cardNumId: cardNumId,
  cvvId: cvvId,
  expDateId: expDateId,
  cardholderNameId: cardholderNameId,
  cardholderPostalCodeId: cardholderPostalCodeId,
  stylesSettings: stylesSettings,
  fieldSettings: fieldSettings,
  displayNoneStyle: displayNoneStyle,
  plainStyle: plainStyle,
  make: Processor_BraintreeHostedFields$Form
};

function use(onVoyage, processor, method, processorEndpointUrl, getFee, param) {
  var braintreeClientToken = param.braintreeClientToken;
  var paymentIntent = param.paymentIntent;
  var tokenizationIntent = param.tokenizationIntent;
  var myself = React.useState(function () {
        return "NotAsked";
      });
  var shouldLoad = React.useState(function () {
        return false;
      });
  var braintreePaymentNonce = React.useState(function () {
        return "NotAsked";
      });
  var fetchTokenizationIntent = Processor_TokenizationIntent$Thin.useTokenizationIntentMutation(tokenizationIntent);
  var match = ReactState$Util.getter(tokenizationIntent);
  var tmp;
  tmp = typeof match !== "object" ? (
      match === "NotAsked" ? [(function () {
              fetchTokenizationIntent();
            })] : []
    ) : [];
  var match$1 = ReactState$Util.getter(tokenizationIntent);
  var match$2 = ReactState$Util.getter(braintreeClientToken);
  var tmp$1;
  tmp$1 = typeof match$1 !== "object" ? [] : (
      match$1.TAG === "Success" ? (
          typeof match$2 !== "object" ? (
              match$2 === "NotAsked" ? [getClientTokenEffect(processorEndpointUrl, match$1._0, braintreeClientToken)] : []
            ) : []
        ) : []
    );
  var match$3 = ReactState$Util.getter(braintreeClientToken);
  var match$4 = ReactState$Util.getter(myself);
  var tmp$2;
  if (typeof match$3 !== "object") {
    tmp$2 = [];
  } else if (match$3.TAG === "Success") {
    if (typeof match$4 !== "object") {
      if (match$4 === "NotAsked") {
        var braintreeClientToken$1 = match$3._0.tok;
        tmp$2 = [(function () {
              RemoteData$Util.$$Promise.makeStatefulEffect(load(braintreeClientToken$1, stylesSettings, fieldSettings), myself);
            })];
      } else {
        tmp$2 = [];
      }
    } else {
      tmp$2 = [];
    }
  } else {
    tmp$2 = [];
  }
  var loadFx = Belt_Array.concatMany([
        tmp,
        tmp$1,
        tmp$2
      ]);
  React.useEffect((function () {
          var match = ReactState$Util.getter(myself);
          if (typeof match !== "object") {
            return ;
          }
          if (match.TAG !== "Success") {
            return ;
          }
          var hostedFieldsInstance = match._0[1];
          return (function () {
                    teardown(hostedFieldsInstance);
                  });
        }), []);
  React.useEffect(function () {
        var loadFx$1 = ReactState$Util.getter(shouldLoad) ? loadFx : [];
        Belt_Array.forEach(loadFx$1, (function (effect) {
                effect();
              }));
      });
  var paymentIntentEffect = Processor_PaymentIntent$Thin.usePaymentIntentMutationEffect(onVoyage, processor, method, undefined);
  var resetForRetry = function () {
    ReactState$Util.setter(paymentIntent)(function (param) {
          return "NotAsked";
        });
    ReactState$Util.setter(braintreePaymentNonce)(function (param) {
          return "NotAsked";
        });
  };
  var processPayment = function (hostedFields, dataCollector, paymentIntentInvocation) {
    return RemoteData$Util.$$Promise.then(RemoteData$Util.$$Promise.then(RemoteData$Util.$$Promise.mapFailure(tokenize(hostedFields), (function (f) {
                          return {
                                  TAG: "BraintreeHostedFieldsError",
                                  _0: f
                                };
                        })), (function (payment_method_nonce) {
                      var match = paymentIntentEffect(paymentIntentInvocation);
                      return RemoteData$Util.$$Promise.mapFailure(RemoteData$Util.$$Promise.mapSuccess(match[1], (function (paymentIntent) {
                                        return [
                                                payment_method_nonce,
                                                paymentIntent
                                              ];
                                      })), (function (f) {
                                    return {
                                            TAG: "StringError",
                                            _0: f
                                          };
                                  }));
                    })), (function (param) {
                  return RemoteData$Util.$$Promise.mapSuccess(RemoteData$Util.$$Promise.mapFailure(getRunBraintreePaymentEffectProm(processorEndpointUrl, param[1], {
                                      payment_method_nonce: param[0],
                                      device_data: dataCollector.deviceData
                                    }), (function (f) {
                                    return {
                                            TAG: "StringError",
                                            _0: f
                                          };
                                  })), (function (f) {
                                resetForRetry();
                                return f;
                              }));
                }));
  };
  return {
          loaded: RemoteData$Util.mapSuccess(ReactState$Util.getter(myself), (function (param) {
                  var dataCollector = param[2];
                  var hostedFields = param[1];
                  return {
                          processPaymentEffect: (function (state, invocation) {
                              RemoteData$Util.$$Promise.makeStatefulEffect(processPayment(hostedFields, dataCollector, invocation), state);
                            }),
                          supportedCardTypes: param[3]
                        };
                })),
          getFee: getFee,
          loadAfterFormInDOM: (function () {
              ReactState$Util.setter(shouldLoad)(function (param) {
                    return true;
                  });
            })
        };
}

exports.mapError = mapError;
exports.clientToken = clientToken;
exports.braintreePaymentInit = braintreePaymentInit;
exports.Bindings = Bindings;
exports.RestAPI = RestAPI;
exports.Form = Form;
exports.use = use;
/* urlmap Not a pure module */
