// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var DateFns$Util = require("util/src/DateFns.bs.js");
var Chip = require("@mui/joy/Chip").default;
var JsxRuntime = require("react/jsx-runtime");
var TimerOutlined = require("@mui/icons-material/TimerOutlined").default;

function Countdown(props) {
  var cabinHoldExpiry = props.cabinHoldExpiry;
  var match = React.useState(function () {
        if (cabinHoldExpiry === undefined) {
          return ;
        }
        var expiry = Caml_option.valFromOption(cabinHoldExpiry);
        if (!DateFns$Util.hasExpired(CS_Make$Util.Dateable.Plain.toDate(expiry))) {
          return DateFns$Util.getRemainingTime(CS_Make$Util.Dateable.Plain.toDate(expiry));
        }
        
      });
  var setTime = match[1];
  var time = match[0];
  React.useEffect((function () {
          var interval = setInterval((function () {
                  if (cabinHoldExpiry !== undefined) {
                    var expiry = Caml_option.valFromOption(cabinHoldExpiry);
                    if (!DateFns$Util.hasExpired(CS_Make$Util.Dateable.Plain.toDate(expiry))) {
                      var remainingTime = DateFns$Util.getRemainingTime(CS_Make$Util.Dateable.Plain.toDate(expiry));
                      return setTime(function (param) {
                                  return remainingTime;
                                });
                    }
                    
                  }
                  setTime(function (param) {
                        
                      });
                }), 1000);
          return (function () {
                    clearInterval(interval);
                  });
        }), [cabinHoldExpiry]);
  if (time !== undefined) {
    return JsxRuntime.jsx(Chip, {
                children: Caml_option.some(time),
                endDecorator: Caml_option.some(JsxRuntime.jsx(TimerOutlined, {})),
                size: "sm",
                sx: {
                  backgroundColor: "primary.500",
                  color: "white",
                  borderRadius: "4px",
                  py: 0,
                  height: "fit-content",
                  justifyContent: "center"
                }
              });
  } else {
    return null;
  }
}

var make = Countdown;

exports.make = make;
/* react Not a pure module */
