// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"upcoming_nodes_bookingId":{"c":"Util.CustomScalars.BookingId.Exn"},"upcoming_nodes":{"f":""},"publicBrandFamilyVoyage_nodes_voyageSlug":{"c":"Util.CustomScalars.VoyageSlug.Exn"},"publicBrandFamilyVoyage_nodes_voyageName":{"c":"Util.CustomScalars.NonemptyString.Exn"}}};

var fragmentConverterMap = {
  "Util.CustomScalars.NonemptyString.Exn": CS_NonemptyStrings$Util.NonEmptyString.Exn.parse,
  "Util.CustomScalars.VoyageSlug.Exn": CS_Slugs$Util.VoyageSlug.Exn.parse,
  "Util.CustomScalars.BookingId.Exn": CS_NonemptyStrings$Util.BookingId.Exn.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "brandFamily"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "currentDate"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpcomingBookingsSection_query",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "_brandFamilySlug",
          "variableName": "brandFamily"
        }
      ],
      "concreteType": "PublicVoyageInfosConnection",
      "kind": "LinkedField",
      "name": "publicBrandFamilyVoyage",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PublicVoyageInfo",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "voyageName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "voyageSlug",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "upcoming",
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "brandFamilySlug",
              "variableName": "brandFamily"
            },
            {
              "kind": "Literal",
              "name": "lifecycleStatus",
              "value": "ACTIVE"
            }
          ],
          "kind": "ObjectValue",
          "name": "condition"
        },
        {
          "fields": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "greaterThanOrEqualTo",
                  "variableName": "currentDate"
                }
              ],
              "kind": "ObjectValue",
              "name": "voyStartDate"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "BookingListitemsConnection",
      "kind": "LinkedField",
      "name": "bookingListitems",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BookingListitem",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "bookingId",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "UpcomingBookingsSection_listitem"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* CS_Slugs-Util Not a pure module */
