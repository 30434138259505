// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../../../utils/Common.bs.js");
var AppChip$Thin = require("../../common/atoms/AppChip.bs.js");
var AppAlert$Thin = require("../../common/atoms/AppAlert.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var CS_Decimal$Util = require("util/src/CustomScalars/CS_Decimal.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var SalesFlowState$Thin = require("../../../utils/sales-flow/SalesFlowState.bs.js");
var FareClassDrawer$Thin = require("./FareClassDrawer.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var RequestCabinHoldByCategory$Thin = require("../mutations/RequestCabinHoldByCategory.bs.js");
var PeopleOutlined = require("@mui/icons-material/PeopleOutlined").default;
var KeyboardArrowDown = require("@mui/icons-material/KeyboardArrowDown").default;
var SquareFootOutlined = require("@mui/icons-material/SquareFootOutlined").default;

function CategoryCard(props) {
  var clearPaymentOption = props.clearPaymentOption;
  var sessionData = props.sessionData;
  var urlState = props.urlState;
  var url = props.url;
  var nextTarget = props.nextTarget;
  var setLoginModalOpen = props.setLoginModalOpen;
  var displayTotal = props.displayTotal;
  var c = props.c;
  var flow = props.flow;
  var occupancy = props.occupancy;
  var needsFareClassDrawer = c.fareClasses.length > 1;
  var fc = Belt_Array.get(c.fareClasses, 0);
  var firstFc = fc !== undefined ? Caml_option.some(fc.fcSlug) : undefined;
  var match = React.useState(function () {
        return false;
      });
  var setShowMore = match[1];
  var showMore = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setFareDrawerOpen = match$1[1];
  var match$2;
  switch (flow) {
    case "ByCategory" :
        match$2 = [
          SalesFlowState$Thin.ByCategory.CabinTarget.setCat,
          SalesFlowState$Thin.ByCategory.CabinTarget.setFare
        ];
        break;
    case "AllNoCabin" :
        match$2 = [
          SalesFlowState$Thin.ByAllNoCabin.CabinTarget.setCat,
          SalesFlowState$Thin.ByAllNoCabin.CabinTarget.setFare
        ];
        break;
    case "All" :
        match$2 = [
          SalesFlowState$Thin.ByAll.CabinTarget.setCat,
          SalesFlowState$Thin.ByAll.CabinTarget.setFare
        ];
        break;
    
  }
  var setFare = match$2[1];
  var setCat = match$2[0];
  var match$3 = displayTotal ? [
      c.quotePricePerBooking,
      c.referencePricePerBooking
    ] : [
      c.quotePricePerPerson,
      c.referencePricePerPerson
    ];
  var referencePrice = match$3[1];
  var quotePrice = match$3[0];
  var needsToLogIn;
  var tmp = props.signOnStatus;
  needsToLogIn = (tmp === "VERIFY_EMAIL" || tmp === "INVITED" || tmp === "PENDING_2FA" || tmp === "CLEAR_JWT" || tmp === "ANONYMOUS" || tmp === "SIGNED_ON") && tmp === "ANONYMOUS" ? true : false;
  var onCabinHoldSuccess = function (newUrlState) {
    return function (expiry) {
      var redirect = SalesFlowState$Thin.urlString(SalesFlowState$Thin.URLState.serialize(SalesFlowState$Thin.Alter.setNavTarget(SalesFlowState$Thin.Alter.setCabinHold(newUrlState, expiry), nextTarget)), url);
      clearPaymentOption(sessionData);
      RescriptReactRouter.push(redirect);
    };
  };
  var match$4 = RequestCabinHoldByCategory$Thin.useMutation(flow, props.voyageSlug, undefined);
  var cabinHoldError = match$4.error;
  var requestCabinHoldByCategory = match$4.requestCabinHoldByCategory;
  var onClick = function (param) {
    if (needsFareClassDrawer) {
      return setFareDrawerOpen(function (param) {
                  return true;
                });
    }
    if (firstFc === undefined) {
      return ;
    }
    var newUrlState = setFare(setCat(urlState, c.slug), Caml_option.valFromOption(firstFc));
    SalesFlowState$Thin.navigate(SalesFlowState$Thin.URLState.serialize(newUrlState), url);
    if (needsToLogIn) {
      return setLoginModalOpen(function (param) {
                  return true;
                });
    } else {
      return requestCabinHoldByCategory(newUrlState, onCabinHoldSuccess(newUrlState));
    }
  };
  var maxOccupancy = c.catMaxOccupancy;
  var blurb = c.blurb;
  var tmp$1;
  if (blurb !== undefined) {
    var blurb$1 = Caml_option.valFromOption(blurb);
    tmp$1 = CS_NonemptyStrings$Util.NonEmptyString.toString(blurb$1).length > 190 ? JsxRuntime.jsxs(Stack, {
            direction: "column",
            sx: {
              position: "relative"
            },
            children: [
              JsxRuntime.jsx(Typography, {
                    level: "body-sm",
                    sx: {
                      height: "auto",
                      overflow: "hidden",
                      textOverflow: showMore ? "auto" : "ellipsis",
                      display: "-webkit-box",
                      "-webkit-line-clamp": showMore ? "unset" : "2",
                      "-webkit-box-orient": "vertical"
                    },
                    children: Caml_option.some(CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(blurb$1))
                  }),
              JsxRuntime.jsx(Stack, {
                    sx: {
                      cursor: "pointer",
                      pt: "5px"
                    },
                    onClick: (function (e) {
                        e.stopPropagation();
                        setShowMore(function (prev) {
                              return !prev;
                            });
                      }),
                    children: Caml_option.some(JsxRuntime.jsx(Typography, {
                              color: "primary",
                              endDecorator: Caml_option.some(JsxRuntime.jsx(KeyboardArrowDown, {
                                        sx: {
                                          transform: showMore ? "rotate(180deg)" : "rotate(0deg)"
                                        }
                                      })),
                              level: "body-sm",
                              children: Caml_option.some(showMore ? "Show less" : "Show more")
                            }))
                  })
            ]
          }) : JsxRuntime.jsx(Typography, {
            level: "body-sm",
            children: Caml_option.some(CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(blurb$1))
          });
  } else {
    tmp$1 = null;
  }
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Stack, {
                      spacing: 1,
                      sx: {
                        backgroundColor: "background.surface",
                        border: "1px solid",
                        borderColor: "neutral.200",
                        borderRadius: "8px",
                        p: {
                          xs: 2,
                          md: 4
                        }
                      },
                      children: [
                        JsxRuntime.jsxs(Stack, {
                              direction: {
                                xs: "column",
                                md: "row"
                              },
                              spacing: {
                                xs: 6,
                                md: 8
                              },
                              sx: {
                                flex: 1
                              },
                              children: [
                                JsxRuntime.jsxs(Stack, {
                                      direction: "column",
                                      spacing: 2,
                                      sx: {
                                        flex: 1
                                      },
                                      children: [
                                        JsxRuntime.jsxs(Stack, {
                                              direction: "column",
                                              spacing: 1,
                                              children: [
                                                JsxRuntime.jsx(Typography, {
                                                      level: "h4",
                                                      children: Caml_option.some(CS_Slugs$Util.CabinCategorySlug.toPrimitive(c.slug).toUpperCase() + " (" + c.catName + ")")
                                                    }),
                                                JsxRuntime.jsxs(Stack, {
                                                      direction: "row",
                                                      flexWrap: "wrap",
                                                      children: [
                                                        JsxRuntime.jsx(AppChip$Thin.make, {
                                                              children: String(c.catMinSqFoot) + "-" + String(c.catMaxSqFoot) + " SQ FT",
                                                              startDecorator: Caml_option.some(JsxRuntime.jsx(SquareFootOutlined, {}))
                                                            }),
                                                        JsxRuntime.jsx(Stack, {
                                                              sx: {
                                                                width: "0.5rem"
                                                              }
                                                            }),
                                                        JsxRuntime.jsx(AppChip$Thin.make, {
                                                              children: maxOccupancy !== 1 ? "Up to " + String(maxOccupancy) + " guests" : "1 guest",
                                                              startDecorator: Caml_option.some(JsxRuntime.jsx(PeopleOutlined, {}))
                                                            })
                                                      ]
                                                    })
                                              ]
                                            }),
                                        tmp$1
                                      ]
                                    }),
                                JsxRuntime.jsxs(Stack, {
                                      direction: "column",
                                      justifyContent: "flex-end",
                                      sx: {
                                        height: "100%",
                                        width: {
                                          xs: "100%",
                                          md: "fit-content"
                                        },
                                        alignItems: {
                                          xs: "flex-start",
                                          md: "flex-end"
                                        }
                                      },
                                      children: [
                                        JsxRuntime.jsxs(Typography, {
                                              level: "body-xs",
                                              sx: {
                                                color: "text.secondary"
                                              },
                                              children: [
                                                "Price for ",
                                                JsxRuntime.jsx("span", {
                                                      children: occupancy > 1 ? String(occupancy) + " Guests" : "1 Guest",
                                                      style: {
                                                        fontWeight: "900"
                                                      }
                                                    })
                                              ]
                                            }),
                                        JsxRuntime.jsx(Stack, {
                                              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                                        direction: {
                                                          xs: "row",
                                                          md: "column"
                                                        },
                                                        justifyContent: "center",
                                                        sx: {
                                                          alignItems: {
                                                            xs: "flex-start",
                                                            md: "flex-end"
                                                          }
                                                        },
                                                        children: [
                                                          JsxRuntime.jsxs(Stack, {
                                                                direction: "row",
                                                                alignItems: "center",
                                                                children: [
                                                                  needsFareClassDrawer ? JsxRuntime.jsx(Typography, {
                                                                          level: "body-xs",
                                                                          sx: {
                                                                            mr: "0.25rem"
                                                                          },
                                                                          children: "From"
                                                                        }) : null,
                                                                  quotePrice !== undefined ? JsxRuntime.jsx(Typography, {
                                                                          level: "body-lg",
                                                                          children: Caml_option.some(Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(Caml_option.valFromOption(quotePrice))))
                                                                        }) : (
                                                                      CS_Decimal$Util.Decimal10_2.toFloat(referencePrice) !== 0.0 ? JsxRuntime.jsx(Typography, {
                                                                              level: "body-lg",
                                                                              children: Caml_option.some(Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(referencePrice)))
                                                                            }) : null
                                                                    )
                                                                ]
                                                              }),
                                                          JsxRuntime.jsx(Stack, {
                                                                direction: "column",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                sx: {
                                                                  height: "100%",
                                                                  ml: "0.25rem"
                                                                },
                                                                children: Caml_option.some(quotePrice !== undefined && CS_Decimal$Util.Decimal10_2.toFloat(referencePrice) !== 0.0 ? JsxRuntime.jsx(Typography, {
                                                                            color: "danger",
                                                                            level: "body-xs",
                                                                            sx: {
                                                                              textDecoration: "line-through"
                                                                            },
                                                                            children: Caml_option.some(Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(referencePrice)))
                                                                          }) : null)
                                                              })
                                                        ]
                                                      }))
                                            }),
                                        JsxRuntime.jsx(Stack, {
                                              sx: {
                                                my: 1,
                                                width: {
                                                  xs: "100%",
                                                  sm: "fit-content"
                                                }
                                              },
                                              children: Caml_option.some(JsxRuntime.jsx(Button, {
                                                        loading: !needsFareClassDrawer && match$4.isMutating,
                                                        onClick: onClick,
                                                        color: "primary",
                                                        size: "sm",
                                                        children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                  color: "inherit",
                                                                  level: "body-sm",
                                                                  noWrap: true,
                                                                  children: Caml_option.some(needsFareClassDrawer ? "Select Fare" : "Reserve for " + String(occupancy) + " guest" + (
                                                                          occupancy > 1 ? "s" : ""
                                                                        ))
                                                                }))
                                                      }))
                                            })
                                      ]
                                    })
                              ]
                            }),
                        cabinHoldError !== undefined ? JsxRuntime.jsx(AppAlert$Thin.make, {
                                message: cabinHoldError,
                                type_: "error"
                              }) : JsxRuntime.jsx(JsxRuntime.Fragment, {})
                      ]
                    }),
                JsxRuntime.jsx(FareClassDrawer$Thin.make, {
                      drawerOpen: match$1[0],
                      onClose: (function () {
                          setFareDrawerOpen(function (param) {
                                return false;
                              });
                        }),
                      category: c.slug,
                      fareClasses: c.fareClasses,
                      displayTotal: displayTotal,
                      openLoginModal: (function () {
                          setLoginModalOpen(function (param) {
                                return true;
                              });
                        }),
                      needsToLogIn: needsToLogIn,
                      urlState: urlState,
                      url: url,
                      setCat: setCat,
                      setFare: setFare,
                      requestCabinHoldByCategory: requestCabinHoldByCategory,
                      onCabinHoldSuccess: onCabinHoldSuccess
                    })
              ]
            });
}

var make = CategoryCard;

exports.make = make;
/* react Not a pure module */
