"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dispatch = void 0;
var _1 = require("./");
function dispatch(origin, event, data, reply, e) {
    if (!_1.subscribers[origin]) {
        return;
    }
    if (!_1.subscribers[origin][event]) {
        return;
    }
    var args = [];
    if (data) {
        args.push(data);
    }
    if (reply) {
        args.push(reply);
    }
    for (var i = 0; i < _1.subscribers[origin][event].length; i++) {
        _1.subscribers[origin][event][i].apply(e, args);
    }
}
exports.dispatch = dispatch;
