// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var AppAlert$Thin = require("../../common/atoms/AppAlert.bs.js");
var Stack = require("@mui/joy/Stack").default;
var AppButton$Thin = require("../../common/atoms/AppButton.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var SelectorCard$Thin = require("../../common/molecules/SelectorCard.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var Typography = require("@mui/joy/Typography").default;
var SalesFlowState$Thin = require("../../../utils/sales-flow/SalesFlowState.bs.js");
var FormControl = require("@mui/joy/FormControl").default;
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var SendOutlined = require("@mui/icons-material/SendOutlined").default;

function InviteSelector(props) {
  var sessionPax = props.sessionPax;
  var paxNum = props.paxNum;
  var sessionData = props.sessionData;
  var updateSessionAndSave = props.updateSessionAndSave;
  var isSelected = props.isSelected;
  var match = React.useState(function () {
        
      });
  var setError = match[1];
  var error = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setInviteLoading = match$1[1];
  var match$2 = React.useState(function () {
        if (sessionPax !== undefined && !(typeof sessionPax !== "object" || sessionPax.TAG !== "Invite")) {
          return CS_Emails$Util.Email.toString(sessionPax._0.email);
        }
        
      });
  var setEmail = match$2[1];
  var email = match$2[0];
  var match$3 = React.useState(function () {
        return false;
      });
  var setSaveSuccess = match$3[1];
  var match$4 = React.useState(function () {
        if (sessionPax !== undefined && !(typeof sessionPax !== "object" || sessionPax.TAG !== "Invite")) {
          return CS_NonemptyStrings$Util.NonEmptyString.toString(sessionPax._0.firstName);
        }
        
      });
  var setFirstName = match$4[1];
  var firstName = match$4[0];
  var match$5 = React.useState(function () {
        if (sessionPax !== undefined && !(typeof sessionPax !== "object" || sessionPax.TAG !== "Invite")) {
          return Belt_Option.map(sessionPax._0.lastName, CS_NonemptyStrings$Util.NonEmptyString.toString);
        }
        
      });
  var setLastName = match$5[1];
  var lastName = match$5[0];
  var fieldsSaveable = function (p) {
    if (p !== undefined && typeof p === "object" && p.TAG === "Invite" && firstName !== undefined) {
      if (email === undefined) {
        return false;
      }
      var match = p._0;
      return !(CS_NonemptyStrings$Util.NonEmptyString.toString(match.firstName) === firstName && Caml_obj.equal(Belt_Option.map(match.lastName, CS_NonemptyStrings$Util.NonEmptyString.toString), lastName) && CS_Emails$Util.Email.toString(match.email) === email);
    }
    if (firstName !== undefined && email !== undefined && Belt_Result.isOk(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(firstName))) {
      return Belt_Result.isOk(CS_Emails$Util.Email.fromPrimitive(email));
    } else {
      return false;
    }
  };
  return JsxRuntime.jsx(SelectorCard$Thin.make, {
              isSelected: isSelected,
              onClick: props.onClick,
              title: props.title,
              description: props.description,
              children: JsxRuntime.jsxs(Stack, {
                    sx: {
                      display: isSelected ? "flex" : "none",
                      overflow: "hidden",
                      py: 1
                    },
                    children: [
                      JsxRuntime.jsxs(Stack, {
                            direction: "column",
                            spacing: 2,
                            sx: {
                              pt: 1
                            },
                            children: [
                              JsxRuntime.jsxs(Stack, {
                                    direction: "column",
                                    spacing: 2,
                                    sx: {
                                      p: 2,
                                      border: "1px solid",
                                      borderColor: "neutral.300",
                                      backgroundColor: "neutral.200",
                                      borderRadius: "sm"
                                    },
                                    children: [
                                      JsxRuntime.jsx(Typography, {
                                            startDecorator: Caml_option.some(JsxRuntime.jsx(SendOutlined, {})),
                                            level: "title-md",
                                            component: "h2",
                                            children: "Invite"
                                          }),
                                      JsxRuntime.jsxs(Joy$Util.Grid.make, {
                                            children: [
                                              JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                    children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                                                              error: email !== undefined ? Belt_Result.isError(CS_Emails$Util.Email.fromPrimitive(email)) : true,
                                                              children: [
                                                                JsxRuntime.jsx(FormLabel, {
                                                                      children: "Invitee email"
                                                                    }),
                                                                JsxRuntime.jsx(Joy$Util.Input.make, {
                                                                      type_: "email",
                                                                      placeholder: "Email (required)",
                                                                      required: true,
                                                                      variant: "outlined",
                                                                      value: Belt_Option.getWithDefault(email, ""),
                                                                      onChange: (function (e) {
                                                                          var val = e.currentTarget.value;
                                                                          setEmail(function (param) {
                                                                                return val;
                                                                              });
                                                                        })
                                                                    })
                                                              ]
                                                            })),
                                                    xs: 12
                                                  }),
                                              JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                    children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                                                              error: firstName !== undefined ? Belt_Result.isError(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(firstName)) : true,
                                                              children: [
                                                                JsxRuntime.jsx(FormLabel, {
                                                                      children: "Preferred first name"
                                                                    }),
                                                                JsxRuntime.jsx(Joy$Util.Input.make, {
                                                                      type_: "text",
                                                                      placeholder: "First name (required)",
                                                                      required: true,
                                                                      variant: "outlined",
                                                                      value: Belt_Option.getWithDefault(firstName, ""),
                                                                      onChange: (function (e) {
                                                                          var val = e.currentTarget.value;
                                                                          setFirstName(function (param) {
                                                                                return val;
                                                                              });
                                                                        })
                                                                    })
                                                              ]
                                                            })),
                                                    xs: 12,
                                                    md: 6
                                                  }),
                                              JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                    children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                                                              children: [
                                                                JsxRuntime.jsx(FormLabel, {
                                                                      children: "Preferred last name"
                                                                    }),
                                                                JsxRuntime.jsx(Joy$Util.Input.make, {
                                                                      type_: "text",
                                                                      placeholder: "Last name (optional)",
                                                                      variant: "outlined",
                                                                      value: Belt_Option.getWithDefault(lastName, ""),
                                                                      onChange: (function (e) {
                                                                          var val = e.currentTarget.value;
                                                                          setLastName(function (param) {
                                                                                return val;
                                                                              });
                                                                        })
                                                                    })
                                                              ]
                                                            })),
                                                    xs: 12,
                                                    md: 6
                                                  })
                                            ],
                                            container: true,
                                            spacing: {
                                              TAG: "Fixed",
                                              _0: 1
                                            },
                                            rowSpacing: 1,
                                            columnSpacing: 1
                                          })
                                    ]
                                  }),
                              JsxRuntime.jsx(Stack, {
                                    direction: "column",
                                    sx: {
                                      width: "fit-content",
                                      py: 1
                                    },
                                    children: Caml_option.some(JsxRuntime.jsx(AppButton$Thin.make, {
                                              onClick: (function (param) {
                                                  setError(function (param) {
                                                        
                                                      });
                                                  var match = Belt_Option.map(firstName, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive);
                                                  var match$1 = Belt_Option.map(lastName, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive);
                                                  var match$2 = Belt_Option.map(email, CS_Emails$Util.Email.fromPrimitive);
                                                  if (match !== undefined && match.TAG === "Ok" && match$2 !== undefined && match$2.TAG === "Ok") {
                                                    var tmp;
                                                    tmp = match$1 !== undefined && match$1.TAG === "Ok" ? Caml_option.some(match$1._0) : undefined;
                                                    return updateSessionAndSave(SalesFlowState$Thin.Alter.upsertPax(sessionData, paxNum, {
                                                                    TAG: "Invite",
                                                                    _0: {
                                                                      email: match$2._0,
                                                                      firstName: match._0,
                                                                      lastName: tmp
                                                                    }
                                                                  }), setError, setInviteLoading, (function () {
                                                                  setSaveSuccess(function (param) {
                                                                        return true;
                                                                      });
                                                                }));
                                                  }
                                                  setError(function (param) {
                                                        return "Please fill out all form fields";
                                                      });
                                                }),
                                              disabled: !fieldsSaveable(sessionPax) || match$3[0],
                                              children: "Save"
                                            }))
                                  })
                            ]
                          }),
                      error !== undefined && isSelected ? JsxRuntime.jsx(AppAlert$Thin.make, {
                              message: error,
                              type_: "error"
                            }) : JsxRuntime.jsx(JsxRuntime.Fragment, {})
                    ]
                  }),
              locked: props.locked
            });
}

var make = InviteSelector;

exports.make = make;
/* react Not a pure module */
