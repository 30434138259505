// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../../../utils/Common.bs.js");
var Stack = require("@mui/joy/Stack").default;
var Table = require("@mui/joy/Table").default;
var CS_Decimal$Util = require("util/src/CustomScalars/CS_Decimal.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;

function BDBillPayTable(props) {
  var pendingTotal = props.pendingTotal;
  var total = props.total;
  var columns = props.columns;
  var tmp;
  tmp = total.TAG === "Equals" ? JsxRuntime.jsx(Typography, {
          level: "body-sm",
          fontWeight: "bold",
          children: Caml_option.some(Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(total._0)))
        }) : JsxRuntime.jsxs(Stack, {
          direction: "column",
          justifyContent: "flex-end",
          children: [
            JsxRuntime.jsx(Typography, {
                  level: "body-sm",
                  sx: {
                    textDecoration: "line-through"
                  },
                  textColor: "neutral.400",
                  fontWeight: "normal",
                  children: Caml_option.some(Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(total._0)))
                }),
            JsxRuntime.jsx(Typography, {
                  level: "body-sm",
                  fontWeight: "normal",
                  children: Caml_option.some(Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(total._1)))
                })
          ]
        });
  var tmp$1;
  if (props.arePending) {
    var tmp$2;
    tmp$2 = pendingTotal.TAG === "Equals" ? JsxRuntime.jsx(Typography, {
            level: "body-sm",
            fontWeight: "normal",
            children: Caml_option.some(Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(pendingTotal._0)))
          }) : JsxRuntime.jsxs(Stack, {
            direction: "column",
            justifyContent: "flex-end",
            children: [
              JsxRuntime.jsx(Typography, {
                    level: "body-sm",
                    sx: {
                      textDecoration: "line-through"
                    },
                    textColor: "neutral.400",
                    fontWeight: "normal",
                    children: Caml_option.some(Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(pendingTotal._0)))
                  }),
              JsxRuntime.jsx(Typography, {
                    level: "body-sm",
                    fontWeight: "normal",
                    children: Caml_option.some(Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(pendingTotal._1)))
                  })
            ]
          });
    tmp$1 = JsxRuntime.jsxs("tr", {
          children: [
            JsxRuntime.jsx("td", {
                  children: JsxRuntime.jsx(Typography, {
                        level: "body-sm",
                        sx: {
                          fontStyle: "italic"
                        },
                        fontWeight: "normal",
                        children: Caml_option.some(props.pendingDescription)
                      }),
                  style: {
                    backgroundColor: "inherit",
                    borderColor: "transparent"
                  }
                }),
            JsxRuntime.jsx("td", {
                  children: JsxRuntime.jsx(Stack, {
                        direction: "row",
                        justifyContent: "flex-end",
                        alignItems: "flex-start",
                        sx: {
                          width: "100%"
                        },
                        children: Caml_option.some(tmp$2)
                      }),
                  style: {
                    backgroundColor: "inherit",
                    borderColor: "transparent"
                  },
                  colSpan: columns.length - 1 | 0
                })
          ],
          style: {
            borderColor: "transparent"
          }
        });
  } else {
    tmp$1 = null;
  }
  return JsxRuntime.jsxs(Table, {
              sx: {
                "--Table-headerUnderlineThickness": "1px",
                "--unstable_TableCell-height": "24px",
                "& th": {
                  py: 1
                },
                "& td": {
                  py: 1
                }
              },
              children: [
                JsxRuntime.jsx("thead", {
                      children: JsxRuntime.jsx("tr", {
                            children: Belt_Array.mapWithIndex(columns, (function (i, param) {
                                    var heading = param.heading;
                                    return JsxRuntime.jsx("th", {
                                                children: JsxRuntime.jsx(Stack, {
                                                      direction: "row",
                                                      justifyContent: heading === "Amount" || i === (columns.length - 1 | 0) ? "flex-end" : "flex-start",
                                                      children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                level: "body-xs",
                                                                textColor: "neutral.500",
                                                                textTransform: "uppercase",
                                                                children: Caml_option.some(heading)
                                                              }))
                                                    }),
                                                style: {
                                                  width: param.width
                                                }
                                              }, String(i));
                                  }))
                          })
                    }),
                JsxRuntime.jsx("tbody", {
                      children: props.children
                    }),
                JsxRuntime.jsxs("tfoot", {
                      children: [
                        JsxRuntime.jsxs("tr", {
                              children: [
                                JsxRuntime.jsx("td", {
                                      children: JsxRuntime.jsx(Typography, {
                                            level: "body-sm",
                                            fontWeight: "bold",
                                            children: Caml_option.some(props.totalDescription)
                                          }),
                                      style: {
                                        backgroundColor: "inherit",
                                        borderRadius: "0"
                                      }
                                    }),
                                JsxRuntime.jsx("td", {
                                      children: JsxRuntime.jsx(Stack, {
                                            direction: "row",
                                            justifyContent: "flex-end",
                                            alignItems: "flex-start",
                                            sx: {
                                              width: "100%"
                                            },
                                            children: Caml_option.some(tmp)
                                          }),
                                      style: {
                                        backgroundColor: "inherit",
                                        borderRadius: "0"
                                      },
                                      colSpan: columns.length - 1 | 0
                                    })
                              ]
                            }),
                        tmp$1
                      ]
                    })
              ]
            });
}

var make = BDBillPayTable;

exports.make = make;
/* Common-Thin Not a pure module */
