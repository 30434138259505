// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var RemoteData$Util = require("util/src/RemoteData.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var ProcessorPaymentIntentBookingMutation_graphql$Thin = require("../../__generated__/ProcessorPaymentIntentBookingMutation_graphql.bs.js");
var ProcessorPaymentIntentSessionMutation_graphql$Thin = require("../../__generated__/ProcessorPaymentIntentSessionMutation_graphql.bs.js");

var convertVariables = ProcessorPaymentIntentBookingMutation_graphql$Thin.Internal.convertVariables;

var convertResponse = ProcessorPaymentIntentBookingMutation_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = ProcessorPaymentIntentBookingMutation_graphql$Thin.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, ProcessorPaymentIntentBookingMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, ProcessorPaymentIntentBookingMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var AgainstBooking_pmtMethod_decode = ProcessorPaymentIntentBookingMutation_graphql$Thin.Utils.pmtMethod_decode;

var AgainstBooking_pmtMethod_fromString = ProcessorPaymentIntentBookingMutation_graphql$Thin.Utils.pmtMethod_fromString;

var AgainstBooking_pmtProcessor_decode = ProcessorPaymentIntentBookingMutation_graphql$Thin.Utils.pmtProcessor_decode;

var AgainstBooking_pmtProcessor_fromString = ProcessorPaymentIntentBookingMutation_graphql$Thin.Utils.pmtProcessor_fromString;

var AgainstBooking = {
  pmtMethod_decode: AgainstBooking_pmtMethod_decode,
  pmtMethod_fromString: AgainstBooking_pmtMethod_fromString,
  pmtProcessor_decode: AgainstBooking_pmtProcessor_decode,
  pmtProcessor_fromString: AgainstBooking_pmtProcessor_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

var convertVariables$1 = ProcessorPaymentIntentSessionMutation_graphql$Thin.Internal.convertVariables;

var convertResponse$1 = ProcessorPaymentIntentSessionMutation_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse$1 = ProcessorPaymentIntentSessionMutation_graphql$Thin.Internal.convertWrapRawResponse;

var commitMutation$1 = RescriptRelay_Mutation.commitMutation(convertVariables$1, ProcessorPaymentIntentSessionMutation_graphql$Thin.node, convertResponse$1, convertWrapRawResponse$1);

var use$1 = RescriptRelay_Mutation.useMutation(convertVariables$1, ProcessorPaymentIntentSessionMutation_graphql$Thin.node, convertResponse$1, convertWrapRawResponse$1);

var AgainstSession_pmtMethod_decode = ProcessorPaymentIntentSessionMutation_graphql$Thin.Utils.pmtMethod_decode;

var AgainstSession_pmtMethod_fromString = ProcessorPaymentIntentSessionMutation_graphql$Thin.Utils.pmtMethod_fromString;

var AgainstSession_pmtProcessor_decode = ProcessorPaymentIntentSessionMutation_graphql$Thin.Utils.pmtProcessor_decode;

var AgainstSession_pmtProcessor_fromString = ProcessorPaymentIntentSessionMutation_graphql$Thin.Utils.pmtProcessor_fromString;

var AgainstSession = {
  pmtMethod_decode: AgainstSession_pmtMethod_decode,
  pmtMethod_fromString: AgainstSession_pmtMethod_fromString,
  pmtProcessor_decode: AgainstSession_pmtProcessor_decode,
  pmtProcessor_fromString: AgainstSession_pmtProcessor_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables$1,
  convertResponse: convertResponse$1,
  convertWrapRawResponse: convertWrapRawResponse$1,
  commitMutation: commitMutation$1,
  use: use$1
};

function usePaymentIntentMutationEffect(onVoyage, withPmtProcessor, withPmtMethod, clientMutationId) {
  var againtBooking = RemoteData$Util.Relay.useMutationAsync(use, (function (response) {
          var bookingPaymentIntent = response.getBookingPaymentIntent.paymentIntent;
          if (bookingPaymentIntent !== undefined) {
            return {
                    TAG: "Success",
                    _0: Caml_option.valFromOption(bookingPaymentIntent)
                  };
          } else {
            return {
                    TAG: "Failure",
                    _0: "No tokenization intent returned"
                  };
          }
        }));
  var againsSession = RemoteData$Util.Relay.useMutationAsync(use$1, (function (response) {
          var checkoutPaymentIntent = response.getCheckoutPaymentIntent.paymentIntent;
          if (checkoutPaymentIntent !== undefined) {
            return {
                    TAG: "Success",
                    _0: Caml_option.valFromOption(checkoutPaymentIntent)
                  };
          } else {
            return {
                    TAG: "Failure",
                    _0: "You were not issued a booking session payment intent... Are you paying a reasonable amount?"
                  };
          }
        }));
  return function (invocation) {
    if (invocation.TAG === "AgainstBooking") {
      return againtBooking({
                  input: {
                    clientMutationId: Belt_Option.getWithDefault(clientMutationId, ""),
                    withPmtProcessor: withPmtProcessor,
                    withPmtMethod: withPmtMethod,
                    againstBooking: invocation.bookingId,
                    forNetAmountInPositiveCents: invocation.netAmountInPositiveCents
                  }
                });
    } else {
      return againsSession({
                  input: {
                    clientMutationId: Belt_Option.getWithDefault(clientMutationId, ""),
                    withPmtProcessor: withPmtProcessor,
                    withPmtMethod: withPmtMethod,
                    onVoyage: onVoyage,
                    forNetAmountInPositiveCents: invocation.netAmountInPositiveCents
                  }
                });
    }
  };
}

exports.AgainstBooking = AgainstBooking;
exports.AgainstSession = AgainstSession;
exports.usePaymentIntentMutationEffect = usePaymentIntentMutationEffect;
/* commitMutation Not a pure module */
