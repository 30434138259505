// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../../../utils/Common.bs.js");
var Stack = require("@mui/joy/Stack").default;
var JsxRuntime = require("react/jsx-runtime");

function AppBanner(props) {
  var color = props.color;
  return JsxRuntime.jsx(Stack, {
              direction: "row",
              alignItems: "flex-start",
              sx: {
                p: 2,
                border: "1px solid",
                borderColor: Common$Thin.NotificationColor.getBorderColor(color),
                backgroundColor: Common$Thin.NotificationColor.getBackgroundColor(color),
                borderRadius: "8px"
              },
              children: Caml_option.some(props.children)
            });
}

var make = AppBanner;

exports.make = make;
/* Common-Thin Not a pure module */
