// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Txt$Thin = require("../text/Txt.bs.js");
var SysCtx$Thin = require("../utils/SysCtx.bs.js");
var AppAlert$Thin = require("../components/common/atoms/AppAlert.bs.js");
var AuthPanel$Thin = require("../components/common/molecules/AuthPanel.bs.js");
var PageFrame$Thin = require("../components/wrappers/PageFrame.bs.js");
var VerifySubmit$Thin = require("../components/verify/mutations/VerifySubmit.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var URLSearchParams$Util = require("util/src/URLSearchParams.bs.js");

function VerifyPage(props) {
  var match = React.useState(function () {
        return true;
      });
  var setLoading = match[1];
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var token = URLSearchParams$Util.getValFromKey(url, SysCtx$Thin.vars.tokenKey);
  var match$1 = URLSearchParams$Util.getValFromKey(url, SysCtx$Thin.vars.expiryKey);
  var expired;
  if (match$1 !== undefined && match$1.TAG === "Number") {
    var now = Date.now();
    var expiry = match$1._0 * 1000.0;
    expired = now >= expiry ? true : false;
  } else {
    expired = undefined;
  }
  var match$2 = VerifySubmit$Thin.useMutation(false);
  var verify = match$2.verify;
  var error = match$2.error;
  React.useEffect((function () {
          if (expired !== undefined) {
            if (!expired && token !== undefined && token.TAG === "String") {
              verify(token._0);
            }
            
          } else if (token !== undefined) {
            
          } else {
            RescriptReactRouter.push(SysCtx$Thin.vars.loginLink);
          }
          setLoading(function (param) {
                return false;
              });
        }), []);
  var tmp;
  var exit = 0;
  if (expired !== undefined && expired) {
    tmp = JsxRuntime.jsx(AppAlert$Thin.make, {
          message: Txt$Thin.Verify.expired.value,
          type_: "warning"
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = token !== undefined ? (
        match[0] ? JsxRuntime.jsx(AppAlert$Thin.make, {
                message: Txt$Thin.Verify.loading.value
              }) : (
            match$2.verified ? JsxRuntime.jsx(AppAlert$Thin.make, {
                    message: Txt$Thin.Verify.successNoRedirect.value,
                    type_: "success"
                  }) : (
                error !== undefined ? JsxRuntime.jsx(AppAlert$Thin.make, {
                        message: error
                      }) : JsxRuntime.jsx(AppAlert$Thin.make, {
                        message: Txt$Thin.$$Error.genericFormSubmit.value
                      })
              )
          )
      ) : JsxRuntime.jsx(AppAlert$Thin.make, {
            message: Txt$Thin.$$Error.genericFormSubmit.value
          });
  }
  return JsxRuntime.jsx(PageFrame$Thin.make, {
              children: JsxRuntime.jsx(AuthPanel$Thin.make, {
                    heading: Txt$Thin.Verify.heading.value,
                    children: tmp
                  }),
              auth: true
            });
}

var make = VerifyPage;

exports.make = make;
/* react Not a pure module */
