// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var DateFns = require("date-fns");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var DateFns$Util = require("util/src/DateFns.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RelayEnv$Thin = require("../../../RelayEnv.bs.js");
var SalesCabin$Thin = require("../../../utils/sales-flow/SalesCabin.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var SalesFlowState$Thin = require("../../../utils/sales-flow/SalesFlowState.bs.js");
var CurrentUserCabinHoldsQuery_graphql$Thin = require("../../../__generated__/CurrentUserCabinHoldsQuery_graphql.bs.js");

var convertVariables = CurrentUserCabinHoldsQuery_graphql$Thin.Internal.convertVariables;

var convertResponse = CurrentUserCabinHoldsQuery_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = CurrentUserCabinHoldsQuery_graphql$Thin.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, CurrentUserCabinHoldsQuery_graphql$Thin.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, CurrentUserCabinHoldsQuery_graphql$Thin.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(CurrentUserCabinHoldsQuery_graphql$Thin.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(CurrentUserCabinHoldsQuery_graphql$Thin.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(CurrentUserCabinHoldsQuery_graphql$Thin.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(CurrentUserCabinHoldsQuery_graphql$Thin.node, convertVariables);

var Query = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function fetchAndRedirect(voyageSlug, brandFamily, flow, url, setQuote, navTarget) {
  var match;
  switch (flow) {
    case "ByCategory" :
        match = [
          SalesFlowState$Thin.ByCategory.CabinTarget.setOcc,
          SalesFlowState$Thin.ByCategory.CabinTarget.setClass,
          SalesFlowState$Thin.ByCategory.CabinTarget.setSub,
          SalesFlowState$Thin.ByCategory.CabinTarget.setCat,
          SalesFlowState$Thin.ByCategory.CabinTarget.setFare,
          SalesFlowState$Thin.Alter.setCabinHold
        ];
        break;
    case "AllNoCabin" :
        match = [
          SalesFlowState$Thin.ByAllNoCabin.CabinTarget.setOcc,
          SalesFlowState$Thin.ByAllNoCabin.CabinTarget.setClass,
          SalesFlowState$Thin.ByAllNoCabin.CabinTarget.setSub,
          SalesFlowState$Thin.ByAllNoCabin.CabinTarget.setCat,
          SalesFlowState$Thin.ByAllNoCabin.CabinTarget.setFare,
          SalesFlowState$Thin.Alter.setCabinHold
        ];
        break;
    case "All" :
        match = [
          SalesFlowState$Thin.ByAll.CabinTarget.setOcc,
          SalesFlowState$Thin.ByAll.CabinTarget.setClass,
          SalesFlowState$Thin.ByAll.CabinTarget.setSub,
          SalesFlowState$Thin.ByAll.CabinTarget.setCat,
          SalesFlowState$Thin.ByAll.CabinTarget.setFare,
          SalesFlowState$Thin.Alter.setCabinHold
        ];
        break;
    
  }
  var setCabinHold = match[5];
  var setFare = match[4];
  var setCat = match[3];
  var setSub = match[2];
  var setClass = match[1];
  var setOcc = match[0];
  setQuote(function (param) {
        return "Loading";
      });
  $$fetch(RelayEnv$Thin.environment, {
        bfSlugString: CS_Slugs$Util.BrandFamilySlug.toString(brandFamily),
        voySlugString: CS_Slugs$Util.VoyageSlug.toString(voyageSlug)
      }, (function (res) {
          if (res.TAG === "Ok") {
            var match = res._0.currentUserCabinHolds;
            if (match === undefined) {
              return ;
            }
            var match$1 = Belt_Array.get(match.nodes, 0);
            if (match$1 === undefined) {
              return ;
            }
            var expiry = match$1.expiresAt;
            if (expiry === undefined) {
              return ;
            }
            var quote = match$1.quote;
            var expiry$1 = Caml_option.valFromOption(expiry);
            if (DateFns.isBefore(Belt_Result.getExn(DateFns$Util.parseISO(CS_Make$Util.Dateable.Plain.toPrimitive(expiry$1))), new Date())) {
              return ;
            }
            if (quote !== undefined) {
              var quote$1 = SalesCabin$Thin.getMiniQuote({
                    TAG: "CabinHold",
                    _0: quote
                  });
              if (quote$1 !== undefined) {
                setQuote(function (param) {
                      return {
                              TAG: "Quote",
                              _0: quote$1
                            };
                    });
              } else {
                setQuote(function (param) {
                      return "Unavailable";
                    });
              }
            } else {
              setQuote(function (param) {
                    return "Unavailable";
                  });
            }
            return SalesFlowState$Thin.navigate(SalesFlowState$Thin.URLState.serialize(SalesFlowState$Thin.Alter.setNavTarget(setCabinHold(setFare(setCat(setSub(setClass(setOcc(SalesFlowState$Thin.empty, match$1.forOccupancy), match$1.voyageClassSlug), Caml_option.some(match$1.voyageSubclassSlug)), match$1.voyageCategorySlug), match$1.fareClassSlug), expiry$1), navTarget)), url);
          }
          console.error(res._0);
        }), undefined, undefined);
}

exports.Query = Query;
exports.fetchAndRedirect = fetchAndRedirect;
/* use Not a pure module */
