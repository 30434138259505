// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Txt$Thin = require("../../text/Txt.bs.js");
var Merge$Util = require("util/src/Merge.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var AppAlert$Thin = require("../common/atoms/AppAlert.bs.js");
var Stack = require("@mui/joy/Stack").default;
var Divider = require("@mui/joy/Divider").default;
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var Typography = require("@mui/joy/Typography").default;
var FormControl = require("@mui/joy/FormControl").default;
var ChangeEmailForm$Thin = require("./ChangeEmailForm.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var FormHelperText = require("@mui/joy/FormHelperText").default;
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var ChangePasswordForm$Thin = require("./ChangePasswordForm.bs.js");
var CopyableWithTooltip$Thin = require("../common/atoms/CopyableWithTooltip.bs.js");
var KeyOutlined = require("@mui/icons-material/KeyOutlined").default;
var AccountAccessSectionQuery_graphql$Thin = require("../../__generated__/AccountAccessSectionQuery_graphql.bs.js");
var AccountAccessSection_query_graphql$Thin = require("../../__generated__/AccountAccessSection_query_graphql.bs.js");

var convertFragment = AccountAccessSection_query_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(AccountAccessSection_query_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, AccountAccessSection_query_graphql$Thin.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(AccountAccessSection_query_graphql$Thin.node, convertFragment, fRef);
}

var makeRefetchVariables = AccountAccessSectionQuery_graphql$Thin.Types.makeRefetchVariables;

var convertRefetchVariables = AccountAccessSectionQuery_graphql$Thin.Internal.convertVariables;

function useRefetchable(fRef) {
  return RescriptRelay_Fragment.useRefetchableFragment(AccountAccessSection_query_graphql$Thin.node, convertFragment, convertRefetchVariables, fRef);
}

var QueryFragment_custSignonStatus_decode = AccountAccessSection_query_graphql$Thin.Utils.custSignonStatus_decode;

var QueryFragment_custSignonStatus_fromString = AccountAccessSection_query_graphql$Thin.Utils.custSignonStatus_fromString;

var QueryFragment = {
  custSignonStatus_decode: QueryFragment_custSignonStatus_decode,
  custSignonStatus_fromString: QueryFragment_custSignonStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment,
  makeRefetchVariables: makeRefetchVariables,
  convertRefetchVariables: convertRefetchVariables,
  useRefetchable: useRefetchable
};

function AccountAccessSection$DetailRow(props) {
  var __copyable = props.copyable;
  var value = props.value;
  var label = props.label;
  var copyable = __copyable !== undefined ? __copyable : false;
  return JsxRuntime.jsxs(Stack, {
              direction: "row",
              sx: {
                width: "25rem",
                py: 2
              },
              children: [
                JsxRuntime.jsx(Stack, {
                      sx: {
                        width: "50%"
                      },
                      children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                level: "body-sm",
                                fontWeight: "700",
                                children: Caml_option.some(label)
                              }))
                    }),
                JsxRuntime.jsxs(Stack, {
                      direction: "row",
                      alignItems: "center",
                      spacing: 1,
                      sx: {
                        width: "50%"
                      },
                      children: [
                        JsxRuntime.jsx(Typography, {
                              level: "body-sm",
                              children: Caml_option.some(value)
                            }),
                        copyable ? JsxRuntime.jsx(CopyableWithTooltip$Thin.make, {
                                value: value,
                                label: label
                              }) : null
                      ]
                    })
              ]
            });
}

var DetailRow = {
  make: AccountAccessSection$DetailRow
};

function AccountAccessSection(props) {
  var userAccount = props.userAccount;
  var userContext = props.userContext;
  var match = useRefetchable(props.fragmentRefs);
  var refetch = match[1];
  var data = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setEmailFormOpen = match$1[1];
  var match$2 = React.useState(function () {
        return false;
      });
  var setPasswordFormOpen = match$2[1];
  var refresh = function (param) {
    return refetch(undefined, "store-and-network", undefined);
  };
  var match$3 = data.currentUserSignonStatus;
  var emailHelperModule;
  var exit = 0;
  if ((match$3 === "VERIFY_EMAIL" || match$3 === "INVITED" || match$3 === "PENDING_2FA" || match$3 === "CLEAR_JWT" || match$3 === "ANONYMOUS" || match$3 === "SIGNED_ON") && match$3 === "VERIFY_EMAIL") {
    emailHelperModule = JsxRuntime.jsx(AppAlert$Thin.make, {
          message: Merge$Util.render(Txt$Thin.Account.emailVerify, userContext),
          type_: "warning",
          size: "sm"
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    emailHelperModule = JsxRuntime.jsx(Typography, {
          level: "body-sm",
          sx: {
            textDecoration: "underline",
            "&:hover": {
              cursor: "pointer"
            }
          },
          children: Caml_option.some(Txt$Thin.Account.changeEmail.value),
          onClick: (function (param) {
              setEmailFormOpen(function (param) {
                    return true;
                  });
            })
        });
  }
  var email = data.currentUserEmail;
  var tier = userAccount.loyaltyTier;
  return JsxRuntime.jsxs(Stack, {
              direction: "column",
              sx: {
                width: "100%",
                py: 2
              },
              children: [
                JsxRuntime.jsx(Typography, {
                      startDecorator: Caml_option.some(JsxRuntime.jsx(KeyOutlined, {})),
                      level: "h4",
                      component: "h2",
                      sx: {
                        mb: 2
                      },
                      children: "Account details"
                    }),
                JsxRuntime.jsx(Joy$Util.Grid.make, {
                      children: Caml_option.some(email !== undefined ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                  children: [
                                    JsxRuntime.jsx(Joy$Util.Grid.make, {
                                          children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                                                    children: [
                                                      JsxRuntime.jsx(FormLabel, {
                                                            children: Caml_option.some(Txt$Thin.Account.emailLabel.value)
                                                          }),
                                                      JsxRuntime.jsx(Joy$Util.Input.make, {
                                                            type_: "email",
                                                            fullWidth: true,
                                                            disabled: true,
                                                            value: email
                                                          }),
                                                      JsxRuntime.jsx(FormHelperText, {
                                                            children: Caml_option.some(emailHelperModule)
                                                          })
                                                    ]
                                                  })),
                                          xs: 12,
                                          md: 6
                                        }),
                                    JsxRuntime.jsx(Joy$Util.Grid.make, {
                                          children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                                                    children: [
                                                      JsxRuntime.jsx(FormLabel, {
                                                            children: Caml_option.some(Txt$Thin.Account.passwordLabel.value)
                                                          }),
                                                      JsxRuntime.jsx(Joy$Util.Input.make, {
                                                            type_: "password",
                                                            fullWidth: true,
                                                            disabled: true,
                                                            value: "*********"
                                                          }),
                                                      JsxRuntime.jsx(FormHelperText, {
                                                            children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                      level: "body-sm",
                                                                      sx: {
                                                                        textDecoration: "underline",
                                                                        "&:hover": {
                                                                          cursor: "pointer"
                                                                        }
                                                                      },
                                                                      children: Caml_option.some(Txt$Thin.Account.changePassword.value),
                                                                      onClick: (function (param) {
                                                                          setPasswordFormOpen(function (param) {
                                                                                return true;
                                                                              });
                                                                        })
                                                                    }))
                                                          })
                                                    ]
                                                  })),
                                          xs: 12,
                                          md: 6
                                        })
                                  ]
                                }) : JsxRuntime.jsx("div", {
                                  children: Txt$Thin.Account.noEmail.value
                                })),
                      container: true,
                      spacing: {
                        TAG: "Fixed",
                        _0: 1
                      },
                      sx: {
                        width: "100%"
                      }
                    }),
                JsxRuntime.jsxs(Stack, {
                      direction: "column",
                      sx: {
                        pt: 3
                      },
                      children: [
                        JsxRuntime.jsx(Divider, {}),
                        tier !== undefined ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                children: [
                                  JsxRuntime.jsx(AccountAccessSection$DetailRow, {
                                        label: "Loyalty Tier",
                                        value: CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(tier))
                                      }),
                                  JsxRuntime.jsx(Divider, {})
                                ]
                              }) : null,
                        JsxRuntime.jsx(AccountAccessSection$DetailRow, {
                              label: "Loyalty Number",
                              value: CS_NonemptyStrings$Util.LoyaltyNumber.toString(userAccount.loyaltyNumber),
                              copyable: true
                            })
                      ]
                    }),
                JsxRuntime.jsx(ChangeEmailForm$Thin.make, {
                      open_: match$1[0],
                      onClose: (function () {
                          setEmailFormOpen(function (param) {
                                return false;
                              });
                        }),
                      refresh: refresh,
                      userContext: userContext
                    }),
                JsxRuntime.jsx(ChangePasswordForm$Thin.make, {
                      open_: match$2[0],
                      onClose: (function () {
                          setPasswordFormOpen(function (param) {
                                return false;
                              });
                        })
                    })
              ]
            });
}

var make = AccountAccessSection;

exports.QueryFragment = QueryFragment;
exports.DetailRow = DetailRow;
exports.make = make;
/* react Not a pure module */
