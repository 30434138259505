// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Txt$Thin = require("../text/Txt.bs.js");
var Merge$Util = require("util/src/Merge.bs.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SysCtx$Thin = require("../utils/SysCtx.bs.js");
var AppAlert$Thin = require("../components/common/atoms/AppAlert.bs.js");
var Stack = require("@mui/joy/Stack").default;
var AppButton$Thin = require("../components/common/atoms/AppButton.bs.js");
var AuthPanel$Thin = require("../components/common/molecules/AuthPanel.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var PageFrame$Thin = require("../components/wrappers/PageFrame.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var PasswordInput$Thin = require("../components/common/molecules/PasswordInput.bs.js");
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var FormControl = require("@mui/joy/FormControl").default;
var URLSearchParams$Util = require("util/src/URLSearchParams.bs.js");
var ClaimTransferWithToken$Thin = require("../components/transfer/mutations/ClaimTransferWithToken.bs.js");

function TransferPage(props) {
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var token = URLSearchParams$Util.getValFromKey(url, SysCtx$Thin.vars.tokenKey);
  var match = URLSearchParams$Util.getValFromKey(url, SysCtx$Thin.vars.expiryKey);
  var expired;
  if (match !== undefined && match.TAG === "Number") {
    var now = Date.now();
    var expiry = match._0 * 1000.0;
    expired = now >= expiry ? true : false;
  } else {
    expired = undefined;
  }
  var match$1 = ClaimTransferWithToken$Thin.useMutation();
  var transferSuccess = match$1.transferSuccess;
  var error = match$1.error;
  var onSubmit = match$1.onSubmit;
  var setConfirmPassword = match$1.setConfirmPassword;
  var confirmPassword = match$1.confirmPassword;
  var setPassword = match$1.setPassword;
  var password = match$1.password;
  var setEmail = match$1.setEmail;
  var email = match$1.email;
  React.useEffect((function () {
          if (transferSuccess !== undefined) {
            setTimeout((function () {
                    RescriptReactRouter.push(SysCtx$Thin.vars.loginLink);
                  }), 4000);
          } else {
            var exit = 0;
            if (!(token !== undefined && token.TAG === "String" && expired !== undefined)) {
              exit = 1;
            }
            if (exit === 1) {
              RescriptReactRouter.push(SysCtx$Thin.vars.loginLink);
            }
            
          }
        }), [transferSuccess]);
  var tmp;
  tmp = token !== undefined && token.TAG === "String" && expired !== undefined && !(expired || transferSuccess !== undefined) ? JsxRuntime.jsx(Typography, {
          level: "body-sm",
          textColor: "text.tertiary",
          children: Caml_option.some(Txt$Thin.Transfer.transferDescription.value)
        }) : null;
  var tmp$1;
  var exit = 0;
  if (expired !== undefined && expired && transferSuccess === undefined) {
    tmp$1 = JsxRuntime.jsx(AppAlert$Thin.make, {
          message: Txt$Thin.Transfer.tokenExpiry.value,
          type_: "warning"
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    if (transferSuccess !== undefined) {
      tmp$1 = transferSuccess === "Login" ? JsxRuntime.jsx(AppAlert$Thin.make, {
              message: Merge$Util.render(Txt$Thin.Transfer.transferSuccess, SysCtx$Thin.vars),
              type_: "success"
            }) : JsxRuntime.jsx(AppAlert$Thin.make, {
              message: Merge$Util.render(Txt$Thin.Transfer.needsVerification, SysCtx$Thin.vars),
              type_: "warning"
            });
    } else if (token !== undefined && token.TAG === "String" && expired !== undefined) {
      var token$1 = token._0;
      tmp$1 = JsxRuntime.jsxs(Stack, {
            direction: "column",
            component: "form",
            spacing: 2,
            sx: {
              width: "100%"
            },
            onSubmit: (function (e) {
                onSubmit(e, token$1);
              }),
            children: [
              JsxRuntime.jsx(FormControl, {
                    error: Belt_Result.isError(CS_Emails$Util.Email.fromPrimitive(email)),
                    children: Caml_option.some(JsxRuntime.jsx(Joy$Util.Input.make, {
                              type_: "email",
                              size: "lg",
                              fullWidth: true,
                              placeholder: Txt$Thin.Transfer.emailPlaceholder.value,
                              value: email,
                              onChange: (function (e) {
                                  var val = e.currentTarget.value;
                                  setEmail(function (param) {
                                        return val;
                                      });
                                })
                            }))
                  }),
              JsxRuntime.jsx(PasswordInput$Thin.make, {
                    fullWidth: true,
                    size: "lg",
                    placeholder: Txt$Thin.Transfer.passwordPlaceholder.value,
                    value: password,
                    onChange: (function (e) {
                        var val = e.currentTarget.value;
                        setPassword(function (param) {
                              return val;
                            });
                      })
                  }),
              JsxRuntime.jsx(PasswordInput$Thin.make, {
                    fullWidth: true,
                    size: "lg",
                    placeholder: Txt$Thin.Transfer.confirmPasswordPlaceholder.value,
                    error: password !== confirmPassword,
                    value: confirmPassword,
                    onChange: (function (e) {
                        var val = e.currentTarget.value;
                        setConfirmPassword(function (param) {
                              return val;
                            });
                      })
                  }),
              JsxRuntime.jsx(AppButton$Thin.make, {
                    fullWidth: true,
                    loading: match$1.isMutating,
                    type_: "submit",
                    size: "lg",
                    disabled: password === "" || confirmPassword === "" || password !== confirmPassword || Belt_Result.isError(CS_Emails$Util.Email.fromPrimitive(email)),
                    children: Caml_option.some(Txt$Thin.Transfer.acceptTransferButton.value)
                  })
            ]
          });
    } else {
      tmp$1 = null;
    }
  }
  return JsxRuntime.jsx(PageFrame$Thin.make, {
              children: JsxRuntime.jsxs(AuthPanel$Thin.make, {
                    heading: Txt$Thin.Transfer.transferHeading.value,
                    subHeadComponent: Caml_option.some(tmp),
                    children: [
                      tmp$1,
                      error !== undefined ? JsxRuntime.jsx(AppAlert$Thin.make, {
                              message: error,
                              type_: "error"
                            }) : JsxRuntime.jsx(JsxRuntime.Fragment, {})
                    ]
                  }),
              auth: true
            });
}

var make = TransferPage;

exports.make = make;
/* react Not a pure module */
