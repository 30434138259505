// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Stack = require("@mui/joy/Stack").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var VoyageMiniCard$Thin = require("../../common/molecules/VoyageMiniCard.bs.js");

function SalesHeading(props) {
  var voyage = props.voyage;
  var __subtitleComponent = props.subtitleComponent;
  var __description = props.description;
  var description = __description !== undefined ? __description : "";
  var subtitleComponent = __subtitleComponent !== undefined ? Caml_option.valFromOption(__subtitleComponent) : null;
  var match = React.useState(function () {
        return false;
      });
  var setSliderOpen = match[1];
  var sliderOpen = match[0];
  return JsxRuntime.jsxs(Stack, {
              direction: "row",
              justifyContent: "space-between",
              sx: {
                py: 3
              },
              children: [
                JsxRuntime.jsxs(Stack, {
                      direction: "column",
                      spacing: 1,
                      children: [
                        JsxRuntime.jsx(Typography, {
                              level: "h3",
                              fontWeight: "400",
                              children: Caml_option.some(props.title)
                            }),
                        description.length !== 0 ? JsxRuntime.jsx(Typography, {
                                level: "body-sm",
                                sx: {
                                  color: "text.tertiary",
                                  maxWidth: {
                                    xs: "95%",
                                    md: "100%"
                                  }
                                },
                                fontWeight: "400",
                                children: Caml_option.some(description)
                              }) : null,
                        subtitleComponent
                      ]
                    }),
                JsxRuntime.jsx(Stack, {
                      direction: "column",
                      justifyContent: "center",
                      sx: {
                        position: {
                          xs: "fixed",
                          md: "relative"
                        },
                        cursor: "pointer",
                        right: {
                          xs: sliderOpen ? "-5px" : "-210px",
                          md: "0"
                        },
                        alignSelf: "center",
                        zIndex: 2,
                        maxWidth: {
                          xs: "250px",
                          md: "none"
                        },
                        transition: "right 0.3s ease-in-out"
                      },
                      onClick: (function (param) {
                          setSliderOpen(function (param) {
                                return !sliderOpen;
                              });
                        }),
                      children: Caml_option.some(voyage !== undefined ? JsxRuntime.jsx(VoyageMiniCard$Thin.make, {
                                  voyage: voyage,
                                  asSlider: true,
                                  sliderOpen: sliderOpen
                                }) : null)
                    })
              ]
            });
}

var make = SalesHeading;

exports.make = make;
/* react Not a pure module */
