// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Txt$Thin = require("../../../text/Txt.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Js_string = require("rescript/lib/js/js_string.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SysCtx$Thin = require("../../../utils/SysCtx.bs.js");
var Box = require("@mui/joy/Box").default;
var NavMenu$Thin = require("./NavMenu.bs.js");
var JoCoLoGo$Thin = require("../../../assets/JoCoLoGo.bs.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var AppAvatar$Thin = require("../atoms/AppAvatar.bs.js");
var AppButton$Thin = require("../atoms/AppButton.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var Navigation_query_graphql$Thin = require("../../../__generated__/Navigation_query_graphql.bs.js");

var convertFragment = Navigation_query_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(Navigation_query_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, Navigation_query_graphql$Thin.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(Navigation_query_graphql$Thin.node, convertFragment, fRef);
}

var QueryFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

function $$Navigation(props) {
  var displayName = props.displayName;
  var query = use(props.fragmentRefs);
  var initial = Js_string.charAt(0, displayName).toUpperCase();
  var match = query.currentUserConnectedAccounts;
  var hasConnectedAccounts = match !== undefined ? match.totalCount > 0 : false;
  var urlObj = RescriptReactRouter.useUrl(undefined, undefined);
  var path = Belt_List.reduce(urlObj.path, "", (function (a, b) {
          return a + "/" + b.trim();
        }));
  Array.from(new URLSearchParams(urlObj.search).entries());
  var loginUrl = function (p, s) {
    if (p.length === 0) {
      return "/login";
    } else {
      return "/login?" + SysCtx$Thin.vars.loginRedirectParam + "=" + p + (
              s.length === 0 ? "" : "&" + s
            );
    }
  };
  var tmp;
  var exit = 0;
  var tmp$1 = props.signOnStatus;
  if ((tmp$1 === "VERIFY_EMAIL" || tmp$1 === "INVITED" || tmp$1 === "PENDING_2FA" || tmp$1 === "CLEAR_JWT" || tmp$1 === "ANONYMOUS" || tmp$1 === "SIGNED_ON") && tmp$1 === "ANONYMOUS") {
    tmp = JsxRuntime.jsx(AppButton$Thin.make, {
          onClick: (function (param) {
              var loginUrl$1 = loginUrl(path, urlObj.search);
              RescriptReactRouter.push(loginUrl$1);
            }),
          children: Caml_option.some(Txt$Thin.Nav.login.value)
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsxs(Stack, {
                  direction: "row",
                  alignItems: "center",
                  spacing: 1,
                  sx: {
                    width: "100%"
                  },
                  children: [
                    JsxRuntime.jsx(AppAvatar$Thin.make, {
                          children: initial,
                          size: "sm"
                        }),
                    JsxRuntime.jsx(Typography, {
                          level: "body-sm",
                          noWrap: true,
                          sx: {
                            display: {
                              xs: "none",
                              md: "block"
                            }
                          },
                          fontWeight: "bold",
                          children: Caml_option.some(displayName)
                        })
                  ]
                }),
            JsxRuntime.jsx(NavMenu$Thin.make, {
                  hasConnectedAccounts: hasConnectedAccounts,
                  alwaysHamburger: props.alwaysHamburger,
                  logout: props.logout
                })
          ]
        });
  }
  return JsxRuntime.jsxs(Sheet, {
              sx: {
                p: 2,
                borderBottom: 1,
                zIndex: 3,
                borderColor: "neutral.200",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              },
              children: [
                JsxRuntime.jsx(Box, {
                      children: Caml_option.some(JsxRuntime.jsx(JoCoLoGo$Thin.make, {})),
                      sx: {
                        height: "100%",
                        width: "7rem"
                      }
                    }),
                JsxRuntime.jsx(Stack, {
                      direction: "row",
                      spacing: 1,
                      children: Caml_option.some(tmp)
                    })
              ]
            });
}

var make = $$Navigation;

exports.QueryFragment = QueryFragment;
exports.make = make;
/* Txt-Thin Not a pure module */
