// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../../../../utils/Common.bs.js");
var Dialog$Thin = require("../Dialog.bs.js");
var Link = require("@mui/joy/Link").default;
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var SalesFlowState$Thin = require("../../../../utils/sales-flow/SalesFlowState.bs.js");
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;

function BraintreeTermsModal(props) {
  var payment = props.payment;
  var calculatedFee = props.calculatedFee;
  var onClose = props.onClose;
  var feeText = Belt_Option.map(calculatedFee, (function (f) {
          return Common$Thin.formatCurrency(f / 100.0);
        }));
  var paymentOption;
  paymentOption = payment.TAG === "BookingSession" ? Belt_Option.map(payment._0, (function (p) {
            return SalesFlowState$Thin.paymentOptionToInt(p);
          })) : payment._0;
  var paymentText = Belt_Option.map(paymentOption, (function (p) {
          return Common$Thin.formatCurrency(p / 100.0);
        }));
  var totalCharge = paymentOption !== undefined && calculatedFee !== undefined ? Common$Thin.formatCurrency((paymentOption + calculatedFee | 0) / 100.0) : undefined;
  var paymentFeeText = paymentText !== undefined && feeText !== undefined && totalCharge !== undefined ? " For your selected payment amount of " + paymentText + ", the processing fee will be " + feeText + ", for a total of " + totalCharge + "." : "";
  return JsxRuntime.jsxs(Dialog$Thin.make, {
              open_: props.open_,
              title: "Braintree Terms & Conditions",
              onClose: onClose,
              children: [
                JsxRuntime.jsx(DialogContent, {
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                spacing: 1,
                                children: [
                                  JsxRuntime.jsx(Typography, {
                                        level: "title-md",
                                        children: "Consent"
                                      }),
                                  JsxRuntime.jsxs(Typography, {
                                        level: "body-sm",
                                        children: [
                                          "JoCo Cruise uses ",
                                          JsxRuntime.jsx(Link, {
                                                children: "Braintree Payments",
                                                href: "https://www.braintreepayments.com/",
                                                target: "_blank"
                                              }),
                                          " to process credit card payments. Specifically, we use their \"Hosted Fields\" implementation. The\n        form provided is fully hosted at Braintree and your payment credentials do not\n        touch JoCo Cruise's servers."
                                        ]
                                      }),
                                  JsxRuntime.jsxs(Typography, {
                                        level: "body-sm",
                                        children: [
                                          "By using the Braintree service, you are consenting to ",
                                          JsxRuntime.jsx(Link, {
                                                children: "Braintree's privacy policy",
                                                href: "https://www.braintreepayments.com/legal/braintree-privacy-policy",
                                                target: "_blank"
                                              }),
                                          "."
                                        ]
                                      }),
                                  JsxRuntime.jsx(Typography, {
                                        level: "title-md",
                                        children: "Processing Fee"
                                      }),
                                  JsxRuntime.jsx(Typography, {
                                        level: "body-sm",
                                        children: Caml_option.some("A processing fee of 3.5% will be added to this transaction." + paymentFeeText)
                                      })
                                ]
                              })),
                      sx: {
                        px: 2
                      }
                    }),
                JsxRuntime.jsx(DialogActions, {
                      children: Caml_option.some(JsxRuntime.jsx(Button, {
                                onClick: onClose,
                                children: "Close"
                              }))
                    })
              ]
            });
}

var make = BraintreeTermsModal;

exports.make = make;
/* Common-Thin Not a pure module */
