// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var Button = require("@mui/joy/Button").default;
var JsxRuntime = require("react/jsx-runtime");

function AppButton(props) {
  var children = props.children;
  var __disabled = props.disabled;
  var __size = props.size;
  var href = props.href;
  var __color = props.color;
  var __variant = props.variant;
  var component = props.component;
  var id = props.id;
  var ariaLabel = props.ariaLabel;
  var endDecorator = props.endDecorator;
  var startDecorator = props.startDecorator;
  var onClick = props.onClick;
  var loadingIndicator = props.loadingIndicator;
  var type_ = props.type_;
  var __loading = props.loading;
  var __fullWidth = props.fullWidth;
  var fullWidth = __fullWidth !== undefined ? __fullWidth : false;
  var loading = __loading !== undefined ? __loading : false;
  var variant = __variant !== undefined ? __variant : "solid";
  var color = __color !== undefined ? __color : "primary";
  var size = __size !== undefined ? __size : "sm";
  var disabled = __disabled !== undefined ? __disabled : false;
  var handleKeyup = function (e) {
    var key = e.key;
    switch (key) {
      case "Enter" :
          console.log("enter");
          return ;
      case "SPACE" :
          console.log("space");
          return ;
      default:
        return ;
    }
  };
  return JsxRuntime.jsx(Button, {
              fullWidth: fullWidth,
              loading: loading,
              type: type_ !== undefined ? type_ : "",
              loadingIndicator: Caml_option.some(loadingIndicator !== undefined ? Caml_option.valFromOption(loadingIndicator) : null),
              onClick: onClick !== undefined ? onClick : (function (param) {
                    
                  }),
              onKeyUp: handleKeyup,
              startDecorator: Caml_option.some(startDecorator !== undefined ? Caml_option.valFromOption(startDecorator) : null),
              endDecorator: Caml_option.some(endDecorator !== undefined ? Caml_option.valFromOption(endDecorator) : null),
              "aria-label": ariaLabel !== undefined ? ariaLabel : "",
              id: id !== undefined ? id : "",
              component: component !== undefined ? component : "",
              variant: variant,
              color: color,
              href: href !== undefined ? href : "",
              size: size,
              disabled: disabled,
              sx: {
                "&.Mui-disabled": {
                  backgroundColor: "background.level2"
                }
              },
              children: Caml_option.some(children !== undefined ? Caml_option.valFromOption(children) : null)
            });
}

var make = AppButton;

exports.make = make;
/* @mui/joy/Button Not a pure module */
