// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../../../../utils/Common.bs.js");
var SysCtx$Thin = require("../../../../utils/SysCtx.bs.js");
var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var DateOnly$Util = require("util/src/DateOnly.bs.js");
var CS_Decimal$Util = require("util/src/CustomScalars/CS_Decimal.bs.js");
var SelectorCard$Thin = require("../../../common/molecules/SelectorCard.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var BDCustomPaymentSelector$Thin = require("./BDCustomPaymentSelector.bs.js");

function BDPaymentAmountSelection(props) {
  var setAmount = props.setAmount;
  var setWhichSelected = props.setWhichSelected;
  var whichSelected = props.whichSelected;
  var paymentSuggestions = props.paymentSuggestions;
  var match = paymentSuggestions.payMin;
  var tmp;
  if (match !== undefined) {
    var minNow = match.minNow;
    tmp = JsxRuntime.jsx(SelectorCard$Thin.make, {
          isSelected: Caml_obj.equal(whichSelected, "Min"),
          onClick: (function () {
              setWhichSelected(function (param) {
                    return "Min";
                  });
              setAmount(function (param) {
                    return CS_Decimal$Util.Decimal10_2.toInt(minNow);
                  });
            }),
          title: "Pay the minimum required amount (" + Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(minNow)) + ")",
          description: "Total due within two weeks: " + Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(match.minSoon)),
          children: null
        });
  } else {
    tmp = null;
  }
  var match$1 = paymentSuggestions.payCurrent;
  var tmp$1;
  if (match$1 !== undefined) {
    var currNow = match$1.currNow;
    tmp$1 = JsxRuntime.jsx(SelectorCard$Thin.make, {
          isSelected: Caml_obj.equal(whichSelected, "Curr"),
          onClick: (function () {
              setWhichSelected(function (param) {
                    return "Curr";
                  });
              setAmount(function (param) {
                    return CS_Decimal$Util.Decimal10_2.toInt(currNow);
                  });
            }),
          title: "Make the current deposit (" + Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(currNow)) + ")",
          description: "Next payment due " + DateOnly$Util.format(DateOnly$Util.fromDate(CS_Make$Util.Dateable.Plain.toDate(match$1.nextDue)), SysCtx$Thin.vars.dateFormat),
          children: null
        });
  } else {
    tmp$1 = null;
  }
  var match$2 = paymentSuggestions.payCustom;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(SelectorCard$Thin.make, {
                      isSelected: Caml_obj.equal(whichSelected, "Full"),
                      onClick: (function () {
                          setWhichSelected(function (param) {
                                return "Full";
                              });
                          setAmount(function (param) {
                                return CS_Decimal$Util.Decimal10_2.toInt(paymentSuggestions.payFull);
                              });
                        }),
                      title: "Pay remaining balance",
                      titleAppendix: Caml_option.some(null),
                      description: "Pay the remaining balance of " + Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(paymentSuggestions.payFull)),
                      children: null
                    }),
                tmp,
                tmp$1,
                match$2 !== undefined ? JsxRuntime.jsx(BDCustomPaymentSelector$Thin.make, {
                        minimum: match$2.minimum,
                        maximum: paymentSuggestions.payFull,
                        isSelected: Caml_obj.equal(whichSelected, "Cust"),
                        onClick: (function () {
                            setWhichSelected(function (param) {
                                  return "Cust";
                                });
                            setAmount(function (param) {
                                  
                                });
                          }),
                        title: "Pay a different amount",
                        description: "Pay a custom amount",
                        setAmount: setAmount
                      }) : null
              ]
            });
}

var make = BDPaymentAmountSelection;

exports.make = make;
/* Common-Thin Not a pure module */
