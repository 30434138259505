// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var Stack = require("@mui/joy/Stack").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;

function PaymentFrame(props) {
  var __locked = props.locked;
  var __required = props.required;
  var required = __required !== undefined ? __required : false;
  var locked = __locked !== undefined ? __locked : false;
  return JsxRuntime.jsxs(Stack, {
              direction: "column",
              justifyContent: "center",
              spacing: 1,
              sx: {
                p: {
                  xs: 2,
                  md: 4
                },
                border: "1px solid",
                borderColor: "neutral.300",
                backgroundColor: "background.surface",
                borderRadius: "12px",
                opacity: locked ? "0.8" : "1"
              },
              children: [
                JsxRuntime.jsx(Stack, {
                      justifyContent: "space-between",
                      spacing: 1,
                      sx: {
                        pb: 1
                      },
                      children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                level: "title-lg",
                                children: Caml_option.some(JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                          children: [
                                            props.title,
                                            required ? JsxRuntime.jsx(Typography, {
                                                    color: "danger",
                                                    level: "title-lg",
                                                    sx: {
                                                      pl: "4px"
                                                    },
                                                    children: Caml_option.some(JsxRuntime.jsx("sup", {
                                                              children: "*"
                                                            }))
                                                  }) : null
                                          ]
                                        }))
                              }))
                    }),
                JsxRuntime.jsx(Stack, {
                      direction: "column",
                      children: Caml_option.some(props.children)
                    })
              ]
            });
}

var make = PaymentFrame;

exports.make = make;
/* @mui/joy/Stack Not a pure module */
