// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thin = require("../Dialog.bs.js");
var Link = require("@mui/joy/Link").default;
var List = require("@mui/joy/List").default;
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var ListItem = require("@mui/joy/ListItem").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;

function PlaidTermsModal(props) {
  var onClose = props.onClose;
  return JsxRuntime.jsxs(Dialog$Thin.make, {
              open_: props.open_,
              title: "Plaid Terms & Conditions",
              onClose: onClose,
              children: [
                JsxRuntime.jsx(DialogContent, {
                      children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                spacing: 1,
                                children: Caml_option.some(JsxRuntime.jsxs(List, {
                                          children: [
                                            JsxRuntime.jsx(ListItem, {
                                                  children: Caml_option.some(JsxRuntime.jsxs(Typography, {
                                                            level: "body-sm",
                                                            children: [
                                                              "JoCo Cruise uses ",
                                                              JsxRuntime.jsx(Link, {
                                                                    children: "Plaid",
                                                                    href: "https://plaid.com/",
                                                                    target: "_blank"
                                                                  }),
                                                              " to connect to your bank. Read more in our ",
                                                              JsxRuntime.jsx(Link, {
                                                                    children: "FAQ",
                                                                    href: "https://jococruise.com/2025faq/#how-does-the-ach-transfer-process",
                                                                    target: "_blank"
                                                                  }),
                                                              "."
                                                            ]
                                                          }))
                                                }),
                                            JsxRuntime.jsx(ListItem, {
                                                  children: Caml_option.some(JsxRuntime.jsxs(Typography, {
                                                            level: "body-sm",
                                                            children: [
                                                              "JoCo Cruise's privacy policy can be found ",
                                                              JsxRuntime.jsx(Link, {
                                                                    children: "here",
                                                                    href: "https://jococruise.com/2025faq/#what-is-your-privacy-policy",
                                                                    target: "_blank"
                                                                  }),
                                                              ". Plaid's can be found  ",
                                                              JsxRuntime.jsx(Link, {
                                                                    children: "here",
                                                                    href: "https://plaid.com/legal/#end-user-privacy-policy",
                                                                    target: "_blank"
                                                                  }),
                                                              "."
                                                            ]
                                                          }))
                                                }),
                                            JsxRuntime.jsx(ListItem, {
                                                  children: Caml_option.some(JsxRuntime.jsxs(Typography, {
                                                            level: "body-sm",
                                                            children: [
                                                              "If you do not have a U.S.-based bank account or your bank is not listed, please ",
                                                              JsxRuntime.jsx(Link, {
                                                                    children: "email us",
                                                                    href: "mailto:booking@jococruise.com?subject=My%20Bank%20is%20Not%20Listed",
                                                                    target: "_blank"
                                                                  }),
                                                              "."
                                                            ]
                                                          }))
                                                }),
                                            JsxRuntime.jsx(ListItem, {
                                                  children: Caml_option.some(JsxRuntime.jsxs(Typography, {
                                                            level: "body-sm",
                                                            children: [
                                                              "By using the Plaid service, you are consenting to  ",
                                                              JsxRuntime.jsx(Link, {
                                                                    children: "JoCo Cruise's privacy policy",
                                                                    href: "https://jococruise.com/2025faq/#what-is-your-privacy-policy",
                                                                    target: "_blank"
                                                                  }),
                                                              "  and to ",
                                                              JsxRuntime.jsx(Link, {
                                                                    children: "Plaid's",
                                                                    href: "https://plaid.com/legal/#end-user-privacy-policy",
                                                                    target: "_blank"
                                                                  }),
                                                              "."
                                                            ]
                                                          }))
                                                }),
                                            JsxRuntime.jsx(ListItem, {
                                                  children: Caml_option.some(JsxRuntime.jsxs(Typography, {
                                                            level: "body-sm",
                                                            children: [
                                                              "If you live in the U.S. but your system won't let you send an ACH transfer, please  ",
                                                              JsxRuntime.jsx(Link, {
                                                                    children: "see our FAQ",
                                                                    href: "https://jococruise.com/2025faq/#i-live-in-the-us-but-your-system",
                                                                    target: "_blank"
                                                                  }),
                                                              "  and email us at ",
                                                              JsxRuntime.jsx(Link, {
                                                                    children: "booking@jococruise.com",
                                                                    href: "mailto:booking@jococruise.com?",
                                                                    target: "_blank"
                                                                  }),
                                                              " so that we can work directly with you to complete your payment."
                                                            ]
                                                          }))
                                                }),
                                            JsxRuntime.jsx(ListItem, {
                                                  children: Caml_option.some(JsxRuntime.jsxs(Typography, {
                                                            level: "body-sm",
                                                            children: [
                                                              "If you don't live in the U.S. and/or otherwise cannot send an ACH bank transfer payment, please email us at  ",
                                                              JsxRuntime.jsx(Link, {
                                                                    children: "booking@jococruise.com",
                                                                    href: "mailto:booking@jococruise.com?",
                                                                    target: "_blank"
                                                                  }),
                                                              " so that we can work directly with you to complete your payment."
                                                            ]
                                                          }))
                                                })
                                          ],
                                          component: "ul"
                                        }))
                              })),
                      sx: {
                        px: 2
                      }
                    }),
                JsxRuntime.jsx(DialogActions, {
                      children: Caml_option.some(JsxRuntime.jsx(Button, {
                                onClick: onClose,
                                children: "Close"
                              }))
                    })
              ]
            });
}

var make = PlaidTermsModal;

exports.make = make;
/* Dialog-Thin Not a pure module */
