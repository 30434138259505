// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Txt$Thin = require("../../../text/Txt.bs.js");
var Common$Thin = require("../../../utils/Common.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var RequestTokenSubmit_RequestPasswordResetMutation_graphql$Thin = require("../../../__generated__/RequestTokenSubmit_RequestPasswordResetMutation_graphql.bs.js");

var convertVariables = RequestTokenSubmit_RequestPasswordResetMutation_graphql$Thin.Internal.convertVariables;

var convertResponse = RequestTokenSubmit_RequestPasswordResetMutation_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = RequestTokenSubmit_RequestPasswordResetMutation_graphql$Thin.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, RequestTokenSubmit_RequestPasswordResetMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, RequestTokenSubmit_RequestPasswordResetMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var RequestPasswordResetMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation() {
  var match = use();
  var mutate = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var email = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setTokenSent = match$2[1];
  var match$3 = React.useState(function () {
        
      });
  var setTokenError = match$3[1];
  var requestToken = function (param) {
    if (email === "" || !Common$Thin.Email.isValid(email)) {
      return ;
    } else {
      setTokenError(function (param) {
            
          });
      mutate({
            input: {
              allegedEmail: email
            }
          }, undefined, undefined, undefined, (function (param, err) {
              if (err === undefined) {
                return setTokenSent(function (param) {
                            return true;
                          });
              } else {
                return setTokenError(function (param) {
                            return Txt$Thin.ResetPassword.resetLinkError.value;
                          });
              }
            }), (function (err) {
              console.log(err);
              setTokenError(function (param) {
                    return Txt$Thin.$$Error.genericFormSubmit.value;
                  });
            }), undefined);
      return ;
    }
  };
  var onSubmit = function (e) {
    e.preventDefault();
    requestToken();
  };
  var onClick = function (e) {
    e.preventDefault();
    requestToken();
  };
  return {
          isMutating: match[1],
          email: email,
          setEmail: match$1[1],
          onSubmit: onSubmit,
          onClick: onClick,
          tokenSent: match$2[0],
          tokenError: match$3[0]
        };
}

exports.RequestPasswordResetMutation = RequestPasswordResetMutation;
exports.useMutation = useMutation;
/* commitMutation Not a pure module */
