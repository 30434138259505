// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RelayEnv$Thin = require("../../../RelayEnv.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var PaymentSuggestions$Thin = require("../../../utils/sales-flow/PaymentSuggestions.bs.js");
var CurrentUserBookingSessionsQuery_graphql$Thin = require("../../../__generated__/CurrentUserBookingSessionsQuery_graphql.bs.js");

var convertVariables = CurrentUserBookingSessionsQuery_graphql$Thin.Internal.convertVariables;

var convertResponse = CurrentUserBookingSessionsQuery_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = CurrentUserBookingSessionsQuery_graphql$Thin.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, CurrentUserBookingSessionsQuery_graphql$Thin.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, CurrentUserBookingSessionsQuery_graphql$Thin.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(CurrentUserBookingSessionsQuery_graphql$Thin.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(CurrentUserBookingSessionsQuery_graphql$Thin.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(CurrentUserBookingSessionsQuery_graphql$Thin.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(CurrentUserBookingSessionsQuery_graphql$Thin.node, convertVariables);

var Query = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function $$fetch$1(setPaymentSuggestions, setError, voyageSlug) {
  setError(function (param) {
        
      });
  $$fetch(RelayEnv$Thin.environment, {
        voySlugString: CS_Slugs$Util.VoyageSlug.toString(voyageSlug)
      }, (function (res) {
          if (res.TAG === "Ok") {
            var match = res._0.currentUserBookingSessions;
            if (match !== undefined) {
              var match$1 = match.nodes;
              if (match$1.length === 1) {
                var match$2 = match$1[0];
                var paymentPlan = match$2.paymentPlan;
                return setPaymentSuggestions(function (param) {
                            if (paymentPlan !== undefined) {
                              return PaymentSuggestions$Thin.getPaymentSuggestions({
                                          TAG: "CurrentSession",
                                          _0: paymentPlan
                                        });
                            }
                            
                          });
              }
              
            }
            
          }
          setError(function (param) {
                return "An error occurred fetching payment suggestions. Please try again.";
              });
        }), undefined, undefined);
}

exports.Query = Query;
exports.$$fetch = $$fetch$1;
/* use Not a pure module */
