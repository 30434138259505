// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Drawer = require("@mui/joy/Drawer").default;
var Divider = require("@mui/joy/Divider").default;
var JsxRuntime = require("react/jsx-runtime");
var ModalClose = require("@mui/joy/ModalClose").default;
var Typography = require("@mui/joy/Typography").default;
var DialogTitle = require("@mui/joy/DialogTitle").default;

function AppDrawer(props) {
  var __desktopAnchor = props.desktopAnchor;
  var title = props.title;
  var desktopAnchor = __desktopAnchor !== undefined ? __desktopAnchor : "right";
  var match = React.useState(function () {
        return "right";
      });
  var setAnchor = match[1];
  var anchor = match[0];
  React.useEffect((function () {
          var toggleAnchor = function (param) {
            var width = innerWidth;
            if (width < 900.0) {
              return setAnchor(function (param) {
                          return "bottom";
                        });
            } else {
              return setAnchor(function (param) {
                          return desktopAnchor;
                        });
            }
          };
          toggleAnchor();
          addEventListener("resize", toggleAnchor, true);
          return (function () {
                    removeEventListener("resize", toggleAnchor, true);
                  });
        }), []);
  var titleComponent = title !== undefined ? JsxRuntime.jsx(DialogTitle, {
          children: Caml_option.some(JsxRuntime.jsx(Typography, {
                    startDecorator: Caml_option.some(Belt_Option.getWithDefault(props.titleStartDecorator, null)),
                    level: "inherit",
                    children: Caml_option.some(title)
                  })),
          sx: {
            px: {
              xs: 1,
              md: 2
            },
            py: 2
          }
        }) : null;
  return JsxRuntime.jsxs(Drawer, {
              children: [
                JsxRuntime.jsx(ModalClose, {}),
                titleComponent,
                JsxRuntime.jsx(Divider, {}),
                props.children
              ],
              open: props.open_,
              anchor: anchor,
              disablePortal: true,
              onClose: props.onClose,
              slotProps: {
                backdrop: {
                  sx: {
                    backdropFilter: "none",
                    backgroundColor: "rgba(16, 13, 12, 0.7)"
                  }
                },
                content: {
                  sx: {
                    borderRadius: "4px",
                    borderBottomLeftRadius: anchor === "right" ? "4px" : "0px",
                    borderBottomRightRadius: anchor === "right" ? "4px" : "0px",
                    margin: "8px",
                    mt: anchor === "right" ? "8px" : "0",
                    mb: anchor === "right" ? "8px" : "0",
                    height: anchor === "right" ? "calc(100% - 16px)" : "clamp(500px, 66%, 75%)",
                    width: anchor === "right" ? "clamp(500px, 45%, 75%)" : "calc(100% - 16px)",
                    overflow: "auto"
                  }
                }
              }
            });
}

var make = AppDrawer;

exports.make = make;
/* react Not a pure module */
