// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"numBerths":{"b":""},"imageSet_imageSetSlug":{"c":"Util.CustomScalars.ImageSetSlug.Exn"},"cabinNumber":{"c":"Util.CustomScalars.CabinNumber.Exn"},"cabinDeckNumber":{"c":"Util.CustomScalars.DeckNumber.Exn"},"cabinClassSlug":{"c":"Util.CustomScalars.CabinClassSlug.Exn"},"cabinClassName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"cabinCategorySlug":{"c":"Util.CustomScalars.CabinCategorySlug.Exn"},"cabinCategoryName":{"c":"Util.CustomScalars.NonemptyString.Exn"}}};

var fragmentConverterMap = {
  "Util.CustomScalars.NonemptyString.Exn": CS_NonemptyStrings$Util.NonEmptyString.Exn.parse,
  "Util.CustomScalars.CabinCategorySlug.Exn": CS_Slugs$Util.CabinCategorySlug.Exn.parse,
  "Util.CustomScalars.CabinClassSlug.Exn": CS_Slugs$Util.CabinClassSlug.Exn.parse,
  "Util.CustomScalars.DeckNumber.Exn": CS_NonemptyStrings$Util.DeckNumber.Exn.parse,
  "Util.CustomScalars.CabinNumber.Exn": CS_NonemptyStrings$Util.CabinNumber.Exn.parse,
  "Util.CustomScalars.ImageSetSlug.Exn": CS_NonemptyStrings$Util.ImageSetSlug.Exn.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

function elevatorProximity_decode($$enum) {
  if ($$enum === "NEARELEVATORS" || $$enum === "FARELEVATORS") {
    return $$enum;
  }
  
}

function elevatorProximity_fromString(str) {
  return elevatorProximity_decode(str);
}

function latitudinality_decode($$enum) {
  if ($$enum === "PORT" || $$enum === "STARBOARD") {
    return $$enum;
  }
  
}

function latitudinality_fromString(str) {
  return latitudinality_decode(str);
}

function longitudinality_decode($$enum) {
  if ($$enum === "AFT" || $$enum === "FORE" || $$enum === "MID") {
    return $$enum;
  }
  
}

function longitudinality_fromString(str) {
  return longitudinality_decode(str);
}

var Utils = {
  elevatorProximity_decode: elevatorProximity_decode,
  elevatorProximity_fromString: elevatorProximity_fromString,
  latitudinality_decode: latitudinality_decode,
  latitudinality_fromString: latitudinality_fromString,
  longitudinality_decode: longitudinality_decode,
  longitudinality_fromString: longitudinality_fromString
};

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BDStateroomDetails_stateroom",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cabinCategorySlug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cabinCategoryName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cabinClassSlug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cabinClassName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numBerths",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cabinNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cabinDeckNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cabinForeAftPlacement",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cabinElevatorPlacement",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cabinPortStarboardPlacement",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ImageSet",
      "kind": "LinkedField",
      "name": "imageSet",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "imageSetSlug",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BookingDetail",
  "abstractKey": null
};

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* CS_Slugs-Util Not a pure module */
