"use strict";

module.exports = function (method, body) {
  if (typeof method !== "string") {
    throw new Error("Method must be a string");
  }

  if (method.toLowerCase() !== "get" && body != null) {
    body = typeof body === "string" ? body : JSON.stringify(body);
  }

  return body;
};
