// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Joy$Util = require("util/src/Joy.bs.js");
var Txt$Thin = require("../../text/Txt.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Merge$Util = require("util/src/Merge.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SysCtx$Thin = require("../../utils/SysCtx.bs.js");
var AppAlert$Thin = require("../common/atoms/AppAlert.bs.js");
var Stack = require("@mui/joy/Stack").default;
var AppDrawer$Thin = require("../common/molecules/AppDrawer.bs.js");
var Button = require("@mui/joy/Button").default;
var $$Option = require("@mui/joy/Option").default;
var DraftUtils$Thin = require("../../utils/booking/DraftUtils.bs.js");
var Divider = require("@mui/joy/Divider").default;
var BookingDiff$Thin = require("../../utils/booking/BookingDiff.bs.js");
var Checkbox = require("@mui/joy/Checkbox").default;
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var CheckValidity$Thin = require("./drafts/CheckValidity.bs.js");
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var VoyageMiniCard$Thin = require("../common/molecules/VoyageMiniCard.bs.js");
var FormControl = require("@mui/joy/FormControl").default;
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var UpdateAccountData$Thin = require("../common/mutations/UpdateAccountData.bs.js");
var FormHelperText = require("@mui/joy/FormHelperText").default;
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var CS_SQLDatesAndTimes$Util = require("util/src/CustomScalars/CS_SQLDatesAndTimes.bs.js");
var PersonOutlined = require("@mui/icons-material/PersonOutlined").default;
var BDPatchPaxDrawer_query_graphql$Thin = require("../../__generated__/BDPatchPaxDrawer_query_graphql.bs.js");
var FetchOwnOrConnectedUserVoyages$Thin = require("../common/queries/FetchOwnOrConnectedUserVoyages.bs.js");

var convertFragment = BDPatchPaxDrawer_query_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(BDPatchPaxDrawer_query_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BDPatchPaxDrawer_query_graphql$Thin.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(BDPatchPaxDrawer_query_graphql$Thin.node, convertFragment, fRef);
}

var QueryFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

function BDPatchPaxDrawer(props) {
  var refetchBookingDetails = props.refetchBookingDetails;
  var pax = props.pax;
  var isCurrentUserConnected = props.isCurrentUserConnected;
  var isSelf = props.isSelf;
  var isBc = props.isBc;
  var currentVoyage = props.currentVoyage;
  var onClose = props.onClose;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var diff = BookingDiff$Thin.parse(url.search);
  var match = React.useState(function () {
        return false;
      });
  var setDateFocused = match[1];
  var countryData = use(props.countryFrag);
  var match$1 = countryData.countries;
  var countryTuples = match$1 !== undefined ? Belt_Array.map(match$1.nodes, (function (c) {
            return [
                    c.alpha3,
                    c.englishName
                  ];
          })) : [];
  var match$2 = pax.paxPatchPerm;
  var match$3 = pax.accountId;
  var initialPatchState;
  var exit = 0;
  switch (match$2) {
    case "Limited" :
        if (match$3 !== undefined) {
          initialPatchState = {
            TAG: "Limited",
            _0: DraftUtils$Thin.Pax.getPaxPatchFromPax(pax, Caml_option.valFromOption(match$3))
          };
        } else {
          exit = 1;
        }
        break;
    case "All" :
    case "None" :
        exit = 1;
        break;
    
  }
  if (exit === 1) {
    initialPatchState = {
      TAG: "Full",
      _0: DraftUtils$Thin.Pax.getConnectedPatchFromPax(pax)
    };
  }
  var match$4 = React.useState(function () {
        return initialPatchState;
      });
  var setPatch = match$4[1];
  var patch = match$4[0];
  var patchesSame = function (initial, $$new) {
    if (initial.TAG === "Limited") {
      var paxPatch = initial._0;
      if ($$new.TAG !== "Limited") {
        return false;
      }
      var newPaxPatch = $$new._0;
      if (Caml_obj.equal(paxPatch.pDocGender, newPaxPatch.pDocGender)) {
        return Caml_obj.equal(paxPatch.pDocCitizenship, newPaxPatch.pDocCitizenship);
      } else {
        return false;
      }
    }
    var connPatch = initial._0;
    if ($$new.TAG === "Limited") {
      return false;
    }
    var newConnPatch = $$new._0;
    if (Caml_obj.equal(connPatch.cpDocFirstName, newConnPatch.cpDocFirstName) && Caml_obj.equal(connPatch.cpDocLastName, newConnPatch.cpDocLastName) && Caml_obj.equal(connPatch.cpDocGender, newConnPatch.cpDocGender) && Caml_obj.equal(connPatch.cpDocCitizenship, newConnPatch.cpDocCitizenship)) {
      return Caml_obj.equal(connPatch.cpBirthdate, newConnPatch.cpBirthdate);
    } else {
      return false;
    }
  };
  var match$5 = React.useState(function () {
        return false;
      });
  var setShowVoyageOptions = match$5[1];
  var showVoyageOptions = match$5[0];
  var isVoyageSelected = function (slug, patch) {
    return Belt_Array.some(patch.updateOtherVoyages, (function (s) {
                  return s === slug;
                }));
  };
  var match$6 = FetchOwnOrConnectedUserVoyages$Thin.useQuery(props.brandFamily, Caml_option.some(currentVoyage));
  var fetchVoyages = match$6.fetchVoyages;
  var setFetchError = match$6.setError;
  var fetchError = match$6.error;
  var voyOpt = match$6.voyOpt;
  React.useEffect((function () {
          var match = pax.accountId;
          if (isSelf) {
            if (match !== undefined) {
              fetchVoyages(Caml_option.valFromOption(match), "Self");
            }
            
          } else if (isCurrentUserConnected && match !== undefined) {
            fetchVoyages(Caml_option.valFromOption(match), "Connected");
          }
          
        }), []);
  var match$7 = CheckValidity$Thin.useQuery(props.getPreviewFromDiff, url, onClose);
  var addOrErr = match$7.addOrErr;
  var setValidityError = match$7.setError;
  var match$8 = UpdateAccountData$Thin.useMutation(function () {
        refetchBookingDetails();
        onClose();
      });
  var patchFromBooking = match$8.patchFromBooking;
  var setPatchError = match$8.setError;
  var patchError = match$8.error;
  var clearAllAndClose = function (param) {
    setFetchError(function (param) {
          
        });
    setPatchError(function (param) {
          
        });
    setValidityError(function (param) {
          
        });
    onClose();
  };
  var onSubmit = function (e) {
    e.preventDefault();
    if (isBc) {
      if (patch.TAG === "Limited") {
        return addOrErr(BookingDiff$Thin.Alter.patchPax(diff, pax.idx, patch._0));
      } else {
        return addOrErr(BookingDiff$Thin.Alter.updateNewConnected(diff, pax.idx, patch._0));
      }
    }
    if (patch.TAG === "Limited") {
      return patchFromBooking(patch._0, currentVoyage);
    }
    console.log("Connected patch not supported for non-bc");
  };
  var tmp;
  if (patch.TAG === "Limited") {
    tmp = false;
  } else {
    var connectedPatch = patch._0;
    tmp = Belt_Option.isNone(connectedPatch.cpDocFirstName) || Belt_Result.isError(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(Belt_Option.getWithDefault(connectedPatch.cpDocFirstName, "")));
  }
  var tmp$1;
  tmp$1 = patch.TAG === "Limited" ? true : false;
  var tmp$2;
  if (patch.TAG === "Limited") {
    var docFName = pax.docFName;
    tmp$2 = docFName !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(Caml_option.valFromOption(docFName)) : "";
  } else {
    var docFName$1 = patch._0.cpDocFirstName;
    tmp$2 = docFName$1 !== undefined ? docFName$1 : "";
  }
  var tmp$3;
  tmp$3 = patch.TAG === "Limited" ? JsxRuntime.jsx(FormHelperText, {
          children: Caml_option.some(JsxRuntime.jsx(Typography, {
                    level: "body-sm",
                    children: Caml_option.some(Merge$Util.render(Txt$Thin.BookingDetails.legalUpdateHelpText, SysCtx$Thin.vars))
                  }))
        }) : null;
  var tmp$4;
  if (patch.TAG === "Limited") {
    tmp$4 = false;
  } else {
    var connectedPatch$1 = patch._0;
    tmp$4 = Belt_Option.isNone(connectedPatch$1.cpDocLastName) || Belt_Result.isError(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(Belt_Option.getWithDefault(connectedPatch$1.cpDocLastName, "")));
  }
  var tmp$5;
  tmp$5 = patch.TAG === "Limited" ? true : false;
  var tmp$6;
  if (patch.TAG === "Limited") {
    var docLName = pax.docLName;
    tmp$6 = docLName !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(Caml_option.valFromOption(docLName)) : "";
  } else {
    var docLName$1 = patch._0.cpDocLastName;
    tmp$6 = docLName$1 !== undefined ? docLName$1 : "";
  }
  var tmp$7;
  tmp$7 = patch.TAG === "Limited" ? JsxRuntime.jsx(FormHelperText, {
          children: Caml_option.some(JsxRuntime.jsx(Typography, {
                    level: "body-sm",
                    children: Caml_option.some(Merge$Util.render(Txt$Thin.BookingDetails.legalUpdateHelpText, SysCtx$Thin.vars))
                  }))
        }) : null;
  var tmp$8;
  tmp$8 = patch.TAG === "Limited" ? false : Belt_Option.isNone(patch._0.cpDocGender);
  var tmp$9;
  tmp$9 = patch.TAG === "Limited" ? patch._0.pDocGender : patch._0.cpDocGender;
  var tmp$10;
  tmp$10 = patch.TAG === "Limited" ? false : Belt_Option.isNone(patch._0.cpDocCitizenship);
  var tmp$11;
  tmp$11 = patch.TAG === "Limited" ? patch._0.pDocCitizenship : patch._0.cpDocCitizenship;
  var tmp$12;
  tmp$12 = patch.TAG === "Limited" ? false : Belt_Option.isNone(patch._0.cpBirthdate);
  var tmp$13;
  tmp$13 = match[0] || patch.TAG === "Limited" || !Belt_Option.isNone(patch._0.cpBirthdate) ? "date" : "text";
  var tmp$14;
  tmp$14 = patch.TAG === "Limited" ? true : false;
  var tmp$15;
  if (patch.TAG === "Limited") {
    var birthdate = pax.birthdate;
    tmp$15 = birthdate !== undefined ? CS_SQLDatesAndTimes$Util.DateOnly.toPrimitive(Caml_option.valFromOption(birthdate)) : "";
  } else {
    var birthdate$1 = patch._0.cpBirthdate;
    tmp$15 = birthdate$1 !== undefined ? CS_SQLDatesAndTimes$Util.DateOnly.toPrimitive(Caml_option.valFromOption(birthdate$1)) : "";
  }
  var tmp$16;
  tmp$16 = patch.TAG === "Limited" ? JsxRuntime.jsx(FormHelperText, {
          children: Caml_option.some(JsxRuntime.jsx(Typography, {
                    level: "body-sm",
                    children: Caml_option.some(Merge$Util.render(Txt$Thin.BookingDetails.legalUpdateHelpText, SysCtx$Thin.vars))
                  }))
        }) : null;
  var match$9 = isBc || isCurrentUserConnected;
  var tmp$17;
  if (match$9 && patch.TAG === "Limited") {
    var paxPatch = patch._0;
    tmp$17 = JsxRuntime.jsxs(Stack, {
          direction: "column",
          spacing: 2,
          children: [
            JsxRuntime.jsxs(Stack, {
                  direction: "row",
                  alignItems: "center",
                  spacing: 1,
                  sx: {
                    cursor: "pointer"
                  },
                  onClick: (function (param) {
                      setPatch(function (param) {
                            return {
                                    TAG: "Limited",
                                    _0: {
                                      accountId: paxPatch.accountId,
                                      pDocGender: paxPatch.pDocGender,
                                      pDocCitizenship: paxPatch.pDocCitizenship,
                                      updateUserAccount: !paxPatch.updateUserAccount,
                                      updateOtherVoyages: paxPatch.updateOtherVoyages
                                    }
                                  };
                          });
                    }),
                  children: [
                    JsxRuntime.jsx(Checkbox, {
                          checked: paxPatch.updateUserAccount,
                          size: "sm"
                        }),
                    JsxRuntime.jsx(Typography, {
                          level: "body-sm",
                          children: "Apply changes to user account"
                        })
                  ]
                }),
            voyOpt !== undefined && voyOpt.length !== 0 ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                    children: [
                      JsxRuntime.jsxs(Stack, {
                            direction: "row",
                            alignItems: "center",
                            spacing: 1,
                            sx: {
                              cursor: "pointer"
                            },
                            onClick: (function (param) {
                                setShowVoyageOptions(function (param) {
                                      return !showVoyageOptions;
                                    });
                              }),
                            children: [
                              JsxRuntime.jsx(Checkbox, {
                                    checked: showVoyageOptions,
                                    size: "sm"
                                  }),
                              JsxRuntime.jsx(Typography, {
                                    level: "body-sm",
                                    children: "Apply changes to other booking(s)"
                                  })
                            ]
                          }),
                      showVoyageOptions ? JsxRuntime.jsx(Joy$Util.Grid.make, {
                              children: Caml_option.some(Belt_Array.mapWithIndex(voyOpt, (function (i, v) {
                                          return JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                      children: Caml_option.some(JsxRuntime.jsx(VoyageMiniCard$Thin.make, {
                                                                voyage: v,
                                                                onSelect: (function () {
                                                                    var slug = v.voyageSlug;
                                                                    if (isVoyageSelected(slug, paxPatch)) {
                                                                      return setPatch(function (param) {
                                                                                  return {
                                                                                          TAG: "Limited",
                                                                                          _0: {
                                                                                            accountId: paxPatch.accountId,
                                                                                            pDocGender: paxPatch.pDocGender,
                                                                                            pDocCitizenship: paxPatch.pDocCitizenship,
                                                                                            updateUserAccount: paxPatch.updateUserAccount,
                                                                                            updateOtherVoyages: Belt_Array.keep(paxPatch.updateOtherVoyages, (function (s) {
                                                                                                    return s !== slug;
                                                                                                  }))
                                                                                          }
                                                                                        };
                                                                                });
                                                                    } else {
                                                                      return setPatch(function (param) {
                                                                                  return {
                                                                                          TAG: "Limited",
                                                                                          _0: {
                                                                                            accountId: paxPatch.accountId,
                                                                                            pDocGender: paxPatch.pDocGender,
                                                                                            pDocCitizenship: paxPatch.pDocCitizenship,
                                                                                            updateUserAccount: paxPatch.updateUserAccount,
                                                                                            updateOtherVoyages: Belt_Array.concat(paxPatch.updateOtherVoyages, [slug])
                                                                                          }
                                                                                        };
                                                                                });
                                                                    }
                                                                  }),
                                                                isSelected: isVoyageSelected(v.voyageSlug, paxPatch)
                                                              })),
                                                      xs: 12,
                                                      md: 6
                                                    }, String(i));
                                        }))),
                              container: true
                            }) : null
                    ]
                  }) : null
          ]
        });
  } else {
    tmp$17 = null;
  }
  var tmp$18 = true;
  if (!Caml_obj.equal(patch, initialPatchState)) {
    var tmp$19;
    if (patch.TAG === "Limited") {
      tmp$19 = Caml_obj.equal(initialPatchState, {
            TAG: "Limited",
            _0: patch._0
          });
    } else {
      var connectedPatch$2 = patch._0;
      tmp$19 = Belt_Option.isNone(connectedPatch$2.cpDocFirstName) || Belt_Option.isNone(connectedPatch$2.cpDocLastName) || Belt_Option.isNone(connectedPatch$2.cpDocGender) || Belt_Option.isNone(connectedPatch$2.cpDocCitizenship) || Belt_Option.isNone(connectedPatch$2.cpBirthdate);
    }
    tmp$18 = tmp$19;
  }
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(AppDrawer$Thin.make, {
                      open_: props.open_,
                      title: "Edit " + (
                        isSelf ? "your" : DraftUtils$Thin.Pax.getPaxDisplayName(pax) + "'s"
                      ) + " details",
                      onClose: clearAllAndClose,
                      children: [
                        JsxRuntime.jsx(DialogContent, {
                              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                        direction: "column",
                                        spacing: 2,
                                        sx: {
                                          p: {
                                            xs: 2,
                                            sm: 4
                                          }
                                        },
                                        children: [
                                          JsxRuntime.jsx(UpdateAccountData$Thin.Form.FormWrapper.make, {
                                                startDecorator: JsxRuntime.jsx(PersonOutlined, {}),
                                                title: "Personal Information",
                                                titleSize: "lg",
                                                variant: "Gray",
                                                showTitleSection: true,
                                                children: JsxRuntime.jsx(Stack, {
                                                      sx: {
                                                        p: 2
                                                      },
                                                      children: Caml_option.some(JsxRuntime.jsxs(Joy$Util.Grid.make, {
                                                                children: [
                                                                  JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                                        children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                                                                                  error: tmp,
                                                                                  children: [
                                                                                    JsxRuntime.jsx(FormLabel, {
                                                                                          children: Caml_option.some(Txt$Thin.BookingDetails.addPassDetailsFirstName.value)
                                                                                        }),
                                                                                    JsxRuntime.jsx(Joy$Util.Input.make, {
                                                                                          type_: "text",
                                                                                          placeholder: Txt$Thin.BookingDetails.addPassDetailsFirstName.value,
                                                                                          disabled: tmp$1,
                                                                                          variant: "plain",
                                                                                          value: tmp$2,
                                                                                          onChange: (function (e) {
                                                                                              var val = e.currentTarget.value;
                                                                                              if (patch.TAG === "Limited") {
                                                                                                return ;
                                                                                              }
                                                                                              var patch$1 = patch._0;
                                                                                              setPatch(function (param) {
                                                                                                    return {
                                                                                                            TAG: "Full",
                                                                                                            _0: {
                                                                                                              cpDocFirstName: val,
                                                                                                              cpDocLastName: patch$1.cpDocLastName,
                                                                                                              cpDocGender: patch$1.cpDocGender,
                                                                                                              cpDocCitizenship: patch$1.cpDocCitizenship,
                                                                                                              cpBirthdate: patch$1.cpBirthdate
                                                                                                            }
                                                                                                          };
                                                                                                  });
                                                                                            })
                                                                                        }),
                                                                                    tmp$3
                                                                                  ]
                                                                                })),
                                                                        xs: 12,
                                                                        md: 6
                                                                      }),
                                                                  JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                                        children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                                                                                  error: tmp$4,
                                                                                  children: [
                                                                                    JsxRuntime.jsx(FormLabel, {
                                                                                          children: Caml_option.some(Txt$Thin.BookingDetails.addPassDetailsLastName.value)
                                                                                        }),
                                                                                    JsxRuntime.jsx(Joy$Util.Input.make, {
                                                                                          type_: "text",
                                                                                          placeholder: Txt$Thin.BookingDetails.addPassDetailsLastName.value,
                                                                                          disabled: tmp$5,
                                                                                          variant: "plain",
                                                                                          value: tmp$6,
                                                                                          onChange: (function (e) {
                                                                                              var val = e.currentTarget.value;
                                                                                              if (patch.TAG === "Limited") {
                                                                                                return ;
                                                                                              }
                                                                                              var patch$1 = patch._0;
                                                                                              setPatch(function (param) {
                                                                                                    return {
                                                                                                            TAG: "Full",
                                                                                                            _0: {
                                                                                                              cpDocFirstName: patch$1.cpDocFirstName,
                                                                                                              cpDocLastName: val,
                                                                                                              cpDocGender: patch$1.cpDocGender,
                                                                                                              cpDocCitizenship: patch$1.cpDocCitizenship,
                                                                                                              cpBirthdate: patch$1.cpBirthdate
                                                                                                            }
                                                                                                          };
                                                                                                  });
                                                                                            })
                                                                                        }),
                                                                                    tmp$7
                                                                                  ]
                                                                                })),
                                                                        xs: 12,
                                                                        md: 6
                                                                      }),
                                                                  JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                                        children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                                                                                  error: tmp$8,
                                                                                  children: [
                                                                                    JsxRuntime.jsx(FormLabel, {
                                                                                          children: Caml_option.some(Txt$Thin.BookingDetails.addPassDetailsGender.value)
                                                                                        }),
                                                                                    JsxRuntime.jsx(Joy$Util.JoySelect.make, {
                                                                                          children: Caml_option.some(Belt_Array.mapWithIndex(DraftUtils$Thin.Pax.genderOptions, (function (i, g) {
                                                                                                      return JsxRuntime.jsx($$Option, {
                                                                                                                  value: g,
                                                                                                                  children: Caml_option.some(DraftUtils$Thin.Pax.genderToText(g))
                                                                                                                }, String(i));
                                                                                                    }))),
                                                                                          onChange: (function (param, v) {
                                                                                              if (patch.TAG === "Limited") {
                                                                                                if (v === undefined) {
                                                                                                  return ;
                                                                                                }
                                                                                                var gender = Caml_option.valFromOption(v);
                                                                                                if (gender === undefined) {
                                                                                                  return ;
                                                                                                }
                                                                                                var patch$1 = patch._0;
                                                                                                return setPatch(function (param) {
                                                                                                            return {
                                                                                                                    TAG: "Limited",
                                                                                                                    _0: {
                                                                                                                      accountId: patch$1.accountId,
                                                                                                                      pDocGender: gender,
                                                                                                                      pDocCitizenship: patch$1.pDocCitizenship,
                                                                                                                      updateUserAccount: patch$1.updateUserAccount,
                                                                                                                      updateOtherVoyages: patch$1.updateOtherVoyages
                                                                                                                    }
                                                                                                                  };
                                                                                                          });
                                                                                              }
                                                                                              if (v === undefined) {
                                                                                                return ;
                                                                                              }
                                                                                              var gender$1 = Caml_option.valFromOption(v);
                                                                                              if (gender$1 === undefined) {
                                                                                                return ;
                                                                                              }
                                                                                              var patch$2 = patch._0;
                                                                                              setPatch(function (param) {
                                                                                                    return {
                                                                                                            TAG: "Full",
                                                                                                            _0: {
                                                                                                              cpDocFirstName: patch$2.cpDocFirstName,
                                                                                                              cpDocLastName: patch$2.cpDocLastName,
                                                                                                              cpDocGender: gender$1,
                                                                                                              cpDocCitizenship: patch$2.cpDocCitizenship,
                                                                                                              cpBirthdate: patch$2.cpBirthdate
                                                                                                            }
                                                                                                          };
                                                                                                  });
                                                                                            }),
                                                                                          placeholder: Txt$Thin.BookingDetails.addPassDetailsGender.value,
                                                                                          slotProps: {
                                                                                            listbox: {
                                                                                              sx: {
                                                                                                zIndex: "modal"
                                                                                              }
                                                                                            }
                                                                                          },
                                                                                          value: tmp$9,
                                                                                          variant: "plain"
                                                                                        })
                                                                                  ]
                                                                                })),
                                                                        xs: 12,
                                                                        md: 6
                                                                      }),
                                                                  JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                                        children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                                                                                  error: tmp$10,
                                                                                  children: [
                                                                                    JsxRuntime.jsx(FormLabel, {
                                                                                          children: Caml_option.some(Txt$Thin.BookingDetails.addPassDetailsCountry.value)
                                                                                        }),
                                                                                    JsxRuntime.jsx(Joy$Util.JoySelect.make, {
                                                                                          children: Caml_option.some(Belt_Array.map(countryTuples, (function (param) {
                                                                                                      var code = param[0];
                                                                                                      return JsxRuntime.jsx($$Option, {
                                                                                                                  value: Caml_option.some(code),
                                                                                                                  children: Caml_option.some(param[1])
                                                                                                                }, code);
                                                                                                    }))),
                                                                                          onChange: (function (param, v) {
                                                                                              if (patch.TAG === "Limited") {
                                                                                                if (v === undefined) {
                                                                                                  return ;
                                                                                                }
                                                                                                var country = Caml_option.valFromOption(v);
                                                                                                if (country === undefined) {
                                                                                                  return ;
                                                                                                }
                                                                                                var country$1 = Caml_option.valFromOption(country);
                                                                                                var patch$1 = patch._0;
                                                                                                return setPatch(function (param) {
                                                                                                            return {
                                                                                                                    TAG: "Limited",
                                                                                                                    _0: {
                                                                                                                      accountId: patch$1.accountId,
                                                                                                                      pDocGender: patch$1.pDocGender,
                                                                                                                      pDocCitizenship: Caml_option.some(country$1),
                                                                                                                      updateUserAccount: patch$1.updateUserAccount,
                                                                                                                      updateOtherVoyages: patch$1.updateOtherVoyages
                                                                                                                    }
                                                                                                                  };
                                                                                                          });
                                                                                              }
                                                                                              if (v === undefined) {
                                                                                                return ;
                                                                                              }
                                                                                              var country$2 = Caml_option.valFromOption(v);
                                                                                              if (country$2 === undefined) {
                                                                                                return ;
                                                                                              }
                                                                                              var country$3 = Caml_option.valFromOption(country$2);
                                                                                              var patch$2 = patch._0;
                                                                                              setPatch(function (param) {
                                                                                                    return {
                                                                                                            TAG: "Full",
                                                                                                            _0: {
                                                                                                              cpDocFirstName: patch$2.cpDocFirstName,
                                                                                                              cpDocLastName: patch$2.cpDocLastName,
                                                                                                              cpDocGender: patch$2.cpDocGender,
                                                                                                              cpDocCitizenship: Caml_option.some(country$3),
                                                                                                              cpBirthdate: patch$2.cpBirthdate
                                                                                                            }
                                                                                                          };
                                                                                                  });
                                                                                            }),
                                                                                          placeholder: Txt$Thin.BookingDetails.addPassDetailsCountry.value,
                                                                                          slotProps: {
                                                                                            listbox: {
                                                                                              sx: {
                                                                                                zIndex: "modal"
                                                                                              }
                                                                                            }
                                                                                          },
                                                                                          value: tmp$11,
                                                                                          variant: "plain"
                                                                                        })
                                                                                  ]
                                                                                })),
                                                                        xs: 12,
                                                                        md: 6
                                                                      }),
                                                                  JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                                        children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                                                                                  error: tmp$12,
                                                                                  children: [
                                                                                    JsxRuntime.jsx(FormLabel, {
                                                                                          children: Caml_option.some(Txt$Thin.BookingDetails.addPassDetailsBirthdate.value)
                                                                                        }),
                                                                                    JsxRuntime.jsx(Joy$Util.Input.make, {
                                                                                          type_: tmp$13,
                                                                                          placeholder: Txt$Thin.BookingDetails.addPassDetailsBirthdate.value,
                                                                                          disabled: tmp$14,
                                                                                          variant: "plain",
                                                                                          value: tmp$15,
                                                                                          onChange: (function (e) {
                                                                                              var val = e.currentTarget.value;
                                                                                              var date = CS_SQLDatesAndTimes$Util.DateOnly.fromPrimitive(val);
                                                                                              if (date.TAG !== "Ok") {
                                                                                                return ;
                                                                                              }
                                                                                              var date$1 = date._0;
                                                                                              if (patch.TAG === "Limited") {
                                                                                                return ;
                                                                                              }
                                                                                              var patch$1 = patch._0;
                                                                                              setPatch(function (param) {
                                                                                                    return {
                                                                                                            TAG: "Full",
                                                                                                            _0: {
                                                                                                              cpDocFirstName: patch$1.cpDocFirstName,
                                                                                                              cpDocLastName: patch$1.cpDocLastName,
                                                                                                              cpDocGender: patch$1.cpDocGender,
                                                                                                              cpDocCitizenship: patch$1.cpDocCitizenship,
                                                                                                              cpBirthdate: Caml_option.some(date$1)
                                                                                                            }
                                                                                                          };
                                                                                                  });
                                                                                            }),
                                                                                          onFocus: (function (param) {
                                                                                              setDateFocused(function (param) {
                                                                                                    return true;
                                                                                                  });
                                                                                            }),
                                                                                          onBlur: (function (param) {
                                                                                              setDateFocused(function (param) {
                                                                                                    return false;
                                                                                                  });
                                                                                            })
                                                                                        }),
                                                                                    tmp$16
                                                                                  ]
                                                                                })),
                                                                        xs: 12,
                                                                        md: 6
                                                                      })
                                                                ],
                                                                container: true,
                                                                spacing: {
                                                                  TAG: "Fixed",
                                                                  _0: 1
                                                                },
                                                                rowSpacing: 2,
                                                                columnSpacing: 2
                                                              }))
                                                    })
                                              }),
                                          tmp$17,
                                          fetchError !== undefined ? JsxRuntime.jsx(AppAlert$Thin.make, {
                                                  message: fetchError,
                                                  type_: "error"
                                                }) : null,
                                          patchError !== undefined ? JsxRuntime.jsx(AppAlert$Thin.make, {
                                                  message: patchError,
                                                  type_: "error"
                                                }) : null
                                        ]
                                      }))
                            }),
                        JsxRuntime.jsx(Divider, {}),
                        JsxRuntime.jsx(DialogActions, {
                              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                        direction: {
                                          xs: "column-reverse",
                                          sm: "row"
                                        },
                                        component: "form",
                                        justifyContent: "space-between",
                                        spacing: 1,
                                        sx: {
                                          width: "100%",
                                          p: 2
                                        },
                                        onSubmit: onSubmit,
                                        disabled: tmp$18,
                                        children: [
                                          JsxRuntime.jsx(Button, {
                                                onClick: (function (param) {
                                                    clearAllAndClose();
                                                    setPatch(function (param) {
                                                          return initialPatchState;
                                                        });
                                                  }),
                                                variant: "outlined",
                                                color: "neutral",
                                                children: "Cancel"
                                              }),
                                          JsxRuntime.jsx(Button, {
                                                loading: isBc ? match$7.loading : match$8.isMutating,
                                                type: "submit",
                                                disabled: patchesSame(initialPatchState, patch),
                                                children: "Confirm"
                                              })
                                        ]
                                      }))
                            })
                      ]
                    }),
                JsxRuntime.jsx(CheckValidity$Thin.ErrorModal.make, {
                      error: match$7.error,
                      setError: setValidityError
                    })
              ]
            });
}

var make = BDPatchPaxDrawer;

exports.QueryFragment = QueryFragment;
exports.make = make;
/* react Not a pure module */
