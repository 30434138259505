// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var RelayEnv$Thin = require("../../../RelayEnv.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var SearchByLoyaltyNumberQuery_graphql$Thin = require("../../../__generated__/SearchByLoyaltyNumberQuery_graphql.bs.js");

var convertVariables = SearchByLoyaltyNumberQuery_graphql$Thin.Internal.convertVariables;

var convertResponse = SearchByLoyaltyNumberQuery_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = SearchByLoyaltyNumberQuery_graphql$Thin.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, SearchByLoyaltyNumberQuery_graphql$Thin.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, SearchByLoyaltyNumberQuery_graphql$Thin.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(SearchByLoyaltyNumberQuery_graphql$Thin.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(SearchByLoyaltyNumberQuery_graphql$Thin.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(SearchByLoyaltyNumberQuery_graphql$Thin.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(SearchByLoyaltyNumberQuery_graphql$Thin.node, convertVariables);

var Query = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function fromResponse(r, l) {
  var match = r.lookupAccountByLoyalty;
  if (match === undefined) {
    return ;
  }
  var match$1 = match.nodes;
  if (match$1.length !== 1) {
    return ;
  }
  var node = match$1[0];
  var match$2 = node.accountId;
  var match$3 = node.protectedName;
  if (match$2 !== undefined && match$3 !== undefined) {
    return {
            loyalty: l,
            accountId: Caml_option.valFromOption(match$2),
            protectedName: Caml_option.valFromOption(match$3),
            email: node.email,
            travelDocComplete: Belt_Option.getWithDefault(node.travelDocComplete, false),
            custodianProtectedName: node.custodianProtectedName,
            custodianEmail: node.custodianEmail
          };
  }
  
}

function toLoyaltyPatch(l) {
  if (l !== undefined) {
    return {
            acctId: l.accountId,
            loyalty: l.loyalty,
            protectedName: l.protectedName,
            loyEmail: l.email
          };
  }
  
}

var Utils = {
  fromResponse: fromResponse,
  toLoyaltyPatch: toLoyaltyPatch
};

function useQuery(setLoyaltyErr, clearError, setLoading, voyageSlug, draftAcctIds) {
  var match = React.useState(function () {
        
      });
  var setError = match[1];
  var lookupByLoyalty = function (l, fn) {
    clearError();
    setError(function (param) {
          
        });
    setLoading(function (param) {
          return true;
        });
    $$fetch(RelayEnv$Thin.environment, {
          _loyaltyNumber: l
        }, (function (res) {
            if (res.TAG === "Ok") {
              var res$1 = res._0;
              var match = res$1.lookupAccountByLoyalty;
              var exit = 0;
              if (match !== undefined) {
                var match$1 = match.nodes;
                if (match$1.length !== 1) {
                  exit = 1;
                } else {
                  var match$2 = match$1[0];
                  var voy = match$2.voyages;
                  if (voy !== undefined && Belt_Array.keepMap(voy, (function (v) {
                            if (v === undefined) {
                              return ;
                            }
                            var v$1 = Caml_option.valFromOption(v);
                            return Caml_obj.equal(v$1, voyageSlug) ? Caml_option.some(v$1) : undefined;
                          })).length !== 0) {
                    setLoyaltyErr(function (param) {
                          return "AlreadyBooked";
                        });
                  } else {
                    exit = 1;
                  }
                }
              } else {
                exit = 1;
              }
              if (exit === 1) {
                var loyaltyAcct = fromResponse(res$1, l);
                if (loyaltyAcct !== undefined) {
                  var accountId = loyaltyAcct.accountId;
                  if (Belt_Array.some(Belt_Option.getWithDefault(draftAcctIds, []), (function (id) {
                            return Caml_obj.equal(id, accountId);
                          }))) {
                    setLoyaltyErr(function (param) {
                          return "AlreadyInDraft";
                        });
                  } else {
                    fn(loyaltyAcct);
                  }
                } else {
                  fn(loyaltyAcct);
                }
              }
              
            } else {
              setError(function (param) {
                    return "An error occurred while fetching the account. Please try again.";
                  });
            }
            setLoading(function (param) {
                  return false;
                });
          }), undefined, undefined);
  };
  return {
          error: match[0],
          lookupByLoyalty: lookupByLoyalty
        };
}

exports.Query = Query;
exports.Utils = Utils;
exports.useQuery = useQuery;
/* use Not a pure module */
