// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Txt$Thin = require("../../text/Txt.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Merge$Util = require("util/src/Merge.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thin = require("../common/molecules/Dialog.bs.js");
var SysCtx$Thin = require("../../utils/SysCtx.bs.js");
var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var Card = require("@mui/joy/Card").default;
var List = require("@mui/joy/List").default;
var DateOnly$Util = require("util/src/DateOnly.bs.js");
var Stack = require("@mui/joy/Stack").default;
var AppAvatar$Thin = require("../common/atoms/AppAvatar.bs.js");
var AppButton$Thin = require("../common/atoms/AppButton.bs.js");
var Button = require("@mui/joy/Button").default;
var DraftUtils$Thin = require("../../utils/booking/DraftUtils.bs.js");
var Divider = require("@mui/joy/Divider").default;
var Tooltip = require("@mui/joy/Tooltip").default;
var ListItem = require("@mui/joy/ListItem").default;
var CS_ISO3166_1$Util = require("util/src/CustomScalars/CS_ISO3166_1.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var IconButton = require("@mui/joy/IconButton").default;
var Typography = require("@mui/joy/Typography").default;
var CATransferForm$Thin = require("./CATransferForm.bs.js");
var DialogContent = require("@mui/joy/DialogContent").default;
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var CopyableWithTooltip$Thin = require("../common/atoms/CopyableWithTooltip.bs.js");
var CAResendInvitationForm$Thin = require("./CAResendInvitationForm.bs.js");
var CARevokeInvitationForm$Thin = require("./CARevokeInvitationForm.bs.js");
var ArrowOutward = require("@mui/icons-material/ArrowOutward").default;
var ChevronRight = require("@mui/icons-material/ChevronRight").default;
var InfoOutlined = require("@mui/icons-material/InfoOutlined").default;
var CAUpdateAccountDetailsDrawer$Thin = require("./CAUpdateAccountDetailsDrawer.bs.js");

function CACard$DataStack(props) {
  var modalContent = props.modalContent;
  var copyable = props.copyable;
  var value = props.value;
  var label = props.label;
  React.useState(function () {
        return false;
      });
  var match = React.useState(function () {
        return false;
      });
  var setModalOpen = match[1];
  var labelCtx = {
    label: label.toLowerCase()
  };
  return JsxRuntime.jsxs(Stack, {
              direction: "column",
              children: [
                JsxRuntime.jsx(Stack, {
                      direction: "row",
                      spacing: 1,
                      children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                level: "body-sm",
                                noWrap: true,
                                sx: {
                                  color: "neutral.500"
                                },
                                fontWeight: "normal",
                                children: Caml_option.some(label)
                              }))
                    }),
                JsxRuntime.jsxs(Stack, {
                      direction: "row",
                      alignItems: "center",
                      spacing: 1,
                      children: [
                        JsxRuntime.jsx(Typography, {
                              level: "body-sm",
                              noWrap: true,
                              sx: {
                                color: value !== undefined ? "text.primary" : "neutral.plainDisabledColor"
                              },
                              fontWeight: "normal",
                              children: Caml_option.some(value !== undefined ? value : Merge$Util.render(Txt$Thin.ConnectedAccounts.noValueProvided, labelCtx))
                            }),
                        modalContent !== undefined ? JsxRuntime.jsx(InfoOutlined, {
                                fontSize: "sm",
                                sx: {
                                  cursor: "pointer"
                                },
                                onClick: (function (param) {
                                    setModalOpen(function (param) {
                                          return true;
                                        });
                                  })
                              }) : null,
                        value !== undefined && copyable !== undefined && copyable ? JsxRuntime.jsx(CopyableWithTooltip$Thin.make, {
                                value: value,
                                label: label
                              }) : null
                      ]
                    }),
                modalContent !== undefined ? JsxRuntime.jsx(Dialog$Thin.make, {
                        open_: match[0],
                        title: modalContent.title,
                        onClose: (function () {
                            setModalOpen(function (param) {
                                  return false;
                                });
                          }),
                        children: Caml_option.some(JsxRuntime.jsx(DialogContent, {
                                  children: Caml_option.some(modalContent.content)
                                }))
                      }) : null
              ]
            });
}

var DataStack = {
  make: CACard$DataStack
};

function CACard(props) {
  var refetch = props.refetch;
  var u = props.u;
  var f = props.f;
  var match = React.useState(function () {
        return false;
      });
  var setMobileOpen = match[1];
  var mobileOpen = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setTransferModalOpen = match$1[1];
  var match$2 = React.useState(function () {
        return false;
      });
  var setRevokeModalOpen = match$2[1];
  var match$3 = React.useState(function () {
        return false;
      });
  var setResendModalOpen = match$3[1];
  var match$4 = React.useState(function () {
        return false;
      });
  var setOpenAccountUpdateDrawer = match$4[1];
  var isTransferring = u.accountStatus === "TRANSFERRING";
  var expiry = f.invitationExpiry;
  var expiryTuple;
  if (expiry !== undefined) {
    var expiry$1 = Caml_option.valFromOption(expiry);
    expiryTuple = CS_Make$Util.Dateable.Plain.isInThePast(expiry$1) ? [
        true,
        expiry$1
      ] : [
        false,
        expiry$1
      ];
  } else {
    expiryTuple = undefined;
  }
  var voyageList = JsxRuntime.jsx(List, {
        children: Caml_option.some(Belt_Array.map(u.loyaltyVoyages, (function (param) {
                    var isPending = param.isPending;
                    return JsxRuntime.jsx(ListItem, {
                                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                          level: "body-sm",
                                          sx: {
                                            color: isPending ? "neutral.500" : "text.primary",
                                            fontStyle: isPending ? "italic" : "normal"
                                          },
                                          children: Caml_option.some(param.voyageName + (
                                                isPending ? " (Upcoming)" : ""
                                              ))
                                        }))
                              });
                  }))),
        size: "sm"
      });
  var voyageInfo = u.loyaltyVoyages.length === 0 ? undefined : ({
        title: f.fullName + "'s Voyages",
        content: voyageList
      });
  var transferTextComponent;
  if (expiryTuple !== undefined) {
    if (expiryTuple[0]) {
      var expiredCtx = {
        inviteeEmail: Belt_Option.getWithDefault(f.invitationEmail, Txt$Thin.ConnectedAccounts.invitationNoEmail.value)
      };
      transferTextComponent = JsxRuntime.jsx(Typography, {
            color: "danger",
            level: "body-sm",
            sx: {
              textAlign: {
                xs: "center",
                md: "left"
              },
              fontStyle: "italic"
            },
            fontWeight: "normal",
            children: Caml_option.some(Merge$Util.render(Txt$Thin.ConnectedAccounts.invitationHasExpired, expiredCtx))
          });
    } else {
      var datetime = expiryTuple[1];
      var inviteCtx_inviteeEmail = Belt_Option.getWithDefault(f.invitationEmail, Txt$Thin.ConnectedAccounts.invitationNoEmail.value);
      var inviteCtx_expiryDay = CS_Make$Util.Dateable.Plain.format(datetime, SysCtx$Thin.vars.longDateFormat);
      var inviteCtx_expiryTime = CS_Make$Util.Dateable.Plain.format(datetime, SysCtx$Thin.vars.timeFormat);
      var inviteCtx = {
        inviteeEmail: inviteCtx_inviteeEmail,
        expiryDay: inviteCtx_expiryDay,
        expiryTime: inviteCtx_expiryTime
      };
      transferTextComponent = JsxRuntime.jsx(Typography, {
            level: "body-sm",
            sx: {
              textAlign: {
                xs: "center",
                md: "left"
              },
              fontStyle: "italic"
            },
            fontWeight: "normal",
            children: Caml_option.some(Merge$Util.render(Txt$Thin.ConnectedAccounts.invitationWillExpire, inviteCtx))
          });
    }
  } else {
    transferTextComponent = null;
  }
  return JsxRuntime.jsxs(Card, {
              children: [
                JsxRuntime.jsxs(Stack, {
                      direction: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      sx: {
                        pt: 2,
                        px: 2,
                        pb: 1
                      },
                      children: [
                        JsxRuntime.jsxs(Stack, {
                              direction: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              spacing: {
                                xs: 1,
                                md: 2
                              },
                              children: [
                                JsxRuntime.jsx(AppAvatar$Thin.make, {
                                      children: f.initials,
                                      color: isTransferring ? "neutral" : "primary",
                                      opacity: isTransferring ? 0.5 : 1.0
                                    }),
                                JsxRuntime.jsx(Typography, {
                                      level: "h3",
                                      sx: {
                                        fontStyle: isTransferring ? "italic" : "normal",
                                        fontWeight: isTransferring ? "300" : "400",
                                        color: isTransferring ? "neutral.500" : "text.primary"
                                      },
                                      children: Caml_option.some(f.fullName)
                                    })
                              ]
                            }),
                        JsxRuntime.jsx(Stack, {
                              direction: "row",
                              spacing: 1,
                              sx: {
                                display: {
                                  xs: "none",
                                  md: "flex"
                                }
                              },
                              children: Caml_option.some(isTransferring ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                          children: [
                                            JsxRuntime.jsx(AppButton$Thin.make, {
                                                  onClick: (function (param) {
                                                      setResendModalOpen(function (param) {
                                                            return true;
                                                          });
                                                    }),
                                                  size: "md",
                                                  children: Caml_option.some(Txt$Thin.ConnectedAccounts.resendButton.value)
                                                }),
                                            JsxRuntime.jsx(AppButton$Thin.make, {
                                                  onClick: (function (param) {
                                                      setRevokeModalOpen(function (param) {
                                                            return true;
                                                          });
                                                    }),
                                                  variant: "outlined",
                                                  color: "danger",
                                                  size: "md",
                                                  children: Caml_option.some(Txt$Thin.ConnectedAccounts.revokeButton.value)
                                                })
                                          ]
                                        }) : JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                          children: [
                                            JsxRuntime.jsx(AppButton$Thin.make, {
                                                  onClick: (function (param) {
                                                      setOpenAccountUpdateDrawer(function (param) {
                                                            return true;
                                                          });
                                                    }),
                                                  size: "md",
                                                  children: Caml_option.some(Txt$Thin.ConnectedAccounts.editButton.value)
                                                }),
                                            JsxRuntime.jsx(Tooltip, {
                                                  children: JsxRuntime.jsx(Button, {
                                                        onClick: (function (param) {
                                                            setTransferModalOpen(function (param) {
                                                                  return true;
                                                                });
                                                          }),
                                                        endDecorator: Caml_option.some(JsxRuntime.jsx(ArrowOutward, {})),
                                                        variant: "outlined",
                                                        color: "neutral",
                                                        size: "md",
                                                        children: Caml_option.some(Txt$Thin.ConnectedAccounts.transferButton.value)
                                                      }),
                                                  arrow: true,
                                                  placement: "top",
                                                  size: "sm",
                                                  title: Txt$Thin.ConnectedAccounts.transferButtonHelperText.value
                                                })
                                          ]
                                        }))
                            }),
                        JsxRuntime.jsx(Stack, {
                              sx: {
                                display: {
                                  xs: "flex",
                                  md: "none"
                                }
                              },
                              children: Caml_option.some(JsxRuntime.jsx(IconButton, {
                                        children: Caml_option.some(JsxRuntime.jsx(ChevronRight, {
                                                  sx: {
                                                    transform: mobileOpen ? "rotate(-90deg)" : "rotate(90deg)"
                                                  },
                                                  onClick: (function (param) {
                                                      setMobileOpen(function (param) {
                                                            return !mobileOpen;
                                                          });
                                                    })
                                                }))
                                      }))
                            })
                      ]
                    }),
                JsxRuntime.jsx(Divider, {
                      sx: {
                        display: {
                          xs: mobileOpen ? "flex" : "none",
                          md: "flex"
                        }
                      }
                    }),
                JsxRuntime.jsx(Stack, {
                      sx: {
                        display: {
                          xs: mobileOpen ? "flex" : "none",
                          md: "flex"
                        },
                        pt: 1,
                        px: 2,
                        pb: 2
                      },
                      children: Caml_option.some(JsxRuntime.jsxs(Joy$Util.Grid.make, {
                                children: [
                                  isTransferring ? JsxRuntime.jsx(Joy$Util.Grid.make, {
                                          children: Caml_option.some(transferTextComponent),
                                          sx: {
                                            display: {
                                              xs: "none",
                                              md: "flex"
                                            }
                                          },
                                          xs: 12
                                        }) : null,
                                  JsxRuntime.jsx(Joy$Util.Grid.make, {
                                        children: Caml_option.some(JsxRuntime.jsx(CACard$DataStack, {
                                                  label: Txt$Thin.ConnectedAccounts.legalNameLabel.value,
                                                  value: f.legalName
                                                })),
                                        xs: 6,
                                        md: 3
                                      }),
                                  JsxRuntime.jsx(Joy$Util.Grid.make, {
                                        children: Caml_option.some(JsxRuntime.jsx(CACard$DataStack, {
                                                  label: Txt$Thin.ConnectedAccounts.birthdateLabel.value,
                                                  value: Belt_Option.map(u.docBirthdate, (function (d) {
                                                          return DateOnly$Util.format(d, SysCtx$Thin.vars.dateFormat);
                                                        }))
                                                })),
                                        xs: 6,
                                        md: 3
                                      }),
                                  JsxRuntime.jsx(Joy$Util.Grid.make, {
                                        children: Caml_option.some(JsxRuntime.jsx(CACard$DataStack, {
                                                  label: Txt$Thin.ConnectedAccounts.citizenshipLabel.value,
                                                  value: Belt_Option.map(u.docCitizenship, CS_ISO3166_1$Util.Alpha3.toString)
                                                })),
                                        xs: 6,
                                        md: 3
                                      }),
                                  JsxRuntime.jsx(Joy$Util.Grid.make, {
                                        children: Caml_option.some(JsxRuntime.jsx(CACard$DataStack, {
                                                  label: Txt$Thin.ConnectedAccounts.genderLabel.value,
                                                  value: Belt_Option.map(u.docGender, DraftUtils$Thin.Pax.genderToText)
                                                })),
                                        xs: 6,
                                        md: 3
                                      }),
                                  JsxRuntime.jsx(Joy$Util.Grid.make, {
                                        children: Caml_option.some(JsxRuntime.jsx(CACard$DataStack, {
                                                  label: Txt$Thin.ConnectedAccounts.emailLabel.value,
                                                  value: f.email
                                                })),
                                        xs: 6,
                                        md: 3
                                      }),
                                  JsxRuntime.jsx(Joy$Util.Grid.make, {
                                        children: Caml_option.some(JsxRuntime.jsx(CACard$DataStack, {
                                                  label: "Loyalty Number",
                                                  value: CS_NonemptyStrings$Util.LoyaltyNumber.toString(u.loyaltyNumber),
                                                  copyable: true
                                                })),
                                        xs: 6,
                                        md: 3
                                      }),
                                  JsxRuntime.jsx(Joy$Util.Grid.make, {
                                        children: Caml_option.some(JsxRuntime.jsx(CACard$DataStack, {
                                                  label: Txt$Thin.ConnectedAccounts.loyaltyStatusLabel.value,
                                                  value: Belt_Option.map(u.loyaltyTier, CS_NonemptyStrings$Util.NonEmptyString.toPrimitive)
                                                })),
                                        xs: 6,
                                        md: 3
                                      }),
                                  JsxRuntime.jsx(Joy$Util.Grid.make, {
                                        children: Caml_option.some(JsxRuntime.jsx(CACard$DataStack, {
                                                  label: Txt$Thin.ConnectedAccounts.voyagesLabel.value,
                                                  value: String(u.numLoyaltyVoyages),
                                                  modalContent: voyageInfo
                                                })),
                                        xs: 6,
                                        md: 3
                                      }),
                                  isTransferring ? JsxRuntime.jsx(Joy$Util.Grid.make, {
                                          children: Caml_option.some(transferTextComponent),
                                          sx: {
                                            display: {
                                              xs: "flex",
                                              md: "none"
                                            }
                                          },
                                          xs: 12
                                        }) : null,
                                  JsxRuntime.jsx(Joy$Util.Grid.make, {
                                        children: Caml_option.some(isTransferring ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                                    children: [
                                                      JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                            children: Caml_option.some(JsxRuntime.jsx(AppButton$Thin.make, {
                                                                      fullWidth: true,
                                                                      onClick: (function (param) {
                                                                          setResendModalOpen(function (param) {
                                                                                return true;
                                                                              });
                                                                        }),
                                                                      size: "md",
                                                                      children: Caml_option.some(Txt$Thin.ConnectedAccounts.resendButton.value)
                                                                    })),
                                                            xs: 6
                                                          }),
                                                      JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                            children: Caml_option.some(JsxRuntime.jsx(AppButton$Thin.make, {
                                                                      fullWidth: true,
                                                                      onClick: (function (param) {
                                                                          setRevokeModalOpen(function (param) {
                                                                                return true;
                                                                              });
                                                                        }),
                                                                      variant: "outlined",
                                                                      color: "danger",
                                                                      size: "md",
                                                                      children: Caml_option.some(Txt$Thin.ConnectedAccounts.revokeButton.value)
                                                                    })),
                                                            xs: 6
                                                          })
                                                    ]
                                                  }) : JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                                    children: [
                                                      JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                            children: Caml_option.some(JsxRuntime.jsx(AppButton$Thin.make, {
                                                                      fullWidth: true,
                                                                      onClick: (function (param) {
                                                                          setOpenAccountUpdateDrawer(function (param) {
                                                                                return true;
                                                                              });
                                                                        }),
                                                                      size: "md",
                                                                      children: Caml_option.some(Txt$Thin.ConnectedAccounts.editButton.value)
                                                                    })),
                                                            xs: 6
                                                          }),
                                                      JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                            children: Caml_option.some(JsxRuntime.jsx(AppButton$Thin.make, {
                                                                      fullWidth: true,
                                                                      onClick: (function (param) {
                                                                          setTransferModalOpen(function (param) {
                                                                                return true;
                                                                              });
                                                                        }),
                                                                      endDecorator: Caml_option.some(JsxRuntime.jsx(ArrowOutward, {})),
                                                                      variant: "outlined",
                                                                      color: "neutral",
                                                                      size: "md",
                                                                      children: Caml_option.some(Txt$Thin.ConnectedAccounts.transferButton.value)
                                                                    })),
                                                            xs: 6
                                                          })
                                                    ]
                                                  })),
                                        container: true,
                                        rowSpacing: 2,
                                        columnSpacing: 2,
                                        sx: {
                                          display: {
                                            xs: "flex",
                                            md: "none"
                                          }
                                        },
                                        xs: 12
                                      })
                                ],
                                container: true,
                                rowSpacing: 2,
                                columnSpacing: 2
                              }))
                    }),
                JsxRuntime.jsx(CATransferForm$Thin.make, {
                      open_: match$1[0],
                      onClose: (function () {
                          setTransferModalOpen(function (param) {
                                return false;
                              });
                        }),
                      accountId: u.accountId,
                      fullName: f.fullName,
                      refetch: refetch
                    }),
                JsxRuntime.jsx(CARevokeInvitationForm$Thin.make, {
                      open_: match$2[0],
                      onClose: (function () {
                          setRevokeModalOpen(function (param) {
                                return false;
                              });
                        }),
                      accountId: u.accountId,
                      fullName: f.fullName,
                      refetch: refetch,
                      inviteeEmail: f.invitationEmail
                    }),
                JsxRuntime.jsx(CAResendInvitationForm$Thin.make, {
                      open_: match$3[0],
                      onClose: (function () {
                          setResendModalOpen(function (param) {
                                return false;
                              });
                        }),
                      accountId: u.accountId,
                      fullName: f.fullName,
                      refetch: refetch,
                      inviteeEmail: f.invitationEmail
                    }),
                JsxRuntime.jsx(CAUpdateAccountDetailsDrawer$Thin.make, {
                      fragmentRefs: props.fragmentRefs,
                      countryFrag: props.countryFrag,
                      open_: match$4[0],
                      onClose: (function () {
                          setOpenAccountUpdateDrawer(function (param) {
                                return false;
                              });
                        }),
                      fullName: f.fullName,
                      u: u,
                      refetch: refetch
                    })
              ],
              sx: {
                maxWidth: "55rem",
                borderRadius: "12px",
                position: "relative"
              }
            });
}

var make = CACard;

exports.DataStack = DataStack;
exports.make = make;
/* react Not a pure module */
