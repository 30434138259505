// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var RemoteData$Util = require("util/src/RemoteData.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var ProcessorTokenizationIntentMutation_graphql$Thin = require("../../__generated__/ProcessorTokenizationIntentMutation_graphql.bs.js");

var convertVariables = ProcessorTokenizationIntentMutation_graphql$Thin.Internal.convertVariables;

var convertResponse = ProcessorTokenizationIntentMutation_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = ProcessorTokenizationIntentMutation_graphql$Thin.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, ProcessorTokenizationIntentMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, ProcessorTokenizationIntentMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var Mutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useTokenizationIntentMutation(state) {
  var mut = RemoteData$Util.Relay.useMutationStatefully(use, state, (function (response) {
          var tokenizationIntent = response.getTokenizationIntent.tokenizationIntent;
          if (tokenizationIntent !== undefined) {
            return {
                    TAG: "Success",
                    _0: Caml_option.valFromOption(tokenizationIntent)
                  };
          } else {
            return {
                    TAG: "Failure",
                    _0: "No tokenization intent returned"
                  };
          }
        }));
  return function (param) {
    return mut({
                input: {
                  clientMutationId: ""
                }
              });
  };
}

exports.Mutation = Mutation;
exports.useTokenizationIntentMutation = useTokenizationIntentMutation;
/* commitMutation Not a pure module */
