// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var Alert = require("@mui/joy/Alert").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var Info = require("@mui/icons-material/Info").default;
var Report = require("@mui/icons-material/Report").default;
var CheckCircle = require("@mui/icons-material/CheckCircle").default;
var WarningAmber = require("@mui/icons-material/WarningAmber").default;

function AppAlert(props) {
  var __variant = props.variant;
  var __startDecorator = props.startDecorator;
  var __size = props.size;
  var __role = props.role;
  var __invertedColors = props.invertedColors;
  var __endDecorator = props.endDecorator;
  var __component = props.component;
  var __type_ = props.type_;
  var __textColor = props.textColor;
  var textColor = __textColor !== undefined ? __textColor : "inherit";
  var type_ = __type_ !== undefined ? __type_ : "neutral";
  var component = __component !== undefined ? __component : "div";
  var endDecorator = __endDecorator !== undefined ? Caml_option.valFromOption(__endDecorator) : null;
  var invertedColors = __invertedColors !== undefined ? __invertedColors : false;
  var role = __role !== undefined ? __role : "alert";
  var size = __size !== undefined ? __size : "md";
  if (__startDecorator !== undefined) {
    Caml_option.valFromOption(__startDecorator);
  }
  var variant = __variant !== undefined ? __variant : "soft";
  var tmp;
  switch (type_) {
    case "error" :
        tmp = "danger";
        break;
    case "primary" :
        tmp = "primary";
        break;
    case "secondary" :
        tmp = "secondary";
        break;
    case "success" :
        tmp = "success";
        break;
    case "warning" :
        tmp = "warning";
        break;
    default:
      tmp = "neutral";
  }
  var tmp$1;
  switch (type_) {
    case "error" :
        tmp$1 = JsxRuntime.jsx(Report, {});
        break;
    case "success" :
        tmp$1 = JsxRuntime.jsx(CheckCircle, {});
        break;
    case "warning" :
        tmp$1 = JsxRuntime.jsx(WarningAmber, {
              sx: {
                color: "#DBA313"
              }
            });
        break;
    default:
      tmp$1 = JsxRuntime.jsx(Info, {});
  }
  var tmp$2;
  switch (type_) {
    case "error" :
        tmp$2 = "danger.500";
        break;
    case "primary" :
        tmp$2 = "primary.500";
        break;
    case "secondary" :
        tmp$2 = "secondary.500";
        break;
    case "success" :
        tmp$2 = "success.500";
        break;
    case "warning" :
        tmp$2 = "warning.500";
        break;
    default:
      tmp$2 = "neutral.500";
  }
  return JsxRuntime.jsx(Alert, {
              children: Caml_option.some(JsxRuntime.jsx(Typography, {
                        level: "body-sm",
                        component: "p",
                        textColor: textColor,
                        children: Caml_option.some(props.message)
                      })),
              color: tmp,
              component: component,
              endDecorator: Caml_option.some(endDecorator),
              invertedColors: invertedColors,
              role: role,
              size: size,
              startDecorator: Caml_option.some(tmp$1),
              sx: {
                alignItems: "flex-start",
                borderLeft: "4px solid",
                borderColor: tmp$2
              },
              variant: variant
            });
}

var make = AppAlert;

exports.make = make;
/* @mui/joy/Alert Not a pure module */
