// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var RelayEnv$Thin = require("../../../RelayEnv.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var BookingPmtByPublicPmtIdQuery_graphql$Thin = require("../../../__generated__/BookingPmtByPublicPmtIdQuery_graphql.bs.js");

var convertVariables = BookingPmtByPublicPmtIdQuery_graphql$Thin.Internal.convertVariables;

var convertResponse = BookingPmtByPublicPmtIdQuery_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = BookingPmtByPublicPmtIdQuery_graphql$Thin.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, BookingPmtByPublicPmtIdQuery_graphql$Thin.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, BookingPmtByPublicPmtIdQuery_graphql$Thin.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(BookingPmtByPublicPmtIdQuery_graphql$Thin.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(BookingPmtByPublicPmtIdQuery_graphql$Thin.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(BookingPmtByPublicPmtIdQuery_graphql$Thin.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(BookingPmtByPublicPmtIdQuery_graphql$Thin.node, convertVariables);

var Query = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function fetchBooking(publicPmtId, onPaySuccess, onPayFailure) {
  $$fetch(RelayEnv$Thin.environment, {
        publicPmtId: publicPmtId
      }, (function (res) {
          if (res.TAG === "Ok") {
            var match = res._0.bookingPaymentByPublicPmtId;
            if (match !== undefined) {
              return onPaySuccess(match.bookingId);
            } else {
              return onPayFailure("Booking not found");
            }
          }
          console.error(res._0);
          onPayFailure("An error occurred fetching the booking");
        }), undefined, undefined);
}

exports.Query = Query;
exports.fetchBooking = fetchBooking;
/* use Not a pure module */
