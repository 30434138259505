// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var SysCtx$Thin = require("../../utils/SysCtx.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");

function IsLoggedIn(props) {
  var signOnStatus = props.signOnStatus;
  var children = props.children;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var params = url.path;
  var redirect;
  var exit = 0;
  if (params) {
    if (params.hd === "2024") {
      var match = params.tl;
      if (match && !match.tl) {
        redirect = SysCtx$Thin.vars.loginLink;
      } else {
        exit = 1;
      }
    } else {
      exit = 1;
    }
  } else {
    redirect = SysCtx$Thin.vars.loginLink;
  }
  if (exit === 1) {
    redirect = encodeURI(Belt_List.reduce(params, SysCtx$Thin.vars.loginLink + "?" + SysCtx$Thin.vars.loginRedirectParam + "=", (function (a, b) {
                return a + "/" + b.trim();
              })));
  }
  React.useEffect((function () {
          if (signOnStatus === "VERIFY_EMAIL" || signOnStatus === "INVITED" || signOnStatus === "PENDING_2FA" || signOnStatus === "CLEAR_JWT" || signOnStatus === "ANONYMOUS" || signOnStatus === "SIGNED_ON") {
            switch (signOnStatus) {
              case "PENDING_2FA" :
              case "VERIFY_EMAIL" :
              case "SIGNED_ON" :
                  break;
              default:
                RescriptReactRouter.push(redirect);
            }
          } else {
            RescriptReactRouter.push(redirect);
          }
        }), [signOnStatus]);
  if (!(signOnStatus === "VERIFY_EMAIL" || signOnStatus === "INVITED" || signOnStatus === "PENDING_2FA" || signOnStatus === "CLEAR_JWT" || signOnStatus === "ANONYMOUS" || signOnStatus === "SIGNED_ON")) {
    return null;
  }
  switch (signOnStatus) {
    case "PENDING_2FA" :
    case "VERIFY_EMAIL" :
    case "SIGNED_ON" :
        return children;
    default:
      return null;
  }
}

var make = IsLoggedIn;

exports.make = make;
/* react Not a pure module */
