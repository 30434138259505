// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Txt$Thin = require("../../../text/Txt.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var List = require("@mui/joy/List").default;
var Stack = require("@mui/joy/Stack").default;
var AppButton$Thin = require("../../common/atoms/AppButton.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var Drawer = require("@mui/joy/Drawer").default;
var InvitePass$Thin = require("./InvitePass.bs.js");
var Divider = require("@mui/joy/Divider").default;
var BookingDiff$Thin = require("../../../utils/booking/BookingDiff.bs.js");
var ListItem = require("@mui/joy/ListItem").default;
var AddByLoyalty$Thin = require("./AddByLoyalty.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var IconButton = require("@mui/joy/IconButton").default;
var ModalClose = require("@mui/joy/ModalClose").default;
var AddPassDetails$Thin = require("./AddPassDetails.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var DialogTitle = require("@mui/joy/DialogTitle").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var AddManagedAccount$Thin = require("./AddManagedAccount.bs.js");
var ListItemButton = require("@mui/joy/ListItemButton").default;
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var ListItemContent = require("@mui/joy/ListItemContent").default;
var CS_SQLDatesAndTimes$Util = require("util/src/CustomScalars/CS_SQLDatesAndTimes.bs.js");
var ListItemDecorator = require("@mui/joy/ListItemDecorator").default;
var SendOutlined = require("@mui/icons-material/SendOutlined").default;
var ArrowBackIosNew = require("@mui/icons-material/ArrowBackIosNew").default;
var ArrowForwardIos = require("@mui/icons-material/ArrowForwardIos").default;
var LoyaltyOutlined = require("@mui/icons-material/LoyaltyOutlined").default;
var ModeEditOutlined = require("@mui/icons-material/ModeEditOutlined").default;
var SupervisorAccountOutlined = require("@mui/icons-material/SupervisorAccountOutlined").default;

function AddPassMobile(props) {
  var birthdateShouldError = props.birthdateShouldError;
  var shouldDisableManaged = props.shouldDisableManaged;
  var pax1MustBeBornBy = props.pax1MustBeBornBy;
  var isPrimaryPax = props.isPrimaryPax;
  var addOrErr = props.addOrErr;
  var voyageSlug = props.voyageSlug;
  var loyaltyPatch = props.loyaltyPatch;
  var loading = props.loading;
  var custodianAccountId = props.custodianAccountId;
  var idx = props.idx;
  var action = props.action;
  var connectedAcct = props.connectedAcct;
  var gender = props.gender;
  var country = props.country;
  var dob = props.dob;
  var email = props.email;
  var setPrefLName = props.setPrefLName;
  var prefLName = props.prefLName;
  var setPrefFName = props.setPrefFName;
  var prefFName = props.prefFName;
  var docLName = props.docLName;
  var docFName = props.docFName;
  var getTitle = props.getTitle;
  var clearAll = props.clearAll;
  var onClose = props.onClose;
  var setTab = props.setTab;
  var tab = props.tab;
  var getMenuContent = function (param) {
    return JsxRuntime.jsxs(List, {
                children: [
                  JsxRuntime.jsx(ListItem, {
                        children: Caml_option.some(JsxRuntime.jsxs(ListItemButton, {
                                  children: [
                                    JsxRuntime.jsx(ListItemDecorator, {
                                          children: Caml_option.some(JsxRuntime.jsx(ModeEditOutlined, {
                                                    fontSize: "md"
                                                  })),
                                          sx: {
                                            "--ListItemDecorator-size": "1.75rem"
                                          }
                                        }),
                                    JsxRuntime.jsx(ListItemContent, {
                                          children: Caml_option.some(getTitle("details"))
                                        }),
                                    JsxRuntime.jsx(ArrowForwardIos, {
                                          fontSize: "md"
                                        })
                                  ],
                                  onClick: (function (param) {
                                      setTab(function (param) {
                                            return "details";
                                          });
                                      clearAll();
                                    })
                                }))
                      }),
                  JsxRuntime.jsx(ListItem, {
                        children: Caml_option.some(JsxRuntime.jsxs(ListItemButton, {
                                  children: [
                                    JsxRuntime.jsx(ListItemDecorator, {
                                          children: Caml_option.some(JsxRuntime.jsx(SendOutlined, {
                                                    fontSize: "md"
                                                  })),
                                          sx: {
                                            "--ListItemDecorator-size": "1.75rem"
                                          }
                                        }),
                                    JsxRuntime.jsx(ListItemContent, {
                                          children: Caml_option.some(getTitle("invite"))
                                        }),
                                    JsxRuntime.jsx(ArrowForwardIos, {
                                          fontSize: "md"
                                        })
                                  ],
                                  disabled: isPrimaryPax,
                                  onClick: (function (param) {
                                      if (isPrimaryPax) {
                                        return ;
                                      } else {
                                        setTab(function (param) {
                                              return "invite";
                                            });
                                        return clearAll();
                                      }
                                    })
                                }))
                      }),
                  JsxRuntime.jsx(ListItem, {
                        children: Caml_option.some(JsxRuntime.jsxs(ListItemButton, {
                                  children: [
                                    JsxRuntime.jsx(ListItemDecorator, {
                                          children: Caml_option.some(JsxRuntime.jsx(SupervisorAccountOutlined, {
                                                    fontSize: "md"
                                                  })),
                                          sx: {
                                            "--ListItemDecorator-size": "1.75rem"
                                          }
                                        }),
                                    JsxRuntime.jsx(ListItemContent, {
                                          children: Caml_option.some(getTitle("managed"))
                                        }),
                                    JsxRuntime.jsx(ArrowForwardIos, {
                                          fontSize: "md"
                                        })
                                  ],
                                  disabled: shouldDisableManaged,
                                  onClick: (function (param) {
                                      if (shouldDisableManaged) {
                                        return ;
                                      } else {
                                        setTab(function (param) {
                                              return "managed";
                                            });
                                        return clearAll();
                                      }
                                    })
                                }))
                      }),
                  JsxRuntime.jsx(ListItem, {
                        children: Caml_option.some(JsxRuntime.jsxs(ListItemButton, {
                                  children: [
                                    JsxRuntime.jsx(ListItemDecorator, {
                                          children: Caml_option.some(JsxRuntime.jsx(LoyaltyOutlined, {
                                                    fontSize: "md"
                                                  })),
                                          sx: {
                                            "--ListItemDecorator-size": "1.75rem"
                                          }
                                        }),
                                    JsxRuntime.jsx(ListItemContent, {
                                          children: Caml_option.some(getTitle("loyalty"))
                                        }),
                                    JsxRuntime.jsx(ArrowForwardIos, {
                                          fontSize: "md"
                                        })
                                  ],
                                  onClick: (function (param) {
                                      setTab(function (param) {
                                            return "loyalty";
                                          });
                                      clearAll();
                                    })
                                }))
                      })
                ],
                sx: {
                  p: 1
                }
              });
  };
  var detailsTab = JsxRuntime.jsx(AddPassDetails$Thin.make, {
        docFName: docFName,
        setDocFName: props.setDocFName,
        docLName: docLName,
        setDocLName: props.setDocLName,
        prefFName: prefFName,
        setPrefFName: setPrefFName,
        prefLName: prefLName,
        setPrefLName: setPrefLName,
        dob: dob,
        setDob: props.setDob,
        country: country,
        setCountry: props.setCountry,
        gender: gender,
        setGender: props.setGender,
        countryFrag: props.queryFrag,
        mobile: true,
        action: action,
        idx: idx,
        onClose: onClose,
        custodianAccountId: custodianAccountId,
        loading: loading,
        addOrErr: addOrErr,
        isPrimaryPax: isPrimaryPax,
        pax1MustBeBornBy: pax1MustBeBornBy,
        birthdateShouldError: birthdateShouldError,
        forceValidate: true
      });
  var inviteTab = JsxRuntime.jsx(InvitePass$Thin.make, {
        prefFName: prefFName,
        setPrefFName: setPrefFName,
        prefLName: prefLName,
        setPrefLName: setPrefLName,
        email: email,
        setEmail: props.setEmail,
        action: action,
        idx: idx,
        loading: loading,
        addOrErr: addOrErr,
        disabled: isPrimaryPax,
        forceValidate: true
      });
  var connectedTab = JsxRuntime.jsx(AddManagedAccount$Thin.make, {
        connectedAcct: connectedAcct,
        setConnectedAcct: props.setConnectedAcct,
        mobile: true,
        idx: idx,
        loading: loading,
        addOrErr: addOrErr,
        action: action,
        voyageSlug: voyageSlug,
        isPrimaryPax: isPrimaryPax,
        managedOptions: props.managedOptions
      });
  var loyaltyTab = JsxRuntime.jsx(AddByLoyalty$Thin.make, {
        loyaltyPatch: loyaltyPatch,
        setLoyaltyPatch: props.setLoyaltyPatch,
        loyaltyNum: props.loyaltyNum,
        setLoyaltyNum: props.setLoyaltyNum,
        searchErrorType: props.searchErrorType,
        setSearchErrorType: props.setSearchErrorType,
        mobile: true,
        idx: idx,
        loading: loading,
        addOrErr: addOrErr,
        action: action,
        voyageSlug: voyageSlug,
        isPrimaryPax: isPrimaryPax,
        pax1MustBeBornBy: pax1MustBeBornBy
      });
  var getContent = function (tab) {
    if (tab === "all") {
      return null;
    } else if (tab === "managed") {
      return connectedTab;
    } else if (tab === "invite") {
      return inviteTab;
    } else if (tab === "details") {
      return detailsTab;
    } else {
      return loyaltyTab;
    }
  };
  var transformStyle = tab === "all" ? "translateX(0%)" : "translateX(-50%)";
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var diff = BookingDiff$Thin.parse(url.search);
  var onSubmit = function (e) {
    e.preventDefault();
    if (tab === "loyalty") {
      if (loyaltyPatch !== undefined) {
        var applyDiff;
        applyDiff = action === "Attach" ? BookingDiff$Thin.Alter.attachExistingLoyaltyPax(diff, idx, loyaltyPatch) : BookingDiff$Thin.Alter.replaceWithExistingLoyaltyPax(diff, idx, loyaltyPatch);
        return addOrErr(applyDiff);
      }
      console.log("No loyalty number entered");
      return ;
    }
    if (tab === "managed") {
      if (connectedAcct !== undefined) {
        var acct = Caml_option.valFromOption(connectedAcct);
        var applyDiff$1;
        applyDiff$1 = action === "Attach" ? BookingDiff$Thin.Alter.attachAnExistingPax(diff, idx, acct) : BookingDiff$Thin.Alter.replaceWithExistingPax(diff, idx, acct);
        return addOrErr(applyDiff$1);
      }
      console.log("No connected account selected");
      return ;
    }
    if (tab === "invite") {
      var match = CS_Emails$Util.Email.fromPrimitive(email);
      var match$1 = CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(prefFName);
      if (match.TAG === "Ok") {
        var email$1 = match._0;
        if (match$1.TAG === "Ok") {
          var firstName = match$1._0;
          var applyDiff$2;
          if (action === "Attach") {
            var lastName = CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(prefLName);
            var tmp;
            tmp = lastName.TAG === "Ok" ? Caml_option.some(lastName._0) : undefined;
            applyDiff$2 = BookingDiff$Thin.Alter.attachAnInvitedPax(diff, idx, {
                  email: email$1,
                  firstName: firstName,
                  lastName: tmp
                });
          } else {
            var lastName$1 = CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(prefLName);
            var tmp$1;
            tmp$1 = lastName$1.TAG === "Ok" ? Caml_option.some(lastName$1._0) : undefined;
            applyDiff$2 = BookingDiff$Thin.Alter.replaceWithInvitedPax(diff, idx, {
                  email: email$1,
                  firstName: firstName,
                  lastName: tmp$1
                });
          }
          return addOrErr(applyDiff$2);
        }
        console.log("Invalid email or first name");
        return ;
      }
      console.log("Invalid email or first name");
      return ;
    }
    if (tab !== "details") {
      return ;
    }
    var match$2 = CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(docFName);
    var match$3 = CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(docLName);
    var match$4 = CS_SQLDatesAndTimes$Util.DateOnly.fromPrimitive(dob);
    if (custodianAccountId !== undefined) {
      if (match$2.TAG === "Ok") {
        if (match$3.TAG === "Ok") {
          if (match$4.TAG === "Ok") {
            if (country !== undefined) {
              if (gender !== undefined) {
                var v = CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(prefFName);
                var tmp$2;
                tmp$2 = v.TAG === "Ok" ? Caml_option.some(v._0) : undefined;
                var v$1 = CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(prefLName);
                var tmp$3;
                tmp$3 = v$1.TAG === "Ok" ? Caml_option.some(v$1._0) : undefined;
                var newAcct_custodianAccountId = Caml_option.valFromOption(custodianAccountId);
                var newAcct_docFirstName = match$2._0;
                var newAcct_docLastName = match$3._0;
                var newAcct_docBirthdate = match$4._0;
                var newAcct_docCitizenship = Caml_option.valFromOption(country);
                var newAcct = {
                  custodianAccountId: newAcct_custodianAccountId,
                  docFirstName: newAcct_docFirstName,
                  docLastName: newAcct_docLastName,
                  docGender: gender,
                  docBirthdate: newAcct_docBirthdate,
                  docCitizenship: newAcct_docCitizenship,
                  preferredFirstName: tmp$2,
                  preferredLastName: tmp$3
                };
                var applyDiff$3;
                applyDiff$3 = action === "Attach" ? BookingDiff$Thin.Alter.attachNewConnectedPax(diff, idx, newAcct) : BookingDiff$Thin.Alter.replaceWithNewConnectedPax(diff, idx, newAcct);
                return addOrErr(applyDiff$3);
              }
              console.log("An error occurred");
              return ;
            }
            console.log("An error occurred");
            return ;
          }
          console.log("An error occurred");
          return ;
        }
        console.log("An error occurred");
        return ;
      }
      console.log("An error occurred");
      return ;
    }
    console.log("An error occurred");
  };
  var tmp;
  tmp = action === "Attach" ? Txt$Thin.BookingDetails.addPassAddPassenger.value : Txt$Thin.BookingDetails.addPassReplacePassenger.value;
  return JsxRuntime.jsxs(Drawer, {
              children: [
                JsxRuntime.jsx(ModalClose, {}),
                JsxRuntime.jsx(DialogTitle, {
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                direction: "row",
                                alignItems: "center",
                                spacing: 1,
                                children: [
                                  tab === "all" ? null : JsxRuntime.jsx(IconButton, {
                                          onClick: (function (param) {
                                              setTab(function (param) {
                                                    return "all";
                                                  });
                                            }),
                                          children: Caml_option.some(JsxRuntime.jsx(ArrowBackIosNew, {
                                                    fontSize: "sm"
                                                  }))
                                        }),
                                  JsxRuntime.jsx(Stack, {
                                        children: Caml_option.some(props.title)
                                      })
                                ]
                              })),
                      level: "title-md"
                    }),
                JsxRuntime.jsx(Divider, {}),
                JsxRuntime.jsx(DialogContent, {
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                direction: "row",
                                sx: {
                                  width: "200%",
                                  transition: "transform " + (
                                    tab !== "all" ? "0.3s" : "0.2s"
                                  ) + " ease-in-out",
                                  transform: transformStyle
                                },
                                children: [
                                  JsxRuntime.jsx(Stack, {
                                        sx: {
                                          width: "100%"
                                        },
                                        children: Caml_option.some(getMenuContent())
                                      }),
                                  JsxRuntime.jsx(Stack, {
                                        sx: {
                                          width: "100%"
                                        },
                                        children: Caml_option.some(getContent(tab))
                                      })
                                ]
                              })),
                      sx: {
                        overflowX: "hidden"
                      }
                    }),
                JsxRuntime.jsx(Stack, {
                      direction: "row",
                      justifyContent: "center",
                      sx: {
                        display: "flex",
                        gap: 1,
                        p: 1.5,
                        pb: 2,
                        borderTop: "1px solid",
                        borderColor: "divider",
                        display: tab === "all" ? "none" : "block"
                      },
                      children: Caml_option.some(JsxRuntime.jsx(AppButton$Thin.make, {
                                fullWidth: true,
                                loading: loading,
                                type_: "submit",
                                onClick: onSubmit,
                                variant: "solid",
                                size: "lg",
                                disabled: tab === "all" ? true : (
                                    tab === "managed" ? Belt_Option.isNone(connectedAcct) : (
                                        tab === "invite" ? Belt_Result.isError(CS_Emails$Util.Email.fromPrimitive(email)) : (
                                            tab === "details" ? Belt_Result.isError(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(docFName)) || Belt_Result.isError(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(docLName)) || Belt_Option.isNone(gender) || birthdateShouldError(dob, pax1MustBeBornBy, isPrimaryPax) || Belt_Option.isNone(country) : Belt_Option.isNone(loyaltyPatch)
                                          )
                                      )
                                  ),
                                children: Caml_option.some(tmp)
                              }))
                    })
              ],
              open: props.open_,
              anchor: "bottom",
              disablePortal: true,
              onClose: onClose,
              size: "md",
              slotProps: {
                backdrop: {
                  sx: {
                    backdropFilter: "none",
                    backgroundColor: "rgba(16, 13, 12, 0.7)"
                  }
                },
                content: {
                  sx: {
                    "--Drawer-verticalSize": "clamp(fit-content, 50%, 50%)",
                    maxHeight: "60%",
                    transition: "height 0.3s ease-in-out",
                    borderTopRightRadius: "4px",
                    borderTopLeftRadius: "4px",
                    ml: "8px",
                    mr: "8px",
                    width: "calc(100% - 16px)",
                    overflowX: "hidden"
                  }
                }
              }
            });
}

var make = AddPassMobile;

exports.make = make;
/* Txt-Thin Not a pure module */
