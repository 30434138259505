// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var Box = require("@mui/joy/Box").default;
var JsxRuntime = require("react/jsx-runtime");

function PageFrame(props) {
  var __auth = props.auth;
  var __backgroundColor = props.backgroundColor;
  var backgroundColor = __backgroundColor !== undefined ? __backgroundColor : "background.body";
  var auth = __auth !== undefined ? __auth : false;
  return JsxRuntime.jsx(Box, {
              children: Caml_option.some(JsxRuntime.jsx(Box, {
                        children: Caml_option.some(props.children),
                        sx: {
                          width: "100%",
                          maxWidth: "1536px",
                          alignSelf: "center",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: {
                            xs: "center",
                            md: auth ? "center" : "flex-start"
                          }
                        }
                      })),
              sx: {
                padding: {
                  xs: auth ? "3rem 1rem" : "1rem",
                  sm: auth ? "3rem" : "2rem"
                },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                flex: "1",
                backgroundColor: backgroundColor
              }
            });
}

var make = PageFrame;

exports.make = make;
/* @mui/joy/Box Not a pure module */
