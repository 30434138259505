// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var ApplyBookingChanges_ApplyBookingChangesMutation_graphql$Thin = require("../../../../__generated__/ApplyBookingChanges_ApplyBookingChangesMutation_graphql.bs.js");

var convertVariables = ApplyBookingChanges_ApplyBookingChangesMutation_graphql$Thin.Internal.convertVariables;

var convertResponse = ApplyBookingChanges_ApplyBookingChangesMutation_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = ApplyBookingChanges_ApplyBookingChangesMutation_graphql$Thin.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, ApplyBookingChanges_ApplyBookingChangesMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, ApplyBookingChanges_ApplyBookingChangesMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var ApplyBookingChangesMutation_bookingDiffError_decode = ApplyBookingChanges_ApplyBookingChangesMutation_graphql$Thin.Utils.bookingDiffError_decode;

var ApplyBookingChangesMutation_bookingDiffError_fromString = ApplyBookingChanges_ApplyBookingChangesMutation_graphql$Thin.Utils.bookingDiffError_fromString;

var ApplyBookingChangesMutation_bookingDiffThinError_decode = ApplyBookingChanges_ApplyBookingChangesMutation_graphql$Thin.Utils.bookingDiffThinError_decode;

var ApplyBookingChangesMutation_bookingDiffThinError_fromString = ApplyBookingChanges_ApplyBookingChangesMutation_graphql$Thin.Utils.bookingDiffThinError_fromString;

var ApplyBookingChangesMutation_docGender_decode = ApplyBookingChanges_ApplyBookingChangesMutation_graphql$Thin.Utils.docGender_decode;

var ApplyBookingChangesMutation_docGender_fromString = ApplyBookingChanges_ApplyBookingChangesMutation_graphql$Thin.Utils.docGender_fromString;

var ApplyBookingChangesMutation_elevatorProximity_decode = ApplyBookingChanges_ApplyBookingChangesMutation_graphql$Thin.Utils.elevatorProximity_decode;

var ApplyBookingChangesMutation_elevatorProximity_fromString = ApplyBookingChanges_ApplyBookingChangesMutation_graphql$Thin.Utils.elevatorProximity_fromString;

var ApplyBookingChangesMutation_longitudinality_decode = ApplyBookingChanges_ApplyBookingChangesMutation_graphql$Thin.Utils.longitudinality_decode;

var ApplyBookingChangesMutation_longitudinality_fromString = ApplyBookingChanges_ApplyBookingChangesMutation_graphql$Thin.Utils.longitudinality_fromString;

var ApplyBookingChangesMutation = {
  bookingDiffError_decode: ApplyBookingChangesMutation_bookingDiffError_decode,
  bookingDiffError_fromString: ApplyBookingChangesMutation_bookingDiffError_fromString,
  bookingDiffThinError_decode: ApplyBookingChangesMutation_bookingDiffThinError_decode,
  bookingDiffThinError_fromString: ApplyBookingChangesMutation_bookingDiffThinError_fromString,
  docGender_decode: ApplyBookingChangesMutation_docGender_decode,
  docGender_fromString: ApplyBookingChangesMutation_docGender_fromString,
  elevatorProximity_decode: ApplyBookingChangesMutation_elevatorProximity_decode,
  elevatorProximity_fromString: ApplyBookingChangesMutation_elevatorProximity_fromString,
  longitudinality_decode: ApplyBookingChangesMutation_longitudinality_decode,
  longitudinality_fromString: ApplyBookingChangesMutation_longitudinality_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(applyArgs, onSuccess) {
  var match = use();
  var mutate = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setError = match$1[1];
  var onSubmit = function (e) {
    e.preventDefault();
    setError(function (param) {
          
        });
    mutate({
          input: {
            _voyageSlug: applyArgs.voyage,
            _bookingId: applyArgs.bookingId,
            _bookingVersionNumber: applyArgs.bookingVersionNumber,
            _thinDiff: applyArgs.thinDiff
          }
        }, undefined, undefined, undefined, (function (resp, err) {
            if (err !== undefined) {
              return setError(function (param) {
                          return "An error occurred. Please try again.";
                        });
            }
            var match = resp.applyBookingChanges;
            if (match !== undefined) {
              var match$1 = match.results;
              if (match$1 !== undefined && match$1.length === 1) {
                var match$2 = match$1[0];
                var err$1 = match$2.nullableErr;
                if (err$1 !== undefined) {
                  return setError(function (param) {
                              return err$1;
                            });
                }
                if (match$2.thinErr === undefined) {
                  return onSuccess();
                }
                var err$2 = match$2.thinErr;
                return setError(function (param) {
                            return err$2;
                          });
              }
              
            }
            setError(function (param) {
                  return "An error occurred. Please try again.";
                });
          }), (function (err) {
            console.error(err);
            setError(function (param) {
                  return "An error occurred. Please try again.";
                });
          }), undefined);
  };
  return {
          error: match$1[0],
          setError: setError,
          isMutating: match[1],
          onSubmit: onSubmit
        };
}

exports.ApplyBookingChangesMutation = ApplyBookingChangesMutation;
exports.useMutation = useMutation;
/* commitMutation Not a pure module */
