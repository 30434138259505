// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Txt$Thin = require("../../../text/Txt.bs.js");
var Js_string = require("rescript/lib/js/js_string.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SysCtx$Thin = require("../../../utils/SysCtx.bs.js");
var List = require("@mui/joy/List").default;
var Stack = require("@mui/joy/Stack").default;
var ListItem = require("@mui/joy/ListItem").default;
var JsxRuntime = require("react/jsx-runtime");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var ListItemButton = require("@mui/joy/ListItemButton").default;
var ListItemContent = require("@mui/joy/ListItemContent").default;
var Logout = require("@mui/icons-material/Logout").default;
var ListItemDecorator = require("@mui/joy/ListItemDecorator").default;
var AccountCircle = require("@mui/icons-material/AccountCircle").default;
var DirectionsBoat = require("@mui/icons-material/DirectionsBoat").default;
var SupervisorAccountOutlined = require("@mui/icons-material/SupervisorAccountOutlined").default;

function Sidebar(props) {
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var match = url.path;
  var tmp;
  if (props.hasConnectedAccounts) {
    var match$1 = url.path;
    tmp = JsxRuntime.jsx(ListItem, {
          children: Caml_option.some(JsxRuntime.jsxs(ListItemButton, {
                    children: [
                      JsxRuntime.jsx(ListItemDecorator, {
                            children: Caml_option.some(JsxRuntime.jsx(SupervisorAccountOutlined, {}))
                          }),
                      JsxRuntime.jsx(ListItemContent, {
                            children: Caml_option.some(Txt$Thin.Nav.connectedAccounts.value)
                          })
                    ],
                    onClick: (function (param) {
                        RescriptReactRouter.push(SysCtx$Thin.vars.connectedAccountsLink);
                      }),
                    selected: match$1 && !match$1.tl ? Js_string.substr(1, SysCtx$Thin.vars.connectedAccountsLink) === match$1.hd : false,
                    sx: {
                      borderRadius: "sm"
                    }
                  }))
        });
  } else {
    tmp = null;
  }
  var match$2 = url.path;
  return JsxRuntime.jsx(Stack, {
              direction: "column",
              justifyContent: "flex-start",
              sx: {
                display: {
                  xs: "none",
                  md: "flex"
                },
                width: "13rem",
                flexShrink: 0,
                overflowY: "auto",
                borderRight: 1,
                borderColor: "neutral.200",
                backgroundColor: "background.surface",
                p: 1
              },
              children: Caml_option.some(JsxRuntime.jsx(List, {
                        children: Caml_option.some(JsxRuntime.jsxs(List, {
                                  children: [
                                    JsxRuntime.jsx(ListItem, {
                                          children: Caml_option.some(JsxRuntime.jsxs(ListItemButton, {
                                                    children: [
                                                      JsxRuntime.jsx(ListItemDecorator, {
                                                            children: Caml_option.some(JsxRuntime.jsx(DirectionsBoat, {}))
                                                          }),
                                                      JsxRuntime.jsx(ListItemContent, {
                                                            children: Caml_option.some(Txt$Thin.Nav.bookings.value)
                                                          })
                                                    ],
                                                    onClick: (function (param) {
                                                        RescriptReactRouter.push(SysCtx$Thin.vars.bookingsLink);
                                                      }),
                                                    selected: match && !match.tl ? Js_string.substr(1, SysCtx$Thin.vars.bookingsLink) === match.hd : false,
                                                    sx: {
                                                      borderRadius: "sm"
                                                    }
                                                  }))
                                        }),
                                    tmp,
                                    JsxRuntime.jsx(ListItem, {
                                          children: Caml_option.some(JsxRuntime.jsxs(ListItemButton, {
                                                    children: [
                                                      JsxRuntime.jsx(ListItemDecorator, {
                                                            children: Caml_option.some(JsxRuntime.jsx(AccountCircle, {}))
                                                          }),
                                                      JsxRuntime.jsx(ListItemContent, {
                                                            children: Caml_option.some(Txt$Thin.Nav.account.value)
                                                          })
                                                    ],
                                                    onClick: (function (param) {
                                                        RescriptReactRouter.push(SysCtx$Thin.vars.accountLink);
                                                      }),
                                                    selected: match$2 && !match$2.tl ? Js_string.substr(1, SysCtx$Thin.vars.accountLink) === match$2.hd : false,
                                                    sx: {
                                                      borderRadius: "sm"
                                                    }
                                                  }))
                                        }),
                                    JsxRuntime.jsx(ListItem, {
                                          children: Caml_option.some(JsxRuntime.jsxs(ListItemButton, {
                                                    children: [
                                                      JsxRuntime.jsx(ListItemDecorator, {
                                                            children: Caml_option.some(JsxRuntime.jsx(Logout, {}))
                                                          }),
                                                      JsxRuntime.jsx(ListItemContent, {
                                                            children: Caml_option.some(Txt$Thin.Nav.logout.value)
                                                          })
                                                    ],
                                                    onClick: props.logout,
                                                    sx: {
                                                      borderRadius: "sm"
                                                    }
                                                  })),
                                          sx: {
                                            flexShrink: 0
                                          }
                                        })
                                  ],
                                  size: "sm",
                                  sx: {
                                    gap: 1,
                                    flexGrow: 1
                                  }
                                })),
                        size: "sm",
                        sx: {
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                          overflowY: "visible"
                        }
                      }))
            });
}

var make = Sidebar;

exports.make = make;
/* Txt-Thin Not a pure module */
