// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Decimal$Util = require("util/src/CustomScalars/CS_Decimal.bs.js");

function getPaymentCents(pa) {
  if (pa.TAG === "BookingSession") {
    var opt = pa._0;
    if (opt !== undefined) {
      return CS_Decimal$Util.Decimal10_2.toInt(opt._0.amt);
    } else {
      return ;
    }
  }
  var $$int = pa._0;
  if ($$int !== undefined) {
    return $$int;
  }
  
}

function getPaymentSuggestions(s) {
  var makeFullRec = function (pmtDiscountDisplayPct) {
    return {
            discountDisplayPct: Belt_Option.map(pmtDiscountDisplayPct, (function (v) {
                    return String(v * 100.0) + "%";
                  }))
          };
  };
  switch (s.TAG) {
    case "SessionSave" :
        var paymentSuggestionTable = Belt_Array.keepMap(s._0.paymentSuggestions, (function (v) {
                return v;
              }));
        var match = Belt_Array.get(Belt_Array.keep(paymentSuggestionTable, (function (v) {
                    return v.isPifSuggestion;
                  })), 0);
        if (match === undefined) {
          return ;
        }
        var fullRec = makeFullRec(match.pmtDiscountDisplayPct);
        var match$1 = Belt_Array.get(Belt_Array.keep(paymentSuggestionTable, (function (v) {
                    return v.overdueOffset === 0;
                  })), 0);
        var payMin;
        if (match$1 !== undefined) {
          var partial = match$1.partialCheckoutOption;
          payMin = partial !== undefined ? ({
                minNow: Caml_option.valFromOption(partial),
                minSoon: match$1.pmtSuggestionTotal
              }) : undefined;
        } else {
          payMin = undefined;
        }
        var payCurrent;
        if (paymentSuggestionTable.length > 1) {
          var match$2 = Belt_Array.get(paymentSuggestionTable, 0);
          var match$3 = Belt_Array.get(paymentSuggestionTable, 1);
          if (match$2 !== undefined && match$3 !== undefined) {
            var date = match$3.activateAfter;
            payCurrent = date !== undefined ? ({
                  currNow: match$2.pmtSuggestionTotal,
                  nextDue: Caml_option.valFromOption(date)
                }) : undefined;
          } else {
            payCurrent = undefined;
          }
        } else {
          payCurrent = undefined;
        }
        var payCustom;
        if (paymentSuggestionTable.length > 1) {
          var match$4 = Belt_Array.get(paymentSuggestionTable, 0);
          if (match$4 !== undefined) {
            var partial$1 = match$4.partialCheckoutOption;
            payCustom = partial$1 !== undefined ? ({
                  minimum: Caml_option.valFromOption(partial$1)
                }) : ({
                  minimum: match$4.pmtSuggestionTotal
                });
          } else {
            payCustom = undefined;
          }
        } else {
          payCustom = undefined;
        }
        return {
                payFull: match.pmtSuggestionTotal,
                fullRecDetails: fullRec,
                payMin: payMin,
                payCurrent: payCurrent,
                payCustom: payCustom
              };
    case "CurrentSession" :
        var paymentSuggestionTable$1 = Belt_Array.keepMap(s._0.paymentSuggestions, (function (v) {
                return v;
              }));
        var match$5 = Belt_Array.get(Belt_Array.keep(paymentSuggestionTable$1, (function (v) {
                    return v.isPifSuggestion;
                  })), 0);
        if (match$5 === undefined) {
          return ;
        }
        var fullRec$1 = makeFullRec(match$5.pmtDiscountDisplayPct);
        var match$6 = Belt_Array.get(Belt_Array.keep(paymentSuggestionTable$1, (function (v) {
                    return v.overdueOffset === 0;
                  })), 0);
        var payMin$1;
        if (match$6 !== undefined) {
          var partial$2 = match$6.partialCheckoutOption;
          payMin$1 = partial$2 !== undefined ? ({
                minNow: Caml_option.valFromOption(partial$2),
                minSoon: match$6.pmtSuggestionTotal
              }) : undefined;
        } else {
          payMin$1 = undefined;
        }
        var payCurrent$1;
        if (paymentSuggestionTable$1.length > 1) {
          var match$7 = Belt_Array.get(paymentSuggestionTable$1, 0);
          var match$8 = Belt_Array.get(paymentSuggestionTable$1, 1);
          if (match$7 !== undefined && match$8 !== undefined) {
            var date$1 = match$8.activateAfter;
            payCurrent$1 = date$1 !== undefined ? ({
                  currNow: match$7.pmtSuggestionTotal,
                  nextDue: Caml_option.valFromOption(date$1)
                }) : undefined;
          } else {
            payCurrent$1 = undefined;
          }
        } else {
          payCurrent$1 = undefined;
        }
        var payCustom$1;
        if (paymentSuggestionTable$1.length > 1) {
          var match$9 = Belt_Array.get(paymentSuggestionTable$1, 0);
          if (match$9 !== undefined) {
            var partial$3 = match$9.partialCheckoutOption;
            payCustom$1 = partial$3 !== undefined ? ({
                  minimum: Caml_option.valFromOption(partial$3)
                }) : ({
                  minimum: match$9.pmtSuggestionTotal
                });
          } else {
            payCustom$1 = undefined;
          }
        } else {
          payCustom$1 = undefined;
        }
        return {
                payFull: match$5.pmtSuggestionTotal,
                fullRecDetails: fullRec$1,
                payMin: payMin$1,
                payCurrent: payCurrent$1,
                payCustom: payCustom$1
              };
    case "ManageBooking" :
        var resp = s._0;
        if (resp === undefined) {
          return ;
        }
        var paymentSuggestions = resp.paymentSuggestions;
        if (paymentSuggestions === undefined) {
          return ;
        }
        var paymentSuggestionTable$2 = Belt_Array.keepMap(paymentSuggestions, (function (v) {
                return v;
              }));
        var match$10 = Belt_Array.get(Belt_Array.keep(paymentSuggestionTable$2, (function (v) {
                    return v.isPifSuggestion;
                  })), 0);
        if (match$10 === undefined) {
          return ;
        }
        var match$11 = Belt_Array.get(Belt_Array.keep(paymentSuggestionTable$2, (function (v) {
                    return v.overdueOffset === 0;
                  })), 0);
        var payMin$2;
        if (match$11 !== undefined) {
          var partial$4 = match$11.partialCheckoutOption;
          payMin$2 = partial$4 !== undefined ? ({
                minNow: Caml_option.valFromOption(partial$4),
                minSoon: match$11.pmtSuggestionTotal
              }) : undefined;
        } else {
          payMin$2 = undefined;
        }
        var payCurrent$2;
        if (paymentSuggestionTable$2.length > 1) {
          var match$12 = Belt_Array.get(paymentSuggestionTable$2, 0);
          var match$13 = Belt_Array.get(paymentSuggestionTable$2, 1);
          if (match$12 !== undefined && match$13 !== undefined) {
            var date$2 = match$13.activateAfter;
            payCurrent$2 = date$2 !== undefined ? ({
                  currNow: match$12.pmtSuggestionTotal,
                  nextDue: Caml_option.valFromOption(date$2)
                }) : undefined;
          } else {
            payCurrent$2 = undefined;
          }
        } else {
          payCurrent$2 = undefined;
        }
        var match$14 = Belt_Array.get(paymentSuggestionTable$2, 0);
        var payCustom$2;
        if (match$14 !== undefined) {
          var pmtSuggestionTotal = match$14.pmtSuggestionTotal;
          payCustom$2 = CS_Decimal$Util.Decimal10_2.toFloat(pmtSuggestionTotal) >= 20.00 ? ({
                minimum: CS_Decimal$Util.Decimal10_2.Exn.fromPrimitive("20.00")
              }) : ({
                minimum: pmtSuggestionTotal
              });
        } else {
          payCustom$2 = undefined;
        }
        return {
                payFull: match$10.pmtSuggestionTotal,
                fullRecDetails: {
                  discountDisplayPct: undefined
                },
                payMin: payMin$2,
                payCurrent: payCurrent$2,
                payCustom: payCustom$2
              };
    
  }
}

exports.getPaymentCents = getPaymentCents;
exports.getPaymentSuggestions = getPaymentSuggestions;
/* CS_Decimal-Util Not a pure module */
