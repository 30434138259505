// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Common$Thin = require("../../../utils/Common.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var InviteToTransfer_InviteToTransferMutation_graphql$Thin = require("../../../__generated__/InviteToTransfer_InviteToTransferMutation_graphql.bs.js");

var convertVariables = InviteToTransfer_InviteToTransferMutation_graphql$Thin.Internal.convertVariables;

var convertResponse = InviteToTransfer_InviteToTransferMutation_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = InviteToTransfer_InviteToTransferMutation_graphql$Thin.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, InviteToTransfer_InviteToTransferMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, InviteToTransfer_InviteToTransferMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var InviteToTransferMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(_accountId, refetch, onClose) {
  var match = use();
  var mutate = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var setNewEmail = match$1[1];
  var newEmail = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setError = match$2[1];
  var onSubmit = function (e) {
    e.preventDefault();
    setError(function (param) {
          
        });
    if (newEmail === "") {
      setError(function (param) {
            return "Please enter an email address";
          });
    } else if (Common$Thin.Email.isValid(newEmail)) {
      mutate({
            input: {
              _invitationEmail: CS_Emails$Util.Email.Exn.fromPrimitive(newEmail),
              _accountId: _accountId
            }
          }, undefined, undefined, undefined, (function (resp, error) {
              if (error !== undefined) {
                console.error(error);
                setError(function (param) {
                      return "Something went wrong inviting to transfer 2";
                    });
                return ;
              }
              var match = resp.inviteToTransfer.inviteToTransferResult;
              if (match !== undefined) {
                var match$1 = match.success;
                if (match$1 !== undefined && match$1) {
                  refetch();
                  onClose();
                  setNewEmail(function (param) {
                        return "";
                      });
                  return ;
                }
                
              }
              console.error("No result from inviteToTransfer");
              setError(function (param) {
                    return "Something went wrong inviting to transfer 1";
                  });
            }), (function (err) {
              console.error(err);
              setError(function (param) {
                    return "Something went wrong inviting to transfer 3";
                  });
            }), undefined);
    } else {
      setError(function (param) {
            return "Please enter a valid email address";
          });
    }
  };
  return {
          isMutating: match[1],
          newEmail: newEmail,
          setNewEmail: setNewEmail,
          error: match$2[0],
          onSubmit: onSubmit
        };
}

exports.InviteToTransferMutation = InviteToTransferMutation;
exports.useMutation = useMutation;
/* commitMutation Not a pure module */
