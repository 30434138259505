// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Joy$Util = require("util/src/Joy.bs.js");
var Txt$Thin = require("../../text/Txt.bs.js");
var Merge$Util = require("util/src/Merge.bs.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thin = require("../common/molecules/Dialog.bs.js");
var AppAlert$Thin = require("../common/atoms/AppAlert.bs.js");
var Stack = require("@mui/joy/Stack").default;
var AppButton$Thin = require("../common/atoms/AppButton.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var FormControl = require("@mui/joy/FormControl").default;
var ChangeEmailSubmit$Thin = require("./mutations/ChangeEmailSubmit.bs.js");
var FormHelperText = require("@mui/joy/FormHelperText").default;
var SendOutlined = require("@mui/icons-material/SendOutlined").default;

function ChangeEmailForm(props) {
  var onClose = props.onClose;
  var match = ChangeEmailSubmit$Thin.useMutation(props.refresh);
  var setError = match.setError;
  var error = match.error;
  var setNewEmail = match.setNewEmail;
  var newEmail = match.newEmail;
  var tmp;
  if (match.success) {
    tmp = JsxRuntime.jsx(AppAlert$Thin.make, {
          message: Merge$Util.render(Txt$Thin.Account.emailVerify, props.userContext),
          type_: "warning"
        });
  } else {
    var match$1 = CS_Emails$Util.Email.fromPrimitive(newEmail);
    var tmp$1;
    tmp$1 = match$1.TAG === "Ok" ? null : "Please enter a valid email address.";
    tmp = JsxRuntime.jsx(Stack, {
          direction: "column",
          component: "form",
          spacing: 2,
          sx: {
            width: "100%"
          },
          onSubmit: match.onSubmit,
          children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                    error: Belt_Result.isError(CS_Emails$Util.Email.fromPrimitive(newEmail)),
                    children: [
                      JsxRuntime.jsx(Joy$Util.Input.make, {
                            type_: "email",
                            placeholder: Txt$Thin.Account.emailModalEmailPlaceholder.value,
                            value: newEmail,
                            onChange: (function (e) {
                                var val = e.currentTarget.value;
                                setNewEmail(function (param) {
                                      return val;
                                    });
                              }),
                            endDecorator: Caml_option.some(JsxRuntime.jsx(AppButton$Thin.make, {
                                      loading: match.isMutating,
                                      type_: "submit",
                                      endDecorator: Caml_option.some(JsxRuntime.jsx(SendOutlined, {
                                                fontSize: "sm"
                                              })),
                                      disabled: Belt_Result.isError(CS_Emails$Util.Email.fromPrimitive(newEmail)),
                                      children: "Send"
                                    }))
                          }),
                      JsxRuntime.jsx(FormHelperText, {
                            children: Caml_option.some(tmp$1)
                          })
                    ]
                  }))
        });
  }
  return JsxRuntime.jsxs(Dialog$Thin.make, {
              open_: props.open_,
              title: Txt$Thin.Account.emailModalHeading.value,
              description: Caml_option.some(Txt$Thin.Account.emailModalDescription.value),
              onClose: (function () {
                  onClose();
                  setNewEmail(function (param) {
                        return "";
                      });
                  setError(function (param) {
                        
                      });
                }),
              titleId: "email-dialog-title",
              titleDescId: "email-dialog-description",
              children: [
                JsxRuntime.jsx(Stack, {
                      spacing: 2,
                      children: Caml_option.some(tmp)
                    }),
                error !== undefined ? JsxRuntime.jsx(AppAlert$Thin.make, {
                        message: error,
                        type_: "error"
                      }) : JsxRuntime.jsx(JsxRuntime.Fragment, {})
              ]
            });
}

var make = ChangeEmailForm;

exports.make = make;
/* Joy-Util Not a pure module */
