// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var AppAlert$Thin = require("../../common/atoms/AppAlert.bs.js");
var Stack = require("@mui/joy/Stack").default;
var PaymentFrame$Thin = require("./PaymentFrame.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var SalesFlowState$Thin = require("../../../utils/sales-flow/SalesFlowState.bs.js");
var PaymentProcessor$Thin = require("../../../utils/payments/PaymentProcessor.bs.js");
var CircularProgress = require("@mui/joy/CircularProgress").default;
var PaymentMethodSelectors$Thin = require("../../common/molecules/payment-modules/PaymentMethodSelectors.bs.js");

function PaymentMethodSelection(props) {
  var sessionData = props.sessionData;
  var flow = PaymentProcessor$Thin.usePaymentProcessor(props.voyageSlug, SalesFlowState$Thin.URLState.serialize(props.urlState));
  var tmp;
  var exit = 0;
  if (typeof flow !== "object") {
    exit = 1;
  } else {
    tmp = flow.TAG === "Success" ? JsxRuntime.jsx(PaymentMethodSelectors$Thin.make, {
            flow: flow._0,
            payment: {
              TAG: "BookingSession",
              _0: sessionData.paymentOption
            },
            setPaymentAction: props.setPaymentAction,
            paymentReturn: props.paymentReturn,
            calculatedFee: props.calculatedFee,
            setCalculatedFee: props.setCalculatedFee,
            locked: Belt_Option.isNone(sessionData.paymentOption),
            needConfirm: true
          }) : JsxRuntime.jsx(AppAlert$Thin.make, {
            message: "An error occurred fetching payment methods. Please try again.",
            type_: "error"
          });
  }
  if (exit === 1) {
    tmp = JsxRuntime.jsx(Stack, {
          direction: "row",
          justifyContent: "center",
          children: Caml_option.some(JsxRuntime.jsx(CircularProgress, {
                    size: "md",
                    variant: "solid"
                  }))
        });
  }
  return JsxRuntime.jsx(PaymentFrame$Thin.make, {
              title: "Payment methods",
              locked: Belt_Option.isNone(sessionData.paymentOption),
              children: JsxRuntime.jsx(Stack, {
                    direction: "column",
                    spacing: 2,
                    children: Caml_option.some(tmp)
                  })
            });
}

var make = PaymentMethodSelection;

exports.make = make;
/* AppAlert-Thin Not a pure module */
