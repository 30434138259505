// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Joy$Util = require("util/src/Joy.bs.js");
var DateFns = require("date-fns");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var AppAlert$Thin = require("../../common/atoms/AppAlert.bs.js");
var CS_Phone$Util = require("util/src/CustomScalars/CS_Phone.bs.js");
var DateOnly$Util = require("util/src/DateOnly.bs.js");
var Alert = require("@mui/joy/Alert").default;
var Stack = require("@mui/joy/Stack").default;
var AppButton$Thin = require("../../common/atoms/AppButton.bs.js");
var ReactState$Util = require("util/src/ReactState.bs.js");
var SelectorCard$Thin = require("../../common/molecules/SelectorCard.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var ConfirmPaxCard$Thin = require("./ConfirmPaxCard.bs.js");
var SalesFlowState$Thin = require("../../../utils/sales-flow/SalesFlowState.bs.js");
var UpdateAccountData$Thin = require("../../common/mutations/UpdateAccountData.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var ModeEditOutlined = require("@mui/icons-material/ModeEditOutlined").default;
var UpdateAccountData_UpdateAccountDataMutation_graphql$Thin = require("../../../__generated__/UpdateAccountData_UpdateAccountDataMutation_graphql.bs.js");

function ProvideInfoSelector$Tester(props) {
  var match = React.useState(function () {
        return "";
      });
  var setTest = match[1];
  return JsxRuntime.jsx(Joy$Util.Input.make, {
              type_: "text",
              value: match[0],
              onChange: (function (e) {
                  var val = e.currentTarget.value;
                  setTest(function (param) {
                        return val;
                      });
                })
            });
}

var Tester = {
  make: ProvideInfoSelector$Tester
};

function ProvideInfoSelector(props) {
  var countryFrag = props.countryFrag;
  var pax1MustBeBornBy = props.pax1MustBeBornBy;
  var setProvideInfoError = props.setProvideInfoError;
  var provideInfoError = props.provideInfoError;
  var sessionPax = props.sessionPax;
  var paxNum = props.paxNum;
  var sessionData = props.sessionData;
  var updateSessionAndSave = props.updateSessionAndSave;
  var sameAsBc = props.sameAsBc;
  var isSelected = props.isSelected;
  var match = React.useState(function () {
        return true;
      });
  var setProvideInfoLoading = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setSaveSuccess = match$1[1];
  var saveSuccess = match$1[0];
  var match$2 = ReactState$Util.useDebouncedState((function () {
          return {
                  TAG: "Pax",
                  _0: sessionPax !== undefined ? UpdateAccountData$Thin.Passenger.sessionPaxToPaxUpdate(sessionPax) : UpdateAccountData$Thin.Passenger.empty
                };
        }), undefined, undefined);
  var match$3 = match$2.state;
  var setPax = match$3[1];
  var pax = match$3[0];
  var match$4 = paxNum === 1;
  var match$5 = sameAsBc && match$4 ? [
      props.bc,
      props.setBc
    ] : [
      pax,
      setPax
    ];
  var setPax$1 = match$5[1];
  var pax$1 = match$5[0];
  var allTravelDocFieldsComplete = function (p) {
    if (p.TAG === "Account") {
      return false;
    }
    var p$1 = p._0;
    var match = p$1.docFirstName;
    var match$1 = p$1.docLastName;
    var match$2 = p$1.docGender;
    var match$3 = p$1.docBirthdate;
    var match$4 = p$1.docCitizenship;
    if (match !== undefined && match$1 !== undefined && match$2 !== undefined && match$3 !== undefined) {
      return match$4 !== undefined;
    } else {
      return false;
    }
  };
  var incompleteBCAllTravelFieldsFilledOut = function (p) {
    if (p !== undefined && !(typeof p !== "object" || p.TAG !== "BCIncomplete")) {
      return true;
    } else {
      return false;
    }
  };
  var fieldsNotSaveable = function (p, s) {
    if (p.TAG === "Account") {
      return false;
    }
    if (s === undefined) {
      return false;
    }
    var match = p._0;
    var docCitizenship = match.docCitizenship;
    var docGender = match.docGender;
    var docBirthdate = match.docBirthdate;
    var docLastName = match.docLastName;
    var docFirstName = match.docFirstName;
    if (typeof s !== "object") {
      return false;
    }
    switch (s.TAG) {
      case "BCIncomplete" :
          var match$1 = s._0;
          if (Caml_obj.equal(docFirstName, CS_NonemptyStrings$Util.NonEmptyString.toString(match$1.iDocFirstName)) && Caml_obj.equal(docLastName, CS_NonemptyStrings$Util.NonEmptyString.toString(match$1.iDocLastName)) && Caml_obj.equal(docGender, match$1.iDocGender) && Caml_obj.equal(docBirthdate, Caml_option.some(match$1.iDocBirthdate))) {
            return Caml_obj.equal(docCitizenship, Caml_option.some(match$1.iDocCitizenship));
          } else {
            return false;
          }
      case "Provide" :
          var match$2 = s._0;
          var iPreferredFirstName = match$2.preferredFirstName;
          if (iPreferredFirstName === undefined) {
            return false;
          }
          var iPreferredLastName = match$2.preferredLastName;
          if (iPreferredLastName === undefined) {
            return false;
          }
          var iPhone = match$2.phone;
          if (iPhone === undefined) {
            return false;
          }
          var iPostalCode = match$2.postalCode;
          if (iPostalCode === undefined) {
            return false;
          }
          var updatePhone = match.phoneCountryCode + " " + Belt_Option.getWithDefault(match.phone, "");
          if (Caml_obj.equal(docFirstName, CS_NonemptyStrings$Util.NonEmptyString.toString(match$2.docFirstName)) && Caml_obj.equal(docLastName, CS_NonemptyStrings$Util.NonEmptyString.toString(match$2.docLastName)) && Caml_obj.equal(docGender, match$2.docGender) && Caml_obj.equal(docBirthdate, Caml_option.some(match$2.docBirthdate)) && Caml_obj.equal(docCitizenship, Caml_option.some(match$2.docCitizenship)) && Caml_obj.equal(match.preferredFirstName, CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(iPreferredFirstName))) && Caml_obj.equal(match.preferredLastName, CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(iPreferredLastName))) && Caml_obj.equal(match.postalCode, CS_NonemptyStrings$Util.PostalCode.toString(Caml_option.valFromOption(iPostalCode))) && updatePhone === CS_Phone$Util.Phone.toString(Caml_option.valFromOption(iPhone))) {
            return Caml_obj.equal(match.residenceCountry, match$2.residenceCountry);
          } else {
            return false;
          }
      default:
        return false;
    }
  };
  var birthdateShouldError = function (thisPaxesDob, pax1BornBefore, isPrimaryPax) {
    if (thisPaxesDob !== undefined) {
      if (pax1BornBefore !== undefined && isPrimaryPax) {
        return DateFns.isBefore(CS_Make$Util.Dateable.Plain.toDate(Caml_option.valFromOption(pax1BornBefore)), DateOnly$Util.toDate(Caml_option.valFromOption(thisPaxesDob)));
      } else {
        return false;
      }
    } else {
      return true;
    }
  };
  var paxFormIncomplete = function (p) {
    if (p.TAG === "Account") {
      return true;
    }
    var p$1 = p._0;
    if (p$1.docFirstName === undefined || p$1.docLastName === undefined || p$1.docGender === undefined || birthdateShouldError(p$1.docBirthdate, pax1MustBeBornBy, paxNum === 1) || p$1.docCitizenship === undefined || p$1.preferredFirstName === undefined || p$1.preferredLastName === undefined || p$1.phone === undefined || p$1.postalCode === undefined) {
      return true;
    } else {
      return p$1.residenceCountry === undefined;
    }
  };
  var maxBirthdate = paxNum === 1 ? pax1MustBeBornBy : undefined;
  var isPaxIncomplete = function (p) {
    if (p.TAG === "Account") {
      return true;
    } else {
      return !p._0.isComplete;
    }
  };
  var tmp;
  if (!sameAsBc || paxNum !== 1) {
    tmp = JsxRuntime.jsx(UpdateAccountData$Thin.Form.Personal.make, {
          fragmentRefs: countryFrag,
          title: "User Profile Data",
          titleSize: "md",
          update: pax$1,
          setUpdate: setPax$1,
          disabled: false,
          noHelperText: true,
          forceValidate: true
        });
  } else if (pax$1.TAG === "Account") {
    tmp = null;
  } else {
    var match$6 = pax$1._0;
    var fn = match$6.preferredFirstName;
    if (fn !== undefined) {
      var ln = match$6.preferredLastName;
      if (ln !== undefined) {
        var email = match$6.email;
        tmp = email !== undefined ? JsxRuntime.jsx(ConfirmPaxCard$Thin.make, {
                name: fn + " " + ln,
                email: email,
                custodian: undefined,
                custodianEmail: undefined,
                isComplete: match$6.isComplete || allTravelDocFieldsComplete(pax$1)
              }) : null;
      } else {
        tmp = null;
      }
    } else {
      tmp = null;
    }
  }
  return JsxRuntime.jsx(SelectorCard$Thin.make, {
              isSelected: isSelected,
              onClick: props.onClick,
              title: props.title,
              description: props.description,
              children: JsxRuntime.jsx(Stack, {
                    spacing: 1,
                    sx: {
                      display: isSelected ? "flex" : "none",
                      overflow: "hidden",
                      py: 1
                    },
                    children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                              sx: {
                                py: 1
                              },
                              children: [
                                JsxRuntime.jsxs(Stack, {
                                      direction: "column",
                                      spacing: 2,
                                      sx: {
                                        p: 2,
                                        border: "1px solid",
                                        borderColor: "neutral.300",
                                        backgroundColor: "neutral.200",
                                        borderRadius: "sm"
                                      },
                                      children: [
                                        tmp,
                                        sameAsBc && paxNum === 1 && isPaxIncomplete(pax$1) || !sameAsBc || paxNum !== 1 ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                                children: [
                                                  sameAsBc && paxNum === 1 && !incompleteBCAllTravelFieldsFilledOut(sessionPax) ? JsxRuntime.jsx(Alert, {
                                                          children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                    color: "warning",
                                                                    level: "body-sm",
                                                                    children: "Passenger information is incomplete. Please fill out the form below!"
                                                                  })),
                                                          color: "warning",
                                                          startDecorator: Caml_option.some(JsxRuntime.jsx(ModeEditOutlined, {
                                                                    sx: {
                                                                      color: "warning.500"
                                                                    }
                                                                  })),
                                                          sx: {
                                                            borderLeft: "4px solid",
                                                            borderColor: "warningSecondary.500",
                                                            alignItems: "flex-start"
                                                          }
                                                        }) : null,
                                                  JsxRuntime.jsx(UpdateAccountData$Thin.Form.Travel.make, {
                                                        fragmentRefs: countryFrag,
                                                        titleSize: "md",
                                                        update: pax$1,
                                                        setUpdate: setPax$1,
                                                        allFieldsEditable: true,
                                                        disabled: false,
                                                        maxBirthdate: maxBirthdate,
                                                        forceValidate: true
                                                      })
                                                ]
                                              }) : null
                                      ]
                                    }),
                                sameAsBc && paxNum === 1 && isPaxIncomplete(pax$1) || !sameAsBc || paxNum !== 1 ? JsxRuntime.jsxs(Stack, {
                                        direction: "column",
                                        sx: {
                                          width: "fit-content",
                                          py: 1
                                        },
                                        children: [
                                          (console.log(pax$1, fieldsNotSaveable(pax$1, sessionPax), paxFormIncomplete(pax$1), saveSuccess), null),
                                          JsxRuntime.jsx(AppButton$Thin.make, {
                                                loading: props.loading,
                                                onClick: (function (param) {
                                                    setProvideInfoError(function (param) {
                                                          
                                                        });
                                                    if (pax$1.TAG === "Account") {
                                                      return ;
                                                    }
                                                    var p = pax$1._0;
                                                    if (paxNum === 1 && sameAsBc) {
                                                      var match = p.isComplete;
                                                      var match$1 = p.accountId;
                                                      var match$2 = Belt_Option.map(p.docFirstName, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive);
                                                      var match$3 = Belt_Option.map(p.docLastName, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive);
                                                      var match$4 = Belt_Option.map(p.docGender, UpdateAccountData_UpdateAccountDataMutation_graphql$Thin.Utils.docGender_decode);
                                                      var match$5 = p.docBirthdate;
                                                      var match$6 = p.docCitizenship;
                                                      if (match) {
                                                        if (match$1 !== undefined) {
                                                          return updateSessionAndSave(SalesFlowState$Thin.Alter.upsertPax(sessionData, paxNum, {
                                                                          TAG: "BC",
                                                                          _0: {
                                                                            id: Caml_option.valFromOption(match$1)
                                                                          }
                                                                        }), setProvideInfoError, setProvideInfoLoading, (function () {
                                                                        setSaveSuccess(function (param) {
                                                                              return true;
                                                                            });
                                                                      }));
                                                        }
                                                        
                                                      } else if (match$1 !== undefined && match$2 !== undefined && match$2.TAG === "Ok" && match$3 !== undefined && match$3.TAG === "Ok" && match$4 !== undefined) {
                                                        var iDocGender = Caml_option.valFromOption(match$4);
                                                        if (iDocGender !== undefined && match$5 !== undefined && match$6 !== undefined) {
                                                          var pax$2 = {
                                                            TAG: "BCIncomplete",
                                                            _0: {
                                                              accountId: Caml_option.valFromOption(match$1),
                                                              iDocFirstName: match$2._0,
                                                              iDocLastName: match$3._0,
                                                              iDocGender: iDocGender,
                                                              iDocBirthdate: Caml_option.valFromOption(match$5),
                                                              iDocCitizenship: Caml_option.valFromOption(match$6)
                                                            }
                                                          };
                                                          return updateSessionAndSave(SalesFlowState$Thin.Alter.upsertPax(sessionData, paxNum, pax$2), setProvideInfoError, setProvideInfoLoading, (function () {
                                                                        setSaveSuccess(function (param) {
                                                                              return true;
                                                                            });
                                                                      }));
                                                        }
                                                        
                                                      }
                                                      return setProvideInfoError(function (param) {
                                                                  return "Please complete all fields";
                                                                });
                                                    }
                                                    var updatePhone = p.phoneCountryCode + " " + Belt_Option.getWithDefault(p.phone, "");
                                                    var match$7 = Belt_Option.map(p.docFirstName, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive);
                                                    var match$8 = Belt_Option.map(p.docLastName, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive);
                                                    var match$9 = Belt_Option.map(p.docGender, UpdateAccountData_UpdateAccountDataMutation_graphql$Thin.Utils.docGender_decode);
                                                    var match$10 = p.docBirthdate;
                                                    var match$11 = p.docCitizenship;
                                                    var match$12 = Belt_Option.map(p.preferredFirstName, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive);
                                                    var match$13 = Belt_Option.map(p.preferredLastName, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive);
                                                    var match$14 = Belt_Option.map(p.postalCode, CS_NonemptyStrings$Util.PostalCode.fromPrimitive);
                                                    var match$15 = CS_Phone$Util.Phone.fromPrimitive(updatePhone);
                                                    var match$16 = p.residenceCountry;
                                                    if (match$7 !== undefined && match$7.TAG === "Ok" && match$8 !== undefined && match$8.TAG === "Ok" && match$9 !== undefined) {
                                                      var docGender = Caml_option.valFromOption(match$9);
                                                      if (docGender !== undefined && match$10 !== undefined && match$11 !== undefined && match$12 !== undefined && match$12.TAG === "Ok" && match$13 !== undefined && match$13.TAG === "Ok" && match$14 !== undefined && match$14.TAG === "Ok" && match$15.TAG === "Ok" && match$16 !== undefined) {
                                                        return updateSessionAndSave(SalesFlowState$Thin.Alter.upsertPax(sessionData, paxNum, {
                                                                        TAG: "Provide",
                                                                        _0: {
                                                                          docFirstName: match$7._0,
                                                                          docLastName: match$8._0,
                                                                          docGender: docGender,
                                                                          docBirthdate: Caml_option.valFromOption(match$10),
                                                                          docCitizenship: Caml_option.valFromOption(match$11),
                                                                          preferredFirstName: Caml_option.some(match$12._0),
                                                                          preferredLastName: Caml_option.some(match$13._0),
                                                                          residenceCountry: Caml_option.some(Caml_option.valFromOption(match$16)),
                                                                          phone: Caml_option.some(match$15._0),
                                                                          postalCode: Caml_option.some(match$14._0)
                                                                        }
                                                                      }), setProvideInfoError, setProvideInfoLoading, (function () {
                                                                      setSaveSuccess(function (param) {
                                                                            return true;
                                                                          });
                                                                    }));
                                                      }
                                                      
                                                    }
                                                    setProvideInfoError(function (param) {
                                                          return "Please complete all fields";
                                                        });
                                                  }),
                                                disabled: fieldsNotSaveable(pax$1, sessionPax) || paxFormIncomplete(pax$1) || saveSuccess,
                                                children: "Save"
                                              })
                                        ]
                                      }) : null,
                                provideInfoError !== undefined ? JsxRuntime.jsx(AppAlert$Thin.make, {
                                        message: provideInfoError,
                                        type_: "error"
                                      }) : JsxRuntime.jsx(JsxRuntime.Fragment, {})
                              ]
                            }))
                  }),
              locked: props.locked
            });
}

var make = ProvideInfoSelector;

exports.Tester = Tester;
exports.make = make;
/* react Not a pure module */
