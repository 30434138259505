// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Txt$Thin = require("../../../text/Txt.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../../../utils/Common.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var LoginSubmit_LoginMutation_graphql$Thin = require("../../../__generated__/LoginSubmit_LoginMutation_graphql.bs.js");

var convertVariables = LoginSubmit_LoginMutation_graphql$Thin.Internal.convertVariables;

var convertResponse = LoginSubmit_LoginMutation_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = LoginSubmit_LoginMutation_graphql$Thin.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, LoginSubmit_LoginMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, LoginSubmit_LoginMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var LoginMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(onBrandFamily, refetch, redirectUrlOpt, onSuccess) {
  var redirectUrl = redirectUrlOpt !== undefined ? Caml_option.valFromOption(redirectUrlOpt) : undefined;
  var match = use();
  var mutate = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var email = match$1[0];
  var match$2 = React.useState(function () {
        return "";
      });
  var password = match$2[0];
  var match$3 = React.useState(function () {
        
      });
  var setError = match$3[1];
  var login = function () {
    if (email === "" || password === "" || !Common$Thin.Email.isValid(email)) {
      return ;
    } else {
      setError(function (param) {
            
          });
      mutate({
            input: {
              onBrandFamily: onBrandFamily,
              allegedEmail: email,
              allegedPass: password
            }
          }, undefined, undefined, (function (store, param) {
              store.invalidateStore();
            }), (function (resp, err) {
              if (err !== undefined) {
                return setError(function (param) {
                            return Txt$Thin.$$Error.genericFormSubmit.value;
                          });
              }
              var match = resp.login;
              if (match !== undefined) {
                var jwt = match.jwt;
                if (jwt !== undefined) {
                  Common$Thin.cookieLogin(Caml_option.valFromOption(jwt), Caml_option.some((function () {
                              console.log("hello");
                              refetch();
                              if (onSuccess !== undefined) {
                                onSuccess();
                              }
                              if (redirectUrl !== undefined) {
                                return RescriptReactRouter.push(redirectUrl);
                              }
                              
                            })), Caml_option.some((function (eString) {
                              setError(function (param) {
                                    console.log(eString);
                                    return eString;
                                  });
                            })), undefined);
                  return ;
                }
                
              }
              setError(function (param) {
                    return Txt$Thin.Login.noUserError.value;
                  });
            }), (function (err) {
              console.log(err);
              setError(function (param) {
                    return Txt$Thin.$$Error.genericFormSubmit.value;
                  });
            }), undefined);
      return ;
    }
  };
  var onSubmit = function (e) {
    e.preventDefault();
    login();
  };
  return {
          isMutating: match[1],
          email: email,
          setEmail: match$1[1],
          password: password,
          setPassword: match$2[1],
          onSubmit: onSubmit,
          error: match$3[0],
          setError: setError
        };
}

function useMutationOnSignup(brandFamily, onSuccess) {
  var match = React.useState(function () {
        
      });
  var setError = match[1];
  var match$1 = use();
  var mutate = match$1[0];
  var loginOnSignUp = function (email, password) {
    setError(function (param) {
          
        });
    mutate({
          input: {
            onBrandFamily: brandFamily,
            allegedEmail: CS_Emails$Util.Email.toString(email),
            allegedPass: password
          }
        }, undefined, undefined, (function (store, param) {
            store.invalidateStore();
          }), (function (v1, param) {
            var match = v1.login;
            if (match === undefined) {
              return setError(function (param) {
                          return "An error occurred while logging in";
                        });
            }
            var jwt = match.jwt;
            if (jwt !== undefined) {
              Common$Thin.cookieLogin(Caml_option.valFromOption(jwt), Caml_option.some(onSuccess), Caml_option.some((function (eString) {
                          setError(function (param) {
                                return eString;
                              });
                        })), undefined);
              return ;
            } else {
              return setError(function (param) {
                          return "An error occurred logging in";
                        });
            }
          }), (function (param) {
            setError(function (param) {
                  return "An error occurred while logging in";
                });
          }), undefined);
  };
  return {
          error: match[0],
          setError: setError,
          isMutating: match$1[1],
          loginOnSignUp: loginOnSignUp
        };
}

exports.LoginMutation = LoginMutation;
exports.useMutation = useMutation;
exports.useMutationOnSignup = useMutationOnSignup;
/* commitMutation Not a pure module */
