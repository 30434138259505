// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var Stack = require("@mui/joy/Stack").default;
var Switch = require("@mui/joy/Switch").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;

function PaxFrame$SameAsBCToggle(props) {
  return JsxRuntime.jsxs(Stack, {
              direction: "row",
              alignItems: "center",
              spacing: 1,
              sx: {
                width: "100%",
                justifyContent: {
                  xs: "flex-start",
                  md: "space-between"
                }
              },
              children: [
                JsxRuntime.jsx(Typography, {
                      level: "body-sm",
                      children: Caml_option.some(props.toggleText)
                    }),
                JsxRuntime.jsx(Switch, {
                      checked: props.sameAsBc,
                      disabled: props.locked,
                      onChange: props.onToggleSameAsBc
                    })
              ]
            });
}

var SameAsBCToggle = {
  make: PaxFrame$SameAsBCToggle
};

function PaxFrame(props) {
  var __locked = props.locked;
  var __variant = props.variant;
  var __toggleText = props.toggleText;
  var __onToggleSameAsBc = props.onToggleSameAsBc;
  var __showToggle = props.showToggle;
  var __sameAsBc = props.sameAsBc;
  var __required = props.required;
  var required = __required !== undefined ? __required : false;
  var sameAsBc = __sameAsBc !== undefined ? __sameAsBc : false;
  var showToggle = __showToggle !== undefined ? __showToggle : false;
  var onToggleSameAsBc = __onToggleSameAsBc !== undefined ? __onToggleSameAsBc : (function () {
        
      });
  var toggleText = __toggleText !== undefined ? __toggleText : "Same as billing contact";
  var variant = __variant !== undefined ? __variant : "White";
  var locked = __locked !== undefined ? __locked : false;
  return JsxRuntime.jsxs(Stack, {
              direction: "column",
              justifyContent: "center",
              spacing: 1,
              sx: {
                p: {
                  xs: 2,
                  md: 4
                },
                border: "1px solid",
                borderColor: "neutral.300",
                backgroundColor: variant === "White" ? "background.surface" : "neutral.200",
                borderRadius: "12px",
                opacity: locked ? "0.7" : "1"
              },
              children: [
                JsxRuntime.jsxs(Stack, {
                      justifyContent: "space-between",
                      spacing: 1,
                      sx: {
                        pb: 1,
                        flexDirection: {
                          xs: "column",
                          md: "row"
                        },
                        alignItems: {
                          xs: "flex-start",
                          md: "center"
                        }
                      },
                      children: [
                        JsxRuntime.jsx(Typography, {
                              level: "title-lg",
                              children: Caml_option.some(JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                        children: [
                                          props.title,
                                          required ? JsxRuntime.jsx(Typography, {
                                                  color: "danger",
                                                  level: "title-lg",
                                                  sx: {
                                                    pl: "4px"
                                                  },
                                                  children: Caml_option.some(JsxRuntime.jsx("sup", {
                                                            children: "*"
                                                          }))
                                                }) : null
                                        ]
                                      }))
                            }),
                        showToggle ? JsxRuntime.jsx(Stack, {
                                direction: "row",
                                sx: {
                                  p: 1,
                                  border: "1px solid",
                                  borderColor: {
                                    xs: "#CDD7E1",
                                    md: "transparent"
                                  },
                                  backgroundColor: "background.surface",
                                  borderRadius: "sm",
                                  width: "fit-content"
                                },
                                children: Caml_option.some(JsxRuntime.jsx(PaxFrame$SameAsBCToggle, {
                                          sameAsBc: sameAsBc,
                                          onToggleSameAsBc: onToggleSameAsBc,
                                          toggleText: toggleText,
                                          locked: locked
                                        }))
                              }) : null
                      ]
                    }),
                JsxRuntime.jsx(Stack, {
                      direction: "column",
                      children: Caml_option.some(props.children)
                    })
              ]
            });
}

var make = PaxFrame;

exports.SameAsBCToggle = SameAsBCToggle;
exports.make = make;
/* @mui/joy/Stack Not a pure module */
