// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Float = require("rescript/lib/js/belt_Float.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../../../utils/Common.bs.js");
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var CS_Decimal$Util = require("util/src/CustomScalars/CS_Decimal.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var FormControl = require("@mui/joy/FormControl").default;
var FormHelperText = require("@mui/joy/FormHelperText").default;
var Check = require("@mui/icons-material/Check").default;
var AttachMoney = require("@mui/icons-material/AttachMoney").default;

function CurrencyInput(props) {
  var presetValue = props.presetValue;
  var onSave = props.onSave;
  var setError = props.setError;
  var maximum = props.maximum;
  var minimum = props.minimum;
  var match = React.useState(function () {
        if (presetValue !== undefined && presetValue.TAG === "Custom") {
          return CS_Decimal$Util.Decimal10_2.toPrimitive(presetValue._0.amt);
        } else {
          return CS_Decimal$Util.Decimal10_2.toPrimitive(minimum);
        }
      });
  var setValue = match[1];
  var value = match[0];
  var match$1 = React.useState(function () {
        if (presetValue !== undefined && presetValue.TAG === "Custom") {
          return true;
        } else {
          return false;
        }
      });
  var setSaved = match$1[1];
  var padIfNoDecimal = function (s) {
    var match = s.split(".");
    var len = match.length;
    if (len >= 3) {
      return s;
    }
    switch (len) {
      case 0 :
          return s;
      case 1 :
          var whole = match[0];
          return whole + ".00";
      case 2 :
          var whole$1 = match[0];
          var decimal = match[1];
          var match$1 = decimal.length;
          if (match$1 !== 1) {
            if (match$1 !== 2) {
              return s;
            } else {
              return whole$1 + "." + decimal;
            }
          } else {
            return whole$1 + "." + decimal + "0";
          }
      
    }
  };
  var onSubmit = function (e) {
    e.preventDefault();
    setError(function (param) {
          
        });
    var val = CS_Decimal$Util.Decimal10_2.fromPrimitive(padIfNoDecimal(value));
    if (val.TAG !== "Ok") {
      return setError(function (param) {
                  return "Invalid amount.";
                });
    }
    var v = Belt_Float.fromString(value);
    if (v !== undefined) {
      if (v < CS_Decimal$Util.Decimal10_2.toFloat(minimum) || v > CS_Decimal$Util.Decimal10_2.toFloat(maximum)) {
        return setError(function (param) {
                    return "Invalid amount.";
                  });
      } else {
        return onSave(val._0, (function () {
                      setSaved(function (param) {
                            return true;
                          });
                    }));
      }
    } else {
      return setError(function (param) {
                  return "Invalid amount.";
                });
    }
  };
  var val = Belt_Float.fromString(value);
  return JsxRuntime.jsx(Stack, {
              component: "form",
              onSubmit: onSubmit,
              children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                        error: val !== undefined ? val < CS_Decimal$Util.Decimal10_2.toFloat(minimum) || val > CS_Decimal$Util.Decimal10_2.toFloat(maximum) : true,
                        children: [
                          JsxRuntime.jsx(Joy$Util.Input.make, {
                                type_: "number",
                                placeholder: CS_Decimal$Util.Decimal10_2.toString(minimum),
                                value: value,
                                onChange: (function (e) {
                                    setSaved(function (param) {
                                          return false;
                                        });
                                    var val = e.currentTarget.value;
                                    var arr = val.split(".");
                                    if (arr.length === 1) {
                                      return setValue(function (param) {
                                                  return val;
                                                });
                                    }
                                    if (arr.length !== 2) {
                                      return ;
                                    }
                                    var decimal = arr[1];
                                    var d = decimal.length;
                                    if (d > 2) {
                                      return ;
                                    } else {
                                      return setValue(function (param) {
                                                  return val;
                                                });
                                    }
                                  }),
                                startDecorator: Caml_option.some(JsxRuntime.jsx(AttachMoney, {})),
                                endDecorator: Caml_option.some(match$1[0] ? JsxRuntime.jsx(Check, {
                                            color: "success"
                                          }) : JsxRuntime.jsx(Button, {
                                            type: "submit",
                                            children: "Save"
                                          })),
                                slotProps: {
                                  input: {
                                    min: CS_Decimal$Util.Decimal10_2.toFloat(minimum),
                                    max: CS_Decimal$Util.Decimal10_2.toFloat(maximum),
                                    step: 0.01
                                  }
                                }
                              }),
                          JsxRuntime.jsx(FormHelperText, {
                                children: Caml_option.some("Must be at least the minimum required amount (" + Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(minimum)) + ").")
                              })
                        ]
                      }))
            });
}

var C;

var make = CurrencyInput;

exports.C = C;
exports.make = make;
/* react Not a pure module */
