// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Id = require("rescript/lib/js/belt_Id.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Txt$Thin = require("../text/Txt.bs.js");
var Http$Util = require("util/src/Http.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SysCtx$Thin = require("./SysCtx.bs.js");

function isPasswordValid(s) {
  return s.length >= 10;
}

function cookieLogin(jwt, aBitLaterOpt, onErrorOpt, delayOpt) {
  var aBitLater = aBitLaterOpt !== undefined ? Caml_option.valFromOption(aBitLaterOpt) : undefined;
  var onError = onErrorOpt !== undefined ? Caml_option.valFromOption(onErrorOpt) : undefined;
  var delay = delayOpt !== undefined ? delayOpt : 100;
  return Http$Util.cookieLogin(process.env.THIN_LOGIN_URL, jwt, Caml_option.some(aBitLater), Caml_option.some(onError), delay);
}

function cookieLogout(aBitLaterOpt, onErrorOpt, delayOpt) {
  var aBitLater = aBitLaterOpt !== undefined ? Caml_option.valFromOption(aBitLaterOpt) : undefined;
  var onError = onErrorOpt !== undefined ? Caml_option.valFromOption(onErrorOpt) : undefined;
  var delay = delayOpt !== undefined ? delayOpt : 100;
  return Http$Util.cookieLogout(process.env.THIN_LOGOUT_URL, Caml_option.some(aBitLater), Caml_option.some(onError), delay);
}

function useStateExtended(useStateArgs) {
  var match = React.useState(Curry.__1(useStateArgs));
  var set = match[1];
  var onChange = function (e) {
    set(e.currentTarget.value);
  };
  return [
          match[0],
          set,
          onChange
        ];
}

function formatCurrency($$float) {
  var options = {
    style: "currency",
    currency: SysCtx$Thin.vars.currency
  };
  var formatter = Intl.NumberFormat(SysCtx$Thin.vars.locale, options);
  return formatter.format($$float);
}

function intsToCents(v) {
  return v / 100.0;
}

var Clipboard = {};

var $$History = {};

var $$Window = {
  Clipboard: Clipboard,
  $$History: $$History
};

async function copyToClipboard(s, fn) {
  try {
    await window.navigator.clipboard.writeText(s);
    fn(function (param) {
          return true;
        });
    setTimeout((function () {
            fn(function (param) {
                  return false;
                });
          }), 1500);
    return ;
  }
  catch (exn){
    console.log(Txt$Thin.Clipboard.copyError.value);
    return ;
  }
}

var regex = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$");

function isValid(email) {
  var match = regex.exec(email);
  return match !== null;
}

var Email = {
  regex: regex,
  isValid: isValid
};

function toString(status) {
  if (!(status === "CANCELLED" || status === "COMPLETED" || status === "PENDING" || status === "NASCENT" || status === "ERROR")) {
    return "Unknown";
  }
  switch (status) {
    case "NASCENT" :
        return "Unknown";
    case "PENDING" :
        return "Pending";
    case "COMPLETED" :
        return "Completed";
    case "CANCELLED" :
        return "Canceled";
    case "ERROR" :
        return "Error";
    
  }
}

function shouldRenderStatus(status) {
  if ((status === "CANCELLED" || status === "COMPLETED" || status === "PENDING" || status === "NASCENT" || status === "ERROR") && status === "COMPLETED") {
    return false;
  } else {
    return true;
  }
}

var PmtLifecycle = {
  toString: toString,
  shouldRenderStatus: shouldRenderStatus
};

var Voyage = {};

var cmp = Caml_obj.compare;

var CabinCmp = Belt_Id.MakeComparable({
      cmp: cmp
    });

var cmp$1 = Caml_obj.compare;

var AccountIdCmp = Belt_Id.MakeComparable({
      cmp: cmp$1
    });

var cmp$2 = Caml_obj.compare;

var VoyageSlugCmp = Belt_Id.MakeComparable({
      cmp: cmp$2
    });

var cmp$3 = Caml_obj.compare;

var StrCmp = Belt_Id.MakeComparable({
      cmp: cmp$3
    });

function toString$1(g) {
  if (!(g === "F" || g === "M" || g === "X")) {
    return "Unknown";
  }
  switch (g) {
    case "M" :
        return "Male";
    case "F" :
        return "Female";
    case "X" :
        return "Unspecified";
    
  }
}

var Gender = {
  toString: toString$1
};

function getBackgroundColor(color) {
  switch (color) {
    case "Neutral" :
        return "#F0F3F5";
    case "Warning" :
        return "#FFC82C";
    case "Warning2" :
        return "#FFF9EB";
    case "Danger" :
        return "#DE4F48";
    case "Danger2" :
        return "#FBF2F2";
    case "Success" :
        return "#E7FDF1";
    case "Clear" :
        return "transparent";
    
  }
}

function getColorNoSuccess(color) {
  switch (color) {
    case "Warning2" :
        return "#916A08";
    case "Danger" :
        return "#FFFFFF";
    case "Danger2" :
        return "#BF1414";
    default:
      return "#000000";
  }
}

function getColorWithSuccess(color) {
  switch (color) {
    case "Warning2" :
        return "#916A08";
    case "Danger" :
        return "#FFFFFF";
    case "Danger2" :
        return "#BF1414";
    case "Success" :
        return "#037E3A";
    default:
      return "#000000";
  }
}

function getBorderColor(color) {
  switch (color) {
    case "Neutral" :
        return "#93A5B1";
    case "Warning2" :
        return "#DBA313";
    case "Clear" :
        return "transparent";
    default:
      return getColorNoSuccess(color);
  }
}

var NotificationColor = {
  getBackgroundColor: getBackgroundColor,
  getColorNoSuccess: getColorNoSuccess,
  getColorWithSuccess: getColorWithSuccess,
  getBorderColor: getBorderColor
};

var passwordLength = 10;

exports.passwordLength = passwordLength;
exports.isPasswordValid = isPasswordValid;
exports.cookieLogin = cookieLogin;
exports.cookieLogout = cookieLogout;
exports.useStateExtended = useStateExtended;
exports.formatCurrency = formatCurrency;
exports.intsToCents = intsToCents;
exports.$$Window = $$Window;
exports.copyToClipboard = copyToClipboard;
exports.Email = Email;
exports.PmtLifecycle = PmtLifecycle;
exports.Voyage = Voyage;
exports.CabinCmp = CabinCmp;
exports.AccountIdCmp = AccountIdCmp;
exports.VoyageSlugCmp = VoyageSlugCmp;
exports.StrCmp = StrCmp;
exports.Gender = Gender;
exports.NotificationColor = NotificationColor;
/* regex Not a pure module */
