// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var RelayEnv$Thin = require("../../RelayEnv.bs.js");
var RemoteData$Util = require("util/src/RemoteData.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var ProcessorEndpointQuery_graphql$Thin = require("../../__generated__/ProcessorEndpointQuery_graphql.bs.js");

var decodeProc = ProcessorEndpointQuery_graphql$Thin.Utils.pmtProcessor_decode;

var decodeMethod = ProcessorEndpointQuery_graphql$Thin.Utils.pmtMethod_decode;

var decodePmtFeeType = ProcessorEndpointQuery_graphql$Thin.Utils.pmtFeeType_decode;

var convertVariables = ProcessorEndpointQuery_graphql$Thin.Internal.convertVariables;

var convertResponse = ProcessorEndpointQuery_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = ProcessorEndpointQuery_graphql$Thin.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, ProcessorEndpointQuery_graphql$Thin.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, ProcessorEndpointQuery_graphql$Thin.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(ProcessorEndpointQuery_graphql$Thin.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(ProcessorEndpointQuery_graphql$Thin.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(ProcessorEndpointQuery_graphql$Thin.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(ProcessorEndpointQuery_graphql$Thin.node, convertVariables);

var Query_pmtFeeType_decode = ProcessorEndpointQuery_graphql$Thin.Utils.pmtFeeType_decode;

var Query_pmtFeeType_fromString = ProcessorEndpointQuery_graphql$Thin.Utils.pmtFeeType_fromString;

var Query_pmtMethod_decode = ProcessorEndpointQuery_graphql$Thin.Utils.pmtMethod_decode;

var Query_pmtMethod_fromString = ProcessorEndpointQuery_graphql$Thin.Utils.pmtMethod_fromString;

var Query_pmtProcessor_decode = ProcessorEndpointQuery_graphql$Thin.Utils.pmtProcessor_decode;

var Query_pmtProcessor_fromString = ProcessorEndpointQuery_graphql$Thin.Utils.pmtProcessor_fromString;

var Query = {
  pmtFeeType_decode: Query_pmtFeeType_decode,
  pmtFeeType_fromString: Query_pmtFeeType_fromString,
  pmtMethod_decode: Query_pmtMethod_decode,
  pmtMethod_fromString: Query_pmtMethod_fromString,
  pmtProcessor_decode: Query_pmtProcessor_decode,
  pmtProcessor_fromString: Query_pmtProcessor_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function getProccessorEndpointsEffect(state, forVoyage) {
  return RemoteData$Util.Relay.fetchQueryStatefully($$fetch, state, (function ($$return) {
                var match = $$return.paymentEndpoints;
                if (match === undefined) {
                  return [];
                }
                var results = match.nodes;
                if (results.length !== 0) {
                  return results;
                } else {
                  return [];
                }
              }), (function (param) {
                return "Error getting Endpoints";
              }), RelayEnv$Thin.environment, {
              forVoyage: forVoyage
            }, undefined, undefined);
}

exports.decodeProc = decodeProc;
exports.decodeMethod = decodeMethod;
exports.decodePmtFeeType = decodePmtFeeType;
exports.Query = Query;
exports.getProccessorEndpointsEffect = getProccessorEndpointsEffect;
/* use Not a pure module */
