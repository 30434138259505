// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thin = require("../../common/molecules/Dialog.bs.js");
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;

function ResendInvitationConfirmModal(props) {
  var onClose = props.onClose;
  return JsxRuntime.jsxs(Dialog$Thin.make, {
              open_: props.open_,
              title: "Invitation Resent",
              onClose: onClose,
              children: [
                JsxRuntime.jsx(DialogContent, {
                      children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                children: "The invitation was successfully resent."
                              }))
                    }),
                JsxRuntime.jsx(DialogActions, {
                      children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                sx: {
                                  width: "fit-content"
                                },
                                children: Caml_option.some(JsxRuntime.jsx(Button, {
                                          onClick: (function (param) {
                                              onClose();
                                            }),
                                          children: "Got it"
                                        }))
                              })),
                      sx: {
                        justifyContent: "center"
                      }
                    })
              ]
            });
}

var make = ResendInvitationConfirmModal;

exports.make = make;
/* Dialog-Thin Not a pure module */
