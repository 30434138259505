// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var VerifyPage$Thin = require("../pages/VerifyPage.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var StandardLayout$Thin = require("../layouts/StandardLayout.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var VerifyRouteQuery_graphql$Thin = require("../__generated__/VerifyRouteQuery_graphql.bs.js");
var VerifyRoute_query_graphql$Thin = require("../__generated__/VerifyRoute_query_graphql.bs.js");
var VerifyRouteSignOnQuery_graphql$Thin = require("../__generated__/VerifyRouteSignOnQuery_graphql.bs.js");

var convertVariables = VerifyRouteQuery_graphql$Thin.Internal.convertVariables;

var convertResponse = VerifyRouteQuery_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = VerifyRouteQuery_graphql$Thin.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, VerifyRouteQuery_graphql$Thin.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, VerifyRouteQuery_graphql$Thin.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(VerifyRouteQuery_graphql$Thin.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(VerifyRouteQuery_graphql$Thin.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(VerifyRouteQuery_graphql$Thin.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(VerifyRouteQuery_graphql$Thin.node, convertVariables);

var Query = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

var convertFragment = VerifyRoute_query_graphql$Thin.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(VerifyRoute_query_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, VerifyRoute_query_graphql$Thin.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(VerifyRoute_query_graphql$Thin.node, convertFragment, fRef);
}

var makeRefetchVariables = VerifyRouteSignOnQuery_graphql$Thin.Types.makeRefetchVariables;

var convertRefetchVariables = VerifyRouteSignOnQuery_graphql$Thin.Internal.convertVariables;

function useRefetchable(fRef) {
  return RescriptRelay_Fragment.useRefetchableFragment(VerifyRoute_query_graphql$Thin.node, convertFragment, convertRefetchVariables, fRef);
}

var QueryFragment_custSignonStatus_decode = VerifyRoute_query_graphql$Thin.Utils.custSignonStatus_decode;

var QueryFragment_custSignonStatus_fromString = VerifyRoute_query_graphql$Thin.Utils.custSignonStatus_fromString;

var QueryFragment = {
  custSignonStatus_decode: QueryFragment_custSignonStatus_decode,
  custSignonStatus_fromString: QueryFragment_custSignonStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use$1,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment,
  makeRefetchVariables: makeRefetchVariables,
  convertRefetchVariables: convertRefetchVariables,
  useRefetchable: useRefetchable
};

function VerifyRoute(props) {
  var data = use({
        brandFamily: props.brandFamily
      }, undefined, undefined, undefined);
  var match = useRefetchable(data.fragmentRefs);
  var refetch = match[1];
  var signOnData = match[0];
  var signOnStatus = signOnData.currentUserSignonStatus;
  var refetchSignOnStatus = function (param) {
    return refetch(makeRefetchVariables(undefined), "store-and-network", undefined);
  };
  var match$1 = signOnData.currentUserProfiles;
  var displayName;
  if (match$1 !== undefined) {
    var match$2 = Belt_Array.get(match$1.nodes, 0);
    var exit = 0;
    if (match$2 !== undefined) {
      var firstName = match$2.firstName;
      if (firstName !== undefined) {
        var lastName = match$2.lastName;
        var firstName$1 = Caml_option.valFromOption(firstName);
        displayName = lastName !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toString(firstName$1) + " " + CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(lastName)) : CS_NonemptyStrings$Util.NonEmptyString.toString(firstName$1);
      } else {
        var lastName$1 = match$2.lastName;
        if (lastName$1 !== undefined) {
          displayName = CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(lastName$1));
        } else {
          exit = 1;
        }
      }
    } else {
      exit = 1;
    }
    if (exit === 1) {
      var email = signOnData.currentUserEmail;
      displayName = email !== undefined ? email : "My account";
    }
    
  } else {
    var email$1 = signOnData.currentUserEmail;
    displayName = email$1 !== undefined ? email$1 : "My account";
  }
  return JsxRuntime.jsx(StandardLayout$Thin.make, {
              fragmentRefs: data.fragmentRefs,
              dash: false,
              signOnStatus: signOnStatus,
              displayName: displayName,
              refetchSignOnStatus: refetchSignOnStatus,
              children: JsxRuntime.jsx(VerifyPage$Thin.make, {})
            });
}

var make = VerifyRoute;

exports.Query = Query;
exports.QueryFragment = QueryFragment;
exports.make = make;
/* use Not a pure module */
