// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Txt$Thin = require("../../../text/Txt.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thin = require("../../common/molecules/Dialog.bs.js");
var RelayEnv$Thin = require("../../../RelayEnv.bs.js");
var Stack = require("@mui/joy/Stack").default;
var DraftUtils$Thin = require("../../../utils/booking/DraftUtils.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var DialogContent = require("@mui/joy/DialogContent").default;
var CheckValidityQuery_graphql$Thin = require("../../../__generated__/CheckValidityQuery_graphql.bs.js");
var InfoOutlined = require("@mui/icons-material/InfoOutlined").default;

var convertVariables = CheckValidityQuery_graphql$Thin.Internal.convertVariables;

var convertResponse = CheckValidityQuery_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = CheckValidityQuery_graphql$Thin.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, CheckValidityQuery_graphql$Thin.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, CheckValidityQuery_graphql$Thin.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(CheckValidityQuery_graphql$Thin.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(CheckValidityQuery_graphql$Thin.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(CheckValidityQuery_graphql$Thin.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(CheckValidityQuery_graphql$Thin.node, convertVariables);

var Query_docGender_decode = CheckValidityQuery_graphql$Thin.Utils.docGender_decode;

var Query_docGender_fromString = CheckValidityQuery_graphql$Thin.Utils.docGender_fromString;

var Query_elevatorProximity_decode = CheckValidityQuery_graphql$Thin.Utils.elevatorProximity_decode;

var Query_elevatorProximity_fromString = CheckValidityQuery_graphql$Thin.Utils.elevatorProximity_fromString;

var Query_longitudinality_decode = CheckValidityQuery_graphql$Thin.Utils.longitudinality_decode;

var Query_longitudinality_fromString = CheckValidityQuery_graphql$Thin.Utils.longitudinality_fromString;

var Query = {
  docGender_decode: Query_docGender_decode,
  docGender_fromString: Query_docGender_fromString,
  elevatorProximity_decode: Query_elevatorProximity_decode,
  elevatorProximity_fromString: Query_elevatorProximity_fromString,
  longitudinality_decode: Query_longitudinality_decode,
  longitudinality_fromString: Query_longitudinality_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function useQuery(getPreviewFromDiff, url, onCloseParentOpt) {
  var onCloseParent = onCloseParentOpt !== undefined ? onCloseParentOpt : (function () {
        
      });
  var match = React.useState(function () {
        
      });
  var setError = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setLoading = match$1[1];
  var addOrErr = function (diff) {
    setLoading(function (param) {
          return true;
        });
    setError(function (param) {
          
        });
    var match = getPreviewFromDiff(diff);
    $$fetch(RelayEnv$Thin.environment, {
          bookingId: match.bookingId,
          bookingVersionNumber: match.bookingVersionNumber,
          thinDiff: match.thinDiff,
          voyage: match.voyage
        }, (function (res) {
            if (res.TAG === "Ok") {
              var match = res._0.previewBookingChanges;
              if (match !== undefined) {
                setError(function (param) {
                      
                    });
                setLoading(function (param) {
                      return false;
                    });
                DraftUtils$Thin.addToDraft(diff, url);
                onCloseParent();
              } else {
                setError(function (param) {
                      return Txt$Thin.BookingDetails.errorModalDescDefault.value;
                    });
                setLoading(function (param) {
                      return false;
                    });
              }
              return ;
            }
            setError(function (param) {
                  return Txt$Thin.$$Error.genericFormSubmit.value;
                });
            setLoading(function (param) {
                  return false;
                });
          }), undefined, undefined);
  };
  return {
          error: match[0],
          setError: setError,
          loading: match$1[0],
          addOrErr: addOrErr
        };
}

function CheckValidity$ErrorModal(props) {
  var setError = props.setError;
  var error = props.error;
  return JsxRuntime.jsx(Dialog$Thin.make, {
              open_: Belt_Option.isSome(error),
              title: "Error",
              onClose: (function () {
                  setError(function (param) {
                        
                      });
                }),
              children: Caml_option.some(JsxRuntime.jsx(DialogContent, {
                        children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                  direction: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  spacing: 1,
                                  children: [
                                    JsxRuntime.jsx(Stack, {
                                          sx: {
                                            width: "62px",
                                            height: "62px"
                                          },
                                          children: Caml_option.some(JsxRuntime.jsx(InfoOutlined, {
                                                    color: "danger",
                                                    sx: {
                                                      width: "100%",
                                                      height: "100%"
                                                    }
                                                  }))
                                        }),
                                    JsxRuntime.jsx(Typography, {
                                          color: "danger",
                                          level: "body-md",
                                          children: Caml_option.some(Belt_Option.getWithDefault(error, "An error occurred. Please try again."))
                                        })
                                  ]
                                })),
                        sx: {
                          pb: 4
                        }
                      }))
            });
}

var ErrorModal = {
  make: CheckValidity$ErrorModal
};

exports.Query = Query;
exports.useQuery = useQuery;
exports.ErrorModal = ErrorModal;
/* use Not a pure module */
