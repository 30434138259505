// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var Stack = require("@mui/joy/Stack").default;
var JsxRuntime = require("react/jsx-runtime");

function ProgressBar(props) {
  var __numSteps = props.numSteps;
  var numSteps = __numSteps !== undefined ? __numSteps : 4;
  var progress = String((2.0 * props.currentStep - 1.0) / (2.0 * numSteps) * 100.0) + "%";
  return JsxRuntime.jsx(Stack, {
              sx: {
                width: "100%",
                height: "8px",
                backgroundColor: "neutral.300"
              },
              children: Caml_option.some(JsxRuntime.jsx(Stack, {
                        sx: {
                          height: "100%",
                          width: progress,
                          backgroundColor: "primary.500"
                        }
                      }))
            });
}

var make = ProgressBar;

exports.make = make;
/* @mui/joy/Stack Not a pure module */
