// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_UnverifiedJWT$Util = require("util/src/CustomScalars/CS_UnverifiedJWT.bs.js");

var Types = {};

var variablesConverter = {"getTokenizationIntentInput":{},"__root":{"input":{"r":"getTokenizationIntentInput"}}};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, undefined, undefined);
}

var wrapResponseConverter = {"__root":{"getTokenizationIntent_tokenizationIntent":{"c":"Util.CustomScalars.UnverifiedJWT.Exn"}}};

var wrapResponseConverterMap = {
  "Util.CustomScalars.UnverifiedJWT.Exn": CS_UnverifiedJWT$Util.UnverifiedJWT.Exn.serialize
};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, wrapResponseConverterMap, null);
}

var responseConverter = {"__root":{"getTokenizationIntent_tokenizationIntent":{"c":"Util.CustomScalars.UnverifiedJWT.Exn"}}};

var responseConverterMap = {
  "Util.CustomScalars.UnverifiedJWT.Exn": CS_UnverifiedJWT$Util.UnverifiedJWT.Exn.parse
};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, responseConverterMap, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: undefined,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: wrapResponseConverterMap,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: responseConverterMap,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

var Utils = {};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "GetTokenizationIntentPayload",
    "kind": "LinkedField",
    "name": "getTokenizationIntent",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tokenizationIntent",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProcessorTokenizationIntentMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProcessorTokenizationIntentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "42871c2855a2707b12fe7f53d819deef",
    "id": null,
    "metadata": {},
    "name": "ProcessorTokenizationIntentMutation",
    "operationKind": "mutation",
    "text": "mutation ProcessorTokenizationIntentMutation(\n  $input: GetTokenizationIntentInput!\n) {\n  getTokenizationIntent(input: $input) {\n    tokenizationIntent\n  }\n}\n"
  }
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
