// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Txt$Thin = require("../../../text/Txt.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SysCtx$Thin = require("../../../utils/SysCtx.bs.js");
var Menu = require("@mui/joy/Menu").default;
var Stack = require("@mui/joy/Stack").default;
var Dropdown = require("@mui/joy/Dropdown").default;
var MenuItem = require("@mui/joy/MenuItem").default;
var JsxRuntime = require("react/jsx-runtime");
var IconButton = require("@mui/joy/IconButton").default;
var MenuButton = require("@mui/joy/MenuButton").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var Menu$1 = require("@mui/icons-material/Menu").default;

function NavMenu(props) {
  var logout = props.logout;
  return JsxRuntime.jsx(Stack, {
              sx: {
                display: {
                  xs: "flex",
                  md: props.alwaysHamburger ? "flex" : "none"
                }
              },
              children: Caml_option.some(JsxRuntime.jsxs(Dropdown, {
                        children: [
                          JsxRuntime.jsx(MenuButton, {
                                children: Caml_option.some(JsxRuntime.jsx(Menu$1, {})),
                                size: "md",
                                slotProps: {
                                  root: {
                                    variant: "outlined",
                                    color: "neutral"
                                  }
                                },
                                slots: {
                                  root: IconButton
                                }
                              }),
                          JsxRuntime.jsxs(Menu, {
                                children: [
                                  JsxRuntime.jsx(MenuItem, {
                                        children: Caml_option.some(Txt$Thin.Nav.bookings.value),
                                        onClick: (function (param) {
                                            RescriptReactRouter.push(SysCtx$Thin.vars.bookingsLink);
                                          })
                                      }),
                                  props.hasConnectedAccounts ? JsxRuntime.jsx(MenuItem, {
                                          children: Caml_option.some(Txt$Thin.Nav.connectedAccounts.value),
                                          onClick: (function (param) {
                                              RescriptReactRouter.push(SysCtx$Thin.vars.connectedAccountsLink);
                                            })
                                        }) : null,
                                  JsxRuntime.jsx(MenuItem, {
                                        children: Caml_option.some(Txt$Thin.Nav.account.value),
                                        onClick: (function (param) {
                                            RescriptReactRouter.push(SysCtx$Thin.vars.accountLink);
                                          })
                                      }),
                                  JsxRuntime.jsx(MenuItem, {
                                        children: Caml_option.some(Txt$Thin.Nav.logout.value),
                                        onClick: (function (param) {
                                            logout();
                                          })
                                      })
                                ],
                                placement: "bottom-end"
                              })
                        ]
                      }))
            });
}

var make = NavMenu;

exports.make = make;
/* Txt-Thin Not a pure module */
