// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Stack = require("@mui/joy/Stack").default;
var JsxRuntime = require("react/jsx-runtime");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var StandardLayout$Thin = require("../layouts/StandardLayout.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var SalesByCategoryPage$Thin = require("../pages/SalesByCategoryPage.bs.js");
var SalesFlowRouteQuery_graphql$Thin = require("../__generated__/SalesFlowRouteQuery_graphql.bs.js");
var SalesFlowRoute_query_graphql$Thin = require("../__generated__/SalesFlowRoute_query_graphql.bs.js");
var SalesFlowRoute_bfname_graphql$Thin = require("../__generated__/SalesFlowRoute_bfname_graphql.bs.js");
var SalesFlowRouteSignOnQuery_graphql$Thin = require("../__generated__/SalesFlowRouteSignOnQuery_graphql.bs.js");

var convertVariables = SalesFlowRouteQuery_graphql$Thin.Internal.convertVariables;

var convertResponse = SalesFlowRouteQuery_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = SalesFlowRouteQuery_graphql$Thin.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, SalesFlowRouteQuery_graphql$Thin.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, SalesFlowRouteQuery_graphql$Thin.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(SalesFlowRouteQuery_graphql$Thin.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(SalesFlowRouteQuery_graphql$Thin.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(SalesFlowRouteQuery_graphql$Thin.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(SalesFlowRouteQuery_graphql$Thin.node, convertVariables);

var Query = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

var convertFragment = SalesFlowRoute_bfname_graphql$Thin.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(SalesFlowRoute_bfname_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, SalesFlowRoute_bfname_graphql$Thin.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(SalesFlowRoute_bfname_graphql$Thin.node, convertFragment, fRef);
}

var BfnameFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use$1,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

var convertFragment$1 = SalesFlowRoute_query_graphql$Thin.Internal.convertFragment;

function use$2(fRef) {
  return RescriptRelay_Fragment.useFragment(SalesFlowRoute_query_graphql$Thin.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, SalesFlowRoute_query_graphql$Thin.node, convertFragment$1);
}

function readResolverFragment$1(fRef) {
  return RescriptRelay_Fragment.read(SalesFlowRoute_query_graphql$Thin.node, convertFragment$1, fRef);
}

var makeRefetchVariables = SalesFlowRouteSignOnQuery_graphql$Thin.Types.makeRefetchVariables;

var convertRefetchVariables = SalesFlowRouteSignOnQuery_graphql$Thin.Internal.convertVariables;

function useRefetchable(fRef) {
  return RescriptRelay_Fragment.useRefetchableFragment(SalesFlowRoute_query_graphql$Thin.node, convertFragment$1, convertRefetchVariables, fRef);
}

var QueryFragment_custSignonStatus_decode = SalesFlowRoute_query_graphql$Thin.Utils.custSignonStatus_decode;

var QueryFragment_custSignonStatus_fromString = SalesFlowRoute_query_graphql$Thin.Utils.custSignonStatus_fromString;

var QueryFragment = {
  custSignonStatus_decode: QueryFragment_custSignonStatus_decode,
  custSignonStatus_fromString: QueryFragment_custSignonStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  use: use$2,
  useOpt: useOpt$1,
  readResolverFragment: readResolverFragment$1,
  makeRefetchVariables: makeRefetchVariables,
  convertRefetchVariables: convertRefetchVariables,
  useRefetchable: useRefetchable
};

function SalesFlowRoute(props) {
  var brandFamily = props.brandFamily;
  var data = use({
        brandFamily: brandFamily
      }, undefined, undefined, undefined);
  var match = useRefetchable(data.fragmentRefs);
  var refetch = match[1];
  var signOnData = match[0];
  var signOnStatus = signOnData.currentUserSignonStatus;
  var refetchSignOnStatus = function (param) {
    return refetch(makeRefetchVariables(undefined), "store-and-network", undefined);
  };
  var match$1 = signOnData.currentUserProfiles;
  var displayName;
  if (match$1 !== undefined) {
    var match$2 = Belt_Array.get(match$1.nodes, 0);
    var exit = 0;
    if (match$2 !== undefined) {
      var firstName = match$2.firstName;
      if (firstName !== undefined) {
        var lastName = match$2.lastName;
        var firstName$1 = Caml_option.valFromOption(firstName);
        displayName = lastName !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toString(firstName$1) + " " + CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(lastName)) : CS_NonemptyStrings$Util.NonEmptyString.toString(firstName$1);
      } else {
        var lastName$1 = match$2.lastName;
        if (lastName$1 !== undefined) {
          displayName = CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(lastName$1));
        } else {
          exit = 1;
        }
      }
    } else {
      exit = 1;
    }
    if (exit === 1) {
      var email = signOnData.currentUserEmail;
      displayName = email !== undefined ? email : "My account";
    }
    
  } else {
    var email$1 = signOnData.currentUserEmail;
    displayName = email$1 !== undefined ? email$1 : "My account";
  }
  var match$3 = React.useState(function () {
        
      });
  var bfnameFrag = use$1(data.fragmentRefs);
  var match$4 = bfnameFrag.brandFamily;
  var brandFamilyName = match$4 !== undefined ? Caml_option.some(match$4.brandFamilyName) : undefined;
  return JsxRuntime.jsx(StandardLayout$Thin.make, {
              fragmentRefs: data.fragmentRefs,
              dash: false,
              alwaysHamburger: true,
              signOnStatus: signOnStatus,
              displayName: displayName,
              refetchSignOnStatus: refetchSignOnStatus,
              bg: "#FAFAFA",
              children: JsxRuntime.jsx(Stack, {
                    direction: "column",
                    alignItems: "center",
                    children: Caml_option.some(JsxRuntime.jsx(Stack, {
                              sx: {
                                maxWidth: "1536px",
                                width: "100%",
                                flex: 1,
                                mx: 0
                              },
                              children: Caml_option.some(JsxRuntime.jsx(SalesByCategoryPage$Thin.make, {
                                        brandFamily: brandFamily,
                                        brandFamilyName: brandFamilyName,
                                        voyage: props.voyage,
                                        signOnStatus: signOnStatus,
                                        refetchSignOnStatus: refetchSignOnStatus,
                                        paymentSuggestions: match$3[0],
                                        setPaymentSuggestions: match$3[1]
                                      }))
                            }))
                  })
            });
}

var make = SalesFlowRoute;

exports.Query = Query;
exports.BfnameFragment = BfnameFragment;
exports.QueryFragment = QueryFragment;
exports.make = make;
/* use Not a pure module */
