"use strict";

function convertDateStringToDate(dateString) {
  var splitDate = dateString.split("-");

  return new Date(splitDate[0], splitDate[1], splitDate[2]);
}

function isDateStringBeforeOrOn(firstDate, secondDate) {
  return (
    convertDateStringToDate(firstDate) <= convertDateStringToDate(secondDate)
  );
}

module.exports = isDateStringBeforeOrOn;
