// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$String = require("rescript/lib/js/string.js");
var Belt_Id = require("rescript/lib/js/belt_Id.js");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_MapInt = require("rescript/lib/js/belt_MapInt.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var DateFns$Util = require("util/src/DateFns.bs.js");
var CS_Phone$Util = require("util/src/CustomScalars/CS_Phone.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var CS_Decimal$Util = require("util/src/CustomScalars/CS_Decimal.bs.js");
var QueryString$Util = require("util/src/QueryString.bs.js");
var S$RescriptSchema = require("rescript-schema/src/S.bs.js");
var CS_ISO3166_1$Util = require("util/src/CustomScalars/CS_ISO3166_1.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var CS_SQLDatesAndTimes$Util = require("util/src/CustomScalars/CS_SQLDatesAndTimes.bs.js");

function fieldToString(urlField) {
  return urlField;
}

var validOccupancies = [
  1,
  2,
  3,
  4
];

var genderSchema = S$RescriptSchema.union([
      S$RescriptSchema.literal("M"),
      S$RescriptSchema.literal("F"),
      S$RescriptSchema.literal("X")
    ]);

var payOptSchema = S$RescriptSchema.union([
      S$RescriptSchema.object(function (o) {
            o.t("kind", "PIF");
            return {
                    TAG: "PIF",
                    _0: {
                      amt: o.f("a", CS_Decimal$Util.Decimal10_2.schema)
                    }
                  };
          }),
      S$RescriptSchema.object(function (o) {
            o.t("kind", "minimum");
            return {
                    TAG: "Minimum",
                    _0: {
                      amt: o.f("a", CS_Decimal$Util.Decimal10_2.schema)
                    }
                  };
          }),
      S$RescriptSchema.object(function (o) {
            o.t("kind", "current");
            return {
                    TAG: "Current",
                    _0: {
                      amt: o.f("a", CS_Decimal$Util.Decimal10_2.schema)
                    }
                  };
          }),
      S$RescriptSchema.object(function (o) {
            o.t("kind", "custom");
            return {
                    TAG: "Custom",
                    _0: {
                      amt: o.f("a", CS_Decimal$Util.Decimal10_2.schema)
                    }
                  };
          })
    ]);

var paxSchema = S$RescriptSchema.union([
      S$RescriptSchema.literal("TBA"),
      S$RescriptSchema.object(function (o) {
            return {
                    TAG: "BC",
                    _0: {
                      id: o.f("bci", CS_NonemptyStrings$Util.AccountId.schema)
                    }
                  };
          }),
      S$RescriptSchema.object(function (o) {
            return {
                    TAG: "BCIncomplete",
                    _0: {
                      accountId: o.f("ba", CS_NonemptyStrings$Util.AccountId.schema),
                      iDocFirstName: o.f("bidf", CS_NonemptyStrings$Util.NonEmptyString.schema),
                      iDocLastName: o.f("bidl", CS_NonemptyStrings$Util.NonEmptyString.schema),
                      iDocGender: o.f("bidg", genderSchema),
                      iDocBirthdate: o.f("bidb", CS_SQLDatesAndTimes$Util.DateOnly.schema),
                      iDocCitizenship: o.f("bidc", CS_ISO3166_1$Util.Alpha3.schema)
                    }
                  };
          }),
      S$RescriptSchema.object(function (o) {
            return {
                    TAG: "Invite",
                    _0: {
                      email: o.f("e", CS_Emails$Util.Email.schema),
                      firstName: o.f("f", CS_NonemptyStrings$Util.NonEmptyString.schema),
                      lastName: o.f("l", S$RescriptSchema.option(CS_NonemptyStrings$Util.NonEmptyString.schema))
                    }
                  };
          }),
      S$RescriptSchema.object(function (o) {
            return {
                    TAG: "Connected",
                    _0: {
                      id: o.f("i", CS_NonemptyStrings$Util.AccountId.schema)
                    }
                  };
          }),
      S$RescriptSchema.object(function (o) {
            return {
                    TAG: "ConnectedIncomplete",
                    _0: {
                      accountId: o.f("ca", CS_NonemptyStrings$Util.AccountId.schema),
                      iDocFirstName: o.f("cidf", CS_NonemptyStrings$Util.NonEmptyString.schema),
                      iDocLastName: o.f("cidl", CS_NonemptyStrings$Util.NonEmptyString.schema),
                      iDocGender: o.f("cidg", genderSchema),
                      iDocBirthdate: o.f("cidb", CS_SQLDatesAndTimes$Util.DateOnly.schema),
                      iDocCitizenship: o.f("cidc", CS_ISO3166_1$Util.Alpha3.schema)
                    }
                  };
          }),
      S$RescriptSchema.object(function (o) {
            return {
                    TAG: "Loyalty",
                    _0: {
                      number: o.f("n", CS_NonemptyStrings$Util.LoyaltyNumber.schema),
                      acctId: o.f("a", CS_NonemptyStrings$Util.AccountId.schema)
                    }
                  };
          }),
      S$RescriptSchema.object(function (o) {
            return {
                    TAG: "Provide",
                    _0: {
                      docFirstName: o.f("df", CS_NonemptyStrings$Util.NonEmptyString.schema),
                      docLastName: o.f("dl", CS_NonemptyStrings$Util.NonEmptyString.schema),
                      docGender: o.f("dg", genderSchema),
                      docBirthdate: o.f("db", CS_SQLDatesAndTimes$Util.DateOnly.schema),
                      docCitizenship: o.f("dc", CS_ISO3166_1$Util.Alpha3.schema),
                      preferredFirstName: o.f("pf", S$RescriptSchema.option(CS_NonemptyStrings$Util.NonEmptyString.schema)),
                      preferredLastName: o.f("pl", S$RescriptSchema.option(CS_NonemptyStrings$Util.NonEmptyString.schema)),
                      residenceCountry: o.f("rc", S$RescriptSchema.option(CS_ISO3166_1$Util.Alpha3.schema)),
                      phone: o.f("ph", S$RescriptSchema.option(CS_Phone$Util.Phone.schema)),
                      postalCode: o.f("pc", S$RescriptSchema.option(CS_NonemptyStrings$Util.PostalCode.schema))
                    }
                  };
          })
    ]);

var bcSchema = S$RescriptSchema.union([
      S$RescriptSchema.literal("NeedsVerification"),
      S$RescriptSchema.object(function (o) {
            return {
                    TAG: "Id",
                    _0: o.f("i", CS_NonemptyStrings$Util.AccountId.schema)
                  };
          })
    ]);

function arrayToIntMapSchema(schema) {
  return S$RescriptSchema.transform(schema, (function (param) {
                return {
                        p: Belt_MapInt.fromArray,
                        s: Belt_MapInt.toArray
                      };
              }));
}

var sessionStorageSchema = S$RescriptSchema.object(function (o) {
      return {
              sessionId: o.f("s", S$RescriptSchema.option(S$RescriptSchema.string)),
              paxes: o.f("p", arrayToIntMapSchema(S$RescriptSchema.array(S$RescriptSchema.tuple2(S$RescriptSchema.$$int, paxSchema)))),
              bc: o.f("b", S$RescriptSchema.option(bcSchema)),
              paymentOption: o.f("pay", S$RescriptSchema.option(payOptSchema))
            };
    });

var sessionStorageEmpty = {
  sessionId: undefined,
  paxes: undefined,
  bc: undefined,
  paymentOption: undefined
};

function decodeSessionStorage(data) {
  var match = $$String.trim(data).length;
  if (match === 0) {
    return sessionStorageEmpty;
  }
  var obj = JSON.parse(data);
  if (obj === undefined) {
    return sessionStorageEmpty;
  }
  var data$1 = S$RescriptSchema.parseWith(Caml_option.valFromOption(obj), sessionStorageSchema);
  if (data$1.TAG === "Ok") {
    return data$1._0;
  } else {
    return sessionStorageEmpty;
  }
}

function encodeSessionStorage(data) {
  return JSON.stringify(S$RescriptSchema.serializeOrRaiseWith(data, sessionStorageSchema));
}

var empty = {
  navTarget: "ClassAndSubclassSelection",
  session: undefined,
  cabinTargetingByCategory: [
    2,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined
  ],
  cabinTargetingByAllNoCabin: [
    2,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined
  ],
  cabinTargetingByAll: [
    2,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined
  ],
  cabinHold: undefined,
  bookingSuccess: undefined
};

var navTargetCodec = QueryString$Util.Codec.customStringable({
      legalVals: [
        "ClassAndSubclassSelection",
        "CategorySelection",
        "LocationSelection",
        "DeckSelection",
        "CabinSelection",
        "EnterPassengerDetails",
        "Checkout"
      ],
      toString: (function (a) {
          switch (a) {
            case "ClassAndSubclassSelection" :
                return "class";
            case "CategorySelection" :
                return "category";
            case "LocationSelection" :
                return "location";
            case "DeckSelection" :
                return "deck";
            case "CabinSelection" :
                return "cabin";
            case "EnterPassengerDetails" :
                return "pax";
            case "Checkout" :
                return "checkout";
            
          }
        })
    });

var cabinTargetingByCategoryCodec = QueryString$Util.Codec.map(QueryString$Util.Codec.tuple6(QueryString$Util.Codec.required(QueryString$Util.Codec.$$int), QueryString$Util.Codec.customScalar(CS_Slugs$Util.CabinClassSlug), QueryString$Util.Codec.customScalar(CS_Slugs$Util.CabinSubclassSlug), QueryString$Util.Codec.customScalar(CS_Slugs$Util.CabinCategorySlug), QueryString$Util.Codec.customScalar(CS_Slugs$Util.FareClassSlug), QueryString$Util.Codec.customScalar(CS_Make$Util.Dateable.Plain)), {
      decode: (function (param) {
          return {
                  TAG: "Ok",
                  _0: [
                    param[0],
                    param[1],
                    param[2],
                    param[3],
                    param[4],
                    param[5]
                  ]
                };
        }),
      encode: (function (param) {
          return [
                  param[0],
                  param[1],
                  param[2],
                  param[3],
                  param[4],
                  param[5]
                ];
        })
    });

var longitudeCodec = QueryString$Util.Codec.customStringable({
      legalVals: [
        "FORE",
        "MID",
        "AFT"
      ],
      toString: (function (a) {
          return a;
        })
    });

var cabinTargetingAllNoCabinCodec = QueryString$Util.Codec.map(QueryString$Util.Codec.tuple8(QueryString$Util.Codec.required(QueryString$Util.Codec.$$int), QueryString$Util.Codec.customScalar(CS_Slugs$Util.CabinClassSlug), QueryString$Util.Codec.customScalar(CS_Slugs$Util.CabinSubclassSlug), QueryString$Util.Codec.customScalar(CS_Slugs$Util.CabinCategorySlug), longitudeCodec, QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.DeckNumber), QueryString$Util.Codec.customScalar(CS_Slugs$Util.FareClassSlug), QueryString$Util.Codec.customScalar(CS_Make$Util.Dateable.Plain)), {
      decode: (function (param) {
          return {
                  TAG: "Ok",
                  _0: [
                    param[0],
                    param[1],
                    param[2],
                    param[3],
                    param[4],
                    param[5],
                    param[6],
                    param[7]
                  ]
                };
        }),
      encode: (function (param) {
          return [
                  param[0],
                  param[1],
                  param[2],
                  param[3],
                  param[4],
                  param[5],
                  param[6],
                  param[7]
                ];
        })
    });

var cabinTargetingAllCodec = QueryString$Util.Codec.map(QueryString$Util.Codec.tuple9(QueryString$Util.Codec.required(QueryString$Util.Codec.$$int), QueryString$Util.Codec.customScalar(CS_Slugs$Util.CabinClassSlug), QueryString$Util.Codec.customScalar(CS_Slugs$Util.CabinSubclassSlug), QueryString$Util.Codec.customScalar(CS_Slugs$Util.CabinCategorySlug), longitudeCodec, QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.DeckNumber), QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.CabinNumber), QueryString$Util.Codec.customScalar(CS_Slugs$Util.FareClassSlug), QueryString$Util.Codec.customScalar(CS_Make$Util.Dateable.Plain)), {
      decode: (function (param) {
          return {
                  TAG: "Ok",
                  _0: [
                    param[0],
                    param[1],
                    param[2],
                    param[3],
                    param[4],
                    param[5],
                    param[6],
                    param[7],
                    param[8]
                  ]
                };
        }),
      encode: (function (param) {
          return [
                  param[0],
                  param[1],
                  param[2],
                  param[3],
                  param[4],
                  param[5],
                  param[6],
                  param[7],
                  param[8]
                ];
        })
    });

function uuid_decode(a) {
  return {
          TAG: "Ok",
          _0: a
        };
}

function uuid_encode(v) {
  return v;
}

var uuid = {
  decode: uuid_decode,
  encode: uuid_encode
};

var salesFlowURLCodec = QueryString$Util.Parser.make([
      QueryString$Util.Param.Singular.make("n", fieldToString, QueryString$Util.Codec.scalar(QueryString$Util.Codec.required(navTargetCodec)), false, (function (diff, navTarget) {
              return {
                      navTarget: Belt_Option.getWithDefault(navTarget, "ClassAndSubclassSelection"),
                      session: diff.session,
                      cabinTargetingByCategory: diff.cabinTargetingByCategory,
                      cabinTargetingByAllNoCabin: diff.cabinTargetingByAllNoCabin,
                      cabinTargetingByAll: diff.cabinTargetingByAll,
                      cabinHold: diff.cabinHold,
                      bookingSuccess: diff.bookingSuccess
                    };
            }), (function (diff) {
              return diff.navTarget;
            })),
      QueryString$Util.Param.Singular.make("s", fieldToString, QueryString$Util.Codec.scalar(QueryString$Util.Codec.required(uuid)), false, (function (diff, session) {
              return {
                      navTarget: diff.navTarget,
                      session: session,
                      cabinTargetingByCategory: diff.cabinTargetingByCategory,
                      cabinTargetingByAllNoCabin: diff.cabinTargetingByAllNoCabin,
                      cabinTargetingByAll: diff.cabinTargetingByAll,
                      cabinHold: diff.cabinHold,
                      bookingSuccess: diff.bookingSuccess
                    };
            }), (function (diff) {
              return diff.session;
            })),
      QueryString$Util.Param.Singular.make("cc", fieldToString, cabinTargetingByCategoryCodec, false, (function (diff, cabinTargetingByCategory) {
              return {
                      navTarget: diff.navTarget,
                      session: diff.session,
                      cabinTargetingByCategory: Belt_Option.getWithDefault(cabinTargetingByCategory, [
                            2,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined
                          ]),
                      cabinTargetingByAllNoCabin: diff.cabinTargetingByAllNoCabin,
                      cabinTargetingByAll: diff.cabinTargetingByAll,
                      cabinHold: diff.cabinHold,
                      bookingSuccess: diff.bookingSuccess
                    };
            }), (function (diff) {
              return diff.cabinTargetingByCategory;
            })),
      QueryString$Util.Param.Singular.make("cn", fieldToString, cabinTargetingAllNoCabinCodec, false, (function (diff, cabinTargetingByAllNoCabin) {
              return {
                      navTarget: diff.navTarget,
                      session: diff.session,
                      cabinTargetingByCategory: diff.cabinTargetingByCategory,
                      cabinTargetingByAllNoCabin: Belt_Option.getWithDefault(cabinTargetingByAllNoCabin, [
                            2,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined
                          ]),
                      cabinTargetingByAll: diff.cabinTargetingByAll,
                      cabinHold: diff.cabinHold,
                      bookingSuccess: diff.bookingSuccess
                    };
            }), (function (diff) {
              return diff.cabinTargetingByAllNoCabin;
            })),
      QueryString$Util.Param.Singular.make("ca", fieldToString, cabinTargetingAllCodec, false, (function (diff, cabinTargetingByAll) {
              return {
                      navTarget: diff.navTarget,
                      session: diff.session,
                      cabinTargetingByCategory: diff.cabinTargetingByCategory,
                      cabinTargetingByAllNoCabin: diff.cabinTargetingByAllNoCabin,
                      cabinTargetingByAll: Belt_Option.getWithDefault(cabinTargetingByAll, [
                            2,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined
                          ]),
                      cabinHold: diff.cabinHold,
                      bookingSuccess: diff.bookingSuccess
                    };
            }), (function (diff) {
              return diff.cabinTargetingByAll;
            })),
      QueryString$Util.Param.Singular.make("h", fieldToString, QueryString$Util.Codec.scalar(QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_Make$Util.Dateable.Plain))), false, (function (diff, cabinHold) {
              return {
                      navTarget: diff.navTarget,
                      session: diff.session,
                      cabinTargetingByCategory: diff.cabinTargetingByCategory,
                      cabinTargetingByAllNoCabin: diff.cabinTargetingByAllNoCabin,
                      cabinTargetingByAll: diff.cabinTargetingByAll,
                      cabinHold: cabinHold,
                      bookingSuccess: diff.bookingSuccess
                    };
            }), (function (diff) {
              return diff.cabinHold;
            })),
      QueryString$Util.Param.Singular.make("b", fieldToString, QueryString$Util.Codec.scalar(QueryString$Util.Codec.required(QueryString$Util.Codec.bool)), false, (function (diff, bookingSuccess) {
              return {
                      navTarget: diff.navTarget,
                      session: diff.session,
                      cabinTargetingByCategory: diff.cabinTargetingByCategory,
                      cabinTargetingByAllNoCabin: diff.cabinTargetingByAllNoCabin,
                      cabinTargetingByAll: diff.cabinTargetingByAll,
                      cabinHold: diff.cabinHold,
                      bookingSuccess: bookingSuccess
                    };
            }), (function (diff) {
              return diff.bookingSuccess;
            }))
    ], empty);

function parse(queryString) {
  return salesFlowURLCodec.decode(queryString);
}

function serialize(urlData) {
  return salesFlowURLCodec.encode(urlData);
}

var URLState = {
  parse: parse,
  serialize: serialize
};

function parse$1(str) {
  return decodeSessionStorage(str);
}

function serialize$1(ssd) {
  return encodeSessionStorage(ssd);
}

function sanitizeIncompletes(ssd) {
  var paxesArray = Belt_MapInt.toArray(ssd.paxes);
  var bcIncomplete = Belt_Array.some(paxesArray, (function (param) {
          var tmp = param[1];
          if (typeof tmp !== "object" || tmp.TAG !== "BCIncomplete") {
            return false;
          } else {
            return true;
          }
        }));
  var connectedIncomplete = Belt_Array.some(paxesArray, (function (param) {
          var tmp = param[1];
          if (typeof tmp !== "object" || tmp.TAG !== "ConnectedIncomplete") {
            return false;
          } else {
            return true;
          }
        }));
  var final_sessionId = ssd.sessionId;
  var final_paxes = Belt_MapInt.fromArray(Belt_Array.map(paxesArray, (function (param) {
              var pax = param[1];
              var idx = param[0];
              if (typeof pax !== "object") {
                return [
                        idx,
                        pax
                      ];
              }
              switch (pax.TAG) {
                case "BCIncomplete" :
                    return [
                            idx,
                            {
                              TAG: "BC",
                              _0: {
                                id: pax._0.accountId
                              }
                            }
                          ];
                case "ConnectedIncomplete" :
                    return [
                            idx,
                            {
                              TAG: "Connected",
                              _0: {
                                id: pax._0.accountId
                              }
                            }
                          ];
                default:
                  return [
                          idx,
                          pax
                        ];
              }
            })));
  var final_bc = ssd.bc;
  var final_paymentOption = ssd.paymentOption;
  var $$final = {
    sessionId: final_sessionId,
    paxes: final_paxes,
    bc: final_bc,
    paymentOption: final_paymentOption
  };
  if (bcIncomplete) {
    return [
            "BillingContact",
            $$final
          ];
  } else if (connectedIncomplete) {
    return [
            "ConnectedAccount",
            $$final
          ];
  } else {
    return [
            undefined,
            $$final
          ];
  }
}

var SessionState = {
  parse: parse$1,
  serialize: serialize$1,
  sanitizeIncompletes: sanitizeIncompletes
};

var cmp = Caml_obj.compare;

var NavTargetCmp = Belt_Id.MakeComparable({
      cmp: cmp
    });

function setNavTarget(urlState, target) {
  return {
          navTarget: target,
          session: urlState.session,
          cabinTargetingByCategory: urlState.cabinTargetingByCategory,
          cabinTargetingByAllNoCabin: urlState.cabinTargetingByAllNoCabin,
          cabinTargetingByAll: urlState.cabinTargetingByAll,
          cabinHold: urlState.cabinHold,
          bookingSuccess: urlState.bookingSuccess
        };
}

function setSession(urlState, session) {
  return {
          navTarget: urlState.navTarget,
          session: session,
          cabinTargetingByCategory: urlState.cabinTargetingByCategory,
          cabinTargetingByAllNoCabin: urlState.cabinTargetingByAllNoCabin,
          cabinTargetingByAll: urlState.cabinTargetingByAll,
          cabinHold: urlState.cabinHold,
          bookingSuccess: urlState.bookingSuccess
        };
}

function setBookingSuccess(urlState, success) {
  return {
          navTarget: urlState.navTarget,
          session: urlState.session,
          cabinTargetingByCategory: urlState.cabinTargetingByCategory,
          cabinTargetingByAllNoCabin: urlState.cabinTargetingByAllNoCabin,
          cabinTargetingByAll: urlState.cabinTargetingByAll,
          cabinHold: urlState.cabinHold,
          bookingSuccess: success
        };
}

function setOccupancy(flow, urlState, occupancy) {
  switch (flow) {
    case "ByCategory" :
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: [
                  occupancy,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined
                ],
                cabinTargetingByAllNoCabin: urlState.cabinTargetingByAllNoCabin,
                cabinTargetingByAll: urlState.cabinTargetingByAll,
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    case "AllNoCabin" :
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: urlState.cabinTargetingByCategory,
                cabinTargetingByAllNoCabin: [
                  occupancy,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined
                ],
                cabinTargetingByAll: urlState.cabinTargetingByAll,
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    case "All" :
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: urlState.cabinTargetingByCategory,
                cabinTargetingByAllNoCabin: urlState.cabinTargetingByAllNoCabin,
                cabinTargetingByAll: [
                  occupancy,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined
                ],
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    
  }
}

function setCabinClass(flow, urlState, cabinClass) {
  switch (flow) {
    case "ByCategory" :
        var match = urlState.cabinTargetingByCategory;
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: [
                  match[0],
                  Caml_option.some(cabinClass),
                  undefined,
                  undefined,
                  undefined,
                  undefined
                ],
                cabinTargetingByAllNoCabin: urlState.cabinTargetingByAllNoCabin,
                cabinTargetingByAll: urlState.cabinTargetingByAll,
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    case "AllNoCabin" :
        var match$1 = urlState.cabinTargetingByAllNoCabin;
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: urlState.cabinTargetingByCategory,
                cabinTargetingByAllNoCabin: [
                  match$1[0],
                  Caml_option.some(cabinClass),
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined
                ],
                cabinTargetingByAll: urlState.cabinTargetingByAll,
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    case "All" :
        var match$2 = urlState.cabinTargetingByAll;
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: urlState.cabinTargetingByCategory,
                cabinTargetingByAllNoCabin: urlState.cabinTargetingByAllNoCabin,
                cabinTargetingByAll: [
                  match$2[0],
                  Caml_option.some(cabinClass),
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined
                ],
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    
  }
}

function setCabinSubclass(flow, urlState, cabinSubclass) {
  switch (flow) {
    case "ByCategory" :
        var match = urlState.cabinTargetingByCategory;
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: [
                  match[0],
                  match[1],
                  cabinSubclass,
                  undefined,
                  undefined,
                  undefined
                ],
                cabinTargetingByAllNoCabin: urlState.cabinTargetingByAllNoCabin,
                cabinTargetingByAll: urlState.cabinTargetingByAll,
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    case "AllNoCabin" :
        var match$1 = urlState.cabinTargetingByAllNoCabin;
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: urlState.cabinTargetingByCategory,
                cabinTargetingByAllNoCabin: [
                  match$1[0],
                  match$1[1],
                  cabinSubclass,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined
                ],
                cabinTargetingByAll: urlState.cabinTargetingByAll,
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    case "All" :
        var match$2 = urlState.cabinTargetingByAll;
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: urlState.cabinTargetingByCategory,
                cabinTargetingByAllNoCabin: urlState.cabinTargetingByAllNoCabin,
                cabinTargetingByAll: [
                  match$2[0],
                  match$2[1],
                  cabinSubclass,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined
                ],
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    
  }
}

function setCabinCategory(flow, urlState, cabinCategory) {
  switch (flow) {
    case "ByCategory" :
        var match = urlState.cabinTargetingByCategory;
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: [
                  match[0],
                  match[1],
                  match[2],
                  Caml_option.some(cabinCategory),
                  undefined,
                  undefined
                ],
                cabinTargetingByAllNoCabin: urlState.cabinTargetingByAllNoCabin,
                cabinTargetingByAll: urlState.cabinTargetingByAll,
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    case "AllNoCabin" :
        var match$1 = urlState.cabinTargetingByAllNoCabin;
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: urlState.cabinTargetingByCategory,
                cabinTargetingByAllNoCabin: [
                  match$1[0],
                  match$1[1],
                  match$1[2],
                  Caml_option.some(cabinCategory),
                  undefined,
                  undefined,
                  undefined,
                  undefined
                ],
                cabinTargetingByAll: urlState.cabinTargetingByAll,
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    case "All" :
        var match$2 = urlState.cabinTargetingByAll;
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: urlState.cabinTargetingByCategory,
                cabinTargetingByAllNoCabin: urlState.cabinTargetingByAllNoCabin,
                cabinTargetingByAll: [
                  match$2[0],
                  match$2[1],
                  match$2[2],
                  Caml_option.some(cabinCategory),
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined
                ],
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    
  }
}

function setFareClassSlug(flow, urlState, fareClassSlug) {
  switch (flow) {
    case "ByCategory" :
        var match = urlState.cabinTargetingByCategory;
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: [
                  match[0],
                  match[1],
                  match[2],
                  match[3],
                  Caml_option.some(fareClassSlug),
                  undefined
                ],
                cabinTargetingByAllNoCabin: urlState.cabinTargetingByAllNoCabin,
                cabinTargetingByAll: urlState.cabinTargetingByAll,
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    case "AllNoCabin" :
        var match$1 = urlState.cabinTargetingByAllNoCabin;
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: urlState.cabinTargetingByCategory,
                cabinTargetingByAllNoCabin: [
                  match$1[0],
                  match$1[1],
                  match$1[2],
                  match$1[3],
                  match$1[4],
                  match$1[5],
                  Caml_option.some(fareClassSlug),
                  undefined
                ],
                cabinTargetingByAll: urlState.cabinTargetingByAll,
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    case "All" :
        var match$2 = urlState.cabinTargetingByAll;
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: urlState.cabinTargetingByCategory,
                cabinTargetingByAllNoCabin: urlState.cabinTargetingByAllNoCabin,
                cabinTargetingByAll: [
                  match$2[0],
                  match$2[1],
                  match$2[2],
                  match$2[3],
                  match$2[4],
                  match$2[5],
                  match$2[6],
                  Caml_option.some(fareClassSlug),
                  undefined
                ],
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    
  }
}

function setCabinLocation(flow, urlState, longitude) {
  switch (flow) {
    case "ByCategory" :
        return urlState;
    case "AllNoCabin" :
        var match = urlState.cabinTargetingByAllNoCabin;
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: urlState.cabinTargetingByCategory,
                cabinTargetingByAllNoCabin: [
                  match[0],
                  match[1],
                  match[2],
                  match[3],
                  longitude,
                  undefined,
                  undefined,
                  undefined
                ],
                cabinTargetingByAll: urlState.cabinTargetingByAll,
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    case "All" :
        var match$1 = urlState.cabinTargetingByAll;
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: urlState.cabinTargetingByCategory,
                cabinTargetingByAllNoCabin: urlState.cabinTargetingByAllNoCabin,
                cabinTargetingByAll: [
                  match$1[0],
                  match$1[1],
                  match$1[2],
                  match$1[3],
                  longitude,
                  undefined,
                  undefined,
                  undefined,
                  undefined
                ],
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    
  }
}

function setCabinDeck(flow, urlState, deck) {
  switch (flow) {
    case "ByCategory" :
        return urlState;
    case "AllNoCabin" :
        var match = urlState.cabinTargetingByAllNoCabin;
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: urlState.cabinTargetingByCategory,
                cabinTargetingByAllNoCabin: [
                  match[0],
                  match[1],
                  match[2],
                  match[3],
                  match[4],
                  Caml_option.some(deck),
                  undefined,
                  undefined
                ],
                cabinTargetingByAll: urlState.cabinTargetingByAll,
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    case "All" :
        var match$1 = urlState.cabinTargetingByAll;
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: urlState.cabinTargetingByCategory,
                cabinTargetingByAllNoCabin: urlState.cabinTargetingByAllNoCabin,
                cabinTargetingByAll: [
                  match$1[0],
                  match$1[1],
                  match$1[2],
                  match$1[3],
                  match$1[4],
                  Caml_option.some(deck),
                  undefined,
                  undefined,
                  undefined
                ],
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    
  }
}

function setCabinNumber(flow, urlState, cabin) {
  switch (flow) {
    case "ByCategory" :
    case "AllNoCabin" :
        return urlState;
    case "All" :
        var match = urlState.cabinTargetingByAll;
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: urlState.cabinTargetingByCategory,
                cabinTargetingByAllNoCabin: urlState.cabinTargetingByAllNoCabin,
                cabinTargetingByAll: [
                  match[0],
                  match[1],
                  match[2],
                  match[3],
                  match[4],
                  match[5],
                  Caml_option.some(cabin),
                  undefined,
                  undefined
                ],
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    
  }
}

function setHoldRequestTime(flow, urlState, requestTime) {
  switch (flow) {
    case "ByCategory" :
        var match = urlState.cabinTargetingByCategory;
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: [
                  match[0],
                  match[1],
                  match[2],
                  match[3],
                  match[4],
                  Caml_option.some(requestTime)
                ],
                cabinTargetingByAllNoCabin: urlState.cabinTargetingByAllNoCabin,
                cabinTargetingByAll: urlState.cabinTargetingByAll,
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    case "AllNoCabin" :
        var match$1 = urlState.cabinTargetingByAllNoCabin;
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: urlState.cabinTargetingByCategory,
                cabinTargetingByAllNoCabin: [
                  match$1[0],
                  match$1[1],
                  match$1[2],
                  match$1[3],
                  match$1[4],
                  match$1[5],
                  match$1[6],
                  Caml_option.some(requestTime)
                ],
                cabinTargetingByAll: urlState.cabinTargetingByAll,
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    case "All" :
        var match$2 = urlState.cabinTargetingByAll;
        return {
                navTarget: urlState.navTarget,
                session: urlState.session,
                cabinTargetingByCategory: urlState.cabinTargetingByCategory,
                cabinTargetingByAllNoCabin: urlState.cabinTargetingByAllNoCabin,
                cabinTargetingByAll: [
                  match$2[0],
                  match$2[1],
                  match$2[2],
                  match$2[3],
                  match$2[4],
                  match$2[5],
                  match$2[6],
                  match$2[7],
                  Caml_option.some(requestTime)
                ],
                cabinHold: urlState.cabinHold,
                bookingSuccess: urlState.bookingSuccess
              };
    
  }
}

function setCabinHold(urlState, expiration) {
  return {
          navTarget: urlState.navTarget,
          session: urlState.session,
          cabinTargetingByCategory: urlState.cabinTargetingByCategory,
          cabinTargetingByAllNoCabin: urlState.cabinTargetingByAllNoCabin,
          cabinTargetingByAll: urlState.cabinTargetingByAll,
          cabinHold: Caml_option.some(expiration),
          bookingSuccess: urlState.bookingSuccess
        };
}

function clearCabinHold(urlState) {
  return {
          navTarget: urlState.navTarget,
          session: urlState.session,
          cabinTargetingByCategory: urlState.cabinTargetingByCategory,
          cabinTargetingByAllNoCabin: urlState.cabinTargetingByAllNoCabin,
          cabinTargetingByAll: urlState.cabinTargetingByAll,
          cabinHold: undefined,
          bookingSuccess: urlState.bookingSuccess
        };
}

function setSessionId(sessionState, sessionId) {
  return {
          sessionId: sessionId,
          paxes: sessionState.paxes,
          bc: sessionState.bc,
          paymentOption: sessionState.paymentOption
        };
}

function setPaxes(sessionState, paxes) {
  return {
          sessionId: sessionState.sessionId,
          paxes: paxes,
          bc: sessionState.bc,
          paymentOption: sessionState.paymentOption
        };
}

function setBC(sessionState, bc) {
  return {
          sessionId: sessionState.sessionId,
          paxes: sessionState.paxes,
          bc: bc,
          paymentOption: sessionState.paymentOption
        };
}

function upsertPax(sessionState, numPax, pax) {
  var paxes = sessionState.paxes;
  var newPaxes = Belt_MapInt.set(paxes, numPax, pax);
  return setPaxes(sessionState, newPaxes);
}

function removePax(sessionState, id) {
  var paxes = sessionState.paxes;
  var newPaxes = Belt_MapInt.remove(paxes, id);
  return setPaxes(sessionState, newPaxes);
}

function setPaymentOption(sessionState, paymentOption) {
  return {
          sessionId: sessionState.sessionId,
          paxes: sessionState.paxes,
          bc: sessionState.bc,
          paymentOption: paymentOption
        };
}

function clearPaymentOption(sessionState) {
  return {
          sessionId: sessionState.sessionId,
          paxes: sessionState.paxes,
          bc: sessionState.bc,
          paymentOption: undefined
        };
}

var Alter = {
  setNavTarget: setNavTarget,
  setSession: setSession,
  setBookingSuccess: setBookingSuccess,
  setOccupancy: setOccupancy,
  setCabinClass: setCabinClass,
  setCabinSubclass: setCabinSubclass,
  setCabinCategory: setCabinCategory,
  setFareClassSlug: setFareClassSlug,
  setCabinLocation: setCabinLocation,
  setCabinDeck: setCabinDeck,
  setCabinNumber: setCabinNumber,
  setHoldRequestTime: setHoldRequestTime,
  setCabinHold: setCabinHold,
  clearCabinHold: clearCabinHold,
  setSessionId: setSessionId,
  setPaxes: setPaxes,
  setBC: setBC,
  upsertPax: upsertPax,
  removePax: removePax,
  setPaymentOption: setPaymentOption,
  clearPaymentOption: clearPaymentOption
};

function generateNavUrl(target, validTargets, urlState, url) {
  if (Belt_Set.has(validTargets, target)) {
    var urlData = setNavTarget(urlState, target);
    return url + "?" + salesFlowURLCodec.encode(urlData);
  }
  if (Belt_Set.has(validTargets, "EnterPassengerDetails")) {
    var urlData$1 = setNavTarget(urlState, "EnterPassengerDetails");
    return url + "?" + salesFlowURLCodec.encode(urlData$1);
  }
  if (Belt_Set.has(validTargets, "CabinSelection")) {
    var urlData$2 = setNavTarget(urlState, "CabinSelection");
    return url + "?" + salesFlowURLCodec.encode(urlData$2);
  }
  if (Belt_Set.has(validTargets, "DeckSelection")) {
    var urlData$3 = setNavTarget(urlState, "DeckSelection");
    return url + "?" + salesFlowURLCodec.encode(urlData$3);
  }
  if (Belt_Set.has(validTargets, "LocationSelection")) {
    var urlData$4 = setNavTarget(urlState, "LocationSelection");
    return url + "?" + salesFlowURLCodec.encode(urlData$4);
  }
  if (Belt_Set.has(validTargets, "CategorySelection")) {
    var urlData$5 = setNavTarget(urlState, "CategorySelection");
    return url + "?" + salesFlowURLCodec.encode(urlData$5);
  }
  var urlData$6 = setNavTarget(urlState, "ClassAndSubclassSelection");
  var queryString = salesFlowURLCodec.encode(urlData$6);
  if (queryString === "") {
    return url;
  } else {
    return url + "?" + queryString;
  }
}

var targetingCriteria = ["ClassAndSubclassSelection"];

var targetingSteppers = [
  {
    title: "Class",
    nav: "ClassAndSubclassSelection",
    params: [
      "Class",
      "Subclass"
    ]
  },
  {
    title: "Category",
    nav: "CategorySelection",
    params: ["Category"]
  }
];

function stepToSteppers(step) {
  if (step !== 1) {
    return ;
  } else {
    return targetingSteppers;
  }
}

var allTargets = [
  "ClassAndSubclassSelection",
  "CategorySelection",
  "EnterPassengerDetails",
  "Checkout"
];

function nextNavTarget(currentTarget) {
  switch (currentTarget) {
    case "ClassAndSubclassSelection" :
        return "CategorySelection";
    case "CategorySelection" :
        return "EnterPassengerDetails";
    case "EnterPassengerDetails" :
        return "Checkout";
    default:
      return "ClassAndSubclassSelection";
  }
}

function validNavStates(urlState, sessionState) {
  var cabinHold = urlState.cabinHold;
  var cabinTargetingByCategory = urlState.cabinTargetingByCategory;
  var initState = ["Initial"];
  var match = Belt_MapInt.size(sessionState.paxes);
  var cabinClass = cabinTargetingByCategory[1];
  var occupancy = cabinTargetingByCategory[0];
  if (cabinClass === undefined) {
    return initState;
  }
  var fareRuleSlug = cabinTargetingByCategory[4];
  var category = cabinTargetingByCategory[3];
  var subclass = cabinTargetingByCategory[2];
  var cabinClass$1 = Caml_option.valFromOption(cabinClass);
  var match$1 = Belt_Option.isSome(sessionState.bc);
  var tmp;
  if (subclass !== undefined) {
    var subclass$1 = Caml_option.valFromOption(subclass);
    if (category !== undefined) {
      if (fareRuleSlug !== undefined) {
        var fareRuleSlug$1 = Caml_option.valFromOption(fareRuleSlug);
        var category$1 = Caml_option.valFromOption(category);
        var exit = 0;
        if (match$1 && cabinHold !== undefined) {
          var expiration = Caml_option.valFromOption(cabinHold);
          if (match >= occupancy && !DateFns$Util.hasExpired(CS_Make$Util.Dateable.Plain.toDate(expiration))) {
            tmp = [
              {
                TAG: "CanSelectCategory",
                _0: occupancy,
                _1: cabinClass$1,
                _2: subclass$1
              },
              {
                TAG: "CanEnterPaxDataByCategory",
                _0: occupancy,
                _1: cabinClass$1,
                _2: subclass$1,
                _3: category$1,
                _4: fareRuleSlug$1
              },
              {
                TAG: "CanCheckoutByCategory",
                _0: occupancy,
                _1: cabinClass$1,
                _2: subclass$1,
                _3: category$1,
                _4: fareRuleSlug$1,
                _5: expiration,
                _6: sessionState
              }
            ];
          } else {
            exit = 1;
          }
        } else {
          exit = 1;
        }
        if (exit === 1) {
          tmp = Belt_Array.keepMap([
                {
                  TAG: "CanSelectCategory",
                  _0: occupancy,
                  _1: cabinClass$1,
                  _2: subclass$1
                },
                {
                  TAG: "CanEnterPaxDataByCategory",
                  _0: occupancy,
                  _1: cabinClass$1,
                  _2: subclass$1,
                  _3: category$1,
                  _4: fareRuleSlug$1
                }
              ], (function (state) {
                  if (typeof state !== "object" || !(state.TAG === "CanEnterPaxDataByCategory" && cabinHold !== undefined && DateFns$Util.hasExpired(CS_Make$Util.Dateable.Plain.toDate(Caml_option.valFromOption(cabinHold))))) {
                    return state;
                  }
                  
                }));
        }
        
      } else {
        tmp = [];
      }
    } else {
      tmp = fareRuleSlug !== undefined ? [] : [{
            TAG: "CanSelectCategory",
            _0: occupancy,
            _1: cabinClass$1,
            _2: subclass$1
          }];
    }
  } else {
    tmp = [];
  }
  return Belt_Array.concat(Belt_Array.concat(initState, [{
                    TAG: "CanSelectSubclass",
                    _0: occupancy,
                    _1: cabinClass$1
                  }]), tmp);
}

function getNavTargetFromState(state) {
  if (typeof state !== "object") {
    return "ClassAndSubclassSelection";
  }
  switch (state.TAG) {
    case "CanSelectCategory" :
        return "CategorySelection";
    case "CanEnterPaxDataByCategory" :
        return "EnterPassengerDetails";
    case "CanCheckoutByCategory" :
        return "Checkout";
    default:
      return "ClassAndSubclassSelection";
  }
}

function validNavTargets(navStates) {
  return Belt_Set.fromArray(Belt_Array.map(navStates, (function (state) {
                    return getNavTargetFromState(state);
                  })), NavTargetCmp);
}

function readyForNavState(arr) {
  return Belt_Option.getWithDefault(Belt_Array.get(arr, arr.length - 1 | 0), "Initial");
}

function navOptions(urlState, sessionState) {
  return validNavTargets(validNavStates(urlState, sessionState));
}

function canNavigate(target, urlState, sessionState) {
  return Belt_Set.has(navOptions(urlState, sessionState), target);
}

function navUrl(target, urlState, sessionData, url) {
  return generateNavUrl(target, navOptions(urlState, sessionData), urlState, url);
}

function setOcc(urlState, occupancy) {
  return clearCabinHold(setOccupancy("ByCategory", urlState, occupancy));
}

function setClass(urlState, cabinClass) {
  return clearCabinHold(setCabinClass("ByCategory", urlState, cabinClass));
}

function setSub(urlState, cabinSubclass) {
  return clearCabinHold(setCabinSubclass("ByCategory", urlState, cabinSubclass));
}

function setCat(urlState, cabinCategory) {
  return clearCabinHold(setCabinCategory("ByCategory", urlState, cabinCategory));
}

function setFare(urlState, fareClassSlug) {
  return clearCabinHold(setFareClassSlug("ByCategory", urlState, fareClassSlug));
}

function setRequestTime(urlState, requestTime) {
  return setHoldRequestTime("ByCategory", urlState, requestTime);
}

var CabinTarget = {
  setOcc: setOcc,
  setClass: setClass,
  setSub: setSub,
  setCat: setCat,
  setFare: setFare,
  setRequestTime: setRequestTime
};

function stepIsComplete(target, states) {
  switch (target) {
    case "Class" :
        return Belt_Array.some(states, (function (state) {
                      if (typeof state !== "object" || state.TAG !== "CanSelectSubclass") {
                        return false;
                      } else {
                        return true;
                      }
                    }));
    case "Subclass" :
        return Belt_Array.some(states, (function (state) {
                      if (typeof state !== "object" || state.TAG !== "CanSelectCategory") {
                        return false;
                      } else {
                        return true;
                      }
                    }));
    case "Category" :
        return Belt_Array.some(states, (function (state) {
                      if (typeof state !== "object" || state.TAG !== "CanEnterPaxDataByCategory") {
                        return false;
                      } else {
                        return true;
                      }
                    }));
    default:
      return false;
  }
}

function isCurrentStep(target, states) {
  var currentStep = Belt_Array.get(states, states.length - 1 | 0);
  switch (target) {
    case "Class" :
        return Caml_obj.equal(currentStep, "Initial");
    case "Subclass" :
        if (currentStep !== undefined && !(typeof currentStep !== "object" || currentStep.TAG !== "CanSelectSubclass")) {
          return true;
        } else {
          return false;
        }
    case "Category" :
        if (currentStep !== undefined && !(typeof currentStep !== "object" || currentStep.TAG !== "CanSelectCategory")) {
          return true;
        } else {
          return false;
        }
    default:
      return false;
  }
}

var ByCategory = {
  targetingCriteria: targetingCriteria,
  targetingSteppers: targetingSteppers,
  stepToSteppers: stepToSteppers,
  allTargets: allTargets,
  nextNavTarget: nextNavTarget,
  validNavStates: validNavStates,
  getNavTargetFromState: getNavTargetFromState,
  validNavTargets: validNavTargets,
  readyForNavState: readyForNavState,
  navOptions: navOptions,
  canNavigate: canNavigate,
  navUrl: navUrl,
  CabinTarget: CabinTarget,
  stepIsComplete: stepIsComplete,
  isCurrentStep: isCurrentStep
};

var targetingCriteria$1 = [
  "ClassAndSubclassSelection",
  "CategorySelection",
  "LocationSelection",
  "DeckSelection"
];

var targetingSteppers$1 = [
  {
    title: "Class",
    nav: "ClassAndSubclassSelection",
    params: [
      "Class",
      "Subclass"
    ]
  },
  {
    title: "Category",
    nav: "CategorySelection",
    params: ["Category"]
  },
  {
    title: "Location",
    nav: "LocationSelection",
    params: ["Location"]
  },
  {
    title: "Deck",
    nav: "DeckSelection",
    params: ["Deck"]
  }
];

function stepToSteppers$1(step) {
  if (step !== 1) {
    return ;
  } else {
    return targetingSteppers$1;
  }
}

function nextNavTarget$1(currentTarget) {
  switch (currentTarget) {
    case "ClassAndSubclassSelection" :
        return "CategorySelection";
    case "CategorySelection" :
        return "LocationSelection";
    case "LocationSelection" :
        return "DeckSelection";
    case "DeckSelection" :
        return "EnterPassengerDetails";
    case "EnterPassengerDetails" :
        return "Checkout";
    case "CabinSelection" :
    case "Checkout" :
        return "ClassAndSubclassSelection";
    
  }
}

function validNavStates$1(urlState, sessionState) {
  var cabinHold = urlState.cabinHold;
  var cabinTargetingByAllNoCabin = urlState.cabinTargetingByAllNoCabin;
  var initState = ["Initial"];
  var match = Belt_MapInt.size(sessionState.paxes);
  var cabinClass = cabinTargetingByAllNoCabin[1];
  var occupancy = cabinTargetingByAllNoCabin[0];
  if (cabinClass === undefined) {
    return initState;
  }
  var fareRuleSlug = cabinTargetingByAllNoCabin[6];
  var deck = cabinTargetingByAllNoCabin[5];
  var longitude = cabinTargetingByAllNoCabin[4];
  var category = cabinTargetingByAllNoCabin[3];
  var subclass = cabinTargetingByAllNoCabin[2];
  var cabinClass$1 = Caml_option.valFromOption(cabinClass);
  var match$1 = Belt_Option.isSome(sessionState.bc);
  var tmp;
  if (subclass !== undefined) {
    var subclass$1 = Caml_option.valFromOption(subclass);
    if (category !== undefined) {
      var category$1 = Caml_option.valFromOption(category);
      if (longitude !== undefined) {
        if (deck !== undefined) {
          if (fareRuleSlug !== undefined) {
            var fareRuleSlug$1 = Caml_option.valFromOption(fareRuleSlug);
            var deck$1 = Caml_option.valFromOption(deck);
            var exit = 0;
            if (match$1 && cabinHold !== undefined) {
              var expiration = Caml_option.valFromOption(cabinHold);
              if (match >= occupancy && !DateFns$Util.hasExpired(CS_Make$Util.Dateable.Plain.toDate(expiration))) {
                tmp = [
                  {
                    TAG: "CanSelectCategory",
                    _0: occupancy,
                    _1: cabinClass$1,
                    _2: subclass$1
                  },
                  {
                    TAG: "CanSelectLocation",
                    _0: occupancy,
                    _1: cabinClass$1,
                    _2: subclass$1,
                    _3: category$1
                  },
                  {
                    TAG: "CanSelectDeck",
                    _0: occupancy,
                    _1: cabinClass$1,
                    _2: subclass$1,
                    _3: category$1,
                    _4: longitude
                  },
                  {
                    TAG: "CanEnterPaxDataAllNoCabin",
                    _0: occupancy,
                    _1: cabinClass$1,
                    _2: subclass$1,
                    _3: category$1,
                    _4: longitude,
                    _5: deck$1,
                    _6: fareRuleSlug$1
                  },
                  {
                    TAG: "CanCheckoutAllNoCabin",
                    _0: occupancy,
                    _1: cabinClass$1,
                    _2: subclass$1,
                    _3: category$1,
                    _4: longitude,
                    _5: deck$1,
                    _6: fareRuleSlug$1,
                    _7: expiration,
                    _8: sessionState
                  }
                ];
              } else {
                exit = 1;
              }
            } else {
              exit = 1;
            }
            if (exit === 1) {
              tmp = Belt_Array.keepMap([
                    {
                      TAG: "CanSelectCategory",
                      _0: occupancy,
                      _1: cabinClass$1,
                      _2: subclass$1
                    },
                    {
                      TAG: "CanSelectLocation",
                      _0: occupancy,
                      _1: cabinClass$1,
                      _2: subclass$1,
                      _3: category$1
                    },
                    {
                      TAG: "CanSelectDeck",
                      _0: occupancy,
                      _1: cabinClass$1,
                      _2: subclass$1,
                      _3: category$1,
                      _4: longitude
                    },
                    {
                      TAG: "CanEnterPaxDataAllNoCabin",
                      _0: occupancy,
                      _1: cabinClass$1,
                      _2: subclass$1,
                      _3: category$1,
                      _4: longitude,
                      _5: deck$1,
                      _6: fareRuleSlug$1
                    }
                  ], (function (state) {
                      if (typeof state !== "object" || !(state.TAG === "CanEnterPaxDataAllNoCabin" && cabinHold !== undefined && DateFns$Util.hasExpired(CS_Make$Util.Dateable.Plain.toDate(Caml_option.valFromOption(cabinHold))))) {
                        return state;
                      }
                      
                    }));
            }
            
          } else {
            tmp = [];
          }
        } else {
          tmp = fareRuleSlug !== undefined ? [] : [
              {
                TAG: "CanSelectCategory",
                _0: occupancy,
                _1: cabinClass$1,
                _2: subclass$1
              },
              {
                TAG: "CanSelectLocation",
                _0: occupancy,
                _1: cabinClass$1,
                _2: subclass$1,
                _3: category$1
              },
              {
                TAG: "CanSelectDeck",
                _0: occupancy,
                _1: cabinClass$1,
                _2: subclass$1,
                _3: category$1,
                _4: longitude
              }
            ];
        }
      } else {
        tmp = deck !== undefined ? [] : (
            fareRuleSlug !== undefined ? [] : [
                {
                  TAG: "CanSelectCategory",
                  _0: occupancy,
                  _1: cabinClass$1,
                  _2: subclass$1
                },
                {
                  TAG: "CanSelectLocation",
                  _0: occupancy,
                  _1: cabinClass$1,
                  _2: subclass$1,
                  _3: category$1
                }
              ]
          );
      }
    } else {
      tmp = [{
          TAG: "CanSelectCategory",
          _0: occupancy,
          _1: cabinClass$1,
          _2: subclass$1
        }];
    }
  } else {
    tmp = [];
  }
  return Belt_Array.concat(Belt_Array.concat(initState, [{
                    TAG: "CanSelectSubclass",
                    _0: occupancy,
                    _1: cabinClass$1
                  }]), tmp);
}

function getNavTargetFromState$1(state) {
  if (typeof state !== "object") {
    return "ClassAndSubclassSelection";
  }
  switch (state.TAG) {
    case "CanSelectCategory" :
        return "CategorySelection";
    case "CanSelectLocation" :
        return "LocationSelection";
    case "CanSelectDeck" :
        return "DeckSelection";
    case "CanEnterPaxDataAllNoCabin" :
        return "EnterPassengerDetails";
    case "CanCheckoutAllNoCabin" :
        return "Checkout";
    default:
      return "ClassAndSubclassSelection";
  }
}

function validNavTargets$1(navStates) {
  return Belt_Set.fromArray(Belt_Array.map(navStates, (function (state) {
                    return getNavTargetFromState$1(state);
                  })), NavTargetCmp);
}

function readyForNavState$1(arr) {
  return Belt_Option.getWithDefault(Belt_Array.get(arr, arr.length - 1 | 0), "Initial");
}

function navOptions$1(urlState, sessionState) {
  return validNavTargets$1(validNavStates$1(urlState, sessionState));
}

function canNavigate$1(target, urlState, sessionState) {
  return Belt_Set.has(navOptions$1(urlState, sessionState), target);
}

function navUrl$1(target, urlState, sessionData, url) {
  return generateNavUrl(target, navOptions$1(urlState, sessionData), urlState, url);
}

function setOcc$1(urlState, occupancy) {
  return clearCabinHold(setOccupancy("AllNoCabin", urlState, occupancy));
}

function setClass$1(urlState, cabinClass) {
  return clearCabinHold(setCabinClass("AllNoCabin", urlState, cabinClass));
}

function setSub$1(urlState, cabinSubclass) {
  return clearCabinHold(setCabinSubclass("AllNoCabin", urlState, cabinSubclass));
}

function setCat$1(urlState, cabinCategory) {
  return clearCabinHold(setCabinCategory("AllNoCabin", urlState, cabinCategory));
}

function setLoc(urlState, longitude) {
  return clearCabinHold(setCabinLocation("AllNoCabin", urlState, longitude));
}

function setDeck(urlState, deck) {
  return clearCabinHold(setCabinDeck("AllNoCabin", urlState, deck));
}

function setFare$1(urlState, fareClassSlug) {
  return clearCabinHold(setFareClassSlug("AllNoCabin", urlState, fareClassSlug));
}

function setRequestTime$1(urlState, requestTime) {
  return setHoldRequestTime("AllNoCabin", urlState, requestTime);
}

var CabinTarget$1 = {
  setOcc: setOcc$1,
  setClass: setClass$1,
  setSub: setSub$1,
  setCat: setCat$1,
  setLoc: setLoc,
  setDeck: setDeck,
  setFare: setFare$1,
  setRequestTime: setRequestTime$1
};

function stepIsComplete$1(target, states) {
  switch (target) {
    case "Class" :
        return Belt_Array.some(states, (function (state) {
                      if (typeof state !== "object" || state.TAG !== "CanSelectSubclass") {
                        return false;
                      } else {
                        return true;
                      }
                    }));
    case "Subclass" :
        return Belt_Array.some(states, (function (state) {
                      if (typeof state !== "object" || state.TAG !== "CanSelectCategory") {
                        return false;
                      } else {
                        return true;
                      }
                    }));
    case "Category" :
        return Belt_Array.some(states, (function (state) {
                      if (typeof state !== "object" || state.TAG !== "CanSelectLocation") {
                        return false;
                      } else {
                        return true;
                      }
                    }));
    case "Location" :
        return Belt_Array.some(states, (function (state) {
                      if (typeof state !== "object" || state.TAG !== "CanSelectDeck") {
                        return false;
                      } else {
                        return true;
                      }
                    }));
    case "Deck" :
        return Belt_Array.some(states, (function (state) {
                      if (typeof state !== "object" || state.TAG !== "CanEnterPaxDataAllNoCabin") {
                        return false;
                      } else {
                        return true;
                      }
                    }));
    case "Cabin" :
        return false;
    
  }
}

function isCurrentStep$1(target, states) {
  var currentStep = Belt_Array.get(states, states.length - 1 | 0);
  switch (target) {
    case "Class" :
        return Caml_obj.equal(currentStep, "Initial");
    case "Subclass" :
        if (currentStep !== undefined && !(typeof currentStep !== "object" || currentStep.TAG !== "CanSelectSubclass")) {
          return true;
        } else {
          return false;
        }
    case "Category" :
        if (currentStep !== undefined && !(typeof currentStep !== "object" || currentStep.TAG !== "CanSelectCategory")) {
          return true;
        } else {
          return false;
        }
    case "Location" :
        if (currentStep !== undefined && !(typeof currentStep !== "object" || currentStep.TAG !== "CanSelectLocation")) {
          return true;
        } else {
          return false;
        }
    case "Deck" :
        if (currentStep !== undefined && !(typeof currentStep !== "object" || currentStep.TAG !== "CanSelectDeck")) {
          return true;
        } else {
          return false;
        }
    case "Cabin" :
        return false;
    
  }
}

var ByAllNoCabin = {
  targetingCriteria: targetingCriteria$1,
  targetingSteppers: targetingSteppers$1,
  stepToSteppers: stepToSteppers$1,
  nextNavTarget: nextNavTarget$1,
  validNavStates: validNavStates$1,
  getNavTargetFromState: getNavTargetFromState$1,
  validNavTargets: validNavTargets$1,
  readyForNavState: readyForNavState$1,
  navOptions: navOptions$1,
  canNavigate: canNavigate$1,
  navUrl: navUrl$1,
  CabinTarget: CabinTarget$1,
  stepIsComplete: stepIsComplete$1,
  isCurrentStep: isCurrentStep$1
};

var targetingCriteria$2 = [
  "ClassAndSubclassSelection",
  "CategorySelection",
  "LocationSelection",
  "DeckSelection",
  "CabinSelection"
];

var targetingSteppers$2 = [
  {
    title: "Class",
    nav: "ClassAndSubclassSelection",
    params: [
      "Class",
      "Subclass"
    ]
  },
  {
    title: "Category",
    nav: "CategorySelection",
    params: ["Category"]
  },
  {
    title: "Location",
    nav: "LocationSelection",
    params: ["Location"]
  },
  {
    title: "Deck",
    nav: "DeckSelection",
    params: ["Deck"]
  },
  {
    title: "Cabin",
    nav: "CabinSelection",
    params: ["Cabin"]
  }
];

function stepToSteppers$2(step) {
  if (step !== 1) {
    return ;
  } else {
    return targetingSteppers$2;
  }
}

function nextNavTarget$2(currentTarget) {
  switch (currentTarget) {
    case "ClassAndSubclassSelection" :
        return "CategorySelection";
    case "CategorySelection" :
        return "LocationSelection";
    case "LocationSelection" :
        return "DeckSelection";
    case "DeckSelection" :
        return "CabinSelection";
    case "CabinSelection" :
        return "EnterPassengerDetails";
    case "EnterPassengerDetails" :
        return "Checkout";
    case "Checkout" :
        return "ClassAndSubclassSelection";
    
  }
}

function validNavStates$2(urlState, sessionState) {
  var cabinHold = urlState.cabinHold;
  var cabinTargetingByAll = urlState.cabinTargetingByAll;
  var initState = ["Initial"];
  var cabinClass = cabinTargetingByAll[1];
  var occupancy = cabinTargetingByAll[0];
  if (cabinClass === undefined) {
    return initState;
  }
  var fareRuleSlug = cabinTargetingByAll[7];
  var cabin = cabinTargetingByAll[6];
  var deck = cabinTargetingByAll[5];
  var longitude = cabinTargetingByAll[4];
  var category = cabinTargetingByAll[3];
  var subclass = cabinTargetingByAll[2];
  var cabinClass$1 = Caml_option.valFromOption(cabinClass);
  var match = Belt_MapInt.size(sessionState.paxes);
  var match$1 = Belt_Option.isSome(sessionState.bc);
  var tmp;
  if (subclass !== undefined) {
    var subclass$1 = Caml_option.valFromOption(subclass);
    if (category !== undefined) {
      var category$1 = Caml_option.valFromOption(category);
      if (longitude !== undefined) {
        if (deck !== undefined) {
          var deck$1 = Caml_option.valFromOption(deck);
          if (cabin !== undefined) {
            if (fareRuleSlug !== undefined) {
              var fareRuleSlug$1 = Caml_option.valFromOption(fareRuleSlug);
              var cabin$1 = Caml_option.valFromOption(cabin);
              var exit = 0;
              if (match$1 && cabinHold !== undefined) {
                var expiration = Caml_option.valFromOption(cabinHold);
                if (match >= occupancy && !DateFns$Util.hasExpired(CS_Make$Util.Dateable.Plain.toDate(expiration))) {
                  tmp = [
                    {
                      TAG: "CanSelectCategory",
                      _0: occupancy,
                      _1: cabinClass$1,
                      _2: subclass$1
                    },
                    {
                      TAG: "CanSelectLocation",
                      _0: occupancy,
                      _1: cabinClass$1,
                      _2: subclass$1,
                      _3: category$1
                    },
                    {
                      TAG: "CanSelectDeck",
                      _0: occupancy,
                      _1: cabinClass$1,
                      _2: subclass$1,
                      _3: category$1,
                      _4: longitude
                    },
                    {
                      TAG: "CanSelectCabin",
                      _0: occupancy,
                      _1: cabinClass$1,
                      _2: subclass$1,
                      _3: category$1,
                      _4: longitude,
                      _5: deck$1
                    },
                    {
                      TAG: "CanEnterPaxDataAll",
                      _0: occupancy,
                      _1: cabinClass$1,
                      _2: subclass$1,
                      _3: category$1,
                      _4: longitude,
                      _5: deck$1,
                      _6: cabin$1,
                      _7: fareRuleSlug$1
                    },
                    {
                      TAG: "CanCheckoutAll",
                      _0: occupancy,
                      _1: cabinClass$1,
                      _2: subclass$1,
                      _3: category$1,
                      _4: longitude,
                      _5: deck$1,
                      _6: cabin$1,
                      _7: fareRuleSlug$1,
                      _8: expiration,
                      _9: sessionState
                    }
                  ];
                } else {
                  exit = 1;
                }
              } else {
                exit = 1;
              }
              if (exit === 1) {
                tmp = Belt_Array.keepMap([
                      {
                        TAG: "CanSelectCategory",
                        _0: occupancy,
                        _1: cabinClass$1,
                        _2: subclass$1
                      },
                      {
                        TAG: "CanSelectLocation",
                        _0: occupancy,
                        _1: cabinClass$1,
                        _2: subclass$1,
                        _3: category$1
                      },
                      {
                        TAG: "CanSelectDeck",
                        _0: occupancy,
                        _1: cabinClass$1,
                        _2: subclass$1,
                        _3: category$1,
                        _4: longitude
                      },
                      {
                        TAG: "CanSelectCabin",
                        _0: occupancy,
                        _1: cabinClass$1,
                        _2: subclass$1,
                        _3: category$1,
                        _4: longitude,
                        _5: deck$1
                      },
                      {
                        TAG: "CanEnterPaxDataAll",
                        _0: occupancy,
                        _1: cabinClass$1,
                        _2: subclass$1,
                        _3: category$1,
                        _4: longitude,
                        _5: deck$1,
                        _6: cabin$1,
                        _7: fareRuleSlug$1
                      }
                    ], (function (state) {
                        if (typeof state !== "object" || !(state.TAG === "CanEnterPaxDataAll" && cabinHold !== undefined && DateFns$Util.hasExpired(CS_Make$Util.Dateable.Plain.toDate(Caml_option.valFromOption(cabinHold))))) {
                          return state;
                        }
                        
                      }));
              }
              
            } else {
              tmp = [];
            }
          } else {
            tmp = fareRuleSlug !== undefined ? [] : [
                {
                  TAG: "CanSelectCategory",
                  _0: occupancy,
                  _1: cabinClass$1,
                  _2: subclass$1
                },
                {
                  TAG: "CanSelectLocation",
                  _0: occupancy,
                  _1: cabinClass$1,
                  _2: subclass$1,
                  _3: category$1
                },
                {
                  TAG: "CanSelectDeck",
                  _0: occupancy,
                  _1: cabinClass$1,
                  _2: subclass$1,
                  _3: category$1,
                  _4: longitude
                },
                {
                  TAG: "CanSelectCabin",
                  _0: occupancy,
                  _1: cabinClass$1,
                  _2: subclass$1,
                  _3: category$1,
                  _4: longitude,
                  _5: deck$1
                }
              ];
          }
        } else {
          tmp = cabin !== undefined ? [] : (
              fareRuleSlug !== undefined ? [] : [
                  {
                    TAG: "CanSelectCategory",
                    _0: occupancy,
                    _1: cabinClass$1,
                    _2: subclass$1
                  },
                  {
                    TAG: "CanSelectLocation",
                    _0: occupancy,
                    _1: cabinClass$1,
                    _2: subclass$1,
                    _3: category$1
                  },
                  {
                    TAG: "CanSelectDeck",
                    _0: occupancy,
                    _1: cabinClass$1,
                    _2: subclass$1,
                    _3: category$1,
                    _4: longitude
                  }
                ]
            );
        }
      } else {
        tmp = deck !== undefined ? [] : (
            cabin !== undefined ? [] : (
                fareRuleSlug !== undefined ? [] : [
                    {
                      TAG: "CanSelectCategory",
                      _0: occupancy,
                      _1: cabinClass$1,
                      _2: subclass$1
                    },
                    {
                      TAG: "CanSelectLocation",
                      _0: occupancy,
                      _1: cabinClass$1,
                      _2: subclass$1,
                      _3: category$1
                    }
                  ]
              )
          );
      }
    } else {
      tmp = longitude !== undefined ? [] : (
          deck !== undefined ? [] : (
              cabin !== undefined ? [] : (
                  fareRuleSlug !== undefined ? [] : [{
                        TAG: "CanSelectCategory",
                        _0: occupancy,
                        _1: cabinClass$1,
                        _2: subclass$1
                      }]
                )
            )
        );
    }
  } else {
    tmp = [];
  }
  return Belt_Array.concat(Belt_Array.concat(initState, [{
                    TAG: "CanSelectSubclass",
                    _0: occupancy,
                    _1: cabinClass$1
                  }]), tmp);
}

function getNavTargetFromState$2(state) {
  if (typeof state !== "object") {
    return "ClassAndSubclassSelection";
  }
  switch (state.TAG) {
    case "CanSelectCategory" :
        return "CategorySelection";
    case "CanSelectLocation" :
        return "LocationSelection";
    case "CanSelectDeck" :
        return "DeckSelection";
    case "CanSelectCabin" :
        return "CabinSelection";
    case "CanEnterPaxDataAll" :
        return "EnterPassengerDetails";
    case "CanCheckoutAll" :
        return "Checkout";
    default:
      return "ClassAndSubclassSelection";
  }
}

function validNavTargets$2(navStates) {
  return Belt_Set.fromArray(Belt_Array.map(navStates, (function (state) {
                    return getNavTargetFromState$2(state);
                  })), NavTargetCmp);
}

function readyForNavState$2(arr) {
  return Belt_Option.getWithDefault(Belt_Array.get(arr, arr.length - 1 | 0), "Initial");
}

function navOptions$2(urlState, sessionState) {
  return validNavTargets$2(validNavStates$2(urlState, sessionState));
}

function canNavigate$2(target, urlState, sessionState) {
  return Belt_Set.has(navOptions$2(urlState, sessionState), target);
}

function navUrl$2(target, urlState, sessionData, url) {
  return generateNavUrl(target, navOptions$2(urlState, sessionData), urlState, url);
}

function setOcc$2(urlState, occupancy) {
  return clearCabinHold(setOccupancy("All", urlState, occupancy));
}

function setClass$2(urlState, cabinClass) {
  return clearCabinHold(setCabinClass("All", urlState, cabinClass));
}

function setSub$2(urlState, cabinSubclass) {
  return clearCabinHold(setCabinSubclass("All", urlState, cabinSubclass));
}

function setCat$2(urlState, cabinCategory) {
  return clearCabinHold(setCabinCategory("All", urlState, cabinCategory));
}

function setLoc$1(urlState, longitude) {
  return clearCabinHold(setCabinLocation("All", urlState, longitude));
}

function setDeck$1(urlState, deck) {
  return clearCabinHold(setCabinDeck("All", urlState, deck));
}

function setCabin(urlState, cabin) {
  return clearCabinHold(setCabinNumber("All", urlState, cabin));
}

function setFare$2(urlState, fareClassSlug) {
  return clearCabinHold(setFareClassSlug("All", urlState, fareClassSlug));
}

function setRequestTime$2(urlState, requestTime) {
  return setHoldRequestTime("All", urlState, requestTime);
}

var CabinTarget$2 = {
  setOcc: setOcc$2,
  setClass: setClass$2,
  setSub: setSub$2,
  setCat: setCat$2,
  setLoc: setLoc$1,
  setDeck: setDeck$1,
  setCabin: setCabin,
  setFare: setFare$2,
  setRequestTime: setRequestTime$2
};

function stepIsComplete$2(target, states) {
  switch (target) {
    case "Class" :
        return Belt_Array.some(states, (function (state) {
                      if (typeof state !== "object" || state.TAG !== "CanSelectSubclass") {
                        return false;
                      } else {
                        return true;
                      }
                    }));
    case "Subclass" :
        return Belt_Array.some(states, (function (state) {
                      if (typeof state !== "object" || state.TAG !== "CanSelectCategory") {
                        return false;
                      } else {
                        return true;
                      }
                    }));
    case "Category" :
        return Belt_Array.some(states, (function (state) {
                      if (typeof state !== "object" || state.TAG !== "CanSelectLocation") {
                        return false;
                      } else {
                        return true;
                      }
                    }));
    case "Location" :
        return Belt_Array.some(states, (function (state) {
                      if (typeof state !== "object" || state.TAG !== "CanSelectDeck") {
                        return false;
                      } else {
                        return true;
                      }
                    }));
    case "Deck" :
        return Belt_Array.some(states, (function (state) {
                      if (typeof state !== "object" || state.TAG !== "CanSelectCabin") {
                        return false;
                      } else {
                        return true;
                      }
                    }));
    case "Cabin" :
        return Belt_Array.some(states, (function (state) {
                      if (typeof state !== "object" || state.TAG !== "CanEnterPaxDataAll") {
                        return false;
                      } else {
                        return true;
                      }
                    }));
    
  }
}

function isCurrentStep$2(target, states) {
  var currentStep = Belt_Array.get(states, states.length - 1 | 0);
  switch (target) {
    case "Class" :
        return Caml_obj.equal(currentStep, "Initial");
    case "Subclass" :
        if (currentStep !== undefined && !(typeof currentStep !== "object" || currentStep.TAG !== "CanSelectSubclass")) {
          return true;
        } else {
          return false;
        }
    case "Category" :
        if (currentStep !== undefined && !(typeof currentStep !== "object" || currentStep.TAG !== "CanSelectCategory")) {
          return true;
        } else {
          return false;
        }
    case "Location" :
        if (currentStep !== undefined && !(typeof currentStep !== "object" || currentStep.TAG !== "CanSelectLocation")) {
          return true;
        } else {
          return false;
        }
    case "Deck" :
        if (currentStep !== undefined && !(typeof currentStep !== "object" || currentStep.TAG !== "CanSelectDeck")) {
          return true;
        } else {
          return false;
        }
    case "Cabin" :
        if (currentStep !== undefined && !(typeof currentStep !== "object" || currentStep.TAG !== "CanSelectCabin")) {
          return true;
        } else {
          return false;
        }
    
  }
}

var ByAll = {
  targetingCriteria: targetingCriteria$2,
  targetingSteppers: targetingSteppers$2,
  stepToSteppers: stepToSteppers$2,
  nextNavTarget: nextNavTarget$2,
  validNavStates: validNavStates$2,
  getNavTargetFromState: getNavTargetFromState$2,
  validNavTargets: validNavTargets$2,
  readyForNavState: readyForNavState$2,
  navOptions: navOptions$2,
  canNavigate: canNavigate$2,
  navUrl: navUrl$2,
  CabinTarget: CabinTarget$2,
  stepIsComplete: stepIsComplete$2,
  isCurrentStep: isCurrentStep$2
};

function urlString(searchStr, url) {
  var match = searchStr.trim().length;
  if (match !== 0) {
    return url + "?" + searchStr;
  } else {
    return url;
  }
}

function navigate(searchStr, url) {
  RescriptReactRouter.push(urlString(searchStr, url));
}

function goTo(urlState, navTarget, url) {
  var urlData = setNavTarget(urlState, navTarget);
  navigate(salesFlowURLCodec.encode(urlData), url);
}

function cabinTypeSelect(urlData, slug, flow) {
  var match;
  switch (flow) {
    case "ByCategory" :
        match = [
          setClass,
          setSub,
          setCat
        ];
        break;
    case "AllNoCabin" :
        match = [
          setClass$1,
          setSub$1,
          setCat$1
        ];
        break;
    case "All" :
        match = [
          setClass$2,
          setSub$2,
          setCat$2
        ];
        break;
    
  }
  switch (slug.TAG) {
    case "Class" :
        return match[0](urlData, slug._0);
    case "Subclass" :
        return match[1](urlData, Caml_option.some(slug._0));
    case "Category" :
        return match[2](urlData, slug._0);
    
  }
}

function sessionDataToSaveSessionInput(occupancy, voyageSlug, sessionData, isPif) {
  var bc = sessionData.bc;
  var paxArray = Belt_MapInt.toArray(sessionData.paxes);
  var controlledPax = Belt_Array.keepMap(paxArray, (function (param) {
          var pax = param[1];
          if (typeof pax !== "object") {
            return ;
          }
          switch (pax.TAG) {
            case "BC" :
            case "Connected" :
                break;
            default:
              return ;
          }
          return {
                  idx: param[0],
                  accountId: pax._0.id
                };
        }));
  var _attachControlledPax = controlledPax.length !== 0 ? Belt_Array.map(controlledPax, (function (v) {
            return v;
          })) : undefined;
  var controlledPaxWithTravelDoc = Belt_Array.keepMap(paxArray, (function (param) {
          var pax = param[1];
          var idx = param[0];
          if (typeof pax !== "object") {
            return ;
          }
          switch (pax.TAG) {
            case "BCIncomplete" :
                var match = pax._0;
                return {
                        idx: idx,
                        accountId: Caml_option.some(match.accountId),
                        docFirstname: Caml_option.some(match.iDocFirstName),
                        docLastname: Caml_option.some(match.iDocLastName),
                        docGender: match.iDocGender,
                        docCitizenship: Caml_option.some(match.iDocCitizenship),
                        docBirthdate: Caml_option.some(match.iDocBirthdate)
                      };
            case "ConnectedIncomplete" :
                var match$1 = pax._0;
                return {
                        idx: idx,
                        accountId: Caml_option.some(match$1.accountId),
                        docFirstname: Caml_option.some(match$1.iDocFirstName),
                        docLastname: Caml_option.some(match$1.iDocLastName),
                        docGender: match$1.iDocGender,
                        docCitizenship: Caml_option.some(match$1.iDocCitizenship),
                        docBirthdate: Caml_option.some(match$1.iDocBirthdate)
                      };
            default:
              return ;
          }
        }));
  var _attachControlledPaxWithTravelDoc = controlledPaxWithTravelDoc.length !== 0 ? Belt_Array.map(controlledPaxWithTravelDoc, (function (v) {
            return v;
          })) : undefined;
  var loyaltyPax = Belt_Array.keepMap(paxArray, (function (param) {
          var pax = param[1];
          if (typeof pax !== "object") {
            return ;
          }
          if (pax.TAG !== "Loyalty") {
            return ;
          }
          var match = pax._0;
          return {
                  idx: param[0],
                  accountId: match.acctId,
                  loyaltyNumber: match.number
                };
        }));
  var _attachLoyaltyPax = loyaltyPax.length !== 0 ? Belt_Array.map(loyaltyPax, (function (v) {
            return v;
          })) : undefined;
  var invitedPax = Belt_Array.keepMap(paxArray, (function (param) {
          var pax = param[1];
          if (typeof pax !== "object") {
            return ;
          }
          if (pax.TAG !== "Invite") {
            return ;
          }
          var match = pax._0;
          return {
                  idx: param[0],
                  invitationEmail: match.email,
                  preferredFirstName: match.firstName,
                  preferredLastName: match.lastName
                };
        }));
  var _attachInvitedPax = invitedPax.length !== 0 ? Belt_Array.map(invitedPax, (function (v) {
            return v;
          })) : undefined;
  var newConnectedPax = Belt_Array.keepMap(paxArray, (function (param) {
          var pax = param[1];
          if (bc === undefined) {
            return ;
          }
          if (typeof bc !== "object") {
            return ;
          }
          if (typeof pax !== "object") {
            return ;
          }
          if (pax.TAG !== "Provide") {
            return ;
          }
          var match = pax._0;
          return {
                  idx: param[0],
                  custodianAccountId: bc._0,
                  docFirstname: match.docFirstName,
                  docLastname: match.docLastName,
                  docGender: match.docGender,
                  docCitizenship: match.docCitizenship,
                  docBirthdate: match.docBirthdate,
                  preferredFirstName: match.preferredFirstName,
                  preferredLastName: match.preferredLastName,
                  phone: match.phone,
                  postalCode: match.postalCode,
                  residenceCountry: match.residenceCountry
                };
        }));
  var _attachNewConnectedPax = newConnectedPax.length !== 0 ? Belt_Array.map(newConnectedPax, (function (v) {
            return v;
          })) : undefined;
  return {
          _voyageSlug: voyageSlug,
          _forOccupancy: occupancy,
          _pifIntention: isPif,
          _attachControlledPax: _attachControlledPax,
          _attachControlledPaxWithTravelDoc: _attachControlledPaxWithTravelDoc,
          _attachLoyaltyPax: _attachLoyaltyPax,
          _attachInvitedPax: _attachInvitedPax,
          _attachNewConnectedPax: _attachNewConnectedPax
        };
}

function paymentOptionToInt(p) {
  return CS_Decimal$Util.Decimal10_2.toInt(p._0.amt);
}

exports.fieldToString = fieldToString;
exports.validOccupancies = validOccupancies;
exports.genderSchema = genderSchema;
exports.payOptSchema = payOptSchema;
exports.paxSchema = paxSchema;
exports.bcSchema = bcSchema;
exports.arrayToIntMapSchema = arrayToIntMapSchema;
exports.sessionStorageSchema = sessionStorageSchema;
exports.sessionStorageEmpty = sessionStorageEmpty;
exports.decodeSessionStorage = decodeSessionStorage;
exports.encodeSessionStorage = encodeSessionStorage;
exports.empty = empty;
exports.navTargetCodec = navTargetCodec;
exports.cabinTargetingByCategoryCodec = cabinTargetingByCategoryCodec;
exports.longitudeCodec = longitudeCodec;
exports.cabinTargetingAllNoCabinCodec = cabinTargetingAllNoCabinCodec;
exports.cabinTargetingAllCodec = cabinTargetingAllCodec;
exports.uuid = uuid;
exports.salesFlowURLCodec = salesFlowURLCodec;
exports.URLState = URLState;
exports.SessionState = SessionState;
exports.NavTargetCmp = NavTargetCmp;
exports.Alter = Alter;
exports.generateNavUrl = generateNavUrl;
exports.ByCategory = ByCategory;
exports.ByAllNoCabin = ByAllNoCabin;
exports.ByAll = ByAll;
exports.urlString = urlString;
exports.navigate = navigate;
exports.goTo = goTo;
exports.cabinTypeSelect = cabinTypeSelect;
exports.sessionDataToSaveSessionInput = sessionDataToSaveSessionInput;
exports.paymentOptionToInt = paymentOptionToInt;
/* genderSchema Not a pure module */
