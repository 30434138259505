// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Txt$Thin = require("../../../text/Txt.bs.js");
var SysCtx$Thin = require("../../../utils/SysCtx.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var VerifySubmit_VerifyEmailWithTokenMutation_graphql$Thin = require("../../../__generated__/VerifySubmit_VerifyEmailWithTokenMutation_graphql.bs.js");

var convertVariables = VerifySubmit_VerifyEmailWithTokenMutation_graphql$Thin.Internal.convertVariables;

var convertResponse = VerifySubmit_VerifyEmailWithTokenMutation_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = VerifySubmit_VerifyEmailWithTokenMutation_graphql$Thin.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, VerifySubmit_VerifyEmailWithTokenMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, VerifySubmit_VerifyEmailWithTokenMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var VerifyEmailWithTokenMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(shouldRedirect) {
  var match = use();
  var mutate = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setError = match$1[1];
  var match$2 = React.useState(function () {
        return false;
      });
  var setVerified = match$2[1];
  var verify = function (allegedToken) {
    setError(function (param) {
          
        });
    mutate({
          input: {
            allegedToken: allegedToken
          }
        }, undefined, undefined, undefined, (function (resp, err) {
            if (err === undefined) {
              var match = resp.verifyEmailWithToken;
              if (match !== undefined) {
                var match$1 = match.boolean;
                if (match$1 !== undefined && match$1) {
                  setVerified(function (param) {
                        return true;
                      });
                  if (shouldRedirect) {
                    setTimeout((function () {
                            RescriptReactRouter.push(SysCtx$Thin.vars.accountLink);
                          }), 3000);
                    return ;
                  } else {
                    return ;
                  }
                }
                
              }
              setError(function (param) {
                    return Txt$Thin.Verify.verifyError.value;
                  });
              return ;
            }
            setError(function (param) {
                  return Txt$Thin.Verify.verifyError.value;
                });
          }), (function (param) {
            setError(function (param) {
                  return Txt$Thin.$$Error.genericFormSubmit.value;
                });
          }), undefined);
  };
  return {
          error: match$1[0],
          verified: match$2[0],
          verify: verify
        };
}

exports.VerifyEmailWithTokenMutation = VerifyEmailWithTokenMutation;
exports.useMutation = useMutation;
/* commitMutation Not a pure module */
