// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Txt$Thin = require("../../../text/Txt.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../../../utils/Common.bs.js");
var SysCtx$Thin = require("../../../utils/SysCtx.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var ChangePasswordSubmit_ChangePasswordMutation_graphql$Thin = require("../../../__generated__/ChangePasswordSubmit_ChangePasswordMutation_graphql.bs.js");

var convertVariables = ChangePasswordSubmit_ChangePasswordMutation_graphql$Thin.Internal.convertVariables;

var convertResponse = ChangePasswordSubmit_ChangePasswordMutation_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = ChangePasswordSubmit_ChangePasswordMutation_graphql$Thin.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, ChangePasswordSubmit_ChangePasswordMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, ChangePasswordSubmit_ChangePasswordMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var ChangePasswordMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation() {
  var match = use();
  var mutate = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var currentPassword = match$1[0];
  var match$2 = React.useState(function () {
        return "";
      });
  var newPassword = match$2[0];
  var match$3 = React.useState(function () {
        return "";
      });
  var match$4 = React.useState(function () {
        
      });
  var setError = match$4[1];
  var match$5 = React.useState(function () {
        return false;
      });
  var setSuccess = match$5[1];
  var onSubmit = function (e) {
    e.preventDefault();
    setError(function (param) {
          
        });
    mutate({
          input: {
            allegedCurrentPass: currentPassword,
            newPass: newPassword
          }
        }, undefined, undefined, (function (store, resp) {
            var match = resp.changePassword.boolean;
            if (match !== undefined && match) {
              store.invalidateStore();
              setTimeout((function () {
                      RescriptReactRouter.push(SysCtx$Thin.vars.loginLink + "?" + SysCtx$Thin.vars.loginRedirectParam + "=" + SysCtx$Thin.vars.accountLink);
                    }), 3000);
              return ;
            }
            
          }), (function (resp, err) {
            if (err === undefined) {
              var match = resp.changePassword.boolean;
              if (match !== undefined && match) {
                Common$Thin.cookieLogout(Caml_option.some((function () {
                            setSuccess(function (param) {
                                  return true;
                                });
                            setTimeout((function () {
                                    RescriptReactRouter.push(SysCtx$Thin.vars.loginLink + "?" + SysCtx$Thin.vars.loginRedirectParam + "=" + SysCtx$Thin.vars.accountLink);
                                  }), 3000);
                          })), undefined, undefined);
                return ;
              }
              setError(function (param) {
                    return Txt$Thin.Account.passwordModalError.value;
                  });
              return ;
            }
            setError(function (param) {
                  return Txt$Thin.Account.passwordModalError.value;
                });
          }), (function (param) {
            setError(function (param) {
                  return Txt$Thin.Account.heading.value;
                });
          }), undefined);
  };
  return {
          isMutating: match[1],
          currentPassword: currentPassword,
          setCurrentPassword: match$1[1],
          newPassword: newPassword,
          setNewPassword: match$2[1],
          confirmPassword: match$3[0],
          setConfirmPassword: match$3[1],
          error: match$4[0],
          setError: setError,
          success: match$5[0],
          onSubmit: onSubmit
        };
}

exports.ChangePasswordMutation = ChangePasswordMutation;
exports.useMutation = useMutation;
/* commitMutation Not a pure module */
