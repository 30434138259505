// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thin = require("../Dialog.bs.js");
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var ReactState$Util = require("util/src/ReactState.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var CircularProgress = require("@mui/joy/CircularProgress").default;
var BookingPmtByPublicPmtId$Thin = require("../../queries/BookingPmtByPublicPmtId.bs.js");
var InfoOutlined = require("@mui/icons-material/InfoOutlined").default;
var CheckCircleOutline = require("@mui/icons-material/CheckCircleOutline").default;

function MakeAPaymentModal(props) {
  var text = props.text;
  var onPaymentSuccess = props.onPaymentSuccess;
  var onFinish = props.onFinish;
  var onClose = props.onClose;
  var match = React.useState(function () {
        return "NotAsked";
      });
  var failureTitle = text.failureTitle;
  var setModalState = match[1];
  var modalState = match[0];
  var paymentState = ReactState$Util.getter(props.paymentReturn);
  var onPaySuccess = function (bookingId) {
    onPaymentSuccess(bookingId);
    setModalState(function (param) {
          return {
                  TAG: "FetchBookingSuccess",
                  _0: bookingId
                };
        });
  };
  var onPayFailure = function (err) {
    setModalState(function (param) {
          return {
                  TAG: "FetchBookingFailure",
                  _0: err
                };
        });
  };
  var onClick = function (param) {
    if (typeof modalState !== "object") {
      return onClose();
    }
    if (modalState.TAG !== "FetchBookingSuccess") {
      return onClose();
    }
    onFinish(modalState._0);
    setModalState(function (param) {
          return "NotAsked";
        });
    onClose();
  };
  React.useEffect((function () {
          if (typeof paymentState !== "object") {
            if (paymentState === "NotAsked") {
              setModalState(function (param) {
                    return "NotAsked";
                  });
            } else {
              setModalState(function (param) {
                    return "PaymentLoading";
                  });
            }
          } else if (paymentState.TAG === "Success") {
            var match = paymentState._0;
            var paymentId = match.pmt_id;
            var err = match.err_msg;
            if (err !== undefined) {
              setModalState(function (param) {
                    return {
                            TAG: "PaymentFailure",
                            _0: "An error occurred processing your payment: " + err
                          };
                  });
            } else if (paymentId !== undefined) {
              setModalState(function (param) {
                    return "PaymentSuccessAndFetchBooking";
                  });
              BookingPmtByPublicPmtId$Thin.fetchBooking(Caml_option.valFromOption(paymentId), onPaySuccess, onPayFailure);
            } else {
              setModalState(function (param) {
                    return {
                            TAG: "PaymentFailure",
                            _0: "An error occurred processing your payment. Please try again!"
                          };
                  });
            }
          } else {
            var f = paymentState._0;
            var exit = 0;
            switch (f.TAG) {
              case "StringError" :
                  exit = 1;
                  break;
              case "BraintreeHostedFieldsError" :
                  var displayableString = f._0.displayableString;
                  setModalState(function (param) {
                        return {
                                TAG: "PaymentFailure",
                                _0: displayableString
                              };
                      });
                  break;
              case "PlaidError" :
                  var str = f._0;
                  if (typeof str !== "object") {
                    exit = 1;
                  } else {
                    var str$1 = str._0;
                    setModalState(function (param) {
                          return {
                                  TAG: "PaymentFailure",
                                  _0: str$1
                                };
                        });
                  }
                  break;
              
            }
            if (exit === 1) {
              setModalState(function (param) {
                    return {
                            TAG: "PaymentFailure",
                            _0: "An error occurred processing your payment. Please try again."
                          };
                  });
            }
            
          }
        }), [paymentState]);
  var tmp;
  var exit = 0;
  if (typeof modalState !== "object") {
    tmp = JsxRuntime.jsx(CircularProgress, {
          size: "lg",
          variant: "solid"
        });
  } else {
    switch (modalState.TAG) {
      case "PaymentFailure" :
      case "FetchBookingFailure" :
          exit = 1;
          break;
      case "FetchBookingSuccess" :
          tmp = JsxRuntime.jsx(CheckCircleOutline, {
                color: "success",
                sx: {
                  width: "100%",
                  height: "100%"
                }
              });
          break;
      
    }
  }
  if (exit === 1) {
    tmp = JsxRuntime.jsx(InfoOutlined, {
          color: "danger",
          sx: {
            width: "100%",
            height: "100%"
          }
        });
  }
  var tmp$1;
  if (typeof modalState !== "object") {
    tmp$1 = text.loadingTitle;
  } else {
    switch (modalState.TAG) {
      case "PaymentFailure" :
      case "FetchBookingFailure" :
          tmp$1 = failureTitle;
          break;
      case "FetchBookingSuccess" :
          tmp$1 = text.successTitle;
          break;
      
    }
  }
  var tmp$2;
  if (typeof modalState !== "object") {
    tmp$2 = text.loadingDesc;
  } else {
    switch (modalState.TAG) {
      case "PaymentFailure" :
      case "FetchBookingFailure" :
          tmp$2 = modalState._0;
          break;
      case "FetchBookingSuccess" :
          tmp$2 = text.successDesc;
          break;
      
    }
  }
  var tmp$3;
  if (typeof modalState !== "object") {
    switch (modalState) {
      case "PaymentLoading" :
      case "PaymentSuccessAndFetchBooking" :
          tmp$3 = true;
          break;
      default:
        tmp$3 = false;
    }
  } else {
    tmp$3 = false;
  }
  var tmp$4;
  tmp$4 = typeof modalState !== "object" || modalState.TAG !== "FetchBookingSuccess" ? "Close" : text.successBtnText;
  return JsxRuntime.jsxs(Dialog$Thin.make, {
              open_: props.open_,
              title: "Processing payment",
              onClose: onClose,
              children: [
                JsxRuntime.jsx(DialogContent, {
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                direction: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                spacing: 1,
                                sx: {
                                  p: {
                                    xs: 1,
                                    md: 4
                                  }
                                },
                                children: [
                                  JsxRuntime.jsx(Stack, {
                                        sx: {
                                          width: "62px",
                                          height: "62px"
                                        },
                                        children: Caml_option.some(tmp)
                                      }),
                                  JsxRuntime.jsx(Typography, {
                                        level: "h3",
                                        component: "h2",
                                        sx: {
                                          textAlign: "center"
                                        },
                                        children: Caml_option.some(tmp$1)
                                      }),
                                  JsxRuntime.jsx(Typography, {
                                        level: "body-sm",
                                        component: "p",
                                        sx: {
                                          textAlign: "center"
                                        },
                                        children: Caml_option.some(tmp$2)
                                      })
                                ]
                              }))
                    }),
                JsxRuntime.jsx(DialogActions, {
                      children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                direction: "row",
                                justifyContent: "center",
                                sx: {
                                  pb: 1,
                                  width: "100%"
                                },
                                children: Caml_option.some(JsxRuntime.jsx(Button, {
                                          onClick: onClick,
                                          size: "lg",
                                          disabled: tmp$3,
                                          sx: {
                                            borderRadius: "6px"
                                          },
                                          children: Caml_option.some(tmp$4)
                                        }))
                              }))
                    })
              ]
            });
}

var make = MakeAPaymentModal;

exports.make = make;
/* react Not a pure module */
