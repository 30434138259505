// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Option = require("rescript/lib/js/belt_Option.js");
var JsxRuntime = require("react/jsx-runtime");
var ResendTransfer$Thin = require("./mutations/ResendTransfer.bs.js");
var ConfirmActionDialog$Thin = require("../common/molecules/ConfirmActionDialog.bs.js");

function CAResendInvitationForm(props) {
  var onClose = props.onClose;
  var match = ResendTransfer$Thin.useMutation(props.accountId, props.refetch, onClose);
  return JsxRuntime.jsx(ConfirmActionDialog$Thin.make, {
              open_: props.open_,
              onClose: onClose,
              action: "Resend invitation",
              actionSlug: "resend-invitation",
              description: "You are about to resend your invitation to " + Belt_Option.getWithDefault(props.inviteeEmail, "another user") + " to claim the account \"" + props.fullName + ".\" Please confirm below.",
              onConfirm: match.onSubmit,
              confirmText: "Resend invitation",
              onCancel: onClose,
              cancelText: "Cancel",
              error: match.error,
              loading: match.isMutating
            });
}

var make = CAResendInvitationForm;

exports.make = make;
/* react/jsx-runtime Not a pure module */
