// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


var Clipboard = {};

var $$History = {};

var $$Window = {
  Clipboard: Clipboard,
  $$History: $$History
};

var addEventListener = ((event, handler) => window.addEventListener(event, handler));

var removeEventListener = ((event, handler) => window.removeEventListener(event, handler));

exports.$$Window = $$Window;
exports.addEventListener = addEventListener;
exports.removeEventListener = removeEventListener;
/* No side effect */
