// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var InvitePass$Thin = require("./InvitePass.bs.js");
var AddByLoyalty$Thin = require("./AddByLoyalty.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var AddPassDetails$Thin = require("./AddPassDetails.bs.js");
var DialogContent = require("@mui/joy/DialogContent").default;
var AddManagedAccount$Thin = require("./AddManagedAccount.bs.js");
var Check = require("@mui/icons-material/Check").default;

function AddPassDesktop$ActionCard(props) {
  var pax1MustBeBornBy = props.pax1MustBeBornBy;
  var isPrimaryPax = props.isPrimaryPax;
  var voyageSlug = props.voyageSlug;
  var addOrErr = props.addOrErr;
  var loading = props.loading;
  var idx = props.idx;
  var action = props.action;
  var setPrefLName = props.setPrefLName;
  var prefLName = props.prefLName;
  var setPrefFName = props.setPrefFName;
  var prefFName = props.prefFName;
  var onClick = props.onClick;
  var isSelected = props.isSelected;
  var tab = props.tab;
  var disabled = tab === "managed" && props.shouldDisableManaged || tab === "invite" && isPrimaryPax;
  return JsxRuntime.jsxs(Sheet, {
              sx: {
                border: "1px solid",
                borderColor: isSelected ? "neutral.400" : "divider",
                borderRadius: "8px",
                p: 2,
                cursor: disabled ? "not-allowed" : "pointer",
                opacity: disabled ? "0.8" : "1"
              },
              children: [
                JsxRuntime.jsx(Stack, {
                      direction: "column",
                      spacing: 2,
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                direction: "column",
                                spacing: 1,
                                sx: {
                                  cursor: disabled ? "not-allowed" : "pointer"
                                },
                                onClick: (function (param) {
                                    if (disabled) {
                                      return ;
                                    } else {
                                      return onClick();
                                    }
                                  }),
                                children: [
                                  JsxRuntime.jsxs(Stack, {
                                        direction: "row",
                                        justifyContent: "space-between",
                                        alignItems: "flex-start",
                                        children: [
                                          JsxRuntime.jsx(Typography, {
                                                level: "body-md",
                                                fontWeight: "bold",
                                                children: Caml_option.some(props.title)
                                              }),
                                          JsxRuntime.jsx(Check, {
                                                fontSize: "2rem",
                                                sx: {
                                                  color: "background.surface",
                                                  padding: "2px",
                                                  border: isSelected ? "0px solid" : "1px solid",
                                                  borderColor: isSelected ? "transparent" : "neutral.400",
                                                  borderRadius: "100%",
                                                  backgroundColor: isSelected ? "primary.500" : "background.surface"
                                                }
                                              })
                                        ]
                                      }),
                                  JsxRuntime.jsx(Typography, {
                                        level: "body-md",
                                        sx: {
                                          color: "text.tertiary"
                                        },
                                        children: Caml_option.some(props.description)
                                      })
                                ]
                              }))
                    }),
                JsxRuntime.jsx(Stack, {
                      sx: {
                        maxHeight: isSelected ? "100%" : "0px",
                        overflowY: isSelected ? "auto" : "hidden"
                      },
                      children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                sx: {
                                  pt: isSelected ? 2 : 0
                                },
                                children: Caml_option.some(tab === "loyalty" ? JsxRuntime.jsx(AddByLoyalty$Thin.make, {
                                            loyaltyPatch: props.loyaltyPatch,
                                            setLoyaltyPatch: props.setLoyaltyPatch,
                                            loyaltyNum: props.loyaltyNum,
                                            setLoyaltyNum: props.setLoyaltyNum,
                                            searchErrorType: props.searchErrorType,
                                            setSearchErrorType: props.setSearchErrorType,
                                            idx: idx,
                                            loading: loading,
                                            addOrErr: addOrErr,
                                            action: action,
                                            voyageSlug: voyageSlug,
                                            isPrimaryPax: isPrimaryPax,
                                            pax1MustBeBornBy: pax1MustBeBornBy
                                          }) : (
                                        tab === "managed" ? JsxRuntime.jsx(AddManagedAccount$Thin.make, {
                                                connectedAcct: props.connectedAcct,
                                                setConnectedAcct: props.setConnectedAcct,
                                                idx: idx,
                                                loading: loading,
                                                addOrErr: addOrErr,
                                                action: action,
                                                voyageSlug: voyageSlug,
                                                isPrimaryPax: isPrimaryPax,
                                                managedOptions: props.managedOptions
                                              }) : (
                                            tab === "invite" ? JsxRuntime.jsx(InvitePass$Thin.make, {
                                                    prefFName: prefFName,
                                                    setPrefFName: setPrefFName,
                                                    prefLName: prefLName,
                                                    setPrefLName: setPrefLName,
                                                    email: props.email,
                                                    setEmail: props.setEmail,
                                                    action: action,
                                                    idx: idx,
                                                    loading: loading,
                                                    addOrErr: addOrErr,
                                                    disabled: disabled,
                                                    forceValidate: true
                                                  }) : (
                                                tab === "details" ? JsxRuntime.jsx(AddPassDetails$Thin.make, {
                                                        docFName: props.docFName,
                                                        setDocFName: props.setDocFName,
                                                        docLName: props.docLName,
                                                        setDocLName: props.setDocLName,
                                                        prefFName: prefFName,
                                                        setPrefFName: setPrefFName,
                                                        prefLName: prefLName,
                                                        setPrefLName: setPrefLName,
                                                        dob: props.dob,
                                                        setDob: props.setDob,
                                                        country: props.country,
                                                        setCountry: props.setCountry,
                                                        gender: props.gender,
                                                        setGender: props.setGender,
                                                        countryFrag: props.queryFrag,
                                                        action: action,
                                                        idx: idx,
                                                        onClose: props.onClose,
                                                        custodianAccountId: props.custodianAccountId,
                                                        loading: loading,
                                                        addOrErr: addOrErr,
                                                        isPrimaryPax: isPrimaryPax,
                                                        pax1MustBeBornBy: pax1MustBeBornBy,
                                                        birthdateShouldError: props.birthdateShouldError,
                                                        forceValidate: true
                                                      }) : null
                                              )
                                          )
                                      ))
                              }))
                    })
              ]
            });
}

var ActionCard = {
  make: AddPassDesktop$ActionCard
};

function AddPassDesktop(props) {
  var queryFrag = props.queryFrag;
  var birthdateShouldError = props.birthdateShouldError;
  var shouldDisableManaged = props.shouldDisableManaged;
  var managedOptions = props.managedOptions;
  var pax1MustBeBornBy = props.pax1MustBeBornBy;
  var isPrimaryPax = props.isPrimaryPax;
  var setSearchErrorType = props.setSearchErrorType;
  var searchErrorType = props.searchErrorType;
  var setLoyaltyNum = props.setLoyaltyNum;
  var loyaltyNum = props.loyaltyNum;
  var setLoyaltyPatch = props.setLoyaltyPatch;
  var loyaltyPatch = props.loyaltyPatch;
  var voyageSlug = props.voyageSlug;
  var addOrErr = props.addOrErr;
  var loading = props.loading;
  var custodianAccountId = props.custodianAccountId;
  var idx = props.idx;
  var action = props.action;
  var setConnectedAcct = props.setConnectedAcct;
  var connectedAcct = props.connectedAcct;
  var setGender = props.setGender;
  var gender = props.gender;
  var setCountry = props.setCountry;
  var country = props.country;
  var setDob = props.setDob;
  var dob = props.dob;
  var setEmail = props.setEmail;
  var email = props.email;
  var setPrefLName = props.setPrefLName;
  var prefLName = props.prefLName;
  var setPrefFName = props.setPrefFName;
  var prefFName = props.prefFName;
  var setDocLName = props.setDocLName;
  var docLName = props.docLName;
  var setDocFName = props.setDocFName;
  var docFName = props.docFName;
  var onClose = props.onClose;
  var clearAll = props.clearAll;
  var getDescription = props.getDescription;
  var getTitle = props.getTitle;
  var setTab = props.setTab;
  var tab = props.tab;
  return JsxRuntime.jsx(DialogContent, {
              children: Caml_option.some(JsxRuntime.jsx(Stack, {
                        direction: "column",
                        spacing: 2,
                        sx: {
                          p: 4
                        },
                        children: Caml_option.some(Belt_Array.mapWithIndex([
                                  "details",
                                  "invite",
                                  "managed",
                                  "loyalty"
                                ], (function (i, opt) {
                                    return JsxRuntime.jsx(AddPassDesktop$ActionCard, {
                                                title: getTitle(opt),
                                                tab: opt,
                                                description: getDescription(opt),
                                                isSelected: tab === opt,
                                                onClick: (function () {
                                                    if (tab === opt) {
                                                      setTab(function (param) {
                                                            return "all";
                                                          });
                                                      return clearAll();
                                                    } else {
                                                      setTab(function (param) {
                                                            return opt;
                                                          });
                                                      return clearAll();
                                                    }
                                                  }),
                                                docFName: docFName,
                                                setDocFName: setDocFName,
                                                docLName: docLName,
                                                setDocLName: setDocLName,
                                                prefFName: prefFName,
                                                setPrefFName: setPrefFName,
                                                prefLName: prefLName,
                                                setPrefLName: setPrefLName,
                                                email: email,
                                                setEmail: setEmail,
                                                dob: dob,
                                                setDob: setDob,
                                                country: country,
                                                setCountry: setCountry,
                                                gender: gender,
                                                setGender: setGender,
                                                connectedAcct: connectedAcct,
                                                setConnectedAcct: setConnectedAcct,
                                                action: action,
                                                idx: idx,
                                                onClose: onClose,
                                                custodianAccountId: custodianAccountId,
                                                loading: loading,
                                                addOrErr: addOrErr,
                                                voyageSlug: voyageSlug,
                                                loyaltyPatch: loyaltyPatch,
                                                setLoyaltyPatch: setLoyaltyPatch,
                                                loyaltyNum: loyaltyNum,
                                                setLoyaltyNum: setLoyaltyNum,
                                                searchErrorType: searchErrorType,
                                                setSearchErrorType: setSearchErrorType,
                                                isPrimaryPax: isPrimaryPax,
                                                pax1MustBeBornBy: pax1MustBeBornBy,
                                                managedOptions: managedOptions,
                                                shouldDisableManaged: shouldDisableManaged,
                                                birthdateShouldError: birthdateShouldError,
                                                queryFrag: queryFrag
                                              }, String(i));
                                  })))
                      }))
            });
}

var make = AddPassDesktop;

exports.ActionCard = ActionCard;
exports.make = make;
/* @mui/joy/Sheet Not a pure module */
