// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var RelayEnv$Thin = require("../RelayEnv.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var ReactState$Util = require("util/src/ReactState.bs.js");
var RemoteData$Util = require("util/src/RemoteData.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var ImageSet_query_graphql$Thin = require("../__generated__/ImageSet_query_graphql.bs.js");
var ImageSetDerefQuery_graphql$Thin = require("../__generated__/ImageSetDerefQuery_graphql.bs.js");
var ImageSet_Prefetch_query_graphql$Thin = require("../__generated__/ImageSet_Prefetch_query_graphql.bs.js");

var convertFragment = ImageSet_query_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(ImageSet_query_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, ImageSet_query_graphql$Thin.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(ImageSet_query_graphql$Thin.node, convertFragment, fRef);
}

var GetSetMembers = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

var convertFragment$1 = ImageSet_Prefetch_query_graphql$Thin.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(ImageSet_Prefetch_query_graphql$Thin.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, ImageSet_Prefetch_query_graphql$Thin.node, convertFragment$1);
}

function readResolverFragment$1(fRef) {
  return RescriptRelay_Fragment.read(ImageSet_Prefetch_query_graphql$Thin.node, convertFragment$1, fRef);
}

var PrefetchQuery = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  use: use$1,
  useOpt: useOpt$1,
  readResolverFragment: readResolverFragment$1
};

var convertVariables = ImageSetDerefQuery_graphql$Thin.Internal.convertVariables;

var convertResponse = ImageSetDerefQuery_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = ImageSetDerefQuery_graphql$Thin.Internal.convertWrapRawResponse;

var use$2 = RescriptRelay_Query.useQuery(convertVariables, ImageSetDerefQuery_graphql$Thin.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, ImageSetDerefQuery_graphql$Thin.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(ImageSetDerefQuery_graphql$Thin.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(ImageSetDerefQuery_graphql$Thin.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(ImageSetDerefQuery_graphql$Thin.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(ImageSetDerefQuery_graphql$Thin.node, convertVariables);

var DerefImageSet = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use$2,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function cfImageUrl(cfImageId, variant) {
  return "https://imagedelivery.net/HkBHq-fC4pDIE3veJo7Q6Q/" + cfImageId + "/" + variant;
}

function use$3(fragmentRefs) {
  var data = useOpt(fragmentRefs);
  return Belt_Array.map(Belt_Option.mapWithDefault(data, [], (function (param) {
                    return param.setMembers.nodes;
                  })), (function (param) {
                var cfImageId = param.cfImageId;
                return {
                        altText: param.altText,
                        captionText: param.captionText,
                        cfImageId: cfImageId,
                        imageIdx: param.imageIdx,
                        getSrc: (function (extra) {
                            return cfImageUrl(cfImageId, extra);
                          })
                      };
              }));
}

var ImageSet = {
  use: use$3
};

function useImageSet(imageSetPrefetch) {
  var is = useOpt$1(imageSetPrefetch);
  var nodes = Belt_Option.mapWithDefault(Belt_Option.flatMap(is, (function (v) {
              return v.imageSets;
            })), [], (function (v) {
          return v.nodes;
        }));
  var map = Belt_MapString.fromArray(Belt_Array.map(nodes, (function (obj) {
              return [
                      CS_NonemptyStrings$Util.ImageSetSlug.toString(obj.imageSetSlug),
                      Belt_Array.map(obj.setMembers.nodes, (function (param) {
                              var cfImageId = param.cfImageId;
                              return {
                                      altText: param.altText,
                                      captionText: param.captionText,
                                      cfImageId: cfImageId,
                                      imageIdx: param.imageIdx,
                                      getSrc: (function (extra) {
                                          return cfImageUrl(cfImageId, extra);
                                        })
                                    };
                            }))
                    ];
            })));
  return function (imageSetSlug) {
    var local = React.useState(function () {
          return "NotAsked";
        });
    var found = Belt_Option.mapWithDefault(imageSetSlug, [], (function (s) {
            return Belt_Option.getWithDefault(Belt_MapString.get(map, CS_NonemptyStrings$Util.ImageSetSlug.toString(s)), []);
          }));
    var doFetch = Belt_Option.flatMap(Belt_Array.get(nodes, 0), (function (param) {
            if (found.length !== 0 || imageSetSlug === undefined) {
              return ;
            } else {
              return RemoteData$Util.Relay.fetchQueryStatefully($$fetch, local, (function (v) {
                            return v;
                          }), (function (param) {
                            return "Error Loading Image Set";
                          }), RelayEnv$Thin.environment, {
                          brandFamilySlug: param.brandFamilySlug,
                          imageSetSlug: Caml_option.valFromOption(imageSetSlug),
                          orgSlug: param.orgSlug
                        }, undefined, undefined);
            }
          }));
    React.useEffect((function () {
            Belt_Option.mapWithDefault(doFetch, undefined, (function (doFetch) {
                    doFetch();
                  }));
          }), [Belt_Option.isSome(doFetch)]);
    var fetched = use$3(Belt_Option.map(Belt_Option.flatMap(RemoteData$Util.toOption(ReactState$Util.getter(local)), (function (v) {
                    return v.imageSet;
                  })), (function (v) {
                return v.fragmentRefs;
              })));
    if (Belt_Option.isSome(doFetch)) {
      return fetched;
    } else {
      return found;
    }
  };
}

var context = React.createContext({
      useImageSet: (function (param) {
          return [];
        })
    });

var make = context.Provider;

var Provider = {
  make: make
};

var Inner = {
  context: context,
  Provider: Provider
};

function ImageSet$Context$Provider(props) {
  var value = {
    useImageSet: useImageSet(props.imageSetPrefetch)
  };
  return JsxRuntime.jsx(make, {
              value: value,
              children: props.children
            });
}

var Provider$1 = {
  make: ImageSet$Context$Provider
};

var Context = {
  Inner: Inner,
  context: context,
  Provider: Provider$1
};

exports.GetSetMembers = GetSetMembers;
exports.PrefetchQuery = PrefetchQuery;
exports.DerefImageSet = DerefImageSet;
exports.cfImageUrl = cfImageUrl;
exports.ImageSet = ImageSet;
exports.useImageSet = useImageSet;
exports.Context = Context;
/* use Not a pure module */
