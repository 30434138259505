"use strict";

var parser;
var legalHosts = {
  "paypal.com": 1,
  "braintreepayments.com": 1,
  "braintreegateway.com": 1,
  "braintree-api.com": 1,
};

function stripSubdomains(domain) {
  return domain.split(".").slice(-2).join(".");
}

function isVerifiedDomain(url) {
  var mainDomain;

  url = url.toLowerCase();

  if (!/^https:/.test(url)) {
    return false;
  }

  parser = parser || document.createElement("a");
  parser.href = url;
  mainDomain = stripSubdomains(parser.hostname);

  return legalHosts.hasOwnProperty(mainDomain);
}

module.exports = isVerifiedDomain;
