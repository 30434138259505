// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Http$Util = require("util/src/Http.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Core__Null = require("@rescript/core/src/Core__Null.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_SetString = require("rescript/lib/js/belt_SetString.js");
var ReactState$Util = require("util/src/ReactState.bs.js");
var RemoteData$Util = require("util/src/RemoteData.bs.js");
var S$RescriptSchema = require("rescript-schema/src/S.bs.js");
var ReactPlaidLink = require("react-plaid-link");
var Locutus$slashphp$slashstrings$slash = require("locutus/php/strings/");
var CS_UnverifiedJWT$Util = require("util/src/CustomScalars/CS_UnverifiedJWT.bs.js");
var Processor_Shared$Thin = require("./Processor_Shared.bs.js");
var Processor_PaymentIntent$Thin = require("./Processor_PaymentIntent.bs.js");
var Processor_TokenizationIntent$Thin = require("./Processor_TokenizationIntent.bs.js");

var linkTokenStruct = Processor_Shared$Thin.PlaidDebit.linkTokenStruct;

var plaidDebitInit = Processor_Shared$Thin.PlaidDebit.plaidDebitInit;

var supportedAccountSubtypes = Belt_SetString.fromArray([
      "checking",
      "savings"
    ]);

var supportedAccountType = "depository";

function subtypeNiceName(prim) {
  return Locutus$slashphp$slashstrings$slash.ucwords(prim);
}

function useOpenPlaidStatefully(plaidLinkState, plaidReceivedRedirectUri, accountSelectionState, linkToken) {
  var onLinkSuccess = function (param, linkSession) {
    var match = Belt_Array.keepMap(linkSession.accounts, (function (account) {
            if (account.type === supportedAccountType && Belt_SetString.has(supportedAccountSubtypes, account.subtype)) {
              return account;
            }
            
          }));
    var rv;
    if (match.length !== 1) {
      rv = {
        TAG: "Failure",
        _0: {
          TAG: "PlaidString",
          _0: "An error occurred while accessing your account data: we require exactly one account to be selected."
        }
      };
    } else {
      var oneAccount = match[0];
      rv = {
        TAG: "Success",
        _0: {
          publicToken: linkSession.public_token,
          publicTokenExpirationMillis: Date.now() + 30 * 60 * 1000,
          selectedAccountId: oneAccount.id,
          accountNiceName: linkSession.institution.name + " " + Locutus$slashphp$slashstrings$slash.ucwords(oneAccount.subtype)
        }
      };
    }
    console.log(rv);
    ReactState$Util.setter(accountSelectionState)(function (param) {
          return rv;
        });
  };
  var match = ReactPlaidLink.usePlaidLink({
        token: Core__Null.fromOption(linkToken),
        onSuccess: onLinkSuccess,
        plaidReceivedRedirectUri: plaidReceivedRedirectUri,
        onExit: (function (param, param$1) {
            ReactState$Util.setter(accountSelectionState)(function (param) {
                  return {
                          TAG: "Failure",
                          _0: "UserClosedTheDialog"
                        };
                });
          })
      });
  var ready = match.ready;
  var openPlaid = match.open;
  React.useEffect((function () {
          ReactState$Util.setter(plaidLinkState)(function (param) {
                if (ready) {
                  return {
                          TAG: "Success",
                          _0: openPlaid
                        };
                } else {
                  return "Loading";
                }
              });
        }), [ready]);
}

var Bindings = {
  supportedAccountSubtypes: supportedAccountSubtypes,
  supportedAccountType: supportedAccountType,
  subtypeNiceName: subtypeNiceName,
  useOpenPlaidStatefully: useOpenPlaidStatefully
};

function getLinkTokenEffect(endpointUrl, tokenizationIntent, state) {
  return Http$Util.statefulJsonRequest(state, endpointUrl + "/plaid/link_token", "POST", undefined, [[
                "authorization",
                "Bearer " + CS_UnverifiedJWT$Util.UnverifiedJWT.toPrimitive(tokenizationIntent)
              ]], linkTokenStruct);
}

function getRunPlaidDebitEffect(endpointUrl, paymentIntent, debitInit) {
  return Http$Util.jsonRequest(endpointUrl + "/" + S$RescriptSchema.parseOrRaiseWith(CS_UnverifiedJWT$Util.UnverifiedJWT.meta(paymentIntent).body, Processor_Shared$Thin.paymentIntentMethod), "POST", [
              debitInit,
              plaidDebitInit
            ], [[
                "authorization",
                "Bearer " + CS_UnverifiedJWT$Util.UnverifiedJWT.toPrimitive(paymentIntent)
              ]], Processor_Shared$Thin.paymentReturn);
}

var RestAPI = {
  getLinkTokenEffect: getLinkTokenEffect,
  getRunPlaidDebitEffect: getRunPlaidDebitEffect
};

function use(onVoyage, processor, method, processorEndpointUrl, getFee, param) {
  var plaidAccountSelection = param.plaidAccountSelection;
  var plaidLinkToken = param.plaidLinkToken;
  var paymentIntent = param.paymentIntent;
  var tokenizationIntent = param.tokenizationIntent;
  var openPlaidLink = React.useState(function () {
        return "NotAsked";
      });
  var fetchTokenizationIntent = Processor_TokenizationIntent$Thin.useTokenizationIntentMutation(tokenizationIntent);
  useOpenPlaidStatefully(openPlaidLink, param.plaidReceivedRedirectUri, plaidAccountSelection, Belt_Option.map(RemoteData$Util.toOption(ReactState$Util.getter(plaidLinkToken)), (function (param) {
              return param.link_token;
            })));
  var match = ReactState$Util.getter(tokenizationIntent);
  var tmp;
  tmp = typeof match !== "object" ? (
      match === "NotAsked" ? [(function () {
              fetchTokenizationIntent();
            })] : []
    ) : [];
  var match$1 = ReactState$Util.getter(tokenizationIntent);
  var match$2 = ReactState$Util.getter(plaidLinkToken);
  var tmp$1;
  tmp$1 = typeof match$1 !== "object" ? [] : (
      match$1.TAG === "Success" ? (
          typeof match$2 !== "object" ? (
              match$2 === "NotAsked" ? [getLinkTokenEffect(processorEndpointUrl, match$1._0, plaidLinkToken)] : []
            ) : []
        ) : []
    );
  var loadFx = Belt_Array.concatMany([
        tmp,
        tmp$1
      ]);
  React.useEffect(function () {
        Belt_Array.forEach(loadFx, (function (effect) {
                effect();
              }));
      });
  var paymentIntentEffect = Processor_PaymentIntent$Thin.usePaymentIntentMutationEffect(onVoyage, processor, method, undefined);
  var resetForRetry = function () {
    ReactState$Util.setter(paymentIntent)(function (param) {
          return "NotAsked";
        });
    ReactState$Util.setter(plaidAccountSelection)(function (param) {
          return "NotAsked";
        });
  };
  var processPayment = function (param, paymentIntentInvocation) {
    var account_id = param.selectedAccountId;
    var public_token = param.publicToken;
    var match = paymentIntentEffect(paymentIntentInvocation);
    return RemoteData$Util.$$Promise.mapFailure(RemoteData$Util.$$Promise.then(match[1], (function (paymentIntent) {
                      return RemoteData$Util.$$Promise.mapSuccess(getRunPlaidDebitEffect(processorEndpointUrl, paymentIntent, {
                                      public_token: public_token,
                                      account_id: account_id
                                    }), (function (f) {
                                    resetForRetry();
                                    return f;
                                  }));
                    })), (function (str) {
                  return {
                          TAG: "StringError",
                          _0: str
                        };
                }));
  };
  var match$3 = ReactState$Util.getter(tokenizationIntent);
  var match$4 = ReactState$Util.getter(plaidLinkToken);
  var match$5 = ReactState$Util.getter(openPlaidLink);
  var tmp$2;
  var exit = 0;
  var exit$1 = 0;
  if (typeof match$3 !== "object" || match$3.TAG !== "Failure") {
    exit$1 = 2;
  } else {
    tmp$2 = {
      TAG: "Failure",
      _0: match$3._0
    };
  }
  if (exit$1 === 2) {
    if (typeof match$4 !== "object" || match$4.TAG !== "Failure") {
      exit = 1;
    } else {
      tmp$2 = {
        TAG: "Failure",
        _0: match$4._0
      };
    }
  }
  if (exit === 1) {
    tmp$2 = typeof match$5 !== "object" ? (
        match$5 === "NotAsked" ? "NotAsked" : "Loading"
      ) : (
        match$5.TAG === "Success" ? ({
              TAG: "Success",
              _0: match$5._0
            }) : ({
              TAG: "Failure",
              _0: "Could not load the plaid flow"
            })
      );
  }
  return {
          loaded: RemoteData$Util.mapSuccess(tmp$2, (function (openPlaid) {
                  return {
                          processPaymentEffect: RemoteData$Util.toOption(RemoteData$Util.mapBoth(ReactState$Util.getter(plaidAccountSelection), (function (accountSelection) {
                                      return function (state, invocation) {
                                        RemoteData$Util.$$Promise.makeStatefulEffect(processPayment(accountSelection, invocation), state);
                                      };
                                    }), (function (plaidErrorState) {
                                      return {
                                              TAG: "PlaidError",
                                              _0: plaidErrorState
                                            };
                                    }))),
                          accountSelection: ReactState$Util.getter(plaidAccountSelection),
                          openPlaidForAccountSelection: openPlaid
                        };
                })),
          getFee: getFee
        };
}

var selectedAccount = Processor_Shared$Thin.PlaidDebit.selectedAccount;

exports.linkTokenStruct = linkTokenStruct;
exports.selectedAccount = selectedAccount;
exports.plaidDebitInit = plaidDebitInit;
exports.Bindings = Bindings;
exports.RestAPI = RestAPI;
exports.use = use;
/* supportedAccountSubtypes Not a pure module */
