// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thin = require("./Dialog.bs.js");
var AppAlert$Thin = require("../atoms/AppAlert.bs.js");
var Stack = require("@mui/joy/Stack").default;
var AppButton$Thin = require("../atoms/AppButton.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var DialogActions = require("@mui/joy/DialogActions").default;

function ConfirmActionDialog(props) {
  var __disabled = props.disabled;
  var error = props.error;
  var actionSlug = props.actionSlug;
  var disabled = __disabled !== undefined ? __disabled : false;
  return JsxRuntime.jsxs(Dialog$Thin.make, {
              open_: props.open_,
              title: props.action,
              description: Caml_option.some(props.description),
              onClose: props.onClose,
              titleId: actionSlug + "-title",
              titleDescId: actionSlug + "-description",
              children: [
                JsxRuntime.jsx(DialogActions, {
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                direction: {
                                  xs: "column-reverse",
                                  md: "row"
                                },
                                justifyContent: "space-between",
                                spacing: 1,
                                sx: {
                                  width: "100%"
                                },
                                children: [
                                  JsxRuntime.jsx(AppButton$Thin.make, {
                                        onClick: props.onCancel,
                                        variant: "outlined",
                                        color: "neutral",
                                        children: Caml_option.some(props.cancelText)
                                      }),
                                  JsxRuntime.jsx(AppButton$Thin.make, {
                                        loading: props.loading,
                                        type_: "submit",
                                        color: "primary",
                                        disabled: disabled,
                                        children: Caml_option.some(props.confirmText)
                                      })
                                ]
                              })),
                      component: "form",
                      onSubmit: props.onConfirm
                    }),
                error !== undefined ? JsxRuntime.jsx(AppAlert$Thin.make, {
                        message: error,
                        type_: "error"
                      }) : JsxRuntime.jsx(JsxRuntime.Fragment, {})
              ]
            });
}

var make = ConfirmActionDialog;

exports.make = make;
/* Dialog-Thin Not a pure module */
