// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thin = require("./Dialog.bs.js");
var Stack = require("@mui/joy/Stack").default;
var Drawer = require("@mui/joy/Drawer").default;
var Divider = require("@mui/joy/Divider").default;
var JsxRuntime = require("react/jsx-runtime");
var ModalClose = require("@mui/joy/ModalClose").default;
var DialogTitle = require("@mui/joy/DialogTitle").default;

function ModalDrawer(props) {
  var __noGap = props.noGap;
  var __contained = props.contained;
  var __size = props.size;
  var children = props.children;
  var onClose = props.onClose;
  var title = props.title;
  var open_ = props.open_;
  var size = __size !== undefined ? __size : "md";
  var contained = __contained !== undefined ? __contained : true;
  var noGap = __noGap !== undefined ? __noGap : false;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Stack, {
                      sx: {
                        display: {
                          xs: "none",
                          md: "flex"
                        }
                      },
                      children: Caml_option.some(JsxRuntime.jsx(Dialog$Thin.make, {
                                open_: open_,
                                title: title !== undefined ? title : "",
                                size: size,
                                onClose: onClose,
                                children: Caml_option.some(children),
                                contained: contained,
                                noGap: noGap,
                                modalDrawer: true
                              }))
                    }),
                JsxRuntime.jsx(Stack, {
                      sx: {
                        display: {
                          xs: "flex",
                          md: "none"
                        }
                      },
                      children: Caml_option.some(JsxRuntime.jsxs(Drawer, {
                                children: [
                                  JsxRuntime.jsx(ModalClose, {}),
                                  title !== undefined ? JsxRuntime.jsx(DialogTitle, {
                                          children: Caml_option.some(title)
                                        }) : null,
                                  JsxRuntime.jsx(Divider, {}),
                                  children
                                ],
                                open: open_,
                                anchor: "bottom",
                                disablePortal: true,
                                onClose: onClose,
                                size: "md",
                                slotProps: {
                                  backdrop: {
                                    sx: {
                                      backdropFilter: "none",
                                      backgroundColor: "rgba(16, 13, 12, 0.7)",
                                      zIndex: "1300"
                                    }
                                  },
                                  content: {
                                    sx: {
                                      "--Drawer-verticalSize": "clamp(25%, 90%, 90%)",
                                      borderTopRightRadius: "4px",
                                      borderTopLeftRadius: "4px",
                                      ml: "8px",
                                      mr: "8px",
                                      width: "calc(100% - 16px)",
                                      zIndex: "1301"
                                    }
                                  }
                                }
                              }))
                    })
              ]
            });
}

var make = ModalDrawer;

exports.make = make;
/* Dialog-Thin Not a pure module */
