// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thin = require("../common/molecules/Dialog.bs.js");
var AppAlert$Thin = require("../common/atoms/AppAlert.bs.js");
var Stack = require("@mui/joy/Stack").default;
var AppButton$Thin = require("../common/atoms/AppButton.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var FormControl = require("@mui/joy/FormControl").default;
var InviteToTransfer$Thin = require("./mutations/InviteToTransfer.bs.js");
var DialogContent = require("@mui/joy/DialogContent").default;
var SendOutlined = require("@mui/icons-material/SendOutlined").default;

function CATransferForm(props) {
  var onClose = props.onClose;
  var match = InviteToTransfer$Thin.useMutation(props.accountId, props.refetch, onClose);
  var error = match.error;
  var setNewEmail = match.setNewEmail;
  var newEmail = match.newEmail;
  return JsxRuntime.jsx(Dialog$Thin.make, {
              open_: props.open_,
              title: "Transfer Account",
              description: Caml_option.some("You are about to invite someone to take ownership of the account \"" + props.fullName + ".\" Please enter their email address below."),
              onClose: onClose,
              children: Caml_option.some(JsxRuntime.jsxs(DialogContent, {
                        children: [
                          JsxRuntime.jsx(Stack, {
                                direction: "column",
                                component: "form",
                                onSubmit: match.onSubmit,
                                children: Caml_option.some(JsxRuntime.jsx(FormControl, {
                                          error: Belt_Result.isError(CS_Emails$Util.Email.fromPrimitive(newEmail)),
                                          children: Caml_option.some(JsxRuntime.jsx(Joy$Util.Input.make, {
                                                    type_: "email",
                                                    placeholder: "New owner email",
                                                    value: newEmail,
                                                    onChange: (function (e) {
                                                        var val = e.currentTarget.value;
                                                        setNewEmail(function (param) {
                                                              return val;
                                                            });
                                                      }),
                                                    endDecorator: Caml_option.some(JsxRuntime.jsx(AppButton$Thin.make, {
                                                              loading: match.isMutating,
                                                              type_: "submit",
                                                              endDecorator: Caml_option.some(JsxRuntime.jsx(SendOutlined, {
                                                                        fontSize: "sm"
                                                                      })),
                                                              disabled: Belt_Result.isError(CS_Emails$Util.Email.fromPrimitive(newEmail)),
                                                              children: "Send"
                                                            }))
                                                  }))
                                        }))
                              }),
                          error !== undefined ? JsxRuntime.jsx(AppAlert$Thin.make, {
                                  message: error,
                                  type_: "error"
                                }) : JsxRuntime.jsx(JsxRuntime.Fragment, {})
                        ]
                      }))
            });
}

var make = CATransferForm;

exports.make = make;
/* Joy-Util Not a pure module */
