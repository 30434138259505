// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var ReactRelay = require("react-relay");
var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_Decimal$Util = require("util/src/CustomScalars/CS_Decimal.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");

function makeRefetchVariables(bfSlugString, voySlugString) {
  return {
          bfSlugString: bfSlugString,
          voySlugString: voySlugString
        };
}

var Types = {
  makeRefetchVariables: makeRefetchVariables
};

var variablesConverter = {};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, undefined, undefined);
}

var wrapResponseConverter = {"__root":{"currentUserCabinHolds_nodes_voyageSubclassSlug":{"c":"Util.CustomScalars.CabinSubclassSlug.Exn"},"currentUserCabinHolds_nodes_voyageClassSlug":{"c":"Util.CustomScalars.CabinClassSlug.Exn"},"currentUserCabinHolds_nodes_voyageCategorySlug":{"c":"Util.CustomScalars.CabinCategorySlug.Exn"},"currentUserCabinHolds_nodes_quote_quoteLines_lineAmount":{"c":"Util.CustomScalars.UndenominatedCurrency.Exn"},"currentUserCabinHolds_nodes_quote_quoteLines_billingLineDescription":{"c":"Util.CustomScalars.NonemptyString.Exn"},"currentUserCabinHolds_nodes_quote_billingAndPaymentTotals_grossBilled":{"c":"Util.CustomScalars.UndenominatedCurrency.Exn"},"currentUserCabinHolds_nodes_forOccupancy":{"b":""},"currentUserCabinHolds_nodes_fareClassSlug":{"c":"Util.CustomScalars.FareClassSlug.Exn"},"currentUserCabinHolds_nodes_expiresAt":{"c":"Util.CustomScalars.SQLDateTime.Exn"}}};

var wrapResponseConverterMap = {
  "Util.CustomScalars.SQLDateTime.Exn": CS_Make$Util.Dateable.Plain.Exn.serialize,
  "Util.CustomScalars.FareClassSlug.Exn": CS_Slugs$Util.FareClassSlug.Exn.serialize,
  "Util.CustomScalars.UndenominatedCurrency.Exn": CS_Decimal$Util.Decimal10_2.Exn.serialize,
  "Util.CustomScalars.NonemptyString.Exn": CS_NonemptyStrings$Util.NonEmptyString.Exn.serialize,
  "Util.CustomScalars.CabinCategorySlug.Exn": CS_Slugs$Util.CabinCategorySlug.Exn.serialize,
  "Util.CustomScalars.CabinClassSlug.Exn": CS_Slugs$Util.CabinClassSlug.Exn.serialize,
  "Util.CustomScalars.CabinSubclassSlug.Exn": CS_Slugs$Util.CabinSubclassSlug.Exn.serialize
};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, wrapResponseConverterMap, null);
}

var responseConverter = {"__root":{"currentUserCabinHolds_nodes_voyageSubclassSlug":{"c":"Util.CustomScalars.CabinSubclassSlug.Exn"},"currentUserCabinHolds_nodes_voyageClassSlug":{"c":"Util.CustomScalars.CabinClassSlug.Exn"},"currentUserCabinHolds_nodes_voyageCategorySlug":{"c":"Util.CustomScalars.CabinCategorySlug.Exn"},"currentUserCabinHolds_nodes_quote_quoteLines_lineAmount":{"c":"Util.CustomScalars.UndenominatedCurrency.Exn"},"currentUserCabinHolds_nodes_quote_quoteLines_billingLineDescription":{"c":"Util.CustomScalars.NonemptyString.Exn"},"currentUserCabinHolds_nodes_quote_billingAndPaymentTotals_grossBilled":{"c":"Util.CustomScalars.UndenominatedCurrency.Exn"},"currentUserCabinHolds_nodes_forOccupancy":{"b":""},"currentUserCabinHolds_nodes_fareClassSlug":{"c":"Util.CustomScalars.FareClassSlug.Exn"},"currentUserCabinHolds_nodes_expiresAt":{"c":"Util.CustomScalars.SQLDateTime.Exn"}}};

var responseConverterMap = {
  "Util.CustomScalars.SQLDateTime.Exn": CS_Make$Util.Dateable.Plain.Exn.parse,
  "Util.CustomScalars.FareClassSlug.Exn": CS_Slugs$Util.FareClassSlug.Exn.parse,
  "Util.CustomScalars.UndenominatedCurrency.Exn": CS_Decimal$Util.Decimal10_2.Exn.parse,
  "Util.CustomScalars.NonemptyString.Exn": CS_NonemptyStrings$Util.NonEmptyString.Exn.parse,
  "Util.CustomScalars.CabinCategorySlug.Exn": CS_Slugs$Util.CabinCategorySlug.Exn.parse,
  "Util.CustomScalars.CabinClassSlug.Exn": CS_Slugs$Util.CabinClassSlug.Exn.parse,
  "Util.CustomScalars.CabinSubclassSlug.Exn": CS_Slugs$Util.CabinSubclassSlug.Exn.parse
};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, responseConverterMap, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: undefined,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: wrapResponseConverterMap,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: responseConverterMap,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

var Utils = {};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "bfSlugString"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "voySlugString"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "equalTo",
                "variableName": "bfSlugString"
              }
            ],
            "kind": "ObjectValue",
            "name": "brandFamilySlug"
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "equalTo",
                "variableName": "voySlugString"
              }
            ],
            "kind": "ObjectValue",
            "name": "voyageSlug"
          }
        ],
        "kind": "ObjectValue",
        "name": "filter"
      }
    ],
    "concreteType": "CurrentUserCabinHoldsConnection",
    "kind": "LinkedField",
    "name": "currentUserCabinHolds",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CurrentUserCabinHold",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "forOccupancy",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "voyageClassSlug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "voyageSubclassSlug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "voyageCategorySlug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fareClassSlug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "expiresAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Quote",
            "kind": "LinkedField",
            "name": "quote",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "QuoteLine",
                "kind": "LinkedField",
                "name": "quoteLines",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "billingLineDescription",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lineAmount",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BillingAndPaymentTotal",
                "kind": "LinkedField",
                "name": "billingAndPaymentTotals",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "grossBilled",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CurrentUserCabinHoldsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CurrentUserCabinHoldsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a662fd7d21ac854134ba34e91eea8281",
    "id": null,
    "metadata": {},
    "name": "CurrentUserCabinHoldsQuery",
    "operationKind": "query",
    "text": "query CurrentUserCabinHoldsQuery(\n  $bfSlugString: String!\n  $voySlugString: String!\n) {\n  currentUserCabinHolds(filter: {brandFamilySlug: {equalTo: $bfSlugString}, voyageSlug: {equalTo: $voySlugString}}) {\n    nodes {\n      forOccupancy\n      voyageClassSlug\n      voyageSubclassSlug\n      voyageCategorySlug\n      fareClassSlug\n      expiresAt\n      quote {\n        quoteLines {\n          billingLineDescription\n          lineAmount\n        }\n        billingAndPaymentTotals {\n          grossBilled\n        }\n      }\n    }\n  }\n}\n"
  }
};
})());

function load(environment, variables, fetchPolicy, fetchKey, networkCacheConfig) {
  return ReactRelay.loadQuery(environment, node, convertVariables(variables), {
              fetchKey: fetchKey,
              fetchPolicy: fetchPolicy,
              networkCacheConfig: networkCacheConfig
            });
}

function queryRefToObservable(token) {
  return Caml_option.nullable_to_opt(token.source);
}

function queryRefToPromise(token) {
  return new Promise((function (resolve, param) {
                var o = queryRefToObservable(token);
                if (o !== undefined) {
                  Caml_option.valFromOption(o).subscribe({
                        complete: (function () {
                            resolve({
                                  TAG: "Ok",
                                  _0: undefined
                                });
                          })
                      });
                  return ;
                } else {
                  return resolve({
                              TAG: "Error",
                              _0: undefined
                            });
                }
              }));
}

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
exports.load = load;
exports.queryRefToObservable = queryRefToObservable;
exports.queryRefToPromise = queryRefToPromise;
/* node Not a pure module */
