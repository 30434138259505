// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var Check = require("@mui/icons-material/Check").default;

function SelectorCard(props) {
  var __locked = props.locked;
  var subEl = props.subEl;
  var description = props.description;
  var titleAppendix = props.titleAppendix;
  var onClick = props.onClick;
  var isSelected = props.isSelected;
  var locked = __locked !== undefined ? __locked : false;
  return JsxRuntime.jsxs(Sheet, {
              onClick: (function (param) {
                  if (!locked) {
                    return onClick();
                  }
                  
                }),
              sx: {
                border: "1px solid",
                borderColor: isSelected ? "neutral.400" : "divider",
                borderRadius: "8px",
                p: 2,
                cursor: locked ? "not-allowed" : "pointer"
              },
              children: [
                JsxRuntime.jsx(Stack, {
                      direction: "column",
                      spacing: 2,
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                direction: "column",
                                spacing: 1,
                                children: [
                                  JsxRuntime.jsxs(Stack, {
                                        direction: "row",
                                        justifyContent: "space-between",
                                        alignItems: "flex-start",
                                        children: [
                                          JsxRuntime.jsx(Typography, {
                                                endDecorator: Caml_option.some(titleAppendix !== undefined ? Caml_option.valFromOption(titleAppendix) : null),
                                                level: "body-md",
                                                fontWeight: "bold",
                                                children: Caml_option.some(props.title)
                                              }),
                                          JsxRuntime.jsx(Check, {
                                                fontSize: "2rem",
                                                sx: {
                                                  color: "background.surface",
                                                  padding: "2px",
                                                  border: isSelected ? "0px solid" : "1px solid",
                                                  borderColor: isSelected ? "transparent" : "neutral.400",
                                                  borderRadius: "100%",
                                                  backgroundColor: isSelected ? "primary.500" : "background.surface"
                                                }
                                              })
                                        ]
                                      }),
                                  description !== undefined ? JsxRuntime.jsx(Typography, {
                                          level: "body-sm",
                                          sx: {
                                            color: "neutral.500"
                                          },
                                          children: Caml_option.some(description)
                                        }) : null,
                                  subEl !== undefined ? Caml_option.valFromOption(subEl) : null
                                ]
                              }))
                    }),
                props.children
              ]
            });
}

var make = SelectorCard;

exports.make = make;
/* @mui/joy/Sheet Not a pure module */
