// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Txt$Thin = require("../../text/Txt.bs.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Stack = require("@mui/joy/Stack").default;
var AppButton$Thin = require("../common/atoms/AppButton.bs.js");
var ReactState$Util = require("util/src/ReactState.bs.js");
var Divider = require("@mui/joy/Divider").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var UpdateAccountData$Thin = require("../common/mutations/UpdateAccountData.bs.js");

function PersonalAndTravelInformation$Modify(props) {
  var setIsEditing = props.setIsEditing;
  var isEditing = props.isEditing;
  var initial = props.initial;
  var updateUserAccount = props.updateUserAccount;
  var setUpdate = props.setUpdate;
  var update = props.update;
  var __actionText = props.actionText;
  var actionText = __actionText !== undefined ? __actionText : Txt$Thin.Account.modifyProfileButton.value;
  if (!isEditing) {
    return JsxRuntime.jsx(Typography, {
                color: "primary",
                level: "body-sm",
                sx: {
                  cursor: "pointer",
                  textDecoration: "underline"
                },
                children: Caml_option.some(actionText),
                onClick: (function (param) {
                    setIsEditing(function (param) {
                          return true;
                        });
                  })
              });
  }
  var tmp;
  if (update.TAG === "Account") {
    var update$1 = update._0;
    tmp = props.equalityFn(update$1, initial) || Belt_Result.isError(UpdateAccountData$Thin.Utils.convertUpdateToMutationInput(update$1, "User"));
  } else {
    tmp = false;
  }
  return JsxRuntime.jsxs(Stack, {
              direction: "row",
              spacing: 1,
              children: [
                JsxRuntime.jsx(AppButton$Thin.make, {
                      loading: props.isMutating && isEditing,
                      onClick: (function (param) {
                          if (update.TAG !== "Account") {
                            return ;
                          }
                          var input = UpdateAccountData$Thin.Utils.convertUpdateToMutationInput(update._0, "User");
                          if (input.TAG === "Ok") {
                            return updateUserAccount(input._0);
                          }
                          
                        }),
                      color: "primary",
                      disabled: tmp,
                      children: Caml_option.some(Txt$Thin.Account.saveButton.value)
                    }),
                JsxRuntime.jsx(AppButton$Thin.make, {
                      onClick: (function (param) {
                          setIsEditing(function (param) {
                                return false;
                              });
                          setUpdate(function (param) {
                                return {
                                        TAG: "Account",
                                        _0: initial
                                      };
                              });
                        }),
                      variant: "outlined",
                      color: "neutral",
                      children: Caml_option.some(Txt$Thin.Account.cancelButton.value)
                    })
              ]
            });
}

var Modify = {
  make: PersonalAndTravelInformation$Modify
};

function PersonalAndTravelInformation(props) {
  var refetch = props.refetch;
  var countryFrag = props.countryFrag;
  var userAccount = props.userAccount;
  var match = React.useState(function () {
        return false;
      });
  var setIsEditingPersonal = match[1];
  var isEditingPersonal = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setIsEditingTravel = match$1[1];
  var isEditingTravel = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setShowVoyageOptions = match$2[1];
  var initial = UpdateAccountData$Thin.Utils.convertUserAccountToUpdate(userAccount);
  var match$3 = ReactState$Util.useDebouncedState((function () {
          return {
                  TAG: "Account",
                  _0: initial
                };
        }), undefined, undefined);
  var match$4 = match$3.state;
  var setUpdate = match$4[1];
  var update = match$4[0];
  var onSuccess = function (param) {
    setIsEditingPersonal(function (param) {
          return false;
        });
    setIsEditingTravel(function (param) {
          return false;
        });
    setShowVoyageOptions(function (param) {
          return false;
        });
    refetch();
  };
  var match$5 = UpdateAccountData$Thin.useMutation(onSuccess);
  var updateUserAccount = match$5.updateUserAccount;
  var isMutating = match$5.isMutating;
  return JsxRuntime.jsxs(Stack, {
              direction: "column",
              sx: {
                width: "100%"
              },
              children: [
                JsxRuntime.jsxs(Stack, {
                      spacing: 2,
                      sx: {
                        py: 2
                      },
                      children: [
                        JsxRuntime.jsx(UpdateAccountData$Thin.Form.Personal.make, {
                              fragmentRefs: countryFrag,
                              subtitle: "Tell us about yourself.",
                              update: update,
                              setUpdate: setUpdate,
                              disabled: !isEditingPersonal,
                              forceValidate: true
                            }),
                        JsxRuntime.jsx(PersonalAndTravelInformation$Modify, {
                              update: update,
                              setUpdate: setUpdate,
                              updateUserAccount: updateUserAccount,
                              initial: initial,
                              isMutating: isMutating,
                              isEditing: isEditingPersonal,
                              setIsEditing: setIsEditingPersonal,
                              equalityFn: UpdateAccountData$Thin.Utils.arePersonalInfoInputsEqual
                            })
                      ]
                    }),
                JsxRuntime.jsx(Divider, {}),
                JsxRuntime.jsxs(Stack, {
                      spacing: 2,
                      sx: {
                        py: 2
                      },
                      children: [
                        JsxRuntime.jsx(UpdateAccountData$Thin.Form.Travel.make, {
                              fragmentRefs: countryFrag,
                              subtitle: "Information below must exactly match your travel document (e.g. passport). We will address you by your preferred name (above) whenever possible.",
                              update: update,
                              setUpdate: setUpdate,
                              userAccount: userAccount,
                              disabled: !isEditingTravel,
                              forceValidate: true
                            }),
                        JsxRuntime.jsx(UpdateAccountData$Thin.Form.Voyages.make, {
                              fragmentRefs: props.paxRecordFrag,
                              showVoyageOptions: match$2[0],
                              setShowVoyageOptions: setShowVoyageOptions,
                              update: update,
                              setUpdate: setUpdate,
                              display: isEditingTravel
                            }),
                        JsxRuntime.jsx(PersonalAndTravelInformation$Modify, {
                              actionText: "Modify your travel documentation",
                              update: update,
                              setUpdate: setUpdate,
                              updateUserAccount: updateUserAccount,
                              initial: initial,
                              isMutating: isMutating,
                              isEditing: isEditingTravel,
                              setIsEditing: setIsEditingTravel,
                              equalityFn: UpdateAccountData$Thin.Utils.areTravelDocInputsEqual
                            })
                      ]
                    })
              ]
            });
}

var make = PersonalAndTravelInformation;

exports.Modify = Modify;
exports.make = make;
/* react Not a pure module */
