// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../../../utils/Common.bs.js");
var SignUp$Thin = require("../../common/mutations/SignUp.bs.js");
var AppAlert$Thin = require("../../common/atoms/AppAlert.bs.js");
var PaxFrame$Thin = require("./PaxFrame.bs.js");
var Stack = require("@mui/joy/Stack").default;
var AppButton$Thin = require("../../common/atoms/AppButton.bs.js");
var ReactState$Util = require("util/src/ReactState.bs.js");
var LoginSubmit$Thin = require("../../login/mutations/LoginSubmit.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var SalesFlowState$Thin = require("../../../utils/sales-flow/SalesFlowState.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var UpdateAccountData$Thin = require("../../common/mutations/UpdateAccountData.bs.js");
var BCForm_query_graphql$Thin = require("../../../__generated__/BCForm_query_graphql.bs.js");
var RequestCabinHoldByCategory$Thin = require("../mutations/RequestCabinHoldByCategory.bs.js");
var UserSignOnStatusQuery_graphql$Thin = require("../../../__generated__/UserSignOnStatusQuery_graphql.bs.js");

var convertFragment = BCForm_query_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(BCForm_query_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BCForm_query_graphql$Thin.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(BCForm_query_graphql$Thin.node, convertFragment, fRef);
}

var makeRefetchVariables = UserSignOnStatusQuery_graphql$Thin.Types.makeRefetchVariables;

var convertRefetchVariables = UserSignOnStatusQuery_graphql$Thin.Internal.convertVariables;

function useRefetchable(fRef) {
  return RescriptRelay_Fragment.useRefetchableFragment(BCForm_query_graphql$Thin.node, convertFragment, convertRefetchVariables, fRef);
}

var QueryFragment_custSignonStatus_decode = BCForm_query_graphql$Thin.Utils.custSignonStatus_decode;

var QueryFragment_custSignonStatus_fromString = BCForm_query_graphql$Thin.Utils.custSignonStatus_fromString;

var QueryFragment = {
  custSignonStatus_decode: QueryFragment_custSignonStatus_decode,
  custSignonStatus_fromString: QueryFragment_custSignonStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment,
  makeRefetchVariables: makeRefetchVariables,
  convertRefetchVariables: convertRefetchVariables,
  useRefetchable: useRefetchable
};

function BCForm$VerifyEmail(props) {
  var refetchAll = props.refetchAll;
  return JsxRuntime.jsx(Stack, {
              direction: "column",
              justifyContent: "center",
              spacing: 1,
              sx: {
                p: {
                  xs: 2,
                  md: 4
                },
                border: "1px solid",
                borderColor: "neutral.300",
                backgroundColor: "neutral.200",
                borderRadius: "12px"
              },
              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                        direction: "column",
                        alignItems: "center",
                        spacing: 1,
                        sx: {
                          pb: 1
                        },
                        children: [
                          JsxRuntime.jsx(Typography, {
                                level: "h4",
                                children: "Verify Your Email"
                              }),
                          JsxRuntime.jsx(Typography, {
                                level: "body-sm",
                                sx: {
                                  textAlign: "center"
                                },
                                textColor: "neutral.600",
                                children: Caml_option.some("We've sent a verification link to " + Belt_Option.getWithDefault(props.email, "your inbox") + ". Please click the link in the email to verify your account and return to this page.")
                              }),
                          JsxRuntime.jsx(Stack, {
                                sx: {
                                  width: "fit-content",
                                  pt: 3
                                },
                                children: Caml_option.some(JsxRuntime.jsx(AppButton$Thin.make, {
                                          onClick: (function (e) {
                                              e.preventDefault();
                                              refetchAll();
                                            }),
                                          size: "lg",
                                          children: "I did it!"
                                        }))
                              })
                        ]
                      }))
            });
}

var VerifyEmail = {
  make: BCForm$VerifyEmail
};

function BCForm(props) {
  var countryFrag = props.countryFrag;
  var setPageLevelError = props.setPageLevelError;
  var refetchSignOnStatus = props.refetchSignOnStatus;
  var refetchCurrentUserInfo = props.refetchCurrentUserInfo;
  var brandFamily = props.brandFamily;
  var urlState = props.urlState;
  var url = props.url;
  var bc = props.bc;
  var data = use(countryFrag);
  var match = ReactState$Util.useDebouncedState((function () {
          return "";
        }), undefined, undefined);
  var match$1 = match.state;
  var password = match$1[0];
  var match$2 = ReactState$Util.useDebouncedState((function () {
          return "";
        }), undefined, undefined);
  var match$3 = match$2.state;
  var confirmPassword = match$3[0];
  var onCabinHoldSuccess = function (expiry) {
    SalesFlowState$Thin.navigate(SalesFlowState$Thin.URLState.serialize(SalesFlowState$Thin.Alter.setCabinHold(urlState, expiry)), url);
  };
  var match$4 = RequestCabinHoldByCategory$Thin.useMutation(props.flow, props.voyageSlug, setPageLevelError);
  var requestCabinHoldByCategory = match$4.requestCabinHoldByCategory;
  var refetchAll = function () {
    refetchCurrentUserInfo();
    refetchSignOnStatus();
  };
  var match$5 = LoginSubmit$Thin.useMutationOnSignup(brandFamily, refetchAll);
  var setLoginError = match$5.setError;
  var match$6 = SignUp$Thin.useMutation(setPageLevelError, undefined, match$5.loginOnSignUp);
  var signUp = match$6.signUp;
  var setSignUpError = match$6.setError;
  var signUpError = match$6.error;
  var onSubmit = function (param) {
    setLoginError(function (param) {
          
        });
    setSignUpError(function (param) {
          
        });
    if (bc.TAG === "Account") {
      return ;
    }
    var err = UpdateAccountData$Thin.Passenger.updateToSignUpInput(bc._0, brandFamily, password);
    if (err.TAG === "Ok") {
      return signUp(err._0);
    }
    var err$1 = err._0;
    setSignUpError(function (param) {
          return err$1;
        });
  };
  React.useEffect((function () {
          var match = data.currentUserSignonStatus;
          var match$1 = urlState.cabinHold;
          if ((match === "VERIFY_EMAIL" || match === "INVITED" || match === "PENDING_2FA" || match === "CLEAR_JWT" || match === "ANONYMOUS" || match === "SIGNED_ON") && match === "SIGNED_ON") {
            if (match$1 !== undefined) {
              
            } else {
              requestCabinHoldByCategory(urlState, onCabinHoldSuccess);
            }
          }
          
        }), [
        data.currentUserSignonStatus,
        urlState.cabinHold
      ]);
  var intervalRef = React.useRef(null);
  React.useEffect((function () {
          var interval = setInterval((function () {
                  refetchAll();
                }), 10000);
          var match = data.currentUserSignonStatus;
          if ((match === "VERIFY_EMAIL" || match === "INVITED" || match === "PENDING_2FA" || match === "CLEAR_JWT" || match === "ANONYMOUS" || match === "SIGNED_ON") && match === "VERIFY_EMAIL") {
            intervalRef.current = interval;
          }
          return (function () {
                    var id = intervalRef.current;
                    if (!(id == null)) {
                      clearInterval(id);
                      return ;
                    }
                    
                  });
        }), [data.currentUserSignonStatus]);
  React.useEffect((function () {
          var match = data.currentUserSignonStatus;
          var exit = 0;
          if (!((match === "VERIFY_EMAIL" || match === "INVITED" || match === "PENDING_2FA" || match === "CLEAR_JWT" || match === "ANONYMOUS" || match === "SIGNED_ON") && match === "VERIFY_EMAIL")) {
            exit = 1;
          }
          if (exit === 1) {
            Belt_Option.map(Caml_option.nullable_to_opt(intervalRef.current), (function (id) {
                    clearInterval(id);
                  }));
          }
          
        }), [data.currentUserSignonStatus]);
  var match$7 = data.currentUserSignonStatus;
  if ((match$7 === "VERIFY_EMAIL" || match$7 === "INVITED" || match$7 === "PENDING_2FA" || match$7 === "CLEAR_JWT" || match$7 === "ANONYMOUS" || match$7 === "SIGNED_ON") && match$7 === "VERIFY_EMAIL") {
    var tmp;
    tmp = bc.TAG === "Account" ? undefined : bc._0.email;
    return JsxRuntime.jsx(BCForm$VerifyEmail, {
                refetchAll: refetchAll,
                email: tmp
              });
  }
  var match$8 = data.currentUserSignonStatus;
  var tmp$1;
  tmp$1 = (match$8 === "VERIFY_EMAIL" || match$8 === "INVITED" || match$8 === "PENDING_2FA" || match$8 === "CLEAR_JWT" || match$8 === "ANONYMOUS" || match$8 === "SIGNED_ON") && match$8 === "ANONYMOUS" ? JsxRuntime.jsx(Typography, {
          level: "body-sm",
          sx: {
            pb: 2
          },
          textColor: "neutral.600",
          children: "We're creating an account for you. Your information will only be used for reservation and customer service purposes."
        }) : JsxRuntime.jsx(JsxRuntime.Fragment, {});
  var tmp$2;
  tmp$2 = bc.TAG === "Account" ? true : Belt_Result.isError(UpdateAccountData$Thin.Passenger.updateToSignUpInput(bc._0, brandFamily, password)) || !Common$Thin.isPasswordValid(password) || password !== confirmPassword;
  return JsxRuntime.jsxs(PaxFrame$Thin.make, {
              title: "Billing Contact",
              required: true,
              variant: "Grey",
              children: [
                tmp$1,
                JsxRuntime.jsx(UpdateAccountData$Thin.Form.SignUp.make, {
                      fragmentRefs: countryFrag,
                      showTitleSection: false,
                      update: bc,
                      setUpdate: props.setBc,
                      password: password,
                      setPassword: match$1[1],
                      confirmPassword: confirmPassword,
                      setConfirmPassword: match$3[1],
                      onSubmit: onSubmit,
                      signedUp: data.currentUserSignonStatus === "SIGNED_ON",
                      disabled: data.currentUserSignonStatus === "SIGNED_ON",
                      noHelperText: true,
                      loading: match$6.isMutating || match$5.isMutating,
                      signUpError: signUpError,
                      blockSignUp: tmp$2,
                      forceValidate: true
                    }),
                signUpError !== undefined ? JsxRuntime.jsx(AppAlert$Thin.make, {
                        message: signUpError,
                        type_: "error"
                      }) : JsxRuntime.jsx(JsxRuntime.Fragment, {})
              ]
            });
}

var make = BCForm;

exports.QueryFragment = QueryFragment;
exports.VerifyEmail = VerifyEmail;
exports.make = make;
/* react Not a pure module */
