"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.broadcastToChildWindows = void 0;
var _1 = require("./");
function broadcastToChildWindows(payload, origin, source) {
    for (var i = _1.childWindows.length - 1; i >= 0; i--) {
        var childWindow = _1.childWindows[i];
        if (childWindow.closed) {
            _1.childWindows.splice(i, 1);
        }
        else if (source !== childWindow) {
            (0, _1.broadcast)(payload, {
                origin: origin,
                frame: childWindow.top,
            });
        }
    }
}
exports.broadcastToChildWindows = broadcastToChildWindows;
