// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var StandardLayoutQuery_graphql$Thin = require("./StandardLayoutQuery_graphql.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"currentUserConnectedAccounts_totalCount":{"b":""},"":{"f":""}}};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, undefined, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: undefined,
  convertFragment: convertFragment
};

var Utils = {};

function makeNode(rescript_graphql_node_StandardLayoutQuery) {
  return ({
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "brandFamily"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": rescript_graphql_node_StandardLayoutQuery
    }
  },
  "name": "StandardLayout_query",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "brandFamily",
          "variableName": "brandFamily"
        }
      ],
      "kind": "FragmentSpread",
      "name": "Navigation_query"
    },
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "brandFamilySlug",
              "variableName": "brandFamily"
            }
          ],
          "kind": "ObjectValue",
          "name": "condition"
        }
      ],
      "concreteType": "CurrentUserConnectedAccountsConnection",
      "kind": "LinkedField",
      "name": "currentUserConnectedAccounts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
});
}

var node = makeNode(StandardLayoutQuery_graphql$Thin.node);

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
