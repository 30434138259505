// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Locutus$slashphp$slashstrings$slash = require("locutus/php/strings/");
var Processor_Endpoint$Thin = require("./Processor_Endpoint.bs.js");

var webviewRegexes = [
  "WebView",
  "(iPhone|iPod|iPad)(?!.*Safari)",
  "Android.*(;\\s+wv|Version/\\d.\\d\\s+Chrome/\\d+(\\.0){3})",
  "Linux; U; Android"
];

function isWebview() {
  var ua = navigator.userAgent;
  var re = new RegExp("(" + webviewRegexes.join("|") + ")", "ig");
  return re.test(ua);
}

var allSupportedProcessors = [
  [
    "INCREASE_COM",
    "SAME_DAY_ACH",
    "PlaidDebit"
  ],
  [
    "BRAINTREEPAYMENTS_COM",
    "CREDIT_CARD",
    "BraintreeHostedFields"
  ]
];

var Internal = {
  webviewRegexes: webviewRegexes,
  isWebview: isWebview,
  allSupportedProcessors: allSupportedProcessors
};

function isUnsupportedBrowser(flow) {
  if (flow === "PlaidDebit" && isWebview()) {
    return "NotPossibleInWebView";
  }
  
}

function calculateFee(param, amount) {
  var pmtFeeType = param.pmtFeeType;
  var pmtFeePct = param.pmtFeePct;
  if (pmtFeeType === "PCT_ON_TOP" || pmtFeeType === "NO_FEE" || pmtFeeType === "BACK_OUT_PCT") {
    switch (pmtFeeType) {
      case "NO_FEE" :
          return 0;
      case "PCT_ON_TOP" :
          return Math.round(amount * Belt_Option.getExn(pmtFeePct));
      case "BACK_OUT_PCT" :
          return Math.round(amount * (1 / (1 - Belt_Option.getExn(pmtFeePct)) - 1));
      
    }
  } else {
    throw {
          RE_EXN_ID: "Invalid_argument",
          _1: pmtFeeType + " is unsupported fee type",
          Error: new Error()
        };
  }
}

function feeKind(param) {
  var pmtFeeType = param.pmtFeeType;
  if (pmtFeeType === "PCT_ON_TOP" || pmtFeeType === "NO_FEE" || pmtFeeType === "BACK_OUT_PCT") {
    switch (pmtFeeType) {
      case "NO_FEE" :
          return ;
      case "PCT_ON_TOP" :
      case "BACK_OUT_PCT" :
          return "PercentageBased";
      
    }
  } else {
    throw {
          RE_EXN_ID: "Invalid_argument",
          _1: pmtFeeType + " is unsupported fee type",
          Error: new Error()
        };
  }
}

function getList(endpoints) {
  var match = (function (param) {
        return [
                Belt_Array.keepMap(param[0], (function (a) {
                        return a;
                      })),
                Belt_Array.keepMap(param[1], (function (a) {
                        return a;
                      }))
              ];
      })(Belt_Array.unzip(Belt_Array.keepMap(allSupportedProcessors, (function (param) {
                  var flow = param[2];
                  var method = param[1];
                  var processor = param[0];
                  return Belt_Array.get(Belt_Array.keepMap(endpoints, (function (endpoint) {
                                    if (!(Caml_obj.equal(processor, Processor_Endpoint$Thin.decodeProc(endpoint.pmtProcessor)) && Caml_obj.equal(method, Processor_Endpoint$Thin.decodeMethod(endpoint.pmtMethod)) && Belt_Option.isSome(Processor_Endpoint$Thin.decodePmtFeeType(endpoint.pmtFeeType)))) {
                                      return ;
                                    }
                                    var t_endpoint_root = endpoint.endpointRootUrl;
                                    var t_pmtFeePct = endpoint.pmtFeePct;
                                    var t_pmtFeeType = endpoint.pmtFeeType;
                                    var t = {
                                      processor: processor,
                                      method: method,
                                      flow: flow,
                                      endpoint_root: t_endpoint_root,
                                      pmtFeePct: t_pmtFeePct,
                                      pmtFeeType: t_pmtFeeType
                                    };
                                    var unsupportedBrowserReason = isUnsupportedBrowser(flow);
                                    return unsupportedBrowserReason !== undefined ? [
                                              undefined,
                                              [
                                                t,
                                                unsupportedBrowserReason
                                              ]
                                            ] : [
                                              t,
                                              undefined
                                            ];
                                  })), 0);
                }))));
  var supps = match[0];
  var lvHash = Locutus$slashphp$slashstrings$slash.md5(Belt_Option.getExn(JSON.stringify(supps))).substring(0, 4);
  return {
          listHash: lvHash,
          avails: supps,
          browserUnsupported: match[1],
          endpoints: endpoints
        };
}

function printFlow(flow) {
  if (flow === "PlaidDebit") {
    return "PlaidDebit";
  } else {
    return "BraintreeHostedFields";
  }
}

function printMethod(s) {
  return s.processor + "**" + s.method + " [[fee: " + Belt_Option.mapWithDefault(feeKind(s), "no fee!", (function (fk) {
                return fk;
              })) + "]] (" + printFlow(s.flow) + ")";
}

var Debug = {
  printFlow: printFlow,
  printMethod: printMethod
};

var Endpoint;

var PlaidDebit;

var BraintreeHostedFields;

exports.Endpoint = Endpoint;
exports.PlaidDebit = PlaidDebit;
exports.BraintreeHostedFields = BraintreeHostedFields;
exports.Internal = Internal;
exports.isUnsupportedBrowser = isUnsupportedBrowser;
exports.calculateFee = calculateFee;
exports.feeKind = feeKind;
exports.getList = getList;
exports.Debug = Debug;
/* locutus/php/strings/ Not a pure module */
