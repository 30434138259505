// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var JsxRuntime = require("react/jsx-runtime");
var VisibilityOutlined = require("@mui/icons-material/VisibilityOutlined").default;
var VisibilityOffOutlined = require("@mui/icons-material/VisibilityOffOutlined").default;

function PasswordInput(props) {
  var __autoComplete = props.autoComplete;
  var __error = props.error;
  var __variant = props.variant;
  var __required = props.required;
  var __disabled = props.disabled;
  var __color = props.color;
  var __size = props.size;
  var __fullWidth = props.fullWidth;
  var fullWidth = __fullWidth !== undefined ? __fullWidth : false;
  var size = __size !== undefined ? __size : "md";
  var color = __color !== undefined ? __color : "neutral";
  var disabled = __disabled !== undefined ? __disabled : false;
  var required = __required !== undefined ? __required : false;
  var variant = __variant !== undefined ? __variant : "outlined";
  var error = __error !== undefined ? __error : false;
  var autoComplete = __autoComplete !== undefined ? __autoComplete : "on";
  var match = React.useState(function () {
        return "password";
      });
  var setInputType = match[1];
  var inputType = match[0];
  var toggle = function (param) {
    if (inputType === "password") {
      return setInputType(function (param) {
                  return "text";
                });
    } else {
      return setInputType(function (param) {
                  return "password";
                });
    }
  };
  var tmp = inputType === "text" ? JsxRuntime.jsx(VisibilityOffOutlined, {
          sx: {
            "&:hover": {
              cursor: "pointer"
            }
          },
          onClick: toggle
        }) : JsxRuntime.jsx(VisibilityOutlined, {
          sx: {
            "&:hover": {
              cursor: "pointer"
            }
          },
          onClick: toggle
        });
  return JsxRuntime.jsx(Joy$Util.Input.make, {
              type_: inputType,
              className: Belt_Option.getWithDefault(props.className, ""),
              size: size,
              fullWidth: fullWidth,
              color: color,
              placeholder: Belt_Option.getWithDefault(props.placeholder, ""),
              disabled: disabled,
              required: required,
              variant: variant,
              error: error,
              role: Belt_Option.getWithDefault(props.role, ""),
              value: props.value,
              onChange: props.onChange,
              endDecorator: Caml_option.some(tmp),
              slotProps: Caml_option.some(props.slotProps),
              autoComplete: autoComplete
            });
}

var make = PasswordInput;

exports.make = make;
/* react Not a pure module */
