// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../utils/Common.bs.js");
var SysCtx$Thin = require("../utils/SysCtx.bs.js");
var Sidebar$Thin = require("../components/common/molecules/Sidebar.bs.js");
var Stack = require("@mui/joy/Stack").default;
var IsLoggedIn$Thin = require("../routes/gates/IsLoggedIn.bs.js");
var Navigation$Thin = require("../components/common/molecules/Navigation.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var FullPageWaveLoader$Thin = require("../components/common/molecules/FullPageWaveLoader.bs.js");
var StandardLayoutQuery_graphql$Thin = require("../__generated__/StandardLayoutQuery_graphql.bs.js");
var StandardLayout_query_graphql$Thin = require("../__generated__/StandardLayout_query_graphql.bs.js");

var convertFragment = StandardLayout_query_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(StandardLayout_query_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, StandardLayout_query_graphql$Thin.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(StandardLayout_query_graphql$Thin.node, convertFragment, fRef);
}

var makeRefetchVariables = StandardLayoutQuery_graphql$Thin.Types.makeRefetchVariables;

var convertRefetchVariables = StandardLayoutQuery_graphql$Thin.Internal.convertVariables;

function useRefetchable(fRef) {
  return RescriptRelay_Fragment.useRefetchableFragment(StandardLayout_query_graphql$Thin.node, convertFragment, convertRefetchVariables, fRef);
}

var QueryFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment,
  makeRefetchVariables: makeRefetchVariables,
  convertRefetchVariables: convertRefetchVariables,
  useRefetchable: useRefetchable
};

function StandardLayout(props) {
  var children = props.children;
  var __mustBeLoggedIn = props.mustBeLoggedIn;
  var refetchSignOnStatus = props.refetchSignOnStatus;
  var signOnStatus = props.signOnStatus;
  var __alwaysHamburger = props.alwaysHamburger;
  var __dash = props.dash;
  var __navigation = props.navigation;
  var navigation = __navigation !== undefined ? __navigation : true;
  var dash = __dash !== undefined ? __dash : true;
  var alwaysHamburger = __alwaysHamburger !== undefined ? __alwaysHamburger : false;
  var mustBeLoggedIn = __mustBeLoggedIn !== undefined ? __mustBeLoggedIn : false;
  var query = use(props.fragmentRefs);
  var logout = function (param) {
    Common$Thin.cookieLogout(Caml_option.some((function () {
                refetchSignOnStatus();
                RescriptReactRouter.push(SysCtx$Thin.vars.loginLink);
              })), undefined, undefined);
  };
  React.useEffect((function () {
          if ((signOnStatus === "VERIFY_EMAIL" || signOnStatus === "INVITED" || signOnStatus === "PENDING_2FA" || signOnStatus === "CLEAR_JWT" || signOnStatus === "ANONYMOUS" || signOnStatus === "SIGNED_ON") && signOnStatus === "CLEAR_JWT") {
            Common$Thin.cookieLogout(Caml_option.some((function () {
                        if (mustBeLoggedIn) {
                          
                        } else {
                          RescriptReactRouter.push(SysCtx$Thin.vars.loginLink);
                        }
                      })), undefined, undefined);
          }
          
        }), [signOnStatus]);
  var match = query.currentUserConnectedAccounts;
  var hasConnectedAccounts = match !== undefined ? match.totalCount > 0 : false;
  return JsxRuntime.jsx(React.Suspense, {
              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                        sx: {
                          height: "100%",
                          backgroundColor: "background.body"
                        },
                        children: [
                          navigation ? JsxRuntime.jsx(Navigation$Thin.make, {
                                  fragmentRefs: query.fragmentRefs,
                                  alwaysHamburger: alwaysHamburger,
                                  signOnStatus: signOnStatus,
                                  logout: logout,
                                  displayName: props.displayName
                                }) : null,
                          dash ? JsxRuntime.jsxs(Stack, {
                                  direction: "row",
                                  sx: {
                                    flex: 1
                                  },
                                  children: [
                                    JsxRuntime.jsx(Sidebar$Thin.make, {
                                          hasConnectedAccounts: hasConnectedAccounts,
                                          logout: logout
                                        }),
                                    JsxRuntime.jsx(Stack, {
                                          sx: {
                                            flexGrow: 1,
                                            flexShrink: 1,
                                            backgroundColor: Belt_Option.getWithDefault(props.bg, "background.white"),
                                            width: {
                                              xs: "100%",
                                              md: "calc(100% - 250px)"
                                            }
                                          },
                                          children: Caml_option.some(mustBeLoggedIn ? JsxRuntime.jsx(IsLoggedIn$Thin.make, {
                                                      children: children,
                                                      signOnStatus: signOnStatus
                                                    }) : children)
                                        })
                                  ]
                                }) : children
                        ]
                      })),
              fallback: Caml_option.some(JsxRuntime.jsx(FullPageWaveLoader$Thin.make, {}))
            });
}

var make = StandardLayout;

exports.QueryFragment = QueryFragment;
exports.make = make;
/* react Not a pure module */
