// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Stack = require("@mui/joy/Stack").default;
var $$Option = require("@mui/joy/Option").default;
var DraftUtils$Thin = require("../../utils/booking/DraftUtils.bs.js");
var BookingDiff$Thin = require("../../utils/booking/BookingDiff.bs.js");
var Textarea = require("@mui/joy/Textarea").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var BDContentBlock$Thin = require("./molecules/BDContentBlock.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var BDPreferences_detail_graphql$Thin = require("../../__generated__/BDPreferences_detail_graphql.bs.js");

var convertFragment = BDPreferences_detail_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(BDPreferences_detail_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BDPreferences_detail_graphql$Thin.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(BDPreferences_detail_graphql$Thin.node, convertFragment, fRef);
}

var DetailFragment_elevatorProximity_decode = BDPreferences_detail_graphql$Thin.Utils.elevatorProximity_decode;

var DetailFragment_elevatorProximity_fromString = BDPreferences_detail_graphql$Thin.Utils.elevatorProximity_fromString;

var DetailFragment_longitudinality_decode = BDPreferences_detail_graphql$Thin.Utils.longitudinality_decode;

var DetailFragment_longitudinality_fromString = BDPreferences_detail_graphql$Thin.Utils.longitudinality_fromString;

var DetailFragment = {
  elevatorProximity_decode: DetailFragment_elevatorProximity_decode,
  elevatorProximity_fromString: DetailFragment_elevatorProximity_fromString,
  longitudinality_decode: DetailFragment_longitudinality_decode,
  longitudinality_fromString: DetailFragment_longitudinality_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

function BDPreferences(props) {
  var draftMode = props.draftMode;
  var data = use(props.fragmentRefs);
  var match = data.preferences;
  var match$1 = data.preferences;
  var match$2 = data.preferences;
  var match$3 = data.preferences;
  var match$4 = data.preferences;
  var match$5 = data.preferences;
  var versionPreferences_bedsTogetherPref = match !== undefined ? match.bedsTogetherPref : undefined;
  var versionPreferences_cabinLongitudePref = match$1 !== undefined ? match$1.cabinLongitudePref : undefined;
  var versionPreferences_cabinElevatorPref = match$2 !== undefined ? match$2.cabinElevatorPref : undefined;
  var versionPreferences_cabinPlacementRequest = match$3 !== undefined ? match$3.cabinPlacementRequest : undefined;
  var versionPreferences_earlyDinnerSeatingPref = match$4 !== undefined ? match$4.earlyDinnerSeatingPref : undefined;
  var versionPreferences_generalPref = match$5 !== undefined ? match$5.generalPref : undefined;
  var versionPreferences = {
    bedsTogetherPref: versionPreferences_bedsTogetherPref,
    cabinLongitudePref: versionPreferences_cabinLongitudePref,
    cabinElevatorPref: versionPreferences_cabinElevatorPref,
    cabinPlacementRequest: versionPreferences_cabinPlacementRequest,
    earlyDinnerSeatingPref: versionPreferences_earlyDinnerSeatingPref,
    generalPref: versionPreferences_generalPref
  };
  var match$6 = React.useState(function () {
        return false;
      });
  var setLoading = match$6[1];
  var match$7 = React.useState(function () {
        return versionPreferences;
      });
  var setPref = match$7[1];
  var pref = match$7[0];
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var diff = BookingDiff$Thin.parse(url.search);
  React.useEffect((function () {
          var prefs = diff.updateBookingPreferences;
          var initialPrefs = prefs !== undefined ? prefs : versionPreferences;
          setPref(function (param) {
                return initialPrefs;
              });
        }), diff.updateBookingPreferences);
  var onChange = function (pref) {
    var applyDiff = BookingDiff$Thin.Alter.updateBookingPreferences(diff, pref, versionPreferences);
    DraftUtils$Thin.addToDraft(applyDiff, url);
  };
  var onChangeDebounceTimerRef = React.useRef(null);
  var debouncedOnChange = function (newPrefs) {
    var timer = onChangeDebounceTimerRef.current;
    if (!(timer == null)) {
      clearTimeout(timer);
    }
    setLoading(function (param) {
          return true;
        });
    onChangeDebounceTimerRef.current = setTimeout((function () {
            onChange(newPrefs);
            setLoading(function (param) {
                  return false;
                });
          }), 1000);
  };
  React.useEffect((function () {
          Belt_Option.forEach(Caml_option.nullable_to_opt(onChangeDebounceTimerRef.current), (function (prim) {
                  clearTimeout(prim);
                }));
        }), []);
  var pref$1 = pref.cabinLongitudePref;
  var tmp;
  if (pref$1 !== undefined) {
    if (pref$1 === "AFT" || pref$1 === "FORE" || pref$1 === "MID") {
      switch (pref$1) {
        case "FORE" :
            tmp = "fore";
            break;
        case "AFT" :
            tmp = "aft";
            break;
        case "MID" :
            tmp = "mid";
            break;
        
      }
    } else {
      tmp = undefined;
    }
  } else {
    tmp = "none";
  }
  var pref$2 = pref.cabinElevatorPref;
  var tmp$1;
  tmp$1 = pref$2 !== undefined ? (
      pref$2 === "NEARELEVATORS" || pref$2 === "FARELEVATORS" ? (
          pref$2 === "NEARELEVATORS" ? "nearelevators" : "farelevators"
        ) : undefined
    ) : "none";
  var pref$3 = pref.earlyDinnerSeatingPref;
  var pref$4 = pref.bedsTogetherPref;
  return JsxRuntime.jsx(BDContentBlock$Thin.make, {
              title: "Preferences",
              children: JsxRuntime.jsx(Stack, {
                    direction: "row",
                    justifyContent: "center",
                    children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                              direction: {
                                xs: "column",
                                md: "row"
                              },
                              spacing: 1,
                              sx: {
                                backgroundColor: "background.level1",
                                p: {
                                  xs: "0.1rem",
                                  md: "1"
                                },
                                m: {
                                  xs: "0",
                                  md: "1"
                                },
                                borderRadius: "sm",
                                width: "fit-content",
                                alignSelf: "center",
                                maxWidth: "100%"
                              },
                              children: [
                                JsxRuntime.jsxs(Stack, {
                                      direction: "column",
                                      spacing: 4,
                                      sx: {
                                        maxWidth: "600px",
                                        p: 2
                                      },
                                      children: [
                                        JsxRuntime.jsxs(Stack, {
                                              direction: "column",
                                              spacing: 2,
                                              sx: {
                                                maxWidth: "100%"
                                              },
                                              children: [
                                                JsxRuntime.jsxs(Stack, {
                                                      direction: "column",
                                                      spacing: 1,
                                                      children: [
                                                        JsxRuntime.jsx(Typography, {
                                                              level: "title-md",
                                                              fontWeight: "600",
                                                              children: "Stateroom Placement: Ship Section"
                                                            }),
                                                        JsxRuntime.jsx(Typography, {
                                                              level: "body-sm",
                                                              sx: {
                                                                fontStyle: "italic"
                                                              },
                                                              children: "Would you prefer a cabin towards the forward, middle, or aft of the ship? We will do the best we can to accommodate your request."
                                                            })
                                                      ]
                                                    }),
                                                JsxRuntime.jsxs(Joy$Util.JoySelect.make, {
                                                      children: [
                                                        JsxRuntime.jsx($$Option, {
                                                              value: "fore",
                                                              children: "I'd like a stateroom towards the front of the ship"
                                                            }),
                                                        JsxRuntime.jsx($$Option, {
                                                              value: "mid",
                                                              children: "I'd like a stateroom towards the middle of the ship"
                                                            }),
                                                        JsxRuntime.jsx($$Option, {
                                                              value: "aft",
                                                              children: "I'd like a stateroom towards the back of the ship"
                                                            }),
                                                        JsxRuntime.jsx($$Option, {
                                                              value: "none",
                                                              children: "No preference"
                                                            })
                                                      ],
                                                      disabled: !draftMode,
                                                      onChange: (function (param, val) {
                                                          var newPrefs;
                                                          var exit = 0;
                                                          if (val !== undefined) {
                                                            var val$1 = Caml_option.valFromOption(val);
                                                            if (val$1 !== undefined) {
                                                              var tmp;
                                                              switch (val$1) {
                                                                case "aft" :
                                                                    tmp = "AFT";
                                                                    break;
                                                                case "fore" :
                                                                    tmp = "FORE";
                                                                    break;
                                                                case "mid" :
                                                                    tmp = "MID";
                                                                    break;
                                                                default:
                                                                  tmp = undefined;
                                                              }
                                                              newPrefs = {
                                                                bedsTogetherPref: pref.bedsTogetherPref,
                                                                cabinLongitudePref: tmp,
                                                                cabinElevatorPref: pref.cabinElevatorPref,
                                                                cabinPlacementRequest: pref.cabinPlacementRequest,
                                                                earlyDinnerSeatingPref: pref.earlyDinnerSeatingPref,
                                                                generalPref: pref.generalPref
                                                              };
                                                            } else {
                                                              exit = 1;
                                                            }
                                                          } else {
                                                            exit = 1;
                                                          }
                                                          if (exit === 1) {
                                                            newPrefs = {
                                                              bedsTogetherPref: pref.bedsTogetherPref,
                                                              cabinLongitudePref: undefined,
                                                              cabinElevatorPref: pref.cabinElevatorPref,
                                                              cabinPlacementRequest: pref.cabinPlacementRequest,
                                                              earlyDinnerSeatingPref: pref.earlyDinnerSeatingPref,
                                                              generalPref: pref.generalPref
                                                            };
                                                          }
                                                          setPref(function (param) {
                                                                return newPrefs;
                                                              });
                                                          onChange(newPrefs);
                                                        }),
                                                      size: "sm",
                                                      sx: {
                                                        maxWidth: "100%"
                                                      },
                                                      value: tmp
                                                    })
                                              ]
                                            }),
                                        JsxRuntime.jsxs(Stack, {
                                              direction: "column",
                                              spacing: 1,
                                              children: [
                                                JsxRuntime.jsxs(Stack, {
                                                      direction: "column",
                                                      spacing: 1,
                                                      children: [
                                                        JsxRuntime.jsx(Typography, {
                                                              level: "title-md",
                                                              fontWeight: "600",
                                                              children: "Stateroom Placement: Elevator Proximity"
                                                            }),
                                                        JsxRuntime.jsx(Typography, {
                                                              level: "body-sm",
                                                              sx: {
                                                                fontStyle: "italic"
                                                              },
                                                              children: "Would you prefer a cabin near an elevator bank or further from an elevator bank? We will do the best we can to accommodate your request."
                                                            })
                                                      ]
                                                    }),
                                                JsxRuntime.jsxs(Joy$Util.JoySelect.make, {
                                                      children: [
                                                        JsxRuntime.jsx($$Option, {
                                                              value: "nearelevators",
                                                              children: "Try to place my stateroom near the elevators"
                                                            }),
                                                        JsxRuntime.jsx($$Option, {
                                                              value: "farelevators",
                                                              children: "Try to place my stateroom far from the elevators"
                                                            }),
                                                        JsxRuntime.jsx($$Option, {
                                                              value: "none",
                                                              children: "No preference"
                                                            })
                                                      ],
                                                      disabled: !draftMode,
                                                      onChange: (function (param, val) {
                                                          var newPrefs;
                                                          var exit = 0;
                                                          if (val !== undefined) {
                                                            var val$1 = Caml_option.valFromOption(val);
                                                            if (val$1 !== undefined) {
                                                              var tmp;
                                                              switch (val$1) {
                                                                case "farelevators" :
                                                                    tmp = "FARELEVATORS";
                                                                    break;
                                                                case "nearelevators" :
                                                                    tmp = "NEARELEVATORS";
                                                                    break;
                                                                default:
                                                                  tmp = undefined;
                                                              }
                                                              newPrefs = {
                                                                bedsTogetherPref: pref.bedsTogetherPref,
                                                                cabinLongitudePref: pref.cabinLongitudePref,
                                                                cabinElevatorPref: tmp,
                                                                cabinPlacementRequest: pref.cabinPlacementRequest,
                                                                earlyDinnerSeatingPref: pref.earlyDinnerSeatingPref,
                                                                generalPref: pref.generalPref
                                                              };
                                                            } else {
                                                              exit = 1;
                                                            }
                                                          } else {
                                                            exit = 1;
                                                          }
                                                          if (exit === 1) {
                                                            newPrefs = {
                                                              bedsTogetherPref: pref.bedsTogetherPref,
                                                              cabinLongitudePref: pref.cabinLongitudePref,
                                                              cabinElevatorPref: undefined,
                                                              cabinPlacementRequest: pref.cabinPlacementRequest,
                                                              earlyDinnerSeatingPref: pref.earlyDinnerSeatingPref,
                                                              generalPref: pref.generalPref
                                                            };
                                                          }
                                                          setPref(function (param) {
                                                                return newPrefs;
                                                              });
                                                          onChange(newPrefs);
                                                        }),
                                                      size: "sm",
                                                      value: tmp$1
                                                    })
                                              ]
                                            }),
                                        JsxRuntime.jsxs(Stack, {
                                              direction: "column",
                                              spacing: 1,
                                              children: [
                                                JsxRuntime.jsx(Typography, {
                                                      level: "title-md",
                                                      fontWeight: "600",
                                                      children: "Additional Stateroom Placement Requests"
                                                    }),
                                                JsxRuntime.jsx(Typography, {
                                                      level: "body-sm",
                                                      sx: {
                                                        fontStyle: "italic"
                                                      },
                                                      children: "If you have additional stateroom placement requests that aren't covered in the \"Stateroom Placement\" sections above, please enter them here. If you have none, please leave this section blank."
                                                    }),
                                                JsxRuntime.jsx(Textarea, {
                                                      value: Belt_Option.getWithDefault(Belt_Option.map(pref.cabinPlacementRequest, CS_NonemptyStrings$Util.NonEmptyString.toString), ""),
                                                      disabled: !draftMode,
                                                      onChange: (function (e) {
                                                          var val = e.currentTarget.value;
                                                          var val$1 = CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(val);
                                                          var tmp;
                                                          tmp = val$1.TAG === "Ok" ? Caml_option.some(val$1._0) : undefined;
                                                          var newPrefs_bedsTogetherPref = pref.bedsTogetherPref;
                                                          var newPrefs_cabinLongitudePref = pref.cabinLongitudePref;
                                                          var newPrefs_cabinElevatorPref = pref.cabinElevatorPref;
                                                          var newPrefs_earlyDinnerSeatingPref = pref.earlyDinnerSeatingPref;
                                                          var newPrefs_generalPref = pref.generalPref;
                                                          var newPrefs = {
                                                            bedsTogetherPref: newPrefs_bedsTogetherPref,
                                                            cabinLongitudePref: newPrefs_cabinLongitudePref,
                                                            cabinElevatorPref: newPrefs_cabinElevatorPref,
                                                            cabinPlacementRequest: tmp,
                                                            earlyDinnerSeatingPref: newPrefs_earlyDinnerSeatingPref,
                                                            generalPref: newPrefs_generalPref
                                                          };
                                                          setPref(function (param) {
                                                                return newPrefs;
                                                              });
                                                          debouncedOnChange(newPrefs);
                                                        }),
                                                      maxRows: 3,
                                                      minRows: 3,
                                                      size: "sm"
                                                    })
                                              ]
                                            })
                                      ]
                                    }),
                                JsxRuntime.jsxs(Stack, {
                                      direction: "column",
                                      spacing: 4,
                                      sx: {
                                        maxWidth: "600px",
                                        p: 2,
                                        pt: {
                                          xs: 0,
                                          md: 2
                                        }
                                      },
                                      children: [
                                        JsxRuntime.jsxs(Stack, {
                                              direction: "column",
                                              spacing: 2,
                                              sx: {
                                                maxWidth: "100%"
                                              },
                                              children: [
                                                JsxRuntime.jsxs(Stack, {
                                                      direction: "column",
                                                      spacing: 1,
                                                      children: [
                                                        JsxRuntime.jsx(Typography, {
                                                              level: "title-md",
                                                              fontWeight: "600",
                                                              children: "Bed Placement"
                                                            }),
                                                        JsxRuntime.jsx(Typography, {
                                                              level: "body-sm",
                                                              sx: {
                                                                fontStyle: "italic"
                                                              },
                                                              children: "Two of the beds in this stateroom can be combined into a single, larger bed. How would you like the beds set up?"
                                                            })
                                                      ]
                                                    }),
                                                JsxRuntime.jsxs(Joy$Util.JoySelect.make, {
                                                      children: [
                                                        JsxRuntime.jsx($$Option, {
                                                              value: "true",
                                                              children: "Join the beds together"
                                                            }),
                                                        JsxRuntime.jsx($$Option, {
                                                              value: "false",
                                                              children: "Keep the beds separate"
                                                            }),
                                                        JsxRuntime.jsx($$Option, {
                                                              value: "none",
                                                              children: "No preference"
                                                            })
                                                      ],
                                                      disabled: !draftMode,
                                                      onChange: (function (param, val) {
                                                          var newPrefs;
                                                          var exit = 0;
                                                          if (val !== undefined) {
                                                            var val$1 = Caml_option.valFromOption(val);
                                                            if (val$1 !== undefined) {
                                                              var tmp;
                                                              switch (val$1) {
                                                                case "false" :
                                                                    tmp = false;
                                                                    break;
                                                                case "true" :
                                                                    tmp = true;
                                                                    break;
                                                                default:
                                                                  tmp = undefined;
                                                              }
                                                              newPrefs = {
                                                                bedsTogetherPref: pref.bedsTogetherPref,
                                                                cabinLongitudePref: pref.cabinLongitudePref,
                                                                cabinElevatorPref: pref.cabinElevatorPref,
                                                                cabinPlacementRequest: pref.cabinPlacementRequest,
                                                                earlyDinnerSeatingPref: tmp,
                                                                generalPref: pref.generalPref
                                                              };
                                                            } else {
                                                              exit = 1;
                                                            }
                                                          } else {
                                                            exit = 1;
                                                          }
                                                          if (exit === 1) {
                                                            newPrefs = {
                                                              bedsTogetherPref: pref.bedsTogetherPref,
                                                              cabinLongitudePref: pref.cabinLongitudePref,
                                                              cabinElevatorPref: pref.cabinElevatorPref,
                                                              cabinPlacementRequest: pref.cabinPlacementRequest,
                                                              earlyDinnerSeatingPref: undefined,
                                                              generalPref: pref.generalPref
                                                            };
                                                          }
                                                          setPref(function (param) {
                                                                return newPrefs;
                                                              });
                                                          onChange(newPrefs);
                                                        }),
                                                      size: "sm",
                                                      value: pref$3 !== undefined ? (
                                                          pref$3 ? "true" : "false"
                                                        ) : "none"
                                                    })
                                              ]
                                            }),
                                        JsxRuntime.jsxs(Stack, {
                                              direction: "column",
                                              spacing: 1,
                                              children: [
                                                JsxRuntime.jsxs(Stack, {
                                                      direction: "column",
                                                      spacing: 1,
                                                      children: [
                                                        JsxRuntime.jsx(Typography, {
                                                              level: "title-md",
                                                              fontWeight: "600",
                                                              children: "Dinner Seating"
                                                            }),
                                                        JsxRuntime.jsx(Typography, {
                                                              level: "body-sm",
                                                              sx: {
                                                                fontStyle: "italic"
                                                              },
                                                              children: "Each day, there are two dinner seatings and two Main Concerts: early and late (tentatively 5 and 7:30 pm). The early dinner seating sees the late show, and vice versa. Do you have a preference?"
                                                            })
                                                      ]
                                                    }),
                                                JsxRuntime.jsxs(Joy$Util.JoySelect.make, {
                                                      children: [
                                                        JsxRuntime.jsx($$Option, {
                                                              value: "true",
                                                              children: "Red Team (Early Dinner/Late Concert), thanks!"
                                                            }),
                                                        JsxRuntime.jsx($$Option, {
                                                              value: "false",
                                                              children: "Gold Team (Late Dinner/Early Concert), thanks!"
                                                            }),
                                                        JsxRuntime.jsx($$Option, {
                                                              value: "none",
                                                              children: "No preference"
                                                            })
                                                      ],
                                                      disabled: !draftMode,
                                                      onChange: (function (param, val) {
                                                          var newPrefs;
                                                          var exit = 0;
                                                          if (val !== undefined) {
                                                            var val$1 = Caml_option.valFromOption(val);
                                                            if (val$1 !== undefined) {
                                                              var tmp;
                                                              switch (val$1) {
                                                                case "false" :
                                                                    tmp = false;
                                                                    break;
                                                                case "true" :
                                                                    tmp = true;
                                                                    break;
                                                                default:
                                                                  tmp = undefined;
                                                              }
                                                              newPrefs = {
                                                                bedsTogetherPref: tmp,
                                                                cabinLongitudePref: pref.cabinLongitudePref,
                                                                cabinElevatorPref: pref.cabinElevatorPref,
                                                                cabinPlacementRequest: pref.cabinPlacementRequest,
                                                                earlyDinnerSeatingPref: pref.earlyDinnerSeatingPref,
                                                                generalPref: pref.generalPref
                                                              };
                                                            } else {
                                                              exit = 1;
                                                            }
                                                          } else {
                                                            exit = 1;
                                                          }
                                                          if (exit === 1) {
                                                            newPrefs = {
                                                              bedsTogetherPref: undefined,
                                                              cabinLongitudePref: pref.cabinLongitudePref,
                                                              cabinElevatorPref: pref.cabinElevatorPref,
                                                              cabinPlacementRequest: pref.cabinPlacementRequest,
                                                              earlyDinnerSeatingPref: pref.earlyDinnerSeatingPref,
                                                              generalPref: pref.generalPref
                                                            };
                                                          }
                                                          setPref(function (param) {
                                                                return newPrefs;
                                                              });
                                                          onChange(newPrefs);
                                                        }),
                                                      size: "sm",
                                                      value: pref$4 !== undefined ? (
                                                          pref$4 ? "true" : "false"
                                                        ) : "none"
                                                    })
                                              ]
                                            }),
                                        JsxRuntime.jsxs(Stack, {
                                              direction: "column",
                                              spacing: 1,
                                              children: [
                                                JsxRuntime.jsx(Typography, {
                                                      level: "title-md",
                                                      fontWeight: "600",
                                                      children: "Anything else we can help you with? Just ask."
                                                    }),
                                                JsxRuntime.jsx(Typography, {
                                                      level: "body-sm",
                                                      sx: {
                                                        fontStyle: "italic"
                                                      },
                                                      children: "Please note that you must make medical and dietary needs known to Holland America directly (see our FAQ for more information on how to do so). Please note any other requests you may have here. If you have none, leave this section blank."
                                                    }),
                                                JsxRuntime.jsx(Textarea, {
                                                      value: Belt_Option.getWithDefault(Belt_Option.map(pref.generalPref, CS_NonemptyStrings$Util.NonEmptyString.toString), ""),
                                                      disabled: !draftMode,
                                                      onChange: (function (e) {
                                                          var val = e.currentTarget.value;
                                                          var val$1 = CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(val);
                                                          var tmp;
                                                          tmp = val$1.TAG === "Ok" ? Caml_option.some(val$1._0) : undefined;
                                                          var newPrefs_bedsTogetherPref = pref.bedsTogetherPref;
                                                          var newPrefs_cabinLongitudePref = pref.cabinLongitudePref;
                                                          var newPrefs_cabinElevatorPref = pref.cabinElevatorPref;
                                                          var newPrefs_cabinPlacementRequest = pref.cabinPlacementRequest;
                                                          var newPrefs_earlyDinnerSeatingPref = pref.earlyDinnerSeatingPref;
                                                          var newPrefs = {
                                                            bedsTogetherPref: newPrefs_bedsTogetherPref,
                                                            cabinLongitudePref: newPrefs_cabinLongitudePref,
                                                            cabinElevatorPref: newPrefs_cabinElevatorPref,
                                                            cabinPlacementRequest: newPrefs_cabinPlacementRequest,
                                                            earlyDinnerSeatingPref: newPrefs_earlyDinnerSeatingPref,
                                                            generalPref: tmp
                                                          };
                                                          setPref(function (param) {
                                                                return newPrefs;
                                                              });
                                                          debouncedOnChange(newPrefs);
                                                        }),
                                                      maxRows: 3,
                                                      minRows: 3,
                                                      size: "sm"
                                                    })
                                              ]
                                            })
                                      ]
                                    })
                              ]
                            }))
                  })
            });
}

var make = BDPreferences;

exports.DetailFragment = DetailFragment;
exports.make = make;
/* react Not a pure module */
