// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var BCForm$Thin = require("./BCForm.bs.js");
var Belt_MapInt = require("rescript/lib/js/belt_MapInt.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var PaxForm$Thin = require("./PaxForm.bs.js");
var Stack = require("@mui/joy/Stack").default;
var ReactState$Util = require("util/src/ReactState.bs.js");
var SalesHeading$Thin = require("../shared/SalesHeading.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var SalesFlowState$Thin = require("../../../utils/sales-flow/SalesFlowState.bs.js");
var UpdateAccountData$Thin = require("../../common/mutations/UpdateAccountData.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var SelectedCabinSummaryCard$Thin = require("../shared/SelectedCabinSummaryCard.bs.js");

function PassengerDetails(props) {
  var countryFrag = props.countryFrag;
  var pax1MustBeBornBy = props.pax1MustBeBornBy;
  var voyageSlug = props.voyageSlug;
  var urlState = props.urlState;
  var sessionSaveLoading = props.sessionSaveLoading;
  var updateSessionAndSave = props.updateSessionAndSave;
  var updateSessionData = props.updateSessionData;
  var sessionData = props.sessionData;
  var url = props.url;
  var signOnStatus = props.signOnStatus;
  var currentUserConnectedInfo = props.currentUserConnectedInfo;
  var currentUserInfo = props.currentUserInfo;
  var match = ReactState$Util.useDebouncedState((function () {
          if (currentUserInfo === undefined) {
            return {
                    TAG: "Pax",
                    _0: UpdateAccountData$Thin.Passenger.empty
                  };
          }
          var match = Belt_MapInt.get(sessionData.paxes, 1);
          if (match === undefined) {
            return {
                    TAG: "Pax",
                    _0: currentUserInfo
                  };
          }
          if (typeof match !== "object") {
            return {
                    TAG: "Pax",
                    _0: currentUserInfo
                  };
          }
          if (match.TAG !== "BCIncomplete") {
            return {
                    TAG: "Pax",
                    _0: currentUserInfo
                  };
          }
          var p = match._0;
          return {
                  TAG: "Pax",
                  _0: {
                    accountId: currentUserInfo.accountId,
                    isComplete: true,
                    isBookable: currentUserInfo.isBookable,
                    preferredFirstName: currentUserInfo.preferredFirstName,
                    preferredLastName: currentUserInfo.preferredLastName,
                    docFirstName: CS_NonemptyStrings$Util.NonEmptyString.toString(p.iDocFirstName),
                    docLastName: CS_NonemptyStrings$Util.NonEmptyString.toString(p.iDocLastName),
                    docBirthdate: Caml_option.some(p.iDocBirthdate),
                    phone: currentUserInfo.phone,
                    phoneCountryCode: currentUserInfo.phoneCountryCode,
                    postalCode: currentUserInfo.postalCode,
                    residenceCountry: currentUserInfo.residenceCountry,
                    docGender: p.iDocGender,
                    docCitizenship: Caml_option.some(p.iDocCitizenship),
                    email: currentUserInfo.email,
                    loyalty: currentUserInfo.loyalty,
                    acquisitionSlug: currentUserInfo.acquisitionSlug
                  }
                };
        }), undefined, undefined);
  var match$1 = match.state;
  var setBc = match$1[1];
  var bc = match$1[0];
  var numPaxes = Belt_Array.range(1, props.occupancy);
  var pageLocked = signOnStatus !== "SIGNED_ON";
  React.useEffect((function () {
          if (currentUserInfo !== undefined) {
            var acctId = currentUserInfo.accountId;
            if (acctId !== undefined) {
              updateSessionData(SalesFlowState$Thin.Alter.setBC(sessionData, {
                        TAG: "Id",
                        _0: Caml_option.valFromOption(acctId)
                      }));
              setBc(function (param) {
                    return {
                            TAG: "Pax",
                            _0: currentUserInfo
                          };
                  });
            }
            
          }
          
        }), [
        Belt_Option.getWithDefault(currentUserInfo, UpdateAccountData$Thin.Passenger.empty).isComplete,
        signOnStatus
      ]);
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Stack, {
                      spacing: 1,
                      sx: {
                        px: {
                          xs: 2,
                          md: 4
                        },
                        py: 2,
                        flex: 1,
                        height: "100%"
                      },
                      children: [
                        JsxRuntime.jsx(SalesHeading$Thin.make, {
                              title: "Passenger Details",
                              description: "Enter passenger details below for each guest.",
                              voyage: props.voyage
                            }),
                        JsxRuntime.jsx(Stack, {
                              direction: "column",
                              sx: {
                                pt: 1,
                                pb: 2
                              },
                              children: Caml_option.some(JsxRuntime.jsxs(Joy$Util.Grid.make, {
                                        children: [
                                          JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                                          direction: "column",
                                                          spacing: 2,
                                                          children: [
                                                            JsxRuntime.jsx(BCForm$Thin.make, {
                                                                  bc: bc,
                                                                  setBc: setBc,
                                                                  url: url,
                                                                  urlState: urlState,
                                                                  brandFamily: props.brandFamily,
                                                                  flow: props.flow,
                                                                  voyageSlug: voyageSlug,
                                                                  refetchCurrentUserInfo: props.refetchCurrentUserInfo,
                                                                  refetchSignOnStatus: props.refetchSignOnStatus,
                                                                  setPageLevelError: props.setPageLevelError,
                                                                  countryFrag: countryFrag
                                                                }),
                                                            Belt_Array.map(numPaxes, (function (paxNum) {
                                                                    return JsxRuntime.jsx(PaxForm$Thin.make, {
                                                                                sessionData: sessionData,
                                                                                updateSessionAndSave: updateSessionAndSave,
                                                                                updateSessionData: updateSessionData,
                                                                                sessionSaveLoading: sessionSaveLoading,
                                                                                paxNum: paxNum,
                                                                                bc: bc,
                                                                                setBc: setBc,
                                                                                currentUserConnectedInfo: currentUserConnectedInfo,
                                                                                pageLocked: pageLocked || paxNum !== 1 && Belt_Option.isNone(Belt_MapInt.get(sessionData.paxes, paxNum - 1 | 0)),
                                                                                voyageSlug: voyageSlug,
                                                                                pax1MustBeBornBy: pax1MustBeBornBy,
                                                                                countryFrag: countryFrag
                                                                              }, String(paxNum));
                                                                  }))
                                                          ]
                                                        })),
                                                xs: 12,
                                                md: 7
                                              }),
                                          JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                children: Caml_option.some(JsxRuntime.jsx(SelectedCabinSummaryCard$Thin.make, {
                                                          category: props.category,
                                                          cabinHoldExpiry: urlState.cabinHold,
                                                          canAdvance: props.canCheckout,
                                                          onAdvance: (function () {
                                                              SalesFlowState$Thin.goTo(urlState, "Checkout", url);
                                                            }),
                                                          advanceText: "Proceed to checkout",
                                                          pageLocked: pageLocked,
                                                          quote: props.quote
                                                        })),
                                                xs: 0,
                                                md: 5
                                              })
                                        ],
                                        container: true,
                                        columnSpacing: 2
                                      }))
                            })
                      ]
                    }),
                JsxRuntime.jsx(Stack, {
                      sx: {
                        height: "5rem",
                        display: {
                          xs: "flex",
                          md: "none"
                        }
                      }
                    })
              ]
            });
}

var make = PassengerDetails;

exports.make = make;
/* react Not a pure module */
