// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var ReactRelay = require("react-relay");
var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_Decimal$Util = require("util/src/CustomScalars/CS_Decimal.bs.js");

function makeRefetchVariables(voySlugString) {
  return {
          voySlugString: voySlugString
        };
}

var Types = {
  makeRefetchVariables: makeRefetchVariables
};

var variablesConverter = {};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, undefined, undefined);
}

var wrapResponseConverter = {"__root":{"currentUserBookingSessions_nodes_paymentPlan_paymentSuggestions_pmtSuggestionTotal":{"c":"Util.CustomScalars.UndenominatedCurrency.Exn"},"currentUserBookingSessions_nodes_paymentPlan_paymentSuggestions_pmtDiscountDisplayPct":{"b":""},"currentUserBookingSessions_nodes_paymentPlan_paymentSuggestions_partialCheckoutOption":{"c":"Util.CustomScalars.UndenominatedCurrency.Exn"},"currentUserBookingSessions_nodes_paymentPlan_paymentSuggestions_overdueOffset":{"b":""},"currentUserBookingSessions_nodes_paymentPlan_paymentSuggestions_activateAfter":{"c":"Util.CustomScalars.SQLDateTime.Exn"}}};

var wrapResponseConverterMap = {
  "Util.CustomScalars.SQLDateTime.Exn": CS_Make$Util.Dateable.Plain.Exn.serialize,
  "Util.CustomScalars.UndenominatedCurrency.Exn": CS_Decimal$Util.Decimal10_2.Exn.serialize
};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, wrapResponseConverterMap, null);
}

var responseConverter = {"__root":{"currentUserBookingSessions_nodes_paymentPlan_paymentSuggestions_pmtSuggestionTotal":{"c":"Util.CustomScalars.UndenominatedCurrency.Exn"},"currentUserBookingSessions_nodes_paymentPlan_paymentSuggestions_pmtDiscountDisplayPct":{"b":""},"currentUserBookingSessions_nodes_paymentPlan_paymentSuggestions_partialCheckoutOption":{"c":"Util.CustomScalars.UndenominatedCurrency.Exn"},"currentUserBookingSessions_nodes_paymentPlan_paymentSuggestions_overdueOffset":{"b":""},"currentUserBookingSessions_nodes_paymentPlan_paymentSuggestions_activateAfter":{"c":"Util.CustomScalars.SQLDateTime.Exn"}}};

var responseConverterMap = {
  "Util.CustomScalars.SQLDateTime.Exn": CS_Make$Util.Dateable.Plain.Exn.parse,
  "Util.CustomScalars.UndenominatedCurrency.Exn": CS_Decimal$Util.Decimal10_2.Exn.parse
};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, responseConverterMap, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: undefined,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: wrapResponseConverterMap,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: responseConverterMap,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

var Utils = {};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "voySlugString"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "equalTo",
                "variableName": "voySlugString"
              }
            ],
            "kind": "ObjectValue",
            "name": "voyageSlug"
          }
        ],
        "kind": "ObjectValue",
        "name": "filter"
      }
    ],
    "concreteType": "CurrentUserBookingSessionsConnection",
    "kind": "LinkedField",
    "name": "currentUserBookingSessions",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CurrentUserBookingSession",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PaymentPlanRollup",
            "kind": "LinkedField",
            "name": "paymentPlan",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PaymentSuggestion",
                "kind": "LinkedField",
                "name": "paymentSuggestions",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isPifSuggestion",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pmtSuggestionTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "overdueOffset",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "partialCheckoutOption",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "activateAfter",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pmtDiscountDisplayPct",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CurrentUserBookingSessionsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CurrentUserBookingSessionsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6082ecd530348033a25e64c3f86897a6",
    "id": null,
    "metadata": {},
    "name": "CurrentUserBookingSessionsQuery",
    "operationKind": "query",
    "text": "query CurrentUserBookingSessionsQuery(\n  $voySlugString: String!\n) {\n  currentUserBookingSessions(filter: {voyageSlug: {equalTo: $voySlugString}}) {\n    nodes {\n      paymentPlan {\n        paymentSuggestions {\n          isPifSuggestion\n          pmtSuggestionTotal\n          overdueOffset\n          partialCheckoutOption\n          activateAfter\n          pmtDiscountDisplayPct\n        }\n      }\n    }\n  }\n}\n"
  }
};
})());

function load(environment, variables, fetchPolicy, fetchKey, networkCacheConfig) {
  return ReactRelay.loadQuery(environment, node, convertVariables(variables), {
              fetchKey: fetchKey,
              fetchPolicy: fetchPolicy,
              networkCacheConfig: networkCacheConfig
            });
}

function queryRefToObservable(token) {
  return Caml_option.nullable_to_opt(token.source);
}

function queryRefToPromise(token) {
  return new Promise((function (resolve, param) {
                var o = queryRefToObservable(token);
                if (o !== undefined) {
                  Caml_option.valFromOption(o).subscribe({
                        complete: (function () {
                            resolve({
                                  TAG: "Ok",
                                  _0: undefined
                                });
                          })
                      });
                  return ;
                } else {
                  return resolve({
                              TAG: "Error",
                              _0: undefined
                            });
                }
              }));
}

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
exports.load = load;
exports.queryRefToObservable = queryRefToObservable;
exports.queryRefToPromise = queryRefToPromise;
/* node Not a pure module */
