// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Joy$Util = require("util/src/Joy.bs.js");
var Txt$Thin = require("../../../text/Txt.bs.js");
var DateFns = require("date-fns");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_MapInt = require("rescript/lib/js/belt_MapInt.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var AppAlert$Thin = require("../../common/atoms/AppAlert.bs.js");
var DateOnly$Util = require("util/src/DateOnly.bs.js");
var Alert = require("@mui/joy/Alert").default;
var Stack = require("@mui/joy/Stack").default;
var AppButton$Thin = require("../../common/atoms/AppButton.bs.js");
var $$Option = require("@mui/joy/Option").default;
var SelectorCard$Thin = require("../../common/molecules/SelectorCard.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var SalesFlowState$Thin = require("../../../utils/sales-flow/SalesFlowState.bs.js");
var FormControl = require("@mui/joy/FormControl").default;
var UpdateAccountData$Thin = require("../../common/mutations/UpdateAccountData.bs.js");
var FormHelperText = require("@mui/joy/FormHelperText").default;
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var Check = require("@mui/icons-material/Check").default;
var CircularProgress = require("@mui/joy/CircularProgress").default;
var ListItemDecorator = require("@mui/joy/ListItemDecorator").default;
var DirectionsBoat = require("@mui/icons-material/DirectionsBoat").default;
var DoNotDisturbAlt = require("@mui/icons-material/DoNotDisturbAlt").default;
var ModeEditOutlined = require("@mui/icons-material/ModeEditOutlined").default;
var AccountCircleOutlined = require("@mui/icons-material/AccountCircleOutlined").default;
var UpdateAccountData_UpdateAccountDataMutation_graphql$Thin = require("../../../__generated__/UpdateAccountData_UpdateAccountDataMutation_graphql.bs.js");

function ConnectedSelector(props) {
  var pax1MustBeBornBy = props.pax1MustBeBornBy;
  var setConnectedError = props.setConnectedError;
  var connectedError = props.connectedError;
  var setConnected = props.setConnected;
  var connected = props.connected;
  var connectedAccounts = props.connectedAccounts;
  var sessionPax = props.sessionPax;
  var paxNum = props.paxNum;
  var sessionData = props.sessionData;
  var updateSessionAndSave = props.updateSessionAndSave;
  var isSelected = props.isSelected;
  var match = React.useState(function () {
        return true;
      });
  var setConnectedLoading = match[1];
  var alreadyUsed = Belt_Array.keepMap(Belt_MapInt.toArray(sessionData.paxes), (function (param) {
          var v = param[1];
          if (typeof v !== "object") {
            return ;
          }
          switch (v.TAG) {
            case "Connected" :
                return CS_NonemptyStrings$Util.AccountId.toString(v._0.id);
            case "ConnectedIncomplete" :
                return CS_NonemptyStrings$Util.AccountId.toString(v._0.accountId);
            default:
              return ;
          }
        }));
  var match$1 = React.useState(function () {
        if (connected === undefined) {
          return {
                  TAG: "Pax",
                  _0: UpdateAccountData$Thin.Passenger.empty
                };
        }
        var acctId = Caml_option.valFromOption(connected);
        var u = Belt_Array.get(Belt_Array.keep(connectedAccounts, (function (v) {
                    return Caml_obj.equal(v.accountId, Caml_option.some(acctId));
                  })), 0);
        if (u !== undefined) {
          return {
                  TAG: "Pax",
                  _0: u
                };
        } else {
          return {
                  TAG: "Pax",
                  _0: UpdateAccountData$Thin.Passenger.empty
                };
        }
      });
  var setSelectedConnected = match$1[1];
  var selectedConnected = match$1[0];
  var selectedConnectedIsComplete;
  if (connected !== undefined) {
    var acctId = Caml_option.valFromOption(connected);
    var u = Belt_Array.get(Belt_Array.keep(connectedAccounts, (function (v) {
                return Caml_obj.equal(v.accountId, Caml_option.some(acctId));
              })), 0);
    selectedConnectedIsComplete = u !== undefined ? u.isComplete : false;
  } else {
    selectedConnectedIsComplete = false;
  }
  React.useEffect((function () {
          setConnectedLoading(function (param) {
                return true;
              });
          if (connected !== undefined) {
            var acctId = Caml_option.valFromOption(connected);
            var u = Belt_Array.get(Belt_Array.keep(connectedAccounts, (function (v) {
                        return Caml_obj.equal(v.accountId, Caml_option.some(acctId));
                      })), 0);
            if (u !== undefined) {
              setSelectedConnected(function (param) {
                    return {
                            TAG: "Pax",
                            _0: u
                          };
                  });
            }
            
          }
          setConnectedLoading(function (param) {
                return false;
              });
        }), [
        selectedConnectedIsComplete,
        Belt_Option.getWithDefault(connected, CS_NonemptyStrings$Util.AccountId.Exn.fromPrimitive("nonce"))
      ]);
  React.useEffect((function () {
          setConnectedLoading(function (param) {
                return true;
              });
          if (connected !== undefined) {
            var acctId = Caml_option.valFromOption(connected);
            setSelectedConnected(function (param) {
                  var u = Belt_Array.get(Belt_Array.keep(connectedAccounts, (function (v) {
                              return Caml_obj.equal(v.accountId, Caml_option.some(acctId));
                            })), 0);
                  if (u === undefined) {
                    return {
                            TAG: "Pax",
                            _0: UpdateAccountData$Thin.Passenger.empty
                          };
                  }
                  var tmp;
                  if (sessionPax !== undefined && !(typeof sessionPax !== "object" || sessionPax.TAG !== "ConnectedIncomplete")) {
                    var p = sessionPax._0;
                    tmp = {
                      accountId: u.accountId,
                      isComplete: true,
                      isBookable: u.isBookable,
                      preferredFirstName: u.preferredFirstName,
                      preferredLastName: u.preferredLastName,
                      docFirstName: CS_NonemptyStrings$Util.NonEmptyString.toString(p.iDocFirstName),
                      docLastName: CS_NonemptyStrings$Util.NonEmptyString.toString(p.iDocLastName),
                      docBirthdate: Caml_option.some(p.iDocBirthdate),
                      phone: u.phone,
                      phoneCountryCode: u.phoneCountryCode,
                      postalCode: u.postalCode,
                      residenceCountry: u.residenceCountry,
                      docGender: p.iDocGender,
                      docCitizenship: Caml_option.some(p.iDocCitizenship),
                      email: u.email,
                      loyalty: u.loyalty,
                      acquisitionSlug: u.acquisitionSlug
                    };
                  } else {
                    tmp = u;
                  }
                  return {
                          TAG: "Pax",
                          _0: tmp
                        };
                });
          } else {
            setSelectedConnected(function (param) {
                  return {
                          TAG: "Pax",
                          _0: UpdateAccountData$Thin.Passenger.empty
                        };
                });
          }
          setConnectedLoading(function (param) {
                return false;
              });
        }), [Belt_Option.getWithDefault(connected, CS_NonemptyStrings$Util.AccountId.Exn.fromPrimitive("nonce"))]);
  React.useEffect((function () {
          if (connected !== undefined) {
            var acctId = Caml_option.valFromOption(connected);
            var v = Belt_Array.get(Belt_Array.keep(connectedAccounts, (function (v) {
                        return Caml_obj.equal(v.accountId, Caml_option.some(acctId));
                      })), 0);
            if (v !== undefined) {
              var match = v.isComplete;
              var match$1 = v.accountId;
              if (match$1 !== undefined && match) {
                updateSessionAndSave(SalesFlowState$Thin.Alter.upsertPax(sessionData, paxNum, {
                          TAG: "Connected",
                          _0: {
                            id: Caml_option.valFromOption(match$1)
                          }
                        }), setConnectedError, setConnectedLoading, undefined);
              }
              
            }
            
          }
          
        }), [Belt_Option.getWithDefault(connected, CS_NonemptyStrings$Util.AccountId.Exn.fromPrimitive("nonce"))]);
  var incompleteConnectedAllTravelFieldsFilledOut = function (p) {
    if (p !== undefined && !(typeof p !== "object" || p.TAG !== "ConnectedIncomplete")) {
      return true;
    } else {
      return false;
    }
  };
  var fieldsNotSaveable = function (p, s) {
    if (p.TAG === "Account") {
      return false;
    }
    if (s === undefined) {
      return false;
    }
    if (typeof s !== "object") {
      return false;
    }
    if (s.TAG !== "ConnectedIncomplete") {
      return false;
    }
    var match = s._0;
    var match$1 = p._0;
    if (Caml_obj.equal(match$1.docFirstName, CS_NonemptyStrings$Util.NonEmptyString.toString(match.iDocFirstName)) && Caml_obj.equal(match$1.docLastName, CS_NonemptyStrings$Util.NonEmptyString.toString(match.iDocLastName)) && Caml_obj.equal(match$1.docGender, match.iDocGender) && Caml_obj.equal(match$1.docBirthdate, Caml_option.some(match.iDocBirthdate))) {
      return Caml_obj.equal(match$1.docCitizenship, Caml_option.some(match.iDocCitizenship));
    } else {
      return false;
    }
  };
  var isAgeGated = function (paxDob, pax1MustBeBornBy, isPrimary) {
    if (paxDob !== undefined && pax1MustBeBornBy !== undefined && isPrimary) {
      return DateFns.isBefore(CS_Make$Util.Dateable.Plain.toDate(Caml_option.valFromOption(pax1MustBeBornBy)), DateOnly$Util.toDate(Caml_option.valFromOption(paxDob)));
    } else {
      return false;
    }
  };
  var tmp;
  tmp = match[0] ? JsxRuntime.jsx(Stack, {
          direction: "row",
          justifyContent: "center",
          children: Caml_option.some(JsxRuntime.jsx(CircularProgress, {
                    size: "md",
                    variant: "solid"
                  }))
        }) : (
      connected !== undefined && selectedConnected.TAG !== "Account" ? (
          selectedConnected._0.isComplete ? JsxRuntime.jsx(Alert, {
                  children: "Passenger information is on file. You can update it later.",
                  color: "success",
                  startDecorator: Caml_option.some(JsxRuntime.jsx(Check, {})),
                  sx: {
                    borderLeft: "4px solid",
                    borderColor: "success.500",
                    alignItems: "flex-start"
                  }
                }) : JsxRuntime.jsxs(Stack, {
                  direction: "column",
                  spacing: 2,
                  children: [
                    incompleteConnectedAllTravelFieldsFilledOut(sessionPax) ? null : JsxRuntime.jsx(Alert, {
                            children: "Passenger information is incomplete. Please fill out the form below!",
                            color: "warning",
                            startDecorator: Caml_option.some(JsxRuntime.jsx(ModeEditOutlined, {})),
                            sx: {
                              borderLeft: "4px solid",
                              borderColor: "warningSecondary.500",
                              alignItems: "flex-start"
                            }
                          }),
                    JsxRuntime.jsx(Stack, {
                          sx: {
                            p: 2,
                            border: "1px solid",
                            borderColor: "neutral.300",
                            backgroundColor: "neutral.200",
                            borderRadius: "sm"
                          },
                          children: Caml_option.some(JsxRuntime.jsx(UpdateAccountData$Thin.Form.Travel.make, {
                                    fragmentRefs: props.countryFrag,
                                    titleSize: "md",
                                    update: selectedConnected,
                                    setUpdate: setSelectedConnected,
                                    allFieldsEditable: true,
                                    disabled: false,
                                    forceValidate: true
                                  }))
                        }),
                    JsxRuntime.jsx(Stack, {
                          direction: "column",
                          sx: {
                            width: "fit-content",
                            py: 1
                          },
                          children: Caml_option.some(JsxRuntime.jsx(AppButton$Thin.make, {
                                    loading: props.loading,
                                    onClick: (function (param) {
                                        setConnectedError(function (param) {
                                              
                                            });
                                        setConnectedLoading(function (param) {
                                              return true;
                                            });
                                        if (selectedConnected.TAG === "Account") {
                                          return ;
                                        }
                                        var p = selectedConnected._0;
                                        var match = p.accountId;
                                        var match$1 = Belt_Option.map(p.docFirstName, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive);
                                        var match$2 = Belt_Option.map(p.docLastName, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive);
                                        var match$3 = Belt_Option.map(p.docGender, UpdateAccountData_UpdateAccountDataMutation_graphql$Thin.Utils.docGender_decode);
                                        var match$4 = p.docBirthdate;
                                        var match$5 = p.docCitizenship;
                                        if (match !== undefined && match$1 !== undefined && match$1.TAG === "Ok" && match$2 !== undefined && match$2.TAG === "Ok" && match$3 !== undefined) {
                                          var iDocGender = Caml_option.valFromOption(match$3);
                                          if (iDocGender !== undefined && match$4 !== undefined && match$5 !== undefined) {
                                            var pax = {
                                              TAG: "ConnectedIncomplete",
                                              _0: {
                                                accountId: Caml_option.valFromOption(match),
                                                iDocFirstName: match$1._0,
                                                iDocLastName: match$2._0,
                                                iDocGender: iDocGender,
                                                iDocBirthdate: Caml_option.valFromOption(match$4),
                                                iDocCitizenship: Caml_option.valFromOption(match$5)
                                              }
                                            };
                                            return updateSessionAndSave(SalesFlowState$Thin.Alter.upsertPax(sessionData, paxNum, pax), setConnectedError, setConnectedLoading, undefined);
                                          }
                                          
                                        }
                                        setConnectedError(function (param) {
                                              return "Please complete all fields";
                                            });
                                      }),
                                    disabled: fieldsNotSaveable(selectedConnected, sessionPax),
                                    children: "Save"
                                  }))
                        })
                  ]
                })
        ) : null
    );
  return JsxRuntime.jsx(SelectorCard$Thin.make, {
              isSelected: isSelected,
              onClick: props.onClick,
              title: props.title,
              description: props.description,
              children: JsxRuntime.jsxs(Stack, {
                    spacing: 1,
                    sx: {
                      display: isSelected ? "flex" : "none",
                      overflow: "hidden",
                      py: 1
                    },
                    children: [
                      JsxRuntime.jsx(Stack, {
                            direction: "column",
                            spacing: 2,
                            sx: {
                              pt: 1
                            },
                            children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                                      children: [
                                        JsxRuntime.jsx(FormLabel, {
                                              children: Caml_option.some(Txt$Thin.BookingDetails.addPassConnectedLabel.value)
                                            }),
                                        JsxRuntime.jsx(Joy$Util.JoySelect.make, {
                                              children: Caml_option.some(Belt_Array.mapWithIndex(connectedAccounts, (function (i, p) {
                                                          var acctId = Belt_Option.getWithDefault(Belt_Option.map(p.accountId, CS_NonemptyStrings$Util.AccountId.toString), String(i));
                                                          var name = Belt_Option.getWithDefault(p.preferredFirstName, "") + " " + Belt_Option.getWithDefault(p.preferredLastName, "");
                                                          var isAgeGated$1 = isAgeGated(p.docBirthdate, pax1MustBeBornBy, paxNum === 1);
                                                          var match = Belt_Array.some(alreadyUsed, (function (v) {
                                                                  return v === acctId;
                                                                })) || !p.isBookable ? [
                                                              true,
                                                              JsxRuntime.jsx(DirectionsBoat, {}),
                                                              "Already booked"
                                                            ] : (
                                                              isAgeGated$1 ? [
                                                                  true,
                                                                  JsxRuntime.jsx(DoNotDisturbAlt, {}),
                                                                  "Cannot be primary"
                                                                ] : [
                                                                  false,
                                                                  JsxRuntime.jsx(AccountCircleOutlined, {}),
                                                                  ""
                                                                ]
                                                            );
                                                          return JsxRuntime.jsxs($$Option, {
                                                                      value: acctId,
                                                                      children: [
                                                                        JsxRuntime.jsx(ListItemDecorator, {
                                                                              children: Caml_option.some(match[1]),
                                                                              sx: {
                                                                                "--ListItemDecorator-size": "1.75rem"
                                                                              }
                                                                            }),
                                                                        name
                                                                      ],
                                                                      disabled: match[0]
                                                                    }, acctId);
                                                        }))),
                                              onChange: (function (param, v) {
                                                  setConnectedLoading(function (param) {
                                                        return true;
                                                      });
                                                  if (v === undefined) {
                                                    return setConnectedLoading(function (param) {
                                                                return false;
                                                              });
                                                  }
                                                  var v$1 = Caml_option.valFromOption(v);
                                                  setConnected(function (param) {
                                                        return v$1;
                                                      });
                                                }),
                                              placeholder: Txt$Thin.BookingDetails.addPassConnectedPlaceholder.value,
                                              slotProps: {
                                                listbox: {
                                                  sx: {
                                                    zIndex: "modal"
                                                  }
                                                }
                                              },
                                              value: connected,
                                              variant: "outlined"
                                            }),
                                        JsxRuntime.jsx(FormHelperText, {
                                              children: Caml_option.some(Txt$Thin.BookingDetails.addPassConnectedHelperText.value)
                                            })
                                      ]
                                    }))
                          }),
                      tmp,
                      connectedError !== undefined && isSelected ? JsxRuntime.jsx(AppAlert$Thin.make, {
                              message: connectedError,
                              type_: "error"
                            }) : JsxRuntime.jsx(JsxRuntime.Fragment, {})
                    ]
                  }),
              locked: props.locked
            });
}

var make = ConnectedSelector;

exports.make = make;
/* react Not a pure module */
