// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var RequestCabinHoldByCategory_RequestCabinHoldByCategoryMutation_graphql$Thin = require("../../../__generated__/RequestCabinHoldByCategory_RequestCabinHoldByCategoryMutation_graphql.bs.js");

var convertVariables = RequestCabinHoldByCategory_RequestCabinHoldByCategoryMutation_graphql$Thin.Internal.convertVariables;

var convertResponse = RequestCabinHoldByCategory_RequestCabinHoldByCategoryMutation_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = RequestCabinHoldByCategory_RequestCabinHoldByCategoryMutation_graphql$Thin.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, RequestCabinHoldByCategory_RequestCabinHoldByCategoryMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, RequestCabinHoldByCategory_RequestCabinHoldByCategoryMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var RequestCabinHoldByCategoryMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(flow, _voyageSlug, setPageLevelError) {
  var match = use();
  var mutate = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setError = setPageLevelError !== undefined ? setPageLevelError : match$1[1];
  var requestCabinHoldByCategory = function (urlData, onSuccess) {
    setError(function (param) {
          
        });
    var match;
    switch (flow) {
      case "ByCategory" :
          var match$1 = urlData.cabinTargetingByCategory;
          match = [
            match$1[0],
            match$1[3],
            match$1[4]
          ];
          break;
      case "AllNoCabin" :
          var match$2 = urlData.cabinTargetingByAllNoCabin;
          match = [
            match$2[0],
            match$2[3],
            match$2[6]
          ];
          break;
      case "All" :
          var match$3 = urlData.cabinTargetingByAll;
          match = [
            match$3[0],
            match$3[3],
            match$3[7]
          ];
          break;
      
    }
    var _fareClassSlug = match[2];
    var _categorySlug = match[1];
    if (_categorySlug !== undefined && _fareClassSlug !== undefined) {
      mutate({
            input: {
              _voyageSlug: _voyageSlug,
              _categorySlug: Caml_option.valFromOption(_categorySlug),
              _fareClassSlug: Caml_option.valFromOption(_fareClassSlug),
              _forOccupancy: match[0]
            }
          }, undefined, undefined, undefined, (function (res, err) {
              if (err !== undefined) {
                return setError(function (param) {
                            return "Cabin hold request unsuccessful. Please try again.";
                          });
              }
              var match = res.requestCabinHoldByCategory;
              if (match !== undefined) {
                var datetime = match.datetime;
                if (datetime !== undefined) {
                  return onSuccess(Caml_option.valFromOption(datetime));
                }
                
              }
              setError(function (param) {
                    return "Cabin hold request unsuccessful. Please try again.";
                  });
            }), (function (err) {
              console.error(err);
              setError(function (param) {
                    return "Cabin hold request unsuccessful. Please try again.";
                  });
            }), undefined);
      return ;
    }
    setError(function (param) {
          return "Error: missing category or fare class";
        });
  };
  var onSubmit = function (e, urlData, onSuccess) {
    e.preventDefault();
    requestCabinHoldByCategory(urlData, onSuccess);
  };
  return {
          onSubmit: onSubmit,
          requestCabinHoldByCategory: requestCabinHoldByCategory,
          error: match$1[0],
          setError: setError,
          isMutating: match[1]
        };
}

exports.RequestCabinHoldByCategoryMutation = RequestCabinHoldByCategoryMutation;
exports.useMutation = useMutation;
/* commitMutation Not a pure module */
