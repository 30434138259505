// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Txt$Thin = require("../text/Txt.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var CACard$Thin = require("../components/connected-accounts/CACard.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Stack = require("@mui/joy/Stack").default;
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var PageFrame$Thin = require("../components/wrappers/PageFrame.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var UpdateAccountData$Thin = require("../components/common/mutations/UpdateAccountData.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var ConnectedAccountsPageQuery_graphql$Thin = require("../__generated__/ConnectedAccountsPageQuery_graphql.bs.js");
var ConnectedAccountsPage_query_graphql$Thin = require("../__generated__/ConnectedAccountsPage_query_graphql.bs.js");

var convertFragment = ConnectedAccountsPage_query_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(ConnectedAccountsPage_query_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, ConnectedAccountsPage_query_graphql$Thin.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(ConnectedAccountsPage_query_graphql$Thin.node, convertFragment, fRef);
}

var makeRefetchVariables = ConnectedAccountsPageQuery_graphql$Thin.Types.makeRefetchVariables;

var convertRefetchVariables = ConnectedAccountsPageQuery_graphql$Thin.Internal.convertVariables;

function useRefetchable(fRef) {
  return RescriptRelay_Fragment.useRefetchableFragment(ConnectedAccountsPage_query_graphql$Thin.node, convertFragment, convertRefetchVariables, fRef);
}

var QueryFragment_accountStatus_decode = ConnectedAccountsPage_query_graphql$Thin.Utils.accountStatus_decode;

var QueryFragment_accountStatus_fromString = ConnectedAccountsPage_query_graphql$Thin.Utils.accountStatus_fromString;

var QueryFragment_docGender_decode = ConnectedAccountsPage_query_graphql$Thin.Utils.docGender_decode;

var QueryFragment_docGender_fromString = ConnectedAccountsPage_query_graphql$Thin.Utils.docGender_fromString;

var QueryFragment = {
  accountStatus_decode: QueryFragment_accountStatus_decode,
  accountStatus_fromString: QueryFragment_accountStatus_fromString,
  docGender_decode: QueryFragment_docGender_decode,
  docGender_fromString: QueryFragment_docGender_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment,
  makeRefetchVariables: makeRefetchVariables,
  convertRefetchVariables: convertRefetchVariables,
  useRefetchable: useRefetchable
};

function ConnectedAccountsPage(props) {
  var match = useRefetchable(props.fragmentRefs);
  var refetch = match[1];
  var query = match[0];
  var refetch$1 = function (param) {
    return refetch(makeRefetchVariables(undefined), "store-and-network", undefined);
  };
  var match$1 = query.connectedAccounts;
  var connectedAccounts = match$1 !== undefined ? Belt_Array.map(match$1.nodes, (function (node) {
            var lName = node.lastName;
            var initials;
            if (lName !== undefined) {
              var firstInitial = CS_NonemptyStrings$Util.NonEmptyString.toString(node.firstName)[0];
              var lastInitial = CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(lName))[0];
              initials = firstInitial + lastInitial;
            } else {
              initials = CS_NonemptyStrings$Util.NonEmptyString.toString(node.firstName)[0];
            }
            var match = node.legalDocumentFirstName;
            var match$1 = node.legalDocumentLastName;
            var tmp;
            if (match !== undefined) {
              var firstName = Caml_option.valFromOption(match);
              tmp = match$1 !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toString(firstName) + " " + CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(match$1)) : CS_NonemptyStrings$Util.NonEmptyString.toString(firstName);
            } else {
              tmp = match$1 !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(match$1)) : undefined;
            }
            var furtherDetails_invitationEmail = Belt_Option.map(node.invitationEmail, CS_Emails$Util.Email.toString);
            var furtherDetails_invitationExpiry = node.invitationExpiry;
            var furtherDetails_fullName = node.fullDisplayName;
            var furtherDetails_email = Belt_Option.map(node.email, CS_Emails$Util.Email.toString);
            var furtherDetails = {
              invitationEmail: furtherDetails_invitationEmail,
              invitationExpiry: furtherDetails_invitationExpiry,
              fullName: furtherDetails_fullName,
              legalName: tmp,
              initials: initials,
              email: furtherDetails_email
            };
            var user = UpdateAccountData$Thin.Utils.convertUserAccount({
                  TAG: "Connected",
                  _0: node
                });
            var countryFrag = query.fragmentRefs;
            var fragmentRefs = node.passenger_record.fragmentRefs;
            return [
                    furtherDetails,
                    user,
                    countryFrag,
                    fragmentRefs
                  ];
          })) : [];
  return JsxRuntime.jsx(PageFrame$Thin.make, {
              children: JsxRuntime.jsxs(Stack, {
                    direction: "column",
                    alignItems: "flex-start",
                    spacing: 2,
                    sx: {
                      width: "100%"
                    },
                    children: [
                      JsxRuntime.jsx(Typography, {
                            level: "h3",
                            component: "h1",
                            children: Caml_option.some(Txt$Thin.ConnectedAccounts.connectedAccountsHeading.value)
                          }),
                      JsxRuntime.jsx(Stack, {
                            spacing: 2,
                            sx: {
                              width: "100%"
                            },
                            children: Caml_option.some(Belt_Array.mapWithIndex(connectedAccounts, (function (i, param) {
                                        return JsxRuntime.jsx(CACard$Thin.make, {
                                                    fragmentRefs: param[3],
                                                    countryFrag: param[2],
                                                    f: param[0],
                                                    u: param[1],
                                                    refetch: refetch$1
                                                  }, String(i));
                                      })))
                          })
                    ]
                  }),
              backgroundColor: "background.level1"
            });
}

var make = ConnectedAccountsPage;

exports.QueryFragment = QueryFragment;
exports.make = make;
/* Txt-Thin Not a pure module */
