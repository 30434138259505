// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Txt$Thin = require("../../../text/Txt.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var ChangeEmailSubmit_ChangeEmailMutation_graphql$Thin = require("../../../__generated__/ChangeEmailSubmit_ChangeEmailMutation_graphql.bs.js");

var convertVariables = ChangeEmailSubmit_ChangeEmailMutation_graphql$Thin.Internal.convertVariables;

var convertResponse = ChangeEmailSubmit_ChangeEmailMutation_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = ChangeEmailSubmit_ChangeEmailMutation_graphql$Thin.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, ChangeEmailSubmit_ChangeEmailMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, ChangeEmailSubmit_ChangeEmailMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var ChangeEmailMutation_changeEmailErr_decode = ChangeEmailSubmit_ChangeEmailMutation_graphql$Thin.Utils.changeEmailErr_decode;

var ChangeEmailMutation_changeEmailErr_fromString = ChangeEmailSubmit_ChangeEmailMutation_graphql$Thin.Utils.changeEmailErr_fromString;

var ChangeEmailMutation = {
  changeEmailErr_decode: ChangeEmailMutation_changeEmailErr_decode,
  changeEmailErr_fromString: ChangeEmailMutation_changeEmailErr_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(refresh) {
  var match = use();
  var mutate = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var newEmail = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setError = match$2[1];
  var match$3 = React.useState(function () {
        return false;
      });
  var setSuccess = match$3[1];
  var onSubmit = function (e) {
    e.preventDefault();
    setError(function (param) {
          
        });
    var newEmail$1 = CS_Emails$Util.Email.fromPrimitive(newEmail);
    if (newEmail$1.TAG === "Ok") {
      mutate({
            input: {
              newEmail: newEmail$1._0
            }
          }, undefined, undefined, undefined, (function (resp, err) {
              if (err === undefined) {
                var match = resp.changeEmail;
                if (match !== undefined) {
                  var err$1 = match.changeEmailErr;
                  if (err$1 !== undefined) {
                    var error;
                    if (err$1 === "EMAIL_CANT_BE_CHANGED_ASK_SUPPORT" || err$1 === "EMAIL_ALREADY_IN_USE" || err$1 === "NOT_SIGNED_ON_STATUS") {
                      switch (err$1) {
                        case "EMAIL_ALREADY_IN_USE" :
                            error = Txt$Thin.$$Error.emailInUse.value;
                            break;
                        case "EMAIL_CANT_BE_CHANGED_ASK_SUPPORT" :
                            error = Txt$Thin.$$Error.emailCantBeChanged.value;
                            break;
                        case "NOT_SIGNED_ON_STATUS" :
                            error = Txt$Thin.Account.emailModalError.value;
                            break;
                        
                      }
                    } else {
                      error = Txt$Thin.Account.emailModalError.value;
                    }
                    setError(function (param) {
                          return error;
                        });
                    return ;
                  }
                  
                }
                refresh();
                setSuccess(function (param) {
                      return true;
                    });
                return ;
              }
              setError(function (param) {
                    return Txt$Thin.$$Error.genericFormSubmit.value;
                  });
            }), (function (param) {
              setError(function (param) {
                    return Txt$Thin.$$Error.genericFormSubmit.value;
                  });
            }), undefined);
      return ;
    }
    var err = newEmail$1._0;
    setError(function (param) {
          return err;
        });
  };
  return {
          newEmail: newEmail,
          setNewEmail: match$1[1],
          error: match$2[0],
          setError: setError,
          success: match$3[0],
          onSubmit: onSubmit,
          isMutating: match[1]
        };
}

exports.ChangeEmailMutation = ChangeEmailMutation;
exports.useMutation = useMutation;
/* commitMutation Not a pure module */
