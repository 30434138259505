// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_MapInt = require("rescript/lib/js/belt_MapInt.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var PaxFrame$Thin = require("./PaxFrame.bs.js");
var Stack = require("@mui/joy/Stack").default;
var SelectorCard$Thin = require("../../common/molecules/SelectorCard.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var InviteSelector$Thin = require("./InviteSelector.bs.js");
var SalesFlowState$Thin = require("../../../utils/sales-flow/SalesFlowState.bs.js");
var LoyaltySelector$Thin = require("./LoyaltySelector.bs.js");
var ConnectedSelector$Thin = require("./ConnectedSelector.bs.js");
var ProvideInfoSelector$Thin = require("./ProvideInfoSelector.bs.js");

function PaxForm(props) {
  var countryFrag = props.countryFrag;
  var pax1MustBeBornBy = props.pax1MustBeBornBy;
  var locked = props.pageLocked;
  var currentUserConnectedInfo = props.currentUserConnectedInfo;
  var bc = props.bc;
  var paxNum = props.paxNum;
  var sessionSaveLoading = props.sessionSaveLoading;
  var updateSessionData = props.updateSessionData;
  var updateSessionAndSave = props.updateSessionAndSave;
  var sessionData = props.sessionData;
  var sessionPax = Belt_MapInt.get(sessionData.paxes, paxNum);
  var match = React.useState(function () {
        if (sessionPax === undefined) {
          return ;
        }
        if (typeof sessionPax !== "object") {
          return "TBA";
        }
        switch (sessionPax.TAG) {
          case "BC" :
          case "BCIncomplete" :
              if (paxNum === 1) {
                return "Provide";
              } else {
                return ;
              }
          case "Invite" :
              return "Invite";
          case "Connected" :
          case "ConnectedIncomplete" :
              return "Connected";
          case "Loyalty" :
              return "Loyalty";
          case "Provide" :
              return "Provide";
          
        }
      });
  var setWhichSelected = match[1];
  var whichSelected = match[0];
  React.useState(function () {
        
      });
  var match$1 = React.useState(function () {
        if (sessionPax === undefined) {
          return ;
        }
        if (typeof sessionPax !== "object") {
          return ;
        }
        switch (sessionPax.TAG) {
          case "Connected" :
              return Caml_option.some(sessionPax._0.id);
          case "ConnectedIncomplete" :
              return Caml_option.some(sessionPax._0.accountId);
          default:
            return ;
        }
      });
  var setConnected = match$1[1];
  var match$2 = React.useState(function () {
        
      });
  var setConnectedError = match$2[1];
  var match$3 = React.useState(function () {
        if (sessionPax === undefined) {
          return false;
        }
        var match = paxNum === 1;
        if (typeof sessionPax !== "object") {
          return false;
        }
        switch (sessionPax.TAG) {
          case "BC" :
          case "BCIncomplete" :
              if (match) {
                return true;
              } else {
                return false;
              }
          default:
            return false;
        }
      });
  var setSameAsBc = match$3[1];
  var sameAsBc = match$3[0];
  var match$4 = React.useState(function () {
        
      });
  var setProvideInfoError = match$4[1];
  var setSameAsBc$1 = function (param) {
    setProvideInfoError(function (param) {
          
        });
    if (sameAsBc) {
      
    } else {
      setWhichSelected(function (param) {
            return "Provide";
          });
    }
    if (!sameAsBc && bc.TAG !== "Account") {
      var match = bc._0;
      var id = match.accountId;
      if (id !== undefined && match.isComplete) {
        updateSessionAndSave(SalesFlowState$Thin.Alter.upsertPax(sessionData, paxNum, {
                  TAG: "BC",
                  _0: {
                    id: Caml_option.valFromOption(id)
                  }
                }), setProvideInfoError, undefined, undefined);
      }
      
    }
    setSameAsBc(function (bc) {
          return !bc;
        });
  };
  var sessionPax$1 = Belt_MapInt.get(sessionData.paxes, paxNum);
  var lockedOrSelect = function (selector) {
    if (locked) {
      return ;
    } else {
      return setWhichSelected(function (param) {
                  return selector;
                });
    }
  };
  var isPaxOne = paxNum === 1;
  var tmp = false;
  if (isPaxOne) {
    var tmp$1;
    if (bc.TAG === "Account") {
      tmp$1 = false;
    } else {
      var match$5 = bc._0;
      tmp$1 = match$5.accountId !== undefined && match$5.isBookable ? true : false;
    }
    tmp = tmp$1;
  }
  return JsxRuntime.jsx(PaxFrame$Thin.make, {
              title: isPaxOne ? "Passenger 1 (Primary Contact)" : "Passenger " + String(paxNum),
              required: isPaxOne,
              sameAsBc: sameAsBc && paxNum === 1 && Caml_obj.equal(whichSelected, "Provide"),
              showToggle: tmp,
              onToggleSameAsBc: (function () {
                  var match = paxNum === 1;
                  if (!sameAsBc && bc.TAG !== "Account") {
                    var match$1 = bc._0;
                    var acctId = match$1.accountId;
                    if (acctId !== undefined && match$1.isComplete && match$1.isBookable && match) {
                      updateSessionAndSave(SalesFlowState$Thin.Alter.upsertPax(sessionData, paxNum, {
                                TAG: "BC",
                                _0: {
                                  id: Caml_option.valFromOption(acctId)
                                }
                              }), undefined, undefined, undefined);
                      setConnected(function (param) {
                            
                          });
                      setConnectedError(function (param) {
                            
                          });
                    }
                    
                  }
                  setSameAsBc$1(function (sabc) {
                        return !sabc;
                      });
                }),
              children: JsxRuntime.jsxs(Stack, {
                    direction: "column",
                    spacing: 2,
                    children: [
                      JsxRuntime.jsx(ProvideInfoSelector$Thin.make, {
                            isSelected: Caml_obj.equal(whichSelected, "Provide"),
                            onClick: (function () {
                                lockedOrSelect("Provide");
                                setConnected(function (param) {
                                      
                                    });
                                setConnectedError(function (param) {
                                      
                                    });
                              }),
                            title: "Provide Info",
                            description: "Enter passenger details below for this guest.",
                            sameAsBc: sameAsBc,
                            bc: bc,
                            setBc: props.setBc,
                            updateSessionAndSave: updateSessionAndSave,
                            sessionData: sessionData,
                            paxNum: paxNum,
                            sessionPax: sessionPax$1,
                            whichSelected: whichSelected,
                            locked: locked,
                            loading: sessionSaveLoading && Caml_obj.equal(whichSelected, "Provide"),
                            provideInfoError: match$4[0],
                            setProvideInfoError: setProvideInfoError,
                            pax1MustBeBornBy: pax1MustBeBornBy,
                            countryFrag: countryFrag
                          }),
                      JsxRuntime.jsx(ConnectedSelector$Thin.make, {
                            isSelected: Caml_obj.equal(whichSelected, "Connected"),
                            onClick: (function () {
                                lockedOrSelect("Connected");
                                if (sameAsBc) {
                                  return setSameAsBc$1(function (param) {
                                              return false;
                                            });
                                }
                                
                              }),
                            title: "Select a connected account",
                            description: "Select from one of your connected accounts.",
                            updateSessionAndSave: updateSessionAndSave,
                            sessionData: sessionData,
                            paxNum: paxNum,
                            sessionPax: sessionPax$1,
                            connectedAccounts: Belt_Option.getWithDefault(currentUserConnectedInfo, []),
                            countryFrag: countryFrag,
                            locked: locked || Belt_Option.isNone(currentUserConnectedInfo),
                            loading: sessionSaveLoading && Caml_obj.equal(whichSelected, "Connected"),
                            connected: match$1[0],
                            setConnected: setConnected,
                            connectedError: match$2[0],
                            setConnectedError: setConnectedError,
                            pax1MustBeBornBy: pax1MustBeBornBy
                          }),
                      JsxRuntime.jsx(LoyaltySelector$Thin.make, {
                            isSelected: Caml_obj.equal(whichSelected, "Loyalty"),
                            onClick: (function () {
                                lockedOrSelect("Loyalty");
                                setConnected(function (param) {
                                      
                                    });
                                setConnectedError(function (param) {
                                      
                                    });
                                if (sameAsBc) {
                                  return setSameAsBc$1(function (param) {
                                              return false;
                                            });
                                }
                                
                              }),
                            title: "Add by loyalty number",
                            description: "Add a guest by their cruiseline loyalty number.",
                            updateSessionAndSave: updateSessionAndSave,
                            sessionData: sessionData,
                            paxNum: paxNum,
                            sessionPax: sessionPax$1,
                            locked: locked,
                            loading: sessionSaveLoading && Caml_obj.equal(whichSelected, "Loyalty"),
                            voyageSlug: props.voyageSlug
                          }),
                      isPaxOne ? null : JsxRuntime.jsxs(JsxRuntime.Fragment, {
                              children: [
                                JsxRuntime.jsx(InviteSelector$Thin.make, {
                                      isSelected: Caml_obj.equal(whichSelected, "Invite"),
                                      onClick: (function () {
                                          lockedOrSelect("Invite");
                                          setConnected(function (param) {
                                                
                                              });
                                          setConnectedError(function (param) {
                                                
                                              });
                                        }),
                                      title: "Invite by email",
                                      description: "Invite a guest to join your booking.",
                                      updateSessionAndSave: updateSessionAndSave,
                                      sessionData: sessionData,
                                      paxNum: paxNum,
                                      sessionPax: sessionPax$1,
                                      locked: locked,
                                      loading: sessionSaveLoading && Caml_obj.equal(whichSelected, "Invite")
                                    }),
                                JsxRuntime.jsx(SelectorCard$Thin.make, {
                                      isSelected: Caml_obj.equal(whichSelected, "TBA"),
                                      onClick: (function () {
                                          if (locked) {
                                            return ;
                                          }
                                          setWhichSelected(function (param) {
                                                return "TBA";
                                              });
                                          setConnected(function (param) {
                                                
                                              });
                                          setConnectedError(function (param) {
                                                
                                              });
                                          var newSessionData = SalesFlowState$Thin.Alter.upsertPax(sessionData, paxNum, "TBA");
                                          updateSessionData(newSessionData);
                                        }),
                                      title: "I'll fill this out later",
                                      description: "I'm not sure who will be traveling yet.",
                                      children: null
                                    })
                              ]
                            })
                    ]
                  }),
              locked: locked
            });
}

var make = PaxForm;

exports.make = make;
/* react Not a pure module */
