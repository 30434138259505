// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Txt$Thin = require("../../text/Txt.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SysCtx$Thin = require("../../utils/SysCtx.bs.js");
var Chip = require("@mui/joy/Chip").default;
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var DateOnly$Util = require("util/src/DateOnly.bs.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var AspectRatio = require("@mui/joy/AspectRatio").default;
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var Cancel = require("@mui/icons-material/Cancel").default;
var $$History = require("@mui/icons-material/History").default;
var ModeEdit = require("@mui/icons-material/ModeEdit").default;
var AttachMoney = require("@mui/icons-material/AttachMoney").default;
var Person2Outlined = require("@mui/icons-material/Person2Outlined").default;
var SailingOutlined = require("@mui/icons-material/SailingOutlined").default;
var BookingHistorySection_query_graphql$Thin = require("../../__generated__/BookingHistorySection_query_graphql.bs.js");
var BookingHistorySection_listitem_graphql$Thin = require("../../__generated__/BookingHistorySection_listitem_graphql.bs.js");

var convertFragment = BookingHistorySection_query_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(BookingHistorySection_query_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BookingHistorySection_query_graphql$Thin.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(BookingHistorySection_query_graphql$Thin.node, convertFragment, fRef);
}

var QueryFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

var convertFragment$1 = BookingHistorySection_listitem_graphql$Thin.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(BookingHistorySection_listitem_graphql$Thin.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BookingHistorySection_listitem_graphql$Thin.node, convertFragment$1);
}

function readResolverFragment$1(fRef) {
  return RescriptRelay_Fragment.read(BookingHistorySection_listitem_graphql$Thin.node, convertFragment$1, fRef);
}

var ListitemFragment_lifecycleStatus_decode = BookingHistorySection_listitem_graphql$Thin.Utils.lifecycleStatus_decode;

var ListitemFragment_lifecycleStatus_fromString = BookingHistorySection_listitem_graphql$Thin.Utils.lifecycleStatus_fromString;

var ListitemFragment = {
  lifecycleStatus_decode: ListitemFragment_lifecycleStatus_decode,
  lifecycleStatus_fromString: ListitemFragment_lifecycleStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  use: use$1,
  useOpt: useOpt$1,
  readResolverFragment: readResolverFragment$1
};

function BookingHistorySection$HistoricalVoyage(props) {
  var query = use$1(props.fragmentRefs);
  var voyageSlug = CS_Slugs$Util.VoyageSlug.toString(query.voyageSlug);
  var bookingId = CS_NonemptyStrings$Util.BookingId.toString(query.bookingId);
  var remainingPax = (query.numBerths - (
      query.isPax ? 1 : 0
    ) | 0) - Belt_Option.getWithDefault(query.connectedPassengers, []).length | 0;
  var match = query.lifecycleStatus;
  var lifecycleChip;
  if (match === "CANCELED" || match === "STAGED" || match === "DRAFT" || match === "ACTIVE" || match === "HISTORY") {
    switch (match) {
      case "ACTIVE" :
          lifecycleChip = JsxRuntime.jsx(Chip, {
                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                          level: "body-xs",
                          textColor: "inherit",
                          children: "Active"
                        })),
                color: "success",
                size: "md",
                startDecorator: Caml_option.some(JsxRuntime.jsx(SailingOutlined, {})),
                sx: {
                  borderRadius: "4px"
                }
              });
          break;
      case "DRAFT" :
          lifecycleChip = JsxRuntime.jsx(Chip, {
                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                          level: "body-xs",
                          textColor: "inherit",
                          children: "Draft"
                        })),
                color: "neutral",
                size: "md",
                startDecorator: Caml_option.some(JsxRuntime.jsx(ModeEdit, {})),
                sx: {
                  borderRadius: "4px"
                }
              });
          break;
      case "STAGED" :
          lifecycleChip = null;
          break;
      case "CANCELED" :
          lifecycleChip = JsxRuntime.jsx(Chip, {
                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                          level: "body-xs",
                          textColor: "inherit",
                          children: "Canceled"
                        })),
                color: "danger",
                size: "md",
                startDecorator: Caml_option.some(JsxRuntime.jsx(Cancel, {})),
                sx: {
                  borderRadius: "4px"
                }
              });
          break;
      case "HISTORY" :
          lifecycleChip = JsxRuntime.jsx(Chip, {
                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                          level: "body-xs",
                          textColor: "inherit",
                          children: "History"
                        })),
                color: "neutral",
                size: "md",
                startDecorator: Caml_option.some(JsxRuntime.jsx($$History, {})),
                sx: {
                  borderRadius: "4px"
                }
              });
          break;
      
    }
  } else {
    lifecycleChip = null;
  }
  var name = Belt_MapString.get(props.voyageNameMap, CS_Slugs$Util.VoyageSlug.toString(query.voyageSlug));
  var connectedPassengers = query.connectedPassengers;
  return JsxRuntime.jsx(Sheet, {
              onClick: (function (param) {
                  RescriptReactRouter.push(SysCtx$Thin.vars.bookingsLink + "/" + bookingId);
                }),
              sx: {
                backgroundColor: "background.surface",
                alignSelf: {
                  xs: "center",
                  md: "flex-start"
                },
                borderRadius: "md",
                border: 1,
                borderColor: "neutral.200",
                p: 2,
                width: "100%",
                boxSizing: "border-box",
                "&:hover": {
                  boxShadow: "md",
                  cursor: "pointer"
                }
              },
              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                        direction: "row",
                        spacing: {
                          xs: 2,
                          md: 3
                        },
                        children: [
                          JsxRuntime.jsx(AspectRatio, {
                                children: Caml_option.some(JsxRuntime.jsx("img", {
                                          draggable: false,
                                          alt: voyageSlug,
                                          loading: "lazy",
                                          src: "https://i.ibb.co/6WW5vVC/shiptest.jpg"
                                        })),
                                ratio: "1.5",
                                sx: {
                                  borderRadius: "sm",
                                  width: {
                                    xs: "16rem",
                                    md: "10rem"
                                  },
                                  height: "100%",
                                  position: "relative"
                                }
                              }),
                          JsxRuntime.jsxs(Stack, {
                                sx: {
                                  width: "100%",
                                  justifyContent: {
                                    xs: "flex-start",
                                    md: "space-between"
                                  },
                                  flexDirection: {
                                    xs: "column",
                                    md: "row"
                                  }
                                },
                                children: [
                                  JsxRuntime.jsxs(Stack, {
                                        direction: "column",
                                        spacing: {
                                          xs: 0,
                                          md: 1
                                        },
                                        children: [
                                          JsxRuntime.jsx(Typography, {
                                                level: "body-xs",
                                                sx: {
                                                  display: {
                                                    xs: "flex",
                                                    md: "none"
                                                  }
                                                },
                                                textColor: "text.tertiary",
                                                children: Caml_option.some(DateOnly$Util.prettyFormatDateRange(query.voyStartDate, query.voyEndDate))
                                              }),
                                          JsxRuntime.jsxs(Stack, {
                                                children: [
                                                  JsxRuntime.jsx(Typography, {
                                                        level: "title-lg",
                                                        component: "h4",
                                                        children: Caml_option.some(bookingId)
                                                      }),
                                                  JsxRuntime.jsx(Typography, {
                                                        level: "body-xs",
                                                        sx: {
                                                          color: "text.tertiary"
                                                        },
                                                        children: Caml_option.some(name !== undefined ? name : CS_Slugs$Util.VoyageSlug.toString(query.voyageSlug))
                                                      })
                                                ]
                                              }),
                                          JsxRuntime.jsxs(Stack, {
                                                direction: {
                                                  xs: "row",
                                                  md: "row"
                                                },
                                                spacing: 1,
                                                children: [
                                                  query.isBc ? JsxRuntime.jsx(Chip, {
                                                          children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                    level: "body-xs",
                                                                    fontWeight: "sm",
                                                                    children: "Billing Contact"
                                                                  })),
                                                          size: "md",
                                                          startDecorator: Caml_option.some(JsxRuntime.jsx(AttachMoney, {})),
                                                          sx: {
                                                            backgroundColor: "neutral.100",
                                                            color: "text.primary",
                                                            borderRadius: "xs"
                                                          }
                                                        }) : null,
                                                  query.isPax ? JsxRuntime.jsx(Chip, {
                                                          children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                    level: "body-xs",
                                                                    fontWeight: "sm",
                                                                    children: "You"
                                                                  })),
                                                          size: "md",
                                                          startDecorator: Caml_option.some(JsxRuntime.jsx(Person2Outlined, {})),
                                                          sx: {
                                                            backgroundColor: "neutral.100",
                                                            color: "text.primary",
                                                            borderRadius: "xs"
                                                          }
                                                        }) : null,
                                                  connectedPassengers !== undefined ? Belt_Array.map(connectedPassengers, (function (v) {
                                                            return JsxRuntime.jsx(Chip, {
                                                                        children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                                  level: "body-xs",
                                                                                  fontWeight: "sm",
                                                                                  children: Caml_option.some(Belt_Option.getWithDefault(v, "Connected Account"))
                                                                                })),
                                                                        size: "md",
                                                                        startDecorator: Caml_option.some(JsxRuntime.jsx(Person2Outlined, {})),
                                                                        sx: {
                                                                          backgroundColor: "neutral.100",
                                                                          color: "text.primary",
                                                                          borderRadius: "xs"
                                                                        }
                                                                      });
                                                          })) : null,
                                                  remainingPax > 0 ? JsxRuntime.jsx(Chip, {
                                                          children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                    level: "body-xs",
                                                                    fontWeight: "sm",
                                                                    children: Caml_option.some(String(remainingPax) + " Other Guest" + (
                                                                          remainingPax === 1 ? "" : "s"
                                                                        ))
                                                                  })),
                                                          size: "md",
                                                          startDecorator: Caml_option.some(JsxRuntime.jsx(Person2Outlined, {})),
                                                          sx: {
                                                            backgroundColor: "neutral.100",
                                                            color: "text.primary",
                                                            borderRadius: "xs"
                                                          }
                                                        }) : null
                                                ]
                                              })
                                        ]
                                      }),
                                  JsxRuntime.jsxs(Stack, {
                                        direction: "column",
                                        spacing: 1,
                                        sx: {
                                          alignItems: {
                                            xs: "flex-start",
                                            md: "flex-end"
                                          }
                                        },
                                        children: [
                                          JsxRuntime.jsx(Typography, {
                                                level: "body-xs",
                                                sx: {
                                                  display: {
                                                    xs: "none",
                                                    md: "flex"
                                                  }
                                                },
                                                textColor: "text.tertiary",
                                                children: Caml_option.some(DateOnly$Util.prettyFormatDateRange(query.voyStartDate, query.voyEndDate))
                                              }),
                                          lifecycleChip
                                        ]
                                      })
                                ]
                              })
                        ]
                      }))
            });
}

var HistoricalVoyage = {
  make: BookingHistorySection$HistoricalVoyage
};

function BookingHistorySection(props) {
  var query = use(props.fragmentRefs);
  var match = query.publicBrandFamilyVoyage;
  var voyageNameMap = match !== undefined ? Belt_MapString.fromArray(Belt_Array.map(match.nodes, (function (n) {
                return [
                        CS_Slugs$Util.VoyageSlug.toString(n.voyageSlug),
                        CS_NonemptyStrings$Util.NonEmptyString.toString(n.voyageName)
                      ];
              }))) : undefined;
  var match$1 = query.history;
  var tmp;
  if (match$1 !== undefined) {
    var nodes = match$1.nodes;
    tmp = nodes.length !== 0 ? JsxRuntime.jsxs(Stack, {
            spacing: 2,
            sx: {
              width: "100%"
            },
            children: [
              JsxRuntime.jsx(Typography, {
                    level: "h3",
                    component: "h1",
                    children: Caml_option.some(Txt$Thin.Bookings.pastHeading.value)
                  }),
              Belt_Array.map(nodes, (function (node) {
                      return JsxRuntime.jsx(BookingHistorySection$HistoricalVoyage, {
                                  fragmentRefs: node.fragmentRefs,
                                  voyageNameMap: voyageNameMap
                                }, node.bookingId);
                    }))
            ]
          }) : null;
  } else {
    tmp = null;
  }
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(tmp)
            });
}

var make = BookingHistorySection;

exports.QueryFragment = QueryFragment;
exports.ListitemFragment = ListitemFragment;
exports.HistoricalVoyage = HistoricalVoyage;
exports.make = make;
/* Txt-Thin Not a pure module */
