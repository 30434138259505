// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Txt$Thin = require("../text/Txt.bs.js");
var Merge$Util = require("util/src/Merge.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SysCtx$Thin = require("../utils/SysCtx.bs.js");
var AppAlert$Thin = require("../components/common/atoms/AppAlert.bs.js");
var Stack = require("@mui/joy/Stack").default;
var AppButton$Thin = require("../components/common/atoms/AppButton.bs.js");
var AuthPanel$Thin = require("../components/common/molecules/AuthPanel.bs.js");
var PageFrame$Thin = require("../components/wrappers/PageFrame.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var PasswordInput$Thin = require("../components/common/molecules/PasswordInput.bs.js");
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var URLSearchParams$Util = require("util/src/URLSearchParams.bs.js");
var ClaimInvitationSubmit$Thin = require("../components/claim/mutations/ClaimInvitationSubmit.bs.js");

function ClaimPage(props) {
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var token = URLSearchParams$Util.getValFromKey(url, SysCtx$Thin.vars.tokenKey);
  var match = URLSearchParams$Util.getValFromKey(url, SysCtx$Thin.vars.expiryKey);
  var expired;
  if (match !== undefined && match.TAG === "Number") {
    var now = Date.now();
    var expiry = match._0 * 1000.0;
    expired = now >= expiry ? true : false;
  } else {
    expired = undefined;
  }
  var match$1 = ClaimInvitationSubmit$Thin.useMutation();
  var claimSuccess = match$1.claimSuccess;
  var error = match$1.error;
  var onSubmit = match$1.onSubmit;
  var setConfirmPassword = match$1.setConfirmPassword;
  var confirmPassword = match$1.confirmPassword;
  var setPassword = match$1.setPassword;
  var password = match$1.password;
  React.useEffect((function () {
          var exit = 0;
          if (!(token !== undefined && token.TAG === "String" && expired !== undefined)) {
            exit = 1;
          }
          if (exit === 1) {
            RescriptReactRouter.push(SysCtx$Thin.vars.loginLink);
          }
          
        }), [
        token,
        expired
      ]);
  var tmp;
  tmp = token !== undefined && token.TAG === "String" && expired !== undefined && !(expired || claimSuccess) ? JsxRuntime.jsx(Typography, {
          level: "body-sm",
          textColor: "text.tertiary",
          children: Caml_option.some(Txt$Thin.Claim.subheading.value)
        }) : null;
  var tmp$1;
  var exit = 0;
  if (expired !== undefined && expired) {
    tmp$1 = JsxRuntime.jsx(AppAlert$Thin.make, {
          message: Txt$Thin.Claim.expired.value,
          type_: "warning"
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    if (claimSuccess) {
      tmp$1 = JsxRuntime.jsx(AppAlert$Thin.make, {
            message: Merge$Util.render(Txt$Thin.Claim.success, SysCtx$Thin.vars),
            type_: "success"
          });
    } else if (token !== undefined && token.TAG === "String" && expired !== undefined) {
      var token$1 = token._0;
      tmp$1 = JsxRuntime.jsxs(Stack, {
            direction: "column",
            component: "form",
            spacing: 2,
            sx: {
              width: "100%"
            },
            onSubmit: (function (e) {
                onSubmit(e, token$1);
              }),
            disabled: password === "" || confirmPassword === "" || password !== confirmPassword,
            children: [
              JsxRuntime.jsx(PasswordInput$Thin.make, {
                    fullWidth: true,
                    size: "lg",
                    placeholder: Txt$Thin.Claim.passwordPlaceholder.value,
                    value: password,
                    onChange: (function (e) {
                        var val = e.currentTarget.value;
                        setPassword(function (param) {
                              return val;
                            });
                      })
                  }),
              JsxRuntime.jsx(PasswordInput$Thin.make, {
                    fullWidth: true,
                    size: "lg",
                    placeholder: Txt$Thin.Claim.confirmPasswordPlaceholder.value,
                    error: password !== confirmPassword,
                    value: confirmPassword,
                    onChange: (function (e) {
                        var val = e.currentTarget.value;
                        setConfirmPassword(function (param) {
                              return val;
                            });
                      })
                  }),
              JsxRuntime.jsx(AppButton$Thin.make, {
                    fullWidth: true,
                    loading: match$1.isMutating,
                    type_: "submit",
                    size: "lg",
                    disabled: password === "" || confirmPassword === "" || password !== confirmPassword,
                    children: Caml_option.some(Txt$Thin.Claim.claim.value)
                  })
            ]
          });
    } else {
      tmp$1 = null;
    }
  }
  return JsxRuntime.jsx(PageFrame$Thin.make, {
              children: JsxRuntime.jsxs(AuthPanel$Thin.make, {
                    heading: Txt$Thin.Claim.heading.value,
                    subHeadComponent: Caml_option.some(tmp),
                    children: [
                      tmp$1,
                      error !== undefined ? JsxRuntime.jsx(AppAlert$Thin.make, {
                              message: error,
                              type_: "error"
                            }) : JsxRuntime.jsx(JsxRuntime.Fragment, {})
                    ]
                  }),
              auth: true
            });
}

var make = ClaimPage;

exports.make = make;
/* react Not a pure module */
