// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Txt$Thin = require("../../../text/Txt.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var AppButton$Thin = require("../../common/atoms/AppButton.bs.js");
var $$Option = require("@mui/joy/Option").default;
var DraftUtils$Thin = require("../../../utils/booking/DraftUtils.bs.js");
var ReactState$Util = require("util/src/ReactState.bs.js");
var BookingDiff$Thin = require("../../../utils/booking/BookingDiff.bs.js");
var CS_ISO3166_1$Util = require("util/src/CustomScalars/CS_ISO3166_1.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var FormControl = require("@mui/joy/FormControl").default;
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var FormHelperText = require("@mui/joy/FormHelperText").default;
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var ListItemContent = require("@mui/joy/ListItemContent").default;
var CS_SQLDatesAndTimes$Util = require("util/src/CustomScalars/CS_SQLDatesAndTimes.bs.js");
var ListItemDecorator = require("@mui/joy/ListItemDecorator").default;
var AddPassDetails_query_graphql$Thin = require("../../../__generated__/AddPassDetails_query_graphql.bs.js");

var convertFragment = AddPassDetails_query_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(AddPassDetails_query_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, AddPassDetails_query_graphql$Thin.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(AddPassDetails_query_graphql$Thin.node, convertFragment, fRef);
}

var QueryFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

function AddPassDetails(props) {
  var forceValidate = props.forceValidate;
  var birthdateShouldError = props.birthdateShouldError;
  var pax1MustBeBornBy = props.pax1MustBeBornBy;
  var isPrimaryPax = props.isPrimaryPax;
  var addOrErr = props.addOrErr;
  var custodianAccountId = props.custodianAccountId;
  var idx = props.idx;
  var action = props.action;
  var __mobile = props.mobile;
  var setGender = props.setGender;
  var gender = props.gender;
  var setCountry = props.setCountry;
  var country = props.country;
  var setDob = props.setDob;
  var dob = props.dob;
  var setPrefLName = props.setPrefLName;
  var prefLName = props.prefLName;
  var setPrefFName = props.setPrefFName;
  var prefFName = props.prefFName;
  var setDocLName = props.setDocLName;
  var docLName = props.docLName;
  var setDocFName = props.setDocFName;
  var docFName = props.docFName;
  var mobile = __mobile !== undefined ? __mobile : false;
  var match = React.useState(function () {
        return false;
      });
  var setDateFocused = match[1];
  var dateFocused = match[0];
  var data = use(props.countryFrag);
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var diff = BookingDiff$Thin.parse(url.search);
  var match$1 = data.countries;
  var countryOptions = match$1 !== undefined ? Belt_Array.map(match$1.nodes, (function (c) {
            return CS_ISO3166_1$Util.Alpha3.meta(c.alpha3);
          })) : [];
  var genderArray = [
    "F",
    "M",
    "X"
  ];
  var onSubmit = function (e) {
    e.preventDefault();
    var match = CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(docFName);
    var match$1 = CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(docLName);
    var match$2 = CS_SQLDatesAndTimes$Util.DateOnly.fromPrimitive(dob);
    if (custodianAccountId !== undefined) {
      if (match.TAG === "Ok") {
        if (match$1.TAG === "Ok") {
          if (match$2.TAG === "Ok") {
            if (country !== undefined) {
              if (gender !== undefined) {
                if (birthdateShouldError(dob, pax1MustBeBornBy, isPrimaryPax)) {
                  console.log("Details not valid");
                  return ;
                }
                var v = CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(prefFName);
                var tmp;
                tmp = v.TAG === "Ok" ? Caml_option.some(v._0) : undefined;
                var v$1 = CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(prefLName);
                var tmp$1;
                tmp$1 = v$1.TAG === "Ok" ? Caml_option.some(v$1._0) : undefined;
                var newAcct_custodianAccountId = Caml_option.valFromOption(custodianAccountId);
                var newAcct_docFirstName = match._0;
                var newAcct_docLastName = match$1._0;
                var newAcct_docBirthdate = match$2._0;
                var newAcct_docCitizenship = Caml_option.valFromOption(country);
                var newAcct = {
                  custodianAccountId: newAcct_custodianAccountId,
                  docFirstName: newAcct_docFirstName,
                  docLastName: newAcct_docLastName,
                  docGender: gender,
                  docBirthdate: newAcct_docBirthdate,
                  docCitizenship: newAcct_docCitizenship,
                  preferredFirstName: tmp,
                  preferredLastName: tmp$1
                };
                var applyDiff;
                applyDiff = action === "Attach" ? BookingDiff$Thin.Alter.attachNewConnectedPax(diff, idx, newAcct) : BookingDiff$Thin.Alter.replaceWithNewConnectedPax(diff, idx, newAcct);
                return addOrErr(applyDiff);
              }
              console.log("Details not valid");
              return ;
            }
            console.log("Details not valid");
            return ;
          }
          console.log("Details not valid");
          return ;
        }
        console.log("Details not valid");
        return ;
      }
      console.log("Details not valid");
      return ;
    }
    console.log("Details not valid");
  };
  if (mobile) {
    return JsxRuntime.jsxs(Stack, {
                component: "form",
                spacing: 2,
                sx: {
                  p: 2
                },
                children: [
                  JsxRuntime.jsx(Typography, {
                        level: "body-sm",
                        children: Caml_option.some(Txt$Thin.BookingDetails.addPassDetailsPreferredNamesTitle.value)
                      }),
                  JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                        state: [
                          prefFName,
                          setPrefFName
                        ],
                        get: (function (prefFName) {
                            return prefFName;
                          }),
                        set: (function (param, val) {
                            return val;
                          }),
                        forceValidate: forceValidate,
                        isValid: (function (v) {
                            return true;
                          }),
                        children: (function (param) {
                            return JsxRuntime.jsx(FormControl, {
                                        error: param.error,
                                        children: Caml_option.some(JsxRuntime.jsx(Joy$Util.Input.make, {
                                                  type_: "text",
                                                  placeholder: Txt$Thin.BookingDetails.addPassDetailsPrefFirstName.value,
                                                  variant: "outlined",
                                                  value: param.get,
                                                  onChange: param.onChange.withCurrentTarget
                                                }))
                                      });
                          })
                      }),
                  JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                        state: [
                          prefLName,
                          setPrefLName
                        ],
                        get: (function (prefLName) {
                            return prefLName;
                          }),
                        set: (function (param, val) {
                            return val;
                          }),
                        forceValidate: forceValidate,
                        isValid: (function (v) {
                            return true;
                          }),
                        children: (function (param) {
                            return JsxRuntime.jsx(FormControl, {
                                        error: param.error,
                                        children: Caml_option.some(JsxRuntime.jsx(Joy$Util.Input.make, {
                                                  type_: "text",
                                                  placeholder: Txt$Thin.BookingDetails.addPassDetailsPrefLastName.value,
                                                  variant: "outlined",
                                                  value: param.get,
                                                  onChange: param.onChange.withCurrentTarget
                                                }))
                                      });
                          })
                      }),
                  JsxRuntime.jsx(Typography, {
                        level: "body-sm",
                        children: Caml_option.some(Txt$Thin.BookingDetails.addPassDetailsLegalDocumentSectionTitle.value)
                      }),
                  JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                        state: [
                          docFName,
                          setDocFName
                        ],
                        get: (function (docFName) {
                            return docFName;
                          }),
                        set: (function (param, val) {
                            return val;
                          }),
                        forceValidate: forceValidate,
                        isValid: (function (v) {
                            return Belt_Result.isOk(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(v));
                          }),
                        children: (function (param) {
                            var error = param.error;
                            return JsxRuntime.jsx(FormControl, {
                                        error: error,
                                        children: Caml_option.some(JsxRuntime.jsx(Joy$Util.Input.make, {
                                                  type_: "text",
                                                  placeholder: Txt$Thin.BookingDetails.addPassDetailsFirstName.value,
                                                  variant: "outlined",
                                                  error: error,
                                                  value: param.get,
                                                  onChange: param.onChange.withCurrentTarget
                                                }))
                                      });
                          })
                      }),
                  JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                        state: [
                          docLName,
                          setDocLName
                        ],
                        get: (function (docLName) {
                            return docLName;
                          }),
                        set: (function (param, val) {
                            return val;
                          }),
                        forceValidate: forceValidate,
                        isValid: (function (v) {
                            return Belt_Result.isOk(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(v));
                          }),
                        children: (function (param) {
                            var error = param.error;
                            return JsxRuntime.jsx(FormControl, {
                                        error: error,
                                        children: Caml_option.some(JsxRuntime.jsx(Joy$Util.Input.make, {
                                                  type_: "text",
                                                  placeholder: Txt$Thin.BookingDetails.addPassDetailsLastName.value,
                                                  variant: "outlined",
                                                  error: error,
                                                  value: param.get,
                                                  onChange: param.onChange.withCurrentTarget
                                                }))
                                      });
                          })
                      }),
                  JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                        state: [
                          gender,
                          setGender
                        ],
                        get: (function (gender) {
                            return gender;
                          }),
                        set: (function (param, val) {
                            return val;
                          }),
                        forceValidate: forceValidate,
                        isValid: Belt_Option.isSome,
                        children: (function (param) {
                            return JsxRuntime.jsx(FormControl, {
                                        error: param.error,
                                        children: Caml_option.some(JsxRuntime.jsx(Joy$Util.JoySelect.make, {
                                                  children: Caml_option.some(Belt_Array.mapWithIndex(genderArray, (function (i, g) {
                                                              return JsxRuntime.jsx($$Option, {
                                                                          value: g,
                                                                          children: Caml_option.some(DraftUtils$Thin.Pax.genderToText(g))
                                                                        }, String(i));
                                                            }))),
                                                  onChange: param.onChange.withDoubleWrappedJoyOpt,
                                                  placeholder: Txt$Thin.BookingDetails.addPassDetailsGender.value,
                                                  slotProps: {
                                                    listbox: {
                                                      sx: {
                                                        zIndex: "modal"
                                                      }
                                                    }
                                                  },
                                                  value: param.get,
                                                  variant: "outlined"
                                                }))
                                      });
                          })
                      }),
                  JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                        state: [
                          dob,
                          setDob
                        ],
                        get: (function (dob) {
                            return dob;
                          }),
                        set: (function (param, val) {
                            return val;
                          }),
                        forceValidate: forceValidate,
                        isValid: (function (v) {
                            return !birthdateShouldError(v, pax1MustBeBornBy, isPrimaryPax);
                          }),
                        children: (function (param) {
                            var match = dob === "";
                            return JsxRuntime.jsxs(FormControl, {
                                        error: param.error,
                                        children: [
                                          JsxRuntime.jsx(Joy$Util.Input.make, {
                                                type_: dateFocused || !match ? "date" : "text",
                                                placeholder: Txt$Thin.BookingDetails.addPassDetailsBirthdate.value,
                                                variant: "outlined",
                                                value: param.get,
                                                onChange: param.onChange.withCurrentTarget,
                                                onFocus: (function (param) {
                                                    setDateFocused(function (param) {
                                                          return true;
                                                        });
                                                  }),
                                                onBlur: (function (param) {
                                                    setDateFocused(function (param) {
                                                          return false;
                                                        });
                                                  })
                                              }),
                                          isPrimaryPax ? JsxRuntime.jsx(FormHelperText, {
                                                  children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                            color: "inherit",
                                                            level: "body-xs",
                                                            children: "Passenger must be 21"
                                                          }))
                                                }) : null
                                        ]
                                      });
                          })
                      }),
                  JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                        state: [
                          country,
                          setCountry
                        ],
                        get: (function (country) {
                            return country;
                          }),
                        set: (function (param, val) {
                            return val;
                          }),
                        forceValidate: forceValidate,
                        isValid: Belt_Option.isSome,
                        children: (function (param) {
                            return JsxRuntime.jsx(FormControl, {
                                        error: param.error,
                                        children: Caml_option.some(JsxRuntime.jsx(Joy$Util.JoySelect.make, {
                                                  children: Caml_option.some(Belt_Array.map(countryOptions, (function (option) {
                                                              var emoji = option.emoji;
                                                              return JsxRuntime.jsxs($$Option, {
                                                                          value: option.alpha3,
                                                                          children: [
                                                                            emoji !== undefined ? JsxRuntime.jsx(ListItemDecorator, {
                                                                                    children: Caml_option.some(emoji)
                                                                                  }) : null,
                                                                            JsxRuntime.jsxs(ListItemContent, {
                                                                                  children: [
                                                                                    option.country,
                                                                                    JsxRuntime.jsx(Typography, {
                                                                                          level: "body-xs",
                                                                                          children: Caml_option.some(option.alpha3 + " (" + Belt_Option.getWithDefault(option.callingCode, "No code") + ")")
                                                                                        })
                                                                                  ],
                                                                                  sx: {
                                                                                    fontSize: "sm"
                                                                                  }
                                                                                })
                                                                          ],
                                                                          label: option.alpha3,
                                                                          sx: {
                                                                            maxWidth: "calc(100vw - 32px)",
                                                                            overflow: "hidden",
                                                                            textOverflow: "ellipsis"
                                                                          }
                                                                        }, option.alpha3);
                                                            }))),
                                                  onChange: param.onChange.withDoubleWrappedJoyOpt,
                                                  placeholder: Txt$Thin.BookingDetails.addPassDetailsCountry.value,
                                                  slotProps: {
                                                    listbox: {
                                                      sx: {
                                                        zIndex: "modal"
                                                      }
                                                    }
                                                  },
                                                  sx: {
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis"
                                                  },
                                                  value: param.get,
                                                  variant: "outlined"
                                                }))
                                      });
                          })
                      })
                ]
              });
  }
  var tmp;
  tmp = action === "Attach" ? Txt$Thin.BookingDetails.addPassAddPassenger.value : Txt$Thin.BookingDetails.addPassReplacePassenger.value;
  return JsxRuntime.jsxs(Stack, {
              direction: "column",
              component: "form",
              spacing: 2,
              children: [
                JsxRuntime.jsx(Sheet, {
                      sx: {
                        borderRadius: "sm",
                        backgroundColor: "background.level1",
                        p: 2
                      },
                      children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                children: Caml_option.some(JsxRuntime.jsxs(Joy$Util.Grid.make, {
                                          children: [
                                            JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                  children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                            level: "body-sm",
                                                            children: "Preferred names (optional):"
                                                          })),
                                                  xs: 12
                                                }),
                                            JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                  children: Caml_option.some(JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                                                            state: [
                                                              prefFName,
                                                              setPrefFName
                                                            ],
                                                            get: (function (prefFName) {
                                                                return prefFName;
                                                              }),
                                                            set: (function (param, val) {
                                                                return val;
                                                              }),
                                                            forceValidate: forceValidate,
                                                            isValid: (function (v) {
                                                                return true;
                                                              }),
                                                            children: (function (param) {
                                                                return JsxRuntime.jsx(FormControl, {
                                                                            error: param.error,
                                                                            children: Caml_option.some(JsxRuntime.jsx(Joy$Util.Input.make, {
                                                                                      type_: "text",
                                                                                      placeholder: Txt$Thin.BookingDetails.addPassDetailsPrefFirstName.value,
                                                                                      variant: "plain",
                                                                                      value: param.get,
                                                                                      onChange: param.onChange.withCurrentTarget
                                                                                    }))
                                                                          });
                                                              })
                                                          })),
                                                  xs: 6
                                                }),
                                            JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                  children: Caml_option.some(JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                                                            state: [
                                                              prefLName,
                                                              setPrefLName
                                                            ],
                                                            get: (function (prefLName) {
                                                                return prefLName;
                                                              }),
                                                            set: (function (param, val) {
                                                                return val;
                                                              }),
                                                            forceValidate: forceValidate,
                                                            isValid: (function (v) {
                                                                return true;
                                                              }),
                                                            children: (function (param) {
                                                                return JsxRuntime.jsx(FormControl, {
                                                                            error: param.error,
                                                                            children: Caml_option.some(JsxRuntime.jsx(Joy$Util.Input.make, {
                                                                                      type_: "text",
                                                                                      placeholder: Txt$Thin.BookingDetails.addPassDetailsPrefLastName.value,
                                                                                      variant: "plain",
                                                                                      value: param.get,
                                                                                      onChange: param.onChange.withCurrentTarget
                                                                                    }))
                                                                          });
                                                              })
                                                          })),
                                                  xs: 6
                                                }),
                                            JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                  children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                            level: "body-sm",
                                                            children: "Enter information as it appears on their travel document:"
                                                          })),
                                                  xs: 12
                                                }),
                                            JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                  children: Caml_option.some(JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                                                            state: [
                                                              docFName,
                                                              setDocFName
                                                            ],
                                                            get: (function (docFName) {
                                                                return docFName;
                                                              }),
                                                            set: (function (param, val) {
                                                                return val;
                                                              }),
                                                            forceValidate: forceValidate,
                                                            isValid: (function (v) {
                                                                return Belt_Result.isOk(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(v));
                                                              }),
                                                            children: (function (param) {
                                                                var error = param.error;
                                                                return JsxRuntime.jsx(FormControl, {
                                                                            error: error,
                                                                            children: Caml_option.some(JsxRuntime.jsx(Joy$Util.Input.make, {
                                                                                      type_: "text",
                                                                                      placeholder: Txt$Thin.BookingDetails.addPassDetailsFirstName.value,
                                                                                      variant: "plain",
                                                                                      error: error,
                                                                                      value: param.get,
                                                                                      onChange: param.onChange.withCurrentTarget
                                                                                    }))
                                                                          });
                                                              })
                                                          })),
                                                  xs: 6
                                                }),
                                            JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                  children: Caml_option.some(JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                                                            state: [
                                                              docLName,
                                                              setDocLName
                                                            ],
                                                            get: (function (docLName) {
                                                                return docLName;
                                                              }),
                                                            set: (function (param, val) {
                                                                return val;
                                                              }),
                                                            forceValidate: forceValidate,
                                                            isValid: (function (v) {
                                                                return Belt_Result.isOk(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(v));
                                                              }),
                                                            children: (function (param) {
                                                                var error = param.error;
                                                                return JsxRuntime.jsx(FormControl, {
                                                                            error: error,
                                                                            children: Caml_option.some(JsxRuntime.jsx(Joy$Util.Input.make, {
                                                                                      type_: "text",
                                                                                      placeholder: Txt$Thin.BookingDetails.addPassDetailsLastName.value,
                                                                                      variant: "plain",
                                                                                      error: error,
                                                                                      value: param.get,
                                                                                      onChange: param.onChange.withCurrentTarget
                                                                                    }))
                                                                          });
                                                              })
                                                          })),
                                                  xs: 6
                                                }),
                                            JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                  children: Caml_option.some(JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                                                            state: [
                                                              gender,
                                                              setGender
                                                            ],
                                                            get: (function (gender) {
                                                                return gender;
                                                              }),
                                                            set: (function (param, val) {
                                                                return val;
                                                              }),
                                                            forceValidate: forceValidate,
                                                            isValid: Belt_Option.isSome,
                                                            children: (function (param) {
                                                                return JsxRuntime.jsx(FormControl, {
                                                                            error: param.error,
                                                                            children: Caml_option.some(JsxRuntime.jsx(Joy$Util.JoySelect.make, {
                                                                                      children: Caml_option.some(Belt_Array.mapWithIndex(genderArray, (function (i, g) {
                                                                                                  return JsxRuntime.jsx($$Option, {
                                                                                                              value: g,
                                                                                                              children: Caml_option.some(DraftUtils$Thin.Pax.genderToText(g))
                                                                                                            }, String(i));
                                                                                                }))),
                                                                                      onChange: param.onChange.withDoubleWrappedJoyOpt,
                                                                                      placeholder: Txt$Thin.BookingDetails.addPassDetailsGender.value,
                                                                                      slotProps: {
                                                                                        listbox: {
                                                                                          sx: {
                                                                                            zIndex: "modal"
                                                                                          }
                                                                                        }
                                                                                      },
                                                                                      value: param.get,
                                                                                      variant: "plain"
                                                                                    }))
                                                                          });
                                                              })
                                                          })),
                                                  xs: 6
                                                }),
                                            JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                  children: Caml_option.some(JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                                                            state: [
                                                              dob,
                                                              setDob
                                                            ],
                                                            get: (function (dob) {
                                                                return dob;
                                                              }),
                                                            set: (function (param, val) {
                                                                return val;
                                                              }),
                                                            forceValidate: forceValidate,
                                                            isValid: (function (v) {
                                                                return !birthdateShouldError(v, pax1MustBeBornBy, isPrimaryPax);
                                                              }),
                                                            children: (function (param) {
                                                                var match = dob === "";
                                                                return JsxRuntime.jsxs(FormControl, {
                                                                            error: param.error,
                                                                            children: [
                                                                              JsxRuntime.jsx(Joy$Util.Input.make, {
                                                                                    type_: dateFocused || !match ? "date" : "text",
                                                                                    placeholder: Txt$Thin.BookingDetails.addPassDetailsBirthdate.value,
                                                                                    variant: "plain",
                                                                                    value: param.get,
                                                                                    onChange: param.onChange.withCurrentTarget,
                                                                                    onFocus: (function (param) {
                                                                                        setDateFocused(function (param) {
                                                                                              return true;
                                                                                            });
                                                                                      }),
                                                                                    onBlur: (function (param) {
                                                                                        setDateFocused(function (param) {
                                                                                              return false;
                                                                                            });
                                                                                      })
                                                                                  }),
                                                                              isPrimaryPax ? JsxRuntime.jsx(FormHelperText, {
                                                                                      children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                                                color: "inherit",
                                                                                                level: "body-xs",
                                                                                                children: "Passenger must be 21"
                                                                                              }))
                                                                                    }) : null
                                                                            ]
                                                                          });
                                                              })
                                                          })),
                                                  xs: 6
                                                }),
                                            JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                  children: Caml_option.some(JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                                                            state: [
                                                              country,
                                                              setCountry
                                                            ],
                                                            get: (function (country) {
                                                                return country;
                                                              }),
                                                            set: (function (param, val) {
                                                                return val;
                                                              }),
                                                            forceValidate: forceValidate,
                                                            isValid: Belt_Option.isSome,
                                                            children: (function (param) {
                                                                return JsxRuntime.jsx(FormControl, {
                                                                            error: param.error,
                                                                            children: Caml_option.some(JsxRuntime.jsx(Joy$Util.JoySelect.make, {
                                                                                      children: Caml_option.some(Belt_Array.map(countryOptions, (function (option) {
                                                                                                  var emoji = option.emoji;
                                                                                                  return JsxRuntime.jsxs($$Option, {
                                                                                                              value: option.alpha3,
                                                                                                              children: [
                                                                                                                emoji !== undefined ? JsxRuntime.jsx(ListItemDecorator, {
                                                                                                                        children: Caml_option.some(emoji)
                                                                                                                      }) : null,
                                                                                                                JsxRuntime.jsxs(ListItemContent, {
                                                                                                                      children: [
                                                                                                                        option.country,
                                                                                                                        JsxRuntime.jsx(Typography, {
                                                                                                                              level: "body-xs",
                                                                                                                              children: Caml_option.some(option.alpha3 + " (" + Belt_Option.getWithDefault(option.callingCode, "No code") + ")")
                                                                                                                            })
                                                                                                                      ],
                                                                                                                      sx: {
                                                                                                                        fontSize: "sm"
                                                                                                                      }
                                                                                                                    })
                                                                                                              ],
                                                                                                              label: option.alpha3,
                                                                                                              sx: {
                                                                                                                maxWidth: "calc(100vw - 32px)",
                                                                                                                overflow: "hidden",
                                                                                                                textOverflow: "ellipsis"
                                                                                                              }
                                                                                                            }, option.alpha3);
                                                                                                }))),
                                                                                      onChange: param.onChange.withDoubleWrappedJoyOpt,
                                                                                      placeholder: Txt$Thin.BookingDetails.addPassDetailsCountry.value,
                                                                                      slotProps: {
                                                                                        listbox: {
                                                                                          sx: {
                                                                                            zIndex: "modal"
                                                                                          }
                                                                                        }
                                                                                      },
                                                                                      sx: {
                                                                                        overflow: "hidden",
                                                                                        textOverflow: "ellipsis"
                                                                                      },
                                                                                      value: param.get,
                                                                                      variant: "plain"
                                                                                    }))
                                                                          });
                                                              })
                                                          })),
                                                  xs: 12
                                                })
                                          ],
                                          container: true,
                                          spacing: {
                                            TAG: "Fixed",
                                            _0: 1
                                          },
                                          rowSpacing: 2,
                                          columnSpacing: 2,
                                          sx: {
                                            p: 1
                                          }
                                        }))
                              }))
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(AppButton$Thin.make, {
                            fullWidth: false,
                            loading: props.loading,
                            onClick: onSubmit,
                            size: "lg",
                            disabled: Belt_Result.isError(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(docFName)) || Belt_Result.isError(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(docLName)) || Belt_Option.isNone(gender) || birthdateShouldError(dob, pax1MustBeBornBy, isPrimaryPax) || Belt_Option.isNone(country),
                            children: Caml_option.some(tmp)
                          })
                    })
              ]
            });
}

var make = AddPassDetails;

exports.QueryFragment = QueryFragment;
exports.make = make;
/* react Not a pure module */
