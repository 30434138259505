// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Js_string = require("rescript/lib/js/js_string.js");
var Theme$Thin = require("./components/wrappers/Theme.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SysCtx$Thin = require("./utils/SysCtx.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var ImageSet$Thin = require("./utils/ImageSet.bs.js");
var ClaimRoute$Thin = require("./routes/ClaimRoute.bs.js");
var LoginRoute$Thin = require("./routes/LoginRoute.bs.js");
var VerifyRoute$Thin = require("./routes/VerifyRoute.bs.js");
var AccountRoute$Thin = require("./routes/AccountRoute.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var BookingsRoute$Thin = require("./routes/BookingsRoute.bs.js");
var TransferRoute$Thin = require("./routes/TransferRoute.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var SalesFlowRoute$Thin = require("./routes/SalesFlowRoute.bs.js");
var FullPageWaveLoader$Thin = require("./components/common/molecules/FullPageWaveLoader.bs.js");
var ResetPasswordRoute$Thin = require("./routes/ResetPasswordRoute.bs.js");
var BookingDetailsRoute$Thin = require("./routes/BookingDetailsRoute.bs.js");
var ThinAppQuery_graphql$Thin = require("./__generated__/ThinAppQuery_graphql.bs.js");
var ConnectedAccountsRoute$Thin = require("./routes/ConnectedAccountsRoute.bs.js");

function ThinApp$Redirect(props) {
  var link = props.link;
  React.useEffect((function () {
          RescriptReactRouter.push(link);
        }), []);
  return JsxRuntime.jsx("div", {});
}

var Redirect = {
  make: ThinApp$Redirect
};

var convertVariables = ThinAppQuery_graphql$Thin.Internal.convertVariables;

var convertResponse = ThinAppQuery_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = ThinAppQuery_graphql$Thin.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, ThinAppQuery_graphql$Thin.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, ThinAppQuery_graphql$Thin.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(ThinAppQuery_graphql$Thin.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(ThinAppQuery_graphql$Thin.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(ThinAppQuery_graphql$Thin.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(ThinAppQuery_graphql$Thin.node, convertVariables);

var GlobalImageSetPrefetch = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function ThinApp(props) {
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var getSubdomain = function (url) {
    var subdomain;
    if (Js_string.includes("garpulon.io", url)) {
      var parts = Js_string.split(".", url);
      var len = parts.length;
      if (len !== 3) {
        if (len !== 4) {
          subdomain = "garpcocruise";
        } else {
          var match = parts[0];
          if (match === "www") {
            var subdomain$1 = parts[1];
            var match$1 = parts[2];
            if (match$1 === "garpulon") {
              var match$2 = parts[3];
              subdomain = match$2 === "io" ? subdomain$1 : "garpcocruise";
            } else {
              subdomain = "garpcocruise";
            }
          } else {
            subdomain = "garpcocruise";
          }
        }
      } else {
        var subdomain$2 = parts[0];
        var match$3 = parts[1];
        if (match$3 === "garpulon") {
          var match$4 = parts[2];
          subdomain = match$4 === "io" ? subdomain$2 : "garpcocruise";
        } else {
          subdomain = "garpcocruise";
        }
      }
    } else {
      subdomain = "garpcocruise";
    }
    if (subdomain === "bfcust-staging") {
      return "garpcocruise";
    } else {
      return subdomain;
    }
  };
  var brandFamily = CS_Slugs$Util.BrandFamilySlug.Exn.fromPrimitive(getSubdomain(window.location.hostname));
  var imageSetPrefetch = Caml_option.some(use({
            brandFamilySlug: brandFamily
          }, undefined, undefined, undefined).query.fragmentRefs);
  var match = url.path;
  var tmp;
  if (match) {
    var voyageSlug = match.hd;
    switch (voyageSlug) {
      case "account" :
          tmp = match.tl ? "Hello World" : JsxRuntime.jsx(AccountRoute$Thin.make, {
                  brandFamily: brandFamily
                });
          break;
      case "bookings" :
          var match$1 = match.tl;
          tmp = match$1 ? (
              match$1.tl ? "Hello World" : JsxRuntime.jsx(BookingDetailsRoute$Thin.make, {
                      bookingId: match$1.hd,
                      brandFamily: brandFamily
                    })
            ) : JsxRuntime.jsx(BookingsRoute$Thin.make, {
                  brandFamily: brandFamily
                });
          break;
      case "claim" :
          tmp = match.tl ? "Hello World" : JsxRuntime.jsx(ClaimRoute$Thin.make, {
                  brandFamily: brandFamily
                });
          break;
      case "connected-accounts" :
          tmp = match.tl ? "Hello World" : JsxRuntime.jsx(ConnectedAccountsRoute$Thin.make, {
                  brandFamily: brandFamily
                });
          break;
      case "login" :
          tmp = match.tl ? "Hello World" : JsxRuntime.jsx(LoginRoute$Thin.make, {
                  brandFamily: brandFamily
                });
          break;
      case "reset-password" :
          tmp = match.tl ? "Hello World" : JsxRuntime.jsx(ResetPasswordRoute$Thin.make, {
                  brandFamily: brandFamily
                });
          break;
      case "transfer" :
          tmp = match.tl ? "Hello World" : JsxRuntime.jsx(TransferRoute$Thin.make, {
                  brandFamily: brandFamily
                });
          break;
      case "verify" :
          tmp = match.tl ? "Hello World" : JsxRuntime.jsx(VerifyRoute$Thin.make, {
                  brandFamily: brandFamily
                });
          break;
      default:
        if (match.tl) {
          tmp = "Hello World";
        } else {
          var voyage = CS_Slugs$Util.VoyageSlug.fromPrimitive(voyageSlug);
          tmp = voyage.TAG === "Ok" ? JsxRuntime.jsx(SalesFlowRoute$Thin.make, {
                  brandFamily: brandFamily,
                  voyage: voyage._0
                }) : JsxRuntime.jsx(ThinApp$Redirect, {
                  link: SysCtx$Thin.vars.loginLink
                });
        }
    }
  } else {
    tmp = JsxRuntime.jsx(ThinApp$Redirect, {
          link: SysCtx$Thin.vars.loginLink
        });
  }
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(JsxRuntime.jsx(React.Suspense, {
                        children: Caml_option.some(JsxRuntime.jsx(ImageSet$Thin.Context.Provider.make, {
                                  imageSetPrefetch: imageSetPrefetch,
                                  children: JsxRuntime.jsx(Theme$Thin.make, {
                                        children: tmp
                                      })
                                })),
                        fallback: Caml_option.some(JsxRuntime.jsx(FullPageWaveLoader$Thin.make, {}))
                      }))
            });
}

var make = ThinApp;

exports.Redirect = Redirect;
exports.GlobalImageSetPrefetch = GlobalImageSetPrefetch;
exports.make = make;
/* use Not a pure module */
