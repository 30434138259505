"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.subscriptionArgsInvalid = void 0;
var _1 = require("./");
function subscriptionArgsInvalid(event, fn, origin) {
    if ((0, _1.isntString)(event)) {
        return true;
    }
    if (typeof fn !== "function") {
        return true;
    }
    return (0, _1.isntString)(origin);
}
exports.subscriptionArgsInvalid = subscriptionArgsInvalid;
