// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var ResendPaxInvitation_ResendInvitationMutation_graphql$Thin = require("../../../../__generated__/ResendPaxInvitation_ResendInvitationMutation_graphql.bs.js");

var convertVariables = ResendPaxInvitation_ResendInvitationMutation_graphql$Thin.Internal.convertVariables;

var convertResponse = ResendPaxInvitation_ResendInvitationMutation_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = ResendPaxInvitation_ResendInvitationMutation_graphql$Thin.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, ResendPaxInvitation_ResendInvitationMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, ResendPaxInvitation_ResendInvitationMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var ResendInvitationMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(onError, onSuccess) {
  var match = use();
  var mutate = match[0];
  var resendInvitation = function (_accountId) {
    onError(function (param) {
          
        });
    mutate({
          input: {
            _accountId: _accountId
          }
        }, undefined, undefined, undefined, (function (resp, err) {
            if (err !== undefined) {
              return onError(function (param) {
                          return "An error occurred resending the invitation. Please try again!";
                        });
            }
            var match = resp.resendInvitation;
            if (match !== undefined) {
              var match$1 = match.boolean;
              if (match$1 !== undefined && match$1) {
                onSuccess();
                return ;
              }
              
            }
            onError(function (param) {
                  return "An error occurred resending the passenger invitation. Please try again.";
                });
          }), (function (err) {
            console.error(err);
            onError(function (param) {
                  return "An error occurred resending the invitation. Please try again.";
                });
          }), undefined);
  };
  return {
          isMutating: match[1],
          resendInvitation: resendInvitation
        };
}

exports.ResendInvitationMutation = ResendInvitationMutation;
exports.useMutation = useMutation;
/* commitMutation Not a pure module */
