// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Joy$Util = require("util/src/Joy.bs.js");
var Txt$Thin = require("../../text/Txt.bs.js");
var Merge$Util = require("util/src/Merge.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Box = require("@mui/joy/Box").default;
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var DraftUtils$Thin = require("../../utils/booking/DraftUtils.bs.js");
var BookingDiff$Thin = require("../../utils/booking/BookingDiff.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var IconButton = require("@mui/joy/IconButton").default;
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var ModeEditOutlined = require("@mui/icons-material/ModeEditOutlined").default;

function EditBanner(props) {
  var setPreviewModalOpen = props.setPreviewModalOpen;
  var editBannerVisible = props.editBannerVisible;
  var setDraftMode = props.setDraftMode;
  var draftMode = props.draftMode;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var diff = BookingDiff$Thin.parse(url.search);
  var ctx = {
    bookingId: CS_NonemptyStrings$Util.BookingId.toString(props.bookingId).toUpperCase()
  };
  return JsxRuntime.jsx(Box, {
              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                        spacing: 1,
                        sx: {
                          padding: {
                            xs: "1rem",
                            sm: "1rem 2rem"
                          },
                          display: "flex",
                          flexDirection: {
                            xs: draftMode ? "column" : "row",
                            md: "row"
                          },
                          justifyContent: "space-between",
                          alignItems: {
                            xs: "flex-start",
                            md: "center"
                          },
                          backgroundColor: draftMode ? "#212A30" : "background.surface",
                          borderColor: "background.surface",
                          borderBottom: {
                            xs: "1px",
                            md: draftMode ? "0" : "1px solid #DDE7EE"
                          },
                          borderTop: {
                            xs: draftMode ? "0" : "1px solid #DDE7EE",
                            md: "0"
                          }
                        },
                        children: [
                          JsxRuntime.jsxs(Stack, {
                                direction: "column",
                                children: [
                                  JsxRuntime.jsxs(Stack, {
                                        direction: "row",
                                        spacing: 1,
                                        children: [
                                          JsxRuntime.jsx(Typography, {
                                                level: "title-md",
                                                sx: {
                                                  color: draftMode ? "common.white" : "text.primary"
                                                },
                                                children: Caml_option.some(Merge$Util.render(Txt$Thin.BookingDetails.draftBookingHeading, ctx))
                                              }),
                                          draftMode ? JsxRuntime.jsx(Stack, {
                                                  direction: "column",
                                                  alignItems: "center",
                                                  sx: {
                                                    height: "100%"
                                                  },
                                                  children: Caml_option.some(JsxRuntime.jsx(ModeEditOutlined, {
                                                            fontSize: "lg",
                                                            sx: {
                                                              mt: "2px",
                                                              color: "common.white"
                                                            }
                                                          }))
                                                }) : null
                                        ]
                                      }),
                                  JsxRuntime.jsx(Typography, {
                                        level: "title-sm",
                                        sx: {
                                          color: draftMode ? "#F0F3F5" : "text.secondary",
                                          display: {
                                            xs: draftMode ? "none" : "block",
                                            md: "block"
                                          }
                                        },
                                        fontWeight: "normal",
                                        children: Caml_option.some(CS_Slugs$Util.VoyageSlug.toString(props.voyageSlug))
                                      })
                                ]
                              }),
                          draftMode ? JsxRuntime.jsx(Stack, {
                                  direction: "column",
                                  alignItems: "center",
                                  sx: {
                                    width: {
                                      xs: "100%",
                                      md: "auto"
                                    }
                                  },
                                  children: Caml_option.some(JsxRuntime.jsxs(Joy$Util.Grid.make, {
                                            children: [
                                              JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                    children: Caml_option.some(JsxRuntime.jsx(Button, {
                                                              fullWidth: true,
                                                              onClick: (function (param) {
                                                                  setDraftMode(function (param) {
                                                                        return false;
                                                                      });
                                                                  DraftUtils$Thin.addToDraft(BookingDiff$Thin.emptyDiff, url);
                                                                }),
                                                              variant: "outlined",
                                                              color: "neutral",
                                                              sx: {
                                                                color: "common.white",
                                                                backgroundColor: "transparent",
                                                                fontWeight: "normal",
                                                                lineHeight: "1.5",
                                                                "&:hover": {
                                                                  color: "text.secondary",
                                                                  backgroundColor: "background.level1"
                                                                }
                                                              },
                                                              children: Caml_option.some(diff.hasDiff ? Txt$Thin.BookingDetails.draftBookingDiscard.value : "Cancel")
                                                            })),
                                                    xs: diff.hasDiff ? 5 : 12,
                                                    md: diff.hasDiff ? 4 : 12
                                                  }),
                                              JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                    children: Caml_option.some(JsxRuntime.jsx(Button, {
                                                              fullWidth: true,
                                                              onClick: (function (param) {
                                                                  setPreviewModalOpen(function (param) {
                                                                        return true;
                                                                      });
                                                                }),
                                                              color: "neutral",
                                                              sx: {
                                                                backgroundColor: "common.white",
                                                                color: "text.primary",
                                                                flexWrap: "nowrap",
                                                                lineHeight: "1.5",
                                                                "&:hover": {
                                                                  backgroundColor: "background.level1"
                                                                }
                                                              },
                                                              children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                        level: "inherit",
                                                                        noWrap: true,
                                                                        children: Caml_option.some(Txt$Thin.BookingDetails.draftBookingSave.value)
                                                                      }))
                                                            })),
                                                    sx: {
                                                      display: diff.hasDiff ? "flex" : "none"
                                                    },
                                                    xs: diff.hasDiff ? 7 : 0,
                                                    md: diff.hasDiff ? 8 : 0
                                                  })
                                            ],
                                            container: true,
                                            columnSpacing: 1,
                                            sx: {
                                              width: "100%"
                                            }
                                          }))
                                }) : JsxRuntime.jsxs(Stack, {
                                  direction: "row",
                                  justifyContent: "flex-end",
                                  alignItems: "flex-start",
                                  spacing: 1,
                                  sx: {
                                    mt: "0 !important"
                                  },
                                  children: [
                                    JsxRuntime.jsx(Button, {
                                          onClick: (function (param) {
                                              setDraftMode(function (param) {
                                                    return true;
                                                  });
                                            }),
                                          endDecorator: Caml_option.some(JsxRuntime.jsx(ModeEditOutlined, {})),
                                          sx: {
                                            display: {
                                              xs: "none",
                                              md: "flex"
                                            }
                                          },
                                          children: Caml_option.some(Txt$Thin.BookingDetails.manageBookingButtonText.value)
                                        }),
                                    JsxRuntime.jsx(IconButton, {
                                          onClick: (function (param) {
                                              setDraftMode(function (param) {
                                                    return true;
                                                  });
                                            }),
                                          variant: "solid",
                                          color: "primary",
                                          sx: {
                                            display: {
                                              xs: "flex",
                                              md: "none"
                                            }
                                          },
                                          children: Caml_option.some(JsxRuntime.jsx(ModeEditOutlined, {}))
                                        })
                                  ]
                                })
                        ]
                      })),
              sx: {
                position: {
                  xs: "fixed",
                  md: draftMode ? "sticky" : "fixed"
                },
                zIndex: 2,
                top: {
                  xs: "auto",
                  md: "0"
                },
                bottom: {
                  xs: "0",
                  md: "auto"
                },
                transform: {
                  xs: draftMode || editBannerVisible ? "translateY(0)" : "translateY(100%)",
                  md: draftMode || editBannerVisible ? "translateY(0)" : "translateY(-100%)"
                },
                transition: "transform 0.3s ease",
                width: {
                  xs: "100%",
                  md: draftMode ? "100%" : "calc(100% - 14rem)"
                }
              }
            });
}

var make = EditBanner;

exports.make = make;
/* Joy-Util Not a pure module */
