// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var Stack = require("@mui/joy/Stack").default;
var WaveLoader$Thin = require("../atoms/WaveLoader.bs.js");
var JsxRuntime = require("react/jsx-runtime");

function FullPageWaveLoader(props) {
  return JsxRuntime.jsx(Stack, {
              justifyContent: "center",
              alignItems: "center",
              sx: {
                position: "fixed",
                top: "0",
                left: "0",
                right: "0",
                bottom: "0",
                width: "100%",
                height: "100%",
                zIndex: 1000,
                backgroundColor: "neutral.100"
              },
              children: Caml_option.some(JsxRuntime.jsx(WaveLoader$Thin.make, {}))
            });
}

var make = FullPageWaveLoader;

exports.make = make;
/* @mui/joy/Stack Not a pure module */
