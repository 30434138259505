// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var RevokeTransfer_RevokeTransferMutation_graphql$Thin = require("../../../__generated__/RevokeTransfer_RevokeTransferMutation_graphql.bs.js");

var convertVariables = RevokeTransfer_RevokeTransferMutation_graphql$Thin.Internal.convertVariables;

var convertResponse = RevokeTransfer_RevokeTransferMutation_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = RevokeTransfer_RevokeTransferMutation_graphql$Thin.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, RevokeTransfer_RevokeTransferMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, RevokeTransfer_RevokeTransferMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var RevokeTransferMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(_accountId, refetch, onClose) {
  var match = use();
  var mutate = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setError = match$1[1];
  var onSubmit = function (e) {
    e.preventDefault();
    setError(function (param) {
          
        });
    mutate({
          input: {
            _accountId: _accountId
          }
        }, undefined, undefined, undefined, (function (resp, error) {
            if (error !== undefined) {
              console.error(error);
              setError(function (param) {
                    return "Something went wrong revoking transfer 2";
                  });
              return ;
            }
            var match = resp.revokeTransfer;
            if (match !== undefined) {
              var match$1 = match.boolean;
              if (match$1 !== undefined && match$1) {
                refetch();
                onClose();
                return ;
              }
              
            }
            console.error("No response from revokeTransfer");
            setError(function (param) {
                  return "Something went wrong revoking transfer 1";
                });
          }), (function (err) {
            console.error(err);
            setError(function (param) {
                  return "Something went wrong revoking transfer 3";
                });
          }), undefined);
  };
  return {
          isMutating: match[1],
          error: match$1[0],
          onSubmit: onSubmit
        };
}

exports.RevokeTransferMutation = RevokeTransferMutation;
exports.useMutation = useMutation;
/* commitMutation Not a pure module */
