// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var CS_Phone$Util = require("util/src/CustomScalars/CS_Phone.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var CS_ISO3166_1$Util = require("util/src/CustomScalars/CS_ISO3166_1.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var CS_SQLDatesAndTimes$Util = require("util/src/CustomScalars/CS_SQLDatesAndTimes.bs.js");
var ConnectedAccountsPageQuery_graphql$Thin = require("./ConnectedAccountsPageQuery_graphql.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"connectedAccounts_nodes_residenceCountry":{"c":"Util.CustomScalars.ISO3166_1.Alpha3.Exn"},"connectedAccounts_nodes_postalCode":{"c":"Util.CustomScalars.PostalCode.Exn"},"connectedAccounts_nodes_phone":{"c":"Util.CustomScalars.Phone.Exn"},"connectedAccounts_nodes_passenger_record":{"f":""},"connectedAccounts_nodes_numPendingVoyages":{"b":""},"connectedAccounts_nodes_numEligibleVoyages":{"b":""},"connectedAccounts_nodes_loyalty_pending_voyage_nodes_voyage_voyageName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"connectedAccounts_nodes_loyalty_eligible_voyage_nodes_voyage_voyageName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"connectedAccounts_nodes_loyaltyNumber":{"c":"Util.CustomScalars.LoyaltyNumber.Exn"},"connectedAccounts_nodes_legalDocumentLastName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"connectedAccounts_nodes_legalDocumentFirstName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"connectedAccounts_nodes_legalDocumentCitizenship":{"c":"Util.CustomScalars.ISO3166_1.Alpha3.Exn"},"connectedAccounts_nodes_legalDocumentBirthdate":{"c":"Util.CustomScalars.SQLDate.Exn"},"connectedAccounts_nodes_lastName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"connectedAccounts_nodes_invitationExpiry":{"c":"Util.CustomScalars.SQLDateTime.Exn"},"connectedAccounts_nodes_invitationEmail":{"c":"Util.CustomScalars.Email.Exn"},"connectedAccounts_nodes_firstName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"connectedAccounts_nodes_email":{"c":"Util.CustomScalars.Email.Exn"},"connectedAccounts_nodes_custAcquisitionSlug":{"c":"Util.CustomScalars.Slug.Exn"},"connectedAccounts_nodes_currentTierTitle":{"c":"Util.CustomScalars.NonemptyString.Exn"},"connectedAccounts_nodes_accountId":{"c":"Util.CustomScalars.AccountId.Exn"},"":{"f":""}}};

var fragmentConverterMap = {
  "Util.CustomScalars.AccountId.Exn": CS_NonemptyStrings$Util.AccountId.Exn.parse,
  "Util.CustomScalars.NonemptyString.Exn": CS_NonemptyStrings$Util.NonEmptyString.Exn.parse,
  "Util.CustomScalars.Slug.Exn": CS_Slugs$Util.Slug.Exn.parse,
  "Util.CustomScalars.Email.Exn": CS_Emails$Util.Email.Exn.parse,
  "Util.CustomScalars.SQLDateTime.Exn": CS_Make$Util.Dateable.Plain.Exn.parse,
  "Util.CustomScalars.SQLDate.Exn": CS_SQLDatesAndTimes$Util.DateOnly.Exn.parse,
  "Util.CustomScalars.ISO3166_1.Alpha3.Exn": CS_ISO3166_1$Util.Alpha3.Exn.parse,
  "Util.CustomScalars.LoyaltyNumber.Exn": CS_NonemptyStrings$Util.LoyaltyNumber.Exn.parse,
  "Util.CustomScalars.Phone.Exn": CS_Phone$Util.Phone.Exn.parse,
  "Util.CustomScalars.PostalCode.Exn": CS_NonemptyStrings$Util.PostalCode.Exn.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

function accountStatus_decode($$enum) {
  if ($$enum === "UNCLAIMED" || $$enum === "TRANSFERRING" || $$enum === "INVITED" || $$enum === "CLAIMED" || $$enum === "DELETED") {
    return $$enum;
  }
  
}

function accountStatus_fromString(str) {
  return accountStatus_decode(str);
}

function docGender_decode($$enum) {
  if ($$enum === "F" || $$enum === "M" || $$enum === "X") {
    return $$enum;
  }
  
}

function docGender_fromString(str) {
  return docGender_decode(str);
}

var Utils = {
  accountStatus_decode: accountStatus_decode,
  accountStatus_fromString: accountStatus_fromString,
  docGender_decode: docGender_decode,
  docGender_fromString: docGender_fromString
};

function makeNode(rescript_graphql_node_ConnectedAccountsPageQuery) {
  return ((function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "BrandFamilyVoyage",
    "kind": "LinkedField",
    "name": "voyage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "voyageName",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "brandFamily"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": rescript_graphql_node_ConnectedAccountsPageQuery
    }
  },
  "name": "ConnectedAccountsPage_query",
  "selections": [
    {
      "alias": "connectedAccounts",
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "brandFamilySlug",
              "variableName": "brandFamily"
            }
          ],
          "kind": "ObjectValue",
          "name": "condition"
        }
      ],
      "concreteType": "CurrentUserConnectedAccountsConnection",
      "kind": "LinkedField",
      "name": "currentUserConnectedAccounts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CurrentUserConnectedAccount",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fullDisplayName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "accountStatus",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "invitationEmail",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "invitationExpiry",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "legalDocumentFirstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "legalDocumentLastName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "legalDocumentBirthdate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "legalDocumentCitizenship",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "legalDocumentGender",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "accountId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "residenceCountry",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "phone",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "postalCode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "loyaltyNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "currentTierTitle",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "numEligibleVoyages",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "numPendingVoyages",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "custAcquisitionSlug",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "LoyaltyEligibleVoyagesConnection",
              "kind": "LinkedField",
              "name": "loyalty_eligible_voyage",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "LoyaltyEligibleVoyage",
                  "kind": "LinkedField",
                  "name": "nodes",
                  "plural": true,
                  "selections": (v0/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "LoyaltyPendingVoyagesConnection",
              "kind": "LinkedField",
              "name": "loyalty_pending_voyage",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "LoyaltyPendingVoyage",
                  "kind": "LinkedField",
                  "name": "nodes",
                  "plural": true,
                  "selections": (v0/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "BookingPassengersConnection",
              "kind": "LinkedField",
              "name": "passenger_record",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "UpdateAccountData_passenger"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UpdateAccountData_query"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "brandFamily",
          "variableName": "brandFamily"
        }
      ],
      "kind": "FragmentSpread",
      "name": "UpdateAccountData_acquisitionslugs"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})());
}

var node = makeNode(ConnectedAccountsPageQuery_graphql$Thin.node);

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
