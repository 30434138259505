// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var VerifyRouteSignOnQuery_graphql$Thin = require("./VerifyRouteSignOnQuery_graphql.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"currentUserProfiles_nodes_lastName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"currentUserProfiles_nodes_firstName":{"c":"Util.CustomScalars.NonemptyString.Exn"}}};

var fragmentConverterMap = {
  "Util.CustomScalars.NonemptyString.Exn": CS_NonemptyStrings$Util.NonEmptyString.Exn.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

function custSignonStatus_decode($$enum) {
  if ($$enum === "VERIFY_EMAIL" || $$enum === "INVITED" || $$enum === "PENDING_2FA" || $$enum === "CLEAR_JWT" || $$enum === "ANONYMOUS" || $$enum === "SIGNED_ON") {
    return $$enum;
  }
  
}

function custSignonStatus_fromString(str) {
  return custSignonStatus_decode(str);
}

var Utils = {
  custSignonStatus_decode: custSignonStatus_decode,
  custSignonStatus_fromString: custSignonStatus_fromString
};

function makeNode(rescript_graphql_node_VerifyRouteSignOnQuery) {
  return ({
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "brandFamily"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": rescript_graphql_node_VerifyRouteSignOnQuery
    }
  },
  "name": "VerifyRoute_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentUserSignonStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentUserEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "brandFamilySlug",
              "variableName": "brandFamily"
            }
          ],
          "kind": "ObjectValue",
          "name": "condition"
        }
      ],
      "concreteType": "CurrentUserProfilesConnection",
      "kind": "LinkedField",
      "name": "currentUserProfiles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CurrentUserProfile",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
});
}

var node = makeNode(VerifyRouteSignOnQuery_graphql$Thin.node);

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
