// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"imageSets_nodes_setMembers_nodes_imageIdx":{"b":""},"imageSets_nodes_setMembers_nodes_cfImageId":{"b":""},"imageSets_nodes_setMembers_nodes_captionText":{"c":"Util.CustomScalars.NonemptyString.Exn"},"imageSets_nodes_setMembers_nodes_altText":{"c":"Util.CustomScalars.NonemptyString.Exn"},"imageSets_nodes_orgSlug":{"c":"Util.CustomScalars.OrgSlug.Exn"},"imageSets_nodes_imageSetSlug":{"c":"Util.CustomScalars.ImageSetSlug.Exn"},"imageSets_nodes_brandFamilySlug":{"c":"Util.CustomScalars.BrandFamilySlug.Exn"}}};

var fragmentConverterMap = {
  "Util.CustomScalars.BrandFamilySlug.Exn": CS_Slugs$Util.BrandFamilySlug.Exn.parse,
  "Util.CustomScalars.ImageSetSlug.Exn": CS_NonemptyStrings$Util.ImageSetSlug.Exn.parse,
  "Util.CustomScalars.OrgSlug.Exn": CS_Slugs$Util.OrgSlug.Exn.parse,
  "Util.CustomScalars.NonemptyString.Exn": CS_NonemptyStrings$Util.NonEmptyString.Exn.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "brandFamilySlug"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ImageSet_Prefetch_query",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "brandFamilySlug",
              "variableName": "brandFamilySlug"
            },
            {
              "kind": "Literal",
              "name": "hasPreloadPurpose",
              "value": true
            }
          ],
          "kind": "ObjectValue",
          "name": "condition"
        }
      ],
      "concreteType": "ImageSetsConnection",
      "kind": "LinkedField",
      "name": "imageSets",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ImageSet",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "imageSetSlug",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "preloadPurposes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "orgSlug",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "brandFamilySlug",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ImageSetImagesConnection",
              "kind": "LinkedField",
              "name": "setMembers",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ImageSetImage",
                  "kind": "LinkedField",
                  "name": "nodes",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "imageIdx",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "altText",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "captionText",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cfImageId",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* CS_Slugs-Util Not a pure module */
