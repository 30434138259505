// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var AppAlert$Thin = require("../../common/atoms/AppAlert.bs.js");
var Stack = require("@mui/joy/Stack").default;
var SelectorCard$Thin = require("../../common/molecules/SelectorCard.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var CurrencyInput$Thin = require("../../common/molecules/CurrencyInput.bs.js");

function CustomPaymentSelector(props) {
  var selectPaymentOption = props.selectPaymentOption;
  var sessionData = props.sessionData;
  var isSelected = props.isSelected;
  var match = React.useState(function () {
        
      });
  var error = match[0];
  var onSave = function (c, successFn) {
    selectPaymentOption(sessionData, {
          TAG: "Custom",
          _0: {
            amt: c
          }
        });
    successFn();
  };
  return JsxRuntime.jsx(SelectorCard$Thin.make, {
              isSelected: isSelected,
              onClick: props.onClick,
              title: "Pay a different amount",
              description: "Pay a custom amount",
              children: JsxRuntime.jsxs(Stack, {
                    spacing: 1,
                    sx: {
                      display: isSelected ? "flex" : "none",
                      overflow: "hidden"
                    },
                    children: [
                      JsxRuntime.jsx(Stack, {
                            direction: "column",
                            spacing: 2,
                            sx: {
                              mt: 1,
                              pt: 1
                            },
                            children: Caml_option.some(JsxRuntime.jsx(CurrencyInput$Thin.make, {
                                      minimum: props.minimum,
                                      maximum: props.maximum,
                                      setError: match[1],
                                      onSave: onSave,
                                      presetValue: sessionData.paymentOption
                                    }))
                          }),
                      error !== undefined ? JsxRuntime.jsx(AppAlert$Thin.make, {
                              message: error,
                              type_: "error"
                            }) : JsxRuntime.jsx(JsxRuntime.Fragment, {})
                    ]
                  })
            });
}

var make = CustomPaymentSelector;

exports.make = make;
/* react Not a pure module */
