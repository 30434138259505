// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var Stack = require("@mui/joy/Stack").default;
var Avatar = require("@mui/joy/Avatar").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var Check = require("@mui/icons-material/Check").default;

function ConfirmationMiniCard(props) {
  var name = props.name;
  var initial = name[0];
  return JsxRuntime.jsxs(Stack, {
              direction: "row",
              justifyContent: "space-between",
              sx: {
                p: 2,
                border: "1px solid",
                borderColor: "neutral.300",
                backgroundColor: "background.surface",
                borderRadius: "12px"
              },
              children: [
                JsxRuntime.jsxs(Stack, {
                      direction: "row",
                      spacing: 2,
                      sx: {
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                      },
                      children: [
                        JsxRuntime.jsx(Avatar, {
                              children: Caml_option.some(initial),
                              size: "lg",
                              sx: {
                                color: "background.surface",
                                backgroundColor: "black"
                              }
                            }),
                        JsxRuntime.jsx(Stack, {
                              direction: "column",
                              justifyContent: "center",
                              sx: {
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                              },
                              children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                        level: "body-md",
                                        noWrap: true,
                                        sx: {
                                          overflow: "hidden",
                                          textOverflow: "ellipsis"
                                        },
                                        fontWeight: "bold",
                                        children: Caml_option.some(name)
                                      }))
                            })
                      ]
                    }),
                JsxRuntime.jsx(Stack, {
                      direction: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      children: Caml_option.some(JsxRuntime.jsx(Check, {
                                color: "success"
                              }))
                    })
              ]
            });
}

var make = ConfirmationMiniCard;

exports.make = make;
/* @mui/joy/Stack Not a pure module */
