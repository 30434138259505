"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.onMessage = void 0;
var _1 = require("./");
function onMessage(e) {
    if ((0, _1.isntString)(e.data)) {
        return;
    }
    var payload = (0, _1.unpackPayload)(e);
    if (!payload) {
        return;
    }
    var data = payload.eventData;
    var reply = payload.reply;
    (0, _1.dispatch)("*", payload.event, data, reply, e);
    (0, _1.dispatch)(e.origin, payload.event, data, reply, e);
    (0, _1.broadcastToChildWindows)(e.data, payload.origin, e.source);
}
exports.onMessage = onMessage;
