// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../../utils/Common.bs.js");
var AppAlert$Thin = require("../common/atoms/AppAlert.bs.js");
var RelayEnv$Thin = require("../../RelayEnv.bs.js");
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var CS_Decimal$Util = require("util/src/CustomScalars/CS_Decimal.bs.js");
var ModalDrawer$Thin = require("../common/molecules/ModalDrawer.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var Block = require("@mui/icons-material/Block").default;
var RemoveSelfOrConnectedQuery_graphql$Thin = require("../../__generated__/RemoveSelfOrConnectedQuery_graphql.bs.js");
var RemoveSelfOrConnected_ApplyRemovePaxMutation_graphql$Thin = require("../../__generated__/RemoveSelfOrConnected_ApplyRemovePaxMutation_graphql.bs.js");

var convertVariables = RemoveSelfOrConnectedQuery_graphql$Thin.Internal.convertVariables;

var convertResponse = RemoveSelfOrConnectedQuery_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = RemoveSelfOrConnectedQuery_graphql$Thin.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, RemoveSelfOrConnectedQuery_graphql$Thin.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, RemoveSelfOrConnectedQuery_graphql$Thin.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(RemoveSelfOrConnectedQuery_graphql$Thin.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(RemoveSelfOrConnectedQuery_graphql$Thin.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(RemoveSelfOrConnectedQuery_graphql$Thin.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(RemoveSelfOrConnectedQuery_graphql$Thin.node, convertVariables);

var Query = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

var convertVariables$1 = RemoveSelfOrConnected_ApplyRemovePaxMutation_graphql$Thin.Internal.convertVariables;

var convertResponse$1 = RemoveSelfOrConnected_ApplyRemovePaxMutation_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse$1 = RemoveSelfOrConnected_ApplyRemovePaxMutation_graphql$Thin.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables$1, RemoveSelfOrConnected_ApplyRemovePaxMutation_graphql$Thin.node, convertResponse$1, convertWrapRawResponse$1);

var use$1 = RescriptRelay_Mutation.useMutation(convertVariables$1, RemoveSelfOrConnected_ApplyRemovePaxMutation_graphql$Thin.node, convertResponse$1, convertWrapRawResponse$1);

var ApplyRemovePaxMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables$1,
  convertResponse: convertResponse$1,
  convertWrapRawResponse: convertWrapRawResponse$1,
  commitMutation: commitMutation,
  use: use$1
};

function getPreviewVars(pax, bookingId, bookingVersionNumber) {
  var accountId = pax.accountId;
  if (accountId !== undefined) {
    return {
            _accountId: Caml_option.valFromOption(accountId),
            _bookingId: bookingId,
            _bookingVersionNumber: bookingVersionNumber,
            _idx: pax.idx
          };
  }
  
}

function getApplyInput(pax, bookingId, bookingVersionNumber) {
  var accountId = pax.accountId;
  if (accountId !== undefined) {
    return {
            _accountId: Caml_option.valFromOption(accountId),
            _bookingId: bookingId,
            _bookingVersionNumber: bookingVersionNumber,
            _idx: pax.idx
          };
  }
  
}

function useQuery() {
  var match = React.useState(function () {
        
      });
  var setError = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setPaxRemoveModalOpen = match$1[1];
  var match$2 = React.useState(function () {
        
      });
  var setFee = match$2[1];
  var match$3 = React.useState(function () {
        return true;
      });
  var setIsQuerying = match$3[1];
  var previewRemove = function (previewVars) {
    setIsQuerying(function (param) {
          return true;
        });
    $$fetch(RelayEnv$Thin.environment, previewVars, (function (res) {
            if (res.TAG === "Ok") {
              var val = res._0.previewRemovePax;
              if (val !== undefined) {
                var val$1 = Caml_option.valFromOption(val);
                setIsQuerying(function (param) {
                      return false;
                    });
                setFee(function (param) {
                      return Caml_option.some(val$1);
                    });
                return setPaxRemoveModalOpen(function (param) {
                            return true;
                          });
              }
              setIsQuerying(function (param) {
                    return false;
                  });
              setError(function (param) {
                    return "An error occurred. Please try again.";
                  });
              return setPaxRemoveModalOpen(function (param) {
                          return true;
                        });
            }
            console.error(res._0);
            setIsQuerying(function (param) {
                  return false;
                });
            setError(function (param) {
                  return "An error occurred. Please try again.";
                });
            setPaxRemoveModalOpen(function (param) {
                  return true;
                });
          }), undefined, undefined);
  };
  return {
          error: match[0],
          setError: setError,
          fee: match$2[0],
          setFee: setFee,
          isQuerying: match$3[0],
          paxRemoveModalOpen: match$1[0],
          setPaxRemoveModalOpen: setPaxRemoveModalOpen,
          previewRemove: previewRemove
        };
}

function RemoveSelfOrConnected$Modal(props) {
  var queryError = props.queryError;
  var fee = props.fee;
  var onSuccess = props.onSuccess;
  var applyInput = props.applyInput;
  var __title = props.title;
  var onClose = props.onClose;
  var title = __title !== undefined ? __title : "Remove Passenger";
  var match = use$1();
  var mutate = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setError = match$1[1];
  var error = match$1[0];
  var applyRemove = function (e) {
    e.preventDefault();
    setError(function (param) {
          
        });
    mutate({
          input: applyInput
        }, undefined, undefined, (function (store, param) {
            store.invalidateStore();
          }), (function (resp, err) {
            if (err !== undefined) {
              return setError(function (param) {
                          return "An error occurred. Please try again.";
                        });
            }
            var match = resp.applyRemovePax;
            if (match !== undefined) {
              var match$1 = match.boolean;
              if (match$1 !== undefined && match$1) {
                onSuccess();
                return onClose();
              }
              
            }
            setError(function (param) {
                  return "An error occurred. Please try again.";
                });
          }), (function (err) {
            console.error(err);
            setError(function (param) {
                  return "An error occurred. Please try again.";
                });
          }), undefined);
  };
  return JsxRuntime.jsxs(ModalDrawer$Thin.make, {
              open_: props.open_,
              title: title,
              onClose: onClose,
              children: [
                JsxRuntime.jsx(DialogContent, {
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                spacing: 2,
                                sx: {
                                  p: 2
                                },
                                children: [
                                  queryError !== undefined ? JsxRuntime.jsx(AppAlert$Thin.make, {
                                          message: queryError,
                                          type_: "error"
                                        }) : (
                                      fee !== undefined ? JsxRuntime.jsxs(Stack, {
                                              direction: "column",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              spacing: 2,
                                              children: [
                                                JsxRuntime.jsx(Stack, {
                                                      justifyContent: "center",
                                                      alignItems: "center",
                                                      sx: {
                                                        width: "48px",
                                                        height: "48px"
                                                      },
                                                      children: Caml_option.some(JsxRuntime.jsx(Block, {
                                                                color: "danger",
                                                                sx: {
                                                                  width: "100%",
                                                                  height: "100%"
                                                                }
                                                              }))
                                                    }),
                                                JsxRuntime.jsx(Typography, {
                                                      level: "h2",
                                                      component: "h3",
                                                      children: "Remove from passenger list"
                                                    }),
                                                JsxRuntime.jsx(Typography, {
                                                      level: "body-sm",
                                                      sx: {
                                                        color: "text.tertiary"
                                                      },
                                                      children: Caml_option.some("Are you sure you want to remove " + (
                                                            props.isSelf ? "yourself" : Belt_Option.getWithDefault(props.name, "this passenger")
                                                          ) + " from booking " + CS_NonemptyStrings$Util.BookingId.toString(applyInput._bookingId) + "? Please note that, once removed, you will no longer have access, and it will incur a fee of " + Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(Caml_option.valFromOption(fee))) + ".")
                                                    })
                                              ]
                                            }) : null
                                    ),
                                  error !== undefined ? JsxRuntime.jsx(AppAlert$Thin.make, {
                                          message: error,
                                          type_: "error"
                                        }) : null
                                ]
                              }))
                    }),
                queryError !== undefined ? null : JsxRuntime.jsx(DialogActions, {
                        children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                  direction: {
                                    xs: "column-reverse",
                                    sm: "row"
                                  },
                                  component: "form",
                                  justifyContent: "space-between",
                                  spacing: 1,
                                  sx: {
                                    width: "100%",
                                    p: {
                                      xs: 2,
                                      md: 0
                                    }
                                  },
                                  onSubmit: applyRemove,
                                  children: [
                                    JsxRuntime.jsx(Button, {
                                          onClick: onClose,
                                          variant: "outlined",
                                          color: "neutral",
                                          children: "Cancel"
                                        }),
                                    JsxRuntime.jsx(Button, {
                                          loading: match[1],
                                          type: "submit",
                                          children: "Yes, remove"
                                        })
                                  ]
                                }))
                      })
              ]
            });
}

var Modal = {
  make: RemoveSelfOrConnected$Modal
};

exports.Query = Query;
exports.ApplyRemovePaxMutation = ApplyRemovePaxMutation;
exports.getPreviewVars = getPreviewVars;
exports.getApplyInput = getApplyInput;
exports.useQuery = useQuery;
exports.Modal = Modal;
/* use Not a pure module */
