// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var ReactRelay = require("react-relay");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var CS_SQLDatesAndTimes$Util = require("util/src/CustomScalars/CS_SQLDatesAndTimes.bs.js");

function makeRefetchVariables(acctId) {
  return {
          acctId: acctId
        };
}

var Types = {
  makeRefetchVariables: makeRefetchVariables
};

var variablesConverter = {"__root":{"acctId":{"c":"Util.CustomScalars.AccountId.Exn"}}};

var variablesConverterMap = {
  "Util.CustomScalars.AccountId.Exn": CS_NonemptyStrings$Util.AccountId.Exn.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {"__root":{"currentUserConnectedAccounts_nodes_passenger_record_nodes_bookings_bookingListitem_voyage_voyageSlug":{"c":"Util.CustomScalars.VoyageSlug.Exn"},"currentUserConnectedAccounts_nodes_passenger_record_nodes_bookings_bookingListitem_voyage_voyageName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"currentUserConnectedAccounts_nodes_passenger_record_nodes_bookings_bookingListitem_voyage_voyStartDate":{"c":"Util.CustomScalars.SQLDate.Exn"},"currentUserConnectedAccounts_nodes_passenger_record_nodes_bookings_bookingListitem_voyage_voyEndDate":{"c":"Util.CustomScalars.SQLDate.Exn"},"currentUserConnectedAccounts_nodes_passenger_record_nodes_bookings_bookingListitem_voyage_imageSetSlug":{"c":"Util.CustomScalars.ImageSetSlug.Exn"}}};

var wrapResponseConverterMap = {
  "Util.CustomScalars.ImageSetSlug.Exn": CS_NonemptyStrings$Util.ImageSetSlug.Exn.serialize,
  "Util.CustomScalars.SQLDate.Exn": CS_SQLDatesAndTimes$Util.DateOnly.Exn.serialize,
  "Util.CustomScalars.NonemptyString.Exn": CS_NonemptyStrings$Util.NonEmptyString.Exn.serialize,
  "Util.CustomScalars.VoyageSlug.Exn": CS_Slugs$Util.VoyageSlug.Exn.serialize
};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, wrapResponseConverterMap, null);
}

var responseConverter = {"__root":{"currentUserConnectedAccounts_nodes_passenger_record_nodes_bookings_bookingListitem_voyage_voyageSlug":{"c":"Util.CustomScalars.VoyageSlug.Exn"},"currentUserConnectedAccounts_nodes_passenger_record_nodes_bookings_bookingListitem_voyage_voyageName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"currentUserConnectedAccounts_nodes_passenger_record_nodes_bookings_bookingListitem_voyage_voyStartDate":{"c":"Util.CustomScalars.SQLDate.Exn"},"currentUserConnectedAccounts_nodes_passenger_record_nodes_bookings_bookingListitem_voyage_voyEndDate":{"c":"Util.CustomScalars.SQLDate.Exn"},"currentUserConnectedAccounts_nodes_passenger_record_nodes_bookings_bookingListitem_voyage_imageSetSlug":{"c":"Util.CustomScalars.ImageSetSlug.Exn"}}};

var responseConverterMap = {
  "Util.CustomScalars.ImageSetSlug.Exn": CS_NonemptyStrings$Util.ImageSetSlug.Exn.parse,
  "Util.CustomScalars.SQLDate.Exn": CS_SQLDatesAndTimes$Util.DateOnly.Exn.parse,
  "Util.CustomScalars.NonemptyString.Exn": CS_NonemptyStrings$Util.NonEmptyString.Exn.parse,
  "Util.CustomScalars.VoyageSlug.Exn": CS_Slugs$Util.VoyageSlug.Exn.parse
};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, responseConverterMap, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: wrapResponseConverterMap,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: responseConverterMap,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

function lifecycleStatus_decode($$enum) {
  if ($$enum === "CANCELED" || $$enum === "STAGED" || $$enum === "DRAFT" || $$enum === "ACTIVE" || $$enum === "HISTORY") {
    return $$enum;
  }
  
}

function lifecycleStatus_fromString(str) {
  return lifecycleStatus_decode(str);
}

var Utils = {
  lifecycleStatus_decode: lifecycleStatus_decode,
  lifecycleStatus_fromString: lifecycleStatus_fromString
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "acctId"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "accountId",
        "variableName": "acctId"
      }
    ],
    "kind": "ObjectValue",
    "name": "condition"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "filter",
    "value": {
      "bookings": {
        "lifecycleStatus": {
          "equalTo": "ACTIVE"
        }
      }
    }
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lifecycleStatus",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voyStartDate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voyEndDate",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voyageSlug",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voyageName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageSetSlug",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FetchOwnOrConnectedUserVoyagesConnectedAccountsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CurrentUserConnectedAccountsConnection",
        "kind": "LinkedField",
        "name": "currentUserConnectedAccounts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CurrentUserConnectedAccount",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "BookingPassengersConnection",
                "kind": "LinkedField",
                "name": "passenger_record",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BookingPassenger",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BookingDetail",
                        "kind": "LinkedField",
                        "name": "bookings",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BookingListitem",
                            "kind": "LinkedField",
                            "name": "bookingListitem",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "BrandFamilyVoyage",
                                "kind": "LinkedField",
                                "name": "voyage",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/),
                                  (v5/*: any*/),
                                  (v6/*: any*/),
                                  (v7/*: any*/),
                                  (v8/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "passenger_record(filter:{\"bookings\":{\"lifecycleStatus\":{\"equalTo\":\"ACTIVE\"}}})"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FetchOwnOrConnectedUserVoyagesConnectedAccountsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CurrentUserConnectedAccountsConnection",
        "kind": "LinkedField",
        "name": "currentUserConnectedAccounts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CurrentUserConnectedAccount",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "BookingPassengersConnection",
                "kind": "LinkedField",
                "name": "passenger_record",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BookingPassenger",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BookingDetail",
                        "kind": "LinkedField",
                        "name": "bookings",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BookingListitem",
                            "kind": "LinkedField",
                            "name": "bookingListitem",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "BrandFamilyVoyage",
                                "kind": "LinkedField",
                                "name": "voyage",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/),
                                  (v5/*: any*/),
                                  (v6/*: any*/),
                                  (v7/*: any*/),
                                  (v8/*: any*/),
                                  (v9/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v9/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "passenger_record(filter:{\"bookings\":{\"lifecycleStatus\":{\"equalTo\":\"ACTIVE\"}}})"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "33439357cea2e5d495b887718bfcffd7",
    "id": null,
    "metadata": {},
    "name": "FetchOwnOrConnectedUserVoyagesConnectedAccountsQuery",
    "operationKind": "query",
    "text": "query FetchOwnOrConnectedUserVoyagesConnectedAccountsQuery(\n  $acctId: AccountId!\n) {\n  currentUserConnectedAccounts(condition: {accountId: $acctId}) {\n    nodes {\n      passenger_record(filter: {bookings: {lifecycleStatus: {equalTo: ACTIVE}}}) {\n        nodes {\n          bookings {\n            lifecycleStatus\n            bookingListitem {\n              voyage {\n                voyStartDate\n                voyEndDate\n                voyageSlug\n                voyageName\n                imageSetSlug\n                id\n              }\n              id\n            }\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})());

function load(environment, variables, fetchPolicy, fetchKey, networkCacheConfig) {
  return ReactRelay.loadQuery(environment, node, convertVariables(variables), {
              fetchKey: fetchKey,
              fetchPolicy: fetchPolicy,
              networkCacheConfig: networkCacheConfig
            });
}

function queryRefToObservable(token) {
  return Caml_option.nullable_to_opt(token.source);
}

function queryRefToPromise(token) {
  return new Promise((function (resolve, param) {
                var o = queryRefToObservable(token);
                if (o !== undefined) {
                  Caml_option.valFromOption(o).subscribe({
                        complete: (function () {
                            resolve({
                                  TAG: "Ok",
                                  _0: undefined
                                });
                          })
                      });
                  return ;
                } else {
                  return resolve({
                              TAG: "Error",
                              _0: undefined
                            });
                }
              }));
}

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
exports.load = load;
exports.queryRefToObservable = queryRefToObservable;
exports.queryRefToPromise = queryRefToPromise;
/* node Not a pure module */
