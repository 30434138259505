// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../../../../utils/Common.bs.js");
var Link = require("@mui/joy/Link").default;
var AppAlert$Thin = require("../../atoms/AppAlert.bs.js");
var Stack = require("@mui/joy/Stack").default;
var PlaidFlow$Thin = require("./PlaidFlow.bs.js");
var SelectorCard$Thin = require("../SelectorCard.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var BraintreeFlow$Thin = require("./BraintreeFlow.bs.js");
var Typography = require("@mui/joy/Typography").default;
var PlaidTermsModal$Thin = require("./PlaidTermsModal.bs.js");
var BraintreeTermsModal$Thin = require("./BraintreeTermsModal.bs.js");

function PaymentMethodSelectors$PaymentMethodWrapper(props) {
  var selectedPaymentMethod = props.selectedPaymentMethod;
  var setChoice = selectedPaymentMethod[1];
  var choice = selectedPaymentMethod[0];
  var clearer = props.clearer;
  var bookingId = props.bookingId;
  var setCalculatedFee = props.setCalculatedFee;
  var calculatedFee = props.calculatedFee;
  var locked = props.locked;
  var paymentReturn = props.paymentReturn;
  var setPaymentAction = props.setPaymentAction;
  var payment = props.payment;
  var supported = props.supported;
  var onSelect = function (param) {
    if (locked) {
      return ;
    } else {
      setPaymentAction(function (param) {
            
          });
      return setChoice(function (param) {
                  return supported;
                });
    }
  };
  React.useEffect((function () {
          if (clearer !== undefined) {
            setChoice(function (param) {
                  
                });
          }
          
        }), [clearer]);
  var isSelected = choice !== undefined ? Caml_obj.equal(choice, supported) : false;
  var subflow = props.getSubflow(supported);
  var getCalcFeeText = function (calculatedFee) {
    if (calculatedFee !== undefined) {
      return " (" + Common$Thin.formatCurrency(calculatedFee / 100.0) + "). ";
    } else {
      return ". ";
    }
  };
  var match = React.useState(function () {
        return false;
      });
  var setBraintreeTermsModalOpen = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setPlaidTermsModalOpen = match$1[1];
  var ccSubEl = function (calculatedFee) {
    return JsxRuntime.jsxs(Typography, {
                level: "body-sm",
                sx: {
                  color: "neutral.500"
                },
                children: [
                  "Incurs a 3.5% fee" + getCalcFeeText(calculatedFee),
                  JsxRuntime.jsx(Link, {
                        children: "More info.",
                        onClick: (function (e) {
                            e.stopPropagation();
                            setBraintreeTermsModalOpen(function (param) {
                                  return true;
                                });
                          }),
                        sx: {
                          textDecoration: "underline"
                        }
                      })
                ]
              });
  };
  var bankSubEl = JsxRuntime.jsxs(Typography, {
        level: "body-sm",
        sx: {
          color: "neutral.500"
        },
        children: [
          "Connect to your bank account. ",
          JsxRuntime.jsx(Link, {
                children: "More info.",
                onClick: (function (e) {
                    e.stopPropagation();
                    setPlaidTermsModalOpen(function (param) {
                          return true;
                        });
                  }),
                sx: {
                  textDecoration: "underline"
                }
              })
        ]
      });
  if (subflow.TAG === "BraintreeHostedFields") {
    return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  JsxRuntime.jsx(SelectorCard$Thin.make, {
                        isSelected: isSelected,
                        onClick: onSelect,
                        title: "Credit card",
                        subEl: Caml_option.some(ccSubEl(calculatedFee)),
                        children: JsxRuntime.jsx(Stack, {
                              spacing: 1,
                              sx: {
                                display: isSelected ? "flex" : "none",
                                overflow: "hidden"
                              },
                              children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                        direction: "column",
                                        spacing: 2,
                                        sx: {
                                          mt: 1,
                                          pt: 1
                                        },
                                        onClick: (function (e) {
                                            e.stopPropagation();
                                          }),
                                        children: Caml_option.some(JsxRuntime.jsx(BraintreeFlow$Thin.make, {
                                                  useHostedFields: subflow.useHostedFields,
                                                  payment: payment,
                                                  bookingId: bookingId,
                                                  isSelected: isSelected,
                                                  setPaymentAction: setPaymentAction,
                                                  paymentReturn: paymentReturn,
                                                  setCalculatedFee: setCalculatedFee
                                                }))
                                      }))
                            }),
                        locked: locked
                      }),
                  JsxRuntime.jsx(BraintreeTermsModal$Thin.make, {
                        open_: match[0],
                        onClose: (function () {
                            setBraintreeTermsModalOpen(function (param) {
                                  return false;
                                });
                          }),
                        calculatedFee: calculatedFee,
                        payment: payment
                      })
                ]
              });
  } else {
    return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  JsxRuntime.jsx(SelectorCard$Thin.make, {
                        isSelected: isSelected,
                        onClick: onSelect,
                        title: "Bank transfer",
                        subEl: Caml_option.some(bankSubEl),
                        children: JsxRuntime.jsx(Stack, {
                              spacing: 1,
                              sx: {
                                display: isSelected ? "flex" : "none",
                                overflow: "hidden"
                              },
                              children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                        direction: "column",
                                        spacing: 2,
                                        sx: {
                                          mt: 1,
                                          pt: 1
                                        },
                                        children: Caml_option.some(JsxRuntime.jsx(PlaidFlow$Thin.make, {
                                                  usePlaidDebit: subflow.usePlaidDebit,
                                                  payment: payment,
                                                  bookingId: bookingId,
                                                  isSelected: isSelected,
                                                  setPaymentAction: setPaymentAction,
                                                  paymentReturn: paymentReturn,
                                                  setCalculatedFee: setCalculatedFee,
                                                  needConfirm: props.needConfirm
                                                }))
                                      }))
                            }),
                        locked: locked
                      }),
                  JsxRuntime.jsx(PlaidTermsModal$Thin.make, {
                        open_: match$1[0],
                        onClose: (function () {
                            setPlaidTermsModalOpen(function (param) {
                                  return false;
                                });
                          })
                      })
                ]
              });
  }
}

var PaymentMethodWrapper = {
  make: PaymentMethodSelectors$PaymentMethodWrapper
};

function PaymentMethodSelectors(props) {
  var match = props.flow;
  var supportedProcessors = match.supportedProcessors;
  var clearer = props.clearer;
  var bookingId = props.bookingId;
  var needConfirm = props.needConfirm;
  var locked = props.locked;
  var setCalculatedFee = props.setCalculatedFee;
  var calculatedFee = props.calculatedFee;
  var paymentReturn = props.paymentReturn;
  var setPaymentAction = props.setPaymentAction;
  var payment = props.payment;
  var selectedPaymentMethod = match.selectedPaymentMethod;
  var getSubflow = match.getSubflow;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                Belt_Array.mapWithIndex(supportedProcessors.avails, (function (i, supported) {
                        return JsxRuntime.jsx(PaymentMethodSelectors$PaymentMethodWrapper, {
                                    supported: supported,
                                    getSubflow: getSubflow,
                                    selectedPaymentMethod: selectedPaymentMethod,
                                    payment: payment,
                                    setPaymentAction: setPaymentAction,
                                    paymentReturn: paymentReturn,
                                    locked: locked,
                                    calculatedFee: calculatedFee,
                                    setCalculatedFee: setCalculatedFee,
                                    needConfirm: needConfirm,
                                    bookingId: bookingId,
                                    clearer: clearer
                                  }, String(i));
                      })),
                supportedProcessors.browserUnsupported.length !== 0 ? JsxRuntime.jsx(AppAlert$Thin.make, {
                        message: "One or more payment methods are not supported in your browser.",
                        type_: "warning"
                      }) : null
              ]
            });
}

var make = PaymentMethodSelectors;

exports.PaymentMethodWrapper = PaymentMethodWrapper;
exports.make = make;
/* react Not a pure module */
