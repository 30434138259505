// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var AppAlert$Thin = require("../../../common/atoms/AppAlert.bs.js");
var Stack = require("@mui/joy/Stack").default;
var JsxRuntime = require("react/jsx-runtime");
var PaymentProcessor$Thin = require("../../../../utils/payments/PaymentProcessor.bs.js");
var CircularProgress = require("@mui/joy/CircularProgress").default;
var PaymentMethodSelectors$Thin = require("../../../common/molecules/payment-modules/PaymentMethodSelectors.bs.js");

function BDPaymentMethodSelection(props) {
  var amount = props.amount;
  var flow = PaymentProcessor$Thin.usePaymentProcessor(props.voyageSlug, "");
  var tmp;
  var exit = 0;
  if (typeof flow !== "object") {
    exit = 1;
  } else {
    tmp = flow.TAG === "Success" ? JsxRuntime.jsx(PaymentMethodSelectors$Thin.make, {
            flow: flow._0,
            payment: {
              TAG: "Manage",
              _0: amount
            },
            setPaymentAction: props.setPaymentAction,
            paymentReturn: props.paymentReturn,
            calculatedFee: props.calculatedFee,
            setCalculatedFee: props.setCalculatedFee,
            locked: Belt_Option.isNone(amount),
            needConfirm: true,
            bookingId: Caml_option.some(props.bookingId),
            clearer: props.clearer
          }) : JsxRuntime.jsx(AppAlert$Thin.make, {
            message: "An error occurred fetching payment methods. Please try again.",
            type_: "error"
          });
  }
  if (exit === 1) {
    tmp = JsxRuntime.jsx(Stack, {
          direction: "row",
          justifyContent: "center",
          children: Caml_option.some(JsxRuntime.jsx(CircularProgress, {
                    size: "md",
                    variant: "solid"
                  }))
        });
  }
  return JsxRuntime.jsx(Stack, {
              direction: "column",
              spacing: 2,
              children: Caml_option.some(tmp)
            });
}

var make = BDPaymentMethodSelection;

exports.make = make;
/* AppAlert-Thin Not a pure module */
