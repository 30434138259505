// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Txt$Thin = require("../../../text/Txt.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var ClaimInvitationSubmit_ClaimInvitationWithTokenMutation_graphql$Thin = require("../../../__generated__/ClaimInvitationSubmit_ClaimInvitationWithTokenMutation_graphql.bs.js");

var convertVariables = ClaimInvitationSubmit_ClaimInvitationWithTokenMutation_graphql$Thin.Internal.convertVariables;

var convertResponse = ClaimInvitationSubmit_ClaimInvitationWithTokenMutation_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = ClaimInvitationSubmit_ClaimInvitationWithTokenMutation_graphql$Thin.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, ClaimInvitationSubmit_ClaimInvitationWithTokenMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, ClaimInvitationSubmit_ClaimInvitationWithTokenMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var ClaimInvitationWithTokenMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation() {
  var match = use();
  var mutate = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var setPassword = match$1[1];
  var password = match$1[0];
  var match$2 = React.useState(function () {
        return "";
      });
  var setConfirmPassword = match$2[1];
  var confirmPassword = match$2[0];
  var match$3 = React.useState(function () {
        
      });
  var setError = match$3[1];
  var match$4 = React.useState(function () {
        return false;
      });
  var setClaimSuccess = match$4[1];
  var onSubmit = function (e, token) {
    e.preventDefault();
    if (password === "" || confirmPassword === "" || password !== confirmPassword) {
      return ;
    } else {
      setError(function (param) {
            
          });
      mutate({
            input: {
              allegedToken: token,
              newPasswd: password
            }
          }, undefined, undefined, undefined, (function (resp, err) {
              if (err === undefined) {
                var match = resp.claimInvitationWithToken;
                var exit = 0;
                if (match !== undefined) {
                  var match$1 = match.boolean;
                  if (match$1 !== undefined && match$1) {
                    setClaimSuccess(function (param) {
                          return true;
                        });
                  } else {
                    exit = 1;
                  }
                } else {
                  exit = 1;
                }
                if (exit === 1) {
                  setError(function (param) {
                        return Txt$Thin.Claim.passwordSetError.value;
                      });
                }
                
              } else {
                setError(function (param) {
                      return Txt$Thin.Claim.passwordSetError.value;
                    });
              }
              setPassword(function (param) {
                    return "";
                  });
              setConfirmPassword(function (param) {
                    return "";
                  });
            }), (function (err) {
              console.log(err);
              setError(function (param) {
                    return Txt$Thin.$$Error.genericFormSubmit.value;
                  });
              setPassword(function (param) {
                    return "";
                  });
              setConfirmPassword(function (param) {
                    return "";
                  });
            }), undefined);
      return ;
    }
  };
  return {
          isMutating: match[1],
          password: password,
          setPassword: setPassword,
          confirmPassword: confirmPassword,
          setConfirmPassword: setConfirmPassword,
          onSubmit: onSubmit,
          error: match$3[0],
          claimSuccess: match$4[0]
        };
}

exports.ClaimInvitationWithTokenMutation = ClaimInvitationWithTokenMutation;
exports.useMutation = useMutation;
/* commitMutation Not a pure module */
